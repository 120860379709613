import { TFunction } from "i18next";
import { toLocaleString } from "../utils";
import { ImageUrls } from "./TreatmentsGraph";

export const translates6MWT = [
  ["tensiometerRead1", "translateImageTensiometerRead1"],
  ["pulsioximeterRead1", "translateImagePulsioximeterRead1"],
  ["tensiometerRead2", "translateImageTensiometerRead2"],
  ["pulsioximeterRead2", "translateImagePulsioximeterRead2"],
  ["pulsioximeterRead3", "translateImagePulsioximeterRead3"],
];

export const formatValues = (value: string): string => {
  let formatted: string = value ? value.toString() : "";

  // ? https://stackoverflow.com/questions/1433212/replace-method-doesnt-work
  if (formatted.includes('"') || formatted.includes("`")) {
    formatted = formatted.replace(/["]/g, `'`).replace(/[']/g, "'");

    // ? https://stackoverflow.com/questions/2903542/javascript-how-many-times-a-character-occurs-in-a-string
    const quotationMarks = formatted.replace(/[^']/g, "").length;

    // Remove the second quotation mark to avoid split item in two columns
    if (quotationMarks % 2 !== 0) {
      const firstIndex = formatted.indexOf("'");
      const secondIndex = formatted.indexOf("'", firstIndex + 1);
      formatted =
        formatted.substring(0, secondIndex - 1) +
        formatted.substring(secondIndex, formatted.length);
    }
  }
  if (formatted.includes(",")) {
    formatted = `"${formatted}"`;
  }

  return formatted;
};

export const getValues6Mwt = (
  element: any,
  m: any,
  t: TFunction<"translation", undefined>,
  imageUrls?: ImageUrls[],
  fromTreatmentsGraph = false
) => {
  const formattedMeasurements: any[] = [];

  const pushMeasurement = (
    measurementType: string,
    value: any,
    unit: string
  ) => {
    if (fromTreatmentsGraph) {
      formattedMeasurements.push({
        id: m ? m.id : "****",
        sign: formatValues(measurementType),
        value: formatValues(value),
        unit: formatValues(unit),
        image: formatValues("--"),
        device: formatValues("6mwt"),
        createdAt: m
          ? formatValues(toLocaleString(new Date(m.createdAt)))
          : "****",
      });
    } else {
      formattedMeasurements.push({
        trackingName: element.name,
        patientId: element.patient ? element.patient.id : null,
        patientName: element.patient ? element.patient.name : null,
        id: m.id,
        sign: formatValues(measurementType),
        value: formatValues(value),
        unit: formatValues(unit),
        readDeviceParentId: formatValues(m.id),
        device: formatValues("6mwt"),
        createdAt: formatValues(toLocaleString(new Date(m.createdAt))),
      });
    }
  };

  for (const translate of translates6MWT) {
    if (m[translate[0]].measurements?.length > 0) {
      m[translate[0]].measurements?.forEach((me: any) => {
        if (fromTreatmentsGraph) {
          formattedMeasurements.push({
            id: me.id,
            sign: formatValues(t(me.sign.name)),
            value: formatValues(me.value.toString()),
            unit: formatValues(me.sign.unit),
            image:
              imageUrls?.find(
                (imageUrl) => imageUrl.readDeviceId === m[translate[0]].id
              )?.url ?? formatValues("--"),
            device: formatValues(translate[0]),
            createdAt: formatValues(toLocaleString(new Date(me.created_at))),
          });
        } else {
          Promise.all(
            m[translate[0]].measurements?.forEach((me: any) => {
              formattedMeasurements.push({
                trackingName: element.name,
                patientId: element.patient.id,
                patientName: element.patient.name,
                id: me.id,
                sign: formatValues(t(me.sign.name)),
                value: formatValues(me.value.toString()),
                unit: formatValues(me.sign.unit),
                readDeviceParentId: formatValues(m.id),
                device: formatValues(translate[0]),
                createdAt: formatValues(
                  toLocaleString(new Date(me.created_at))
                ),
              });
            })
          );
        }
      });
    }
  }

  pushMeasurement("Distance", m.distance, "m");
  pushMeasurement("Pain1", m.painForm1, "--");
  pushMeasurement("Pain2", m.painForm2, "--");
  pushMeasurement("Breath1", m.breathForm1, "--");
  pushMeasurement("Breath2", m.breathForm1, "--");

  return formattedMeasurements;
};
