import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  PopoverOrigin,
  ListItem,
  Button,
  Stack,
  useMediaQuery,
  Drawer,
  SelectChangeEvent,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Role, UpdateProfileRequestDto } from "@doctomatic/sdk/build/dto/User";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { BreadcrumbNameMap } from "../../breadcrumbMap";
import { processError } from "../../App/errorToast";
import MenuIcon from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import { Theme } from "@mui/material/styles";
import classnames from "classnames";
import { LanguageSelector } from "@doctomatic/components-react/build/LanguageSelector/LanguageSelector";

const Appbar = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useProfile, useCompany, logout, role, companyId } = useApi();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const { response: profileResponse, update } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleLogoError = () => {
    setImageError(true);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const anchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "center",
  };
  const profile = profileResponse?.data;
  const company = companyResponse?.data;

  const disablePointerEvents = () => {
    const iframeElement = document.getElementById("freshwidget-embedded-form");
    if (iframeElement) {
      iframeElement.style.pointerEvents = "none";
    }
  };

  const enablePointerEvents = () => {
    const iframeElement = document.getElementById("freshwidget-embedded-form");
    if (iframeElement) {
      iframeElement.style.pointerEvents = "auto";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    enablePointerEvents();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    disablePointerEvents();
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("management");
    logout();
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // TODO: move to a proper hook/service so it can be use in different places
  const getI18nRole = (role: Role) => {
    // if no role present - return empty string
    if (!role) {
      return "";
    }
    // Transform role to have first char upper case
    const capitalisedRole: string = `${role
      .charAt(0)
      .toUpperCase()}${role.substring(1)}`;

    // Generate localise key
    const localiseRole: string = `Role${capitalisedRole}`;

    // Obtain i18n
    const i18nRole: string = t(localiseRole);

    return i18nRole;
  };

  const i18nRole = getI18nRole(role);

  const handleLanguageChange = async (lang: string) => {
    handleClose();
    i18n.changeLanguage(lang);
    try {
      const user = new UpdateProfileRequestDto();
      user.language = lang;

      await update(user);
    } catch (err: any) {
      toast.error(
        `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
      );
    }
  };

  useEffect(() => {
    if (profile) i18n.changeLanguage(profile.language);
  }, [profile, i18n]);

  if (!profile || !company) return <></>;
  const management =
    localStorage.getItem("management") === "true" ? true : false;

  // mostraré botones de menú si:
  //  el usuario tiene password
  //  en caso de ser paciente, ha aceptado el contrato
  //  en caso de ser paciente, ha de tener subscription o la empresa no tener StripeId
  //  no estoy en la página de recover password
  const checkSubscription: boolean =
    ((profile as GetPatientResponseDto).mustHaveSubscription &&
      (profile as GetPatientResponseDto).hasSubscription) ||
    (!(profile as GetPatientResponseDto).mustHaveSubscription &&
      !(profile as GetPatientResponseDto).hasSubscription);

  const darkenColor = (color: string, amount: number) => {
    // Convertir el color a un formato adecuado para trabajar con él
    color = color.replace("#", "");

    // Convertir el color a RGB
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    // Calcular la cantidad de oscurecimiento
    const newR = Math.round(r * (1 - amount));
    const newG = Math.round(g * (1 - amount));
    const newB = Math.round(b * (1 - amount));

    // Convertir el nuevo color a hexadecimal
    const newColor =
      "#" +
      newR.toString(16).padStart(2, "0") +
      newG.toString(16).padStart(2, "0") +
      newB.toString(16).padStart(2, "0");

    return newColor;
  };

  function getContrastColor(backgroundColor: string | undefined) {
    if (!backgroundColor) return "#000";

    // Convert the hexadecimal color code to RGB format
    const hexColor = backgroundColor.replace("#", "");
    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the relative luminance of the color
    const relativeLuminance =
      (red * 0.299 + green * 0.587 + blue * 0.114) / 255;

    // If the relative luminance is less than 0.5, return white as the text color, otherwise return black
    return relativeLuminance < 0.5 ? "#fff" : "#000";
  }

  const isActive = (url: string) => {
    return url === location.pathname;
  };

  const showButtons =
    (management ? true : (profile as GetPatientResponseDto).hasPassword) &&
    (role === Role.user
      ? (profile as GetPatientResponseDto).acceptedContract
      : true) &&
    (role === Role.user ? checkSubscription || !company.stripeAccount : true) &&
    (role === Role.user
      ? (profile as GetPatientResponseDto).acceptedConsent
      : true) &&
    location.pathname !== BreadcrumbNameMap.Recover.url;

  const buttons = [
    {
      role: Role.admin,
      label: t("HeaderAdministration"),
      url: BreadcrumbNameMap.Administration.url,
    },
    {
      role: Role.doctor,
      label: t("HeaderPatients"),
      url: BreadcrumbNameMap.Patients.url,
    },
    {
      role: Role.doctor,
      label: t("HeaderLocations"),
      url: BreadcrumbNameMap.Locations.url,
    },
    {
      role: Role.doctor,
      label: t("HeaderAlerts"),
      url: BreadcrumbNameMap.Alerts.url,
    },
    {
      role: Role.doctor,
      label: t("HeaderTreatments"),
      url: BreadcrumbNameMap.Trackings.url,
    },
    {
      role: Role.doctor,
      label: t("HeaderAdministration"),
      url: BreadcrumbNameMap.Administration.url,
    },
    {
      role: Role.nurse,
      label: t("HeaderPatients"),
      url: BreadcrumbNameMap.Patients.url,
    },
    {
      role: Role.nurse,
      label: t("HeaderLocations"),
      url: BreadcrumbNameMap.Locations.url,
    },
    {
      role: Role.nurse,
      label: t("HeaderAlerts"),
      url: BreadcrumbNameMap.Alerts.url,
    },
    {
      role: Role.nurse,
      label: t("HeaderTreatments"),
      url: BreadcrumbNameMap.Trackings.url,
    },
    {
      role: Role.nurse,
      label: t("HeaderAdministration"),
      url: BreadcrumbNameMap.Administration.url,
    },
    {
      role: Role.user,
      label: t("HeaderTreatments"),
      url: BreadcrumbNameMap.Trackings.url,
    },
    {
      role: Role.user,
      label: t("Devices"),
      url: BreadcrumbNameMap.Devices.url,
    },
  ];

  const renderedButtons = buttons
    .filter((button) => button.role === role)
    .map((button, index) => (
      <NavLink
        key={index}
        className={isMobile ? classes.linkMobile : classes.link}
        to={
          searchParams ? `${button.url}?${searchParams.toString()}` : button.url
        }
      >
        {button.label}
      </NavLink>
    ));

  const burgerMenu = (
    <>
      <IconButton
        color="inherit"
        edge="start"
        onClick={handleMobileMenuToggle}
        style={{ marginRight: "20px" }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={handleMobileMenuToggle}
      >
        <Box p={3} width="240px" textAlign="center" role="presentation">
          <div
            style={{
              display: "flex",
              marginTop: "-12px",
              marginRight: "-12px",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              className={classes.closeButton}
              onClick={handleMobileMenuToggle}
            >
              <Close />
            </Button>
          </div>
          {showButtons &&
            buttons
              .filter((button) => button.role === role)
              .map((button, index) => (
                <NavLink
                  key={index}
                  className={isMobile ? classes.linkMobile : classes.link}
                  to={
                    searchParams
                      ? `${button.url}?${searchParams.toString()}`
                      : button.url
                  }
                >
                  <Button
                    key={index}
                    component="button"
                    variant="contained"
                    className={classes.menuButtonMobile}
                    onClick={handleMobileMenuToggle}
                    style={
                      isActive(button.url)
                        ? {
                            backgroundColor: darkenColor(
                              company?.primary || "#b8e0ba",
                              0.2
                            ),
                            color: getContrastColor(
                              company?.primary || "#b8e0ba"
                            ),
                          }
                        : company?.primary
                        ? {
                            backgroundColor: company?.primary,
                            color: getContrastColor(company?.primary),
                          }
                        : {
                            backgroundColor: "#b8e0ba",
                          }
                    }
                  >
                    {button.label}
                  </Button>
                </NavLink>
              ))}
        </Box>
      </Drawer>
    </>
  );

  const companyNameLogo = (
    <>
      {company?.logo && !imageError ? (
        <Box
          mr={2}
          component={Link}
          to="/"
          className={classnames({
            [classes.title]: isMobile,
          })}
        >
          <img
            src={company?.logo}
            onError={handleLogoError}
            style={{ height: 40, width: "auto" }}
          />
        </Box>
      ) : (
        <Box
          mr={4}
          className={classnames({
            [classes.title]: isMobile,
          })}
        >
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.label}
          >
            {company?.name || "Doctomatic"}
          </Typography>
        </Box>
      )}
    </>
  );

  const handleSelectLanguage = (e: SelectChangeEvent<string>) => {
    handleLanguageChange(e.target.value as string);
  };

  return (
    <>
      {
        <AppBar position="static">
          <Toolbar>
            {isMobile ? (
              <>
                {burgerMenu}
                {companyNameLogo}
              </>
            ) : (
              <>
                {companyNameLogo}
                <div className={classes.title}>
                  {showButtons && renderedButtons}
                </div>
              </>
            )}
            <IconButton onClick={handleProfileMenuOpen} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={anchorOrigin}
            >
              <ListItem>
                <Box
                  textAlign="center"
                  fontWeight="700"
                  style={{ width: "100%" }}
                >
                  {profile?.name}
                  <br />
                  {profile?.email}
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  textAlign="center"
                  fontWeight="700"
                  fontStyle="italic"
                  style={{ width: "100%" }}
                >
                  {i18nRole}
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  textAlign="center"
                  fontWeight="400"
                  style={{ width: "100%" }}
                >
                  <Stack spacing={2}>
                    <NavLink
                      onClick={handleClose}
                      className={classes.menuLink}
                      to={
                        searchParams
                          ? `${
                              BreadcrumbNameMap.Profile.url
                            }?${searchParams.toString()}`
                          : BreadcrumbNameMap.Profile.url
                      }
                    >
                      {t("HeaderSettings")}
                    </NavLink>
                    <NavLink
                      onClick={handleClose}
                      className={classes.menuLink}
                      to={
                        searchParams
                          ? `${
                              BreadcrumbNameMap.About.url
                            }?${searchParams.toString()}`
                          : BreadcrumbNameMap.Profile.url
                      }
                    >
                      {t("HeaderAbout")}
                    </NavLink>
                    <NavLink
                      onClick={handleClose}
                      className={classes.menuLink}
                      to={
                        searchParams
                          ? `${
                              BreadcrumbNameMap.Support.url
                            }?${searchParams.toString()}`
                          : BreadcrumbNameMap.Profile.url
                      }
                    >
                      {t("HeaderSupport")}
                    </NavLink>
                  </Stack>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "280px" }}
                >
                  <Button size="small" variant="text" onClick={handleLogout}>
                    {t("HeaderLogout")}
                  </Button>
                  <LanguageSelector
                    value={i18n.language}
                    onChange={handleSelectLanguage}
                    t={t}
                  />
                </Box>
              </ListItem>
            </Menu>
          </Toolbar>
        </AppBar>
      }
    </>
  );
};

export { Appbar };
