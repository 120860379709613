import { GridColDef } from "@mui/x-data-grid";
import AdminLocationDataRow from "./AdminLocationDataRow";
import {
  createColumnActionAdminLocation,
  PopoverAdminLocationConfig,
} from "./popoverRowAdminLocation";
import { HtmlTooltipLines } from "@doctomatic/components-react/build/Tooltips/HtmlTooltip";

export const AdminLocationGridColumns = (
  t: (text: string) => string,
  popoverConf?: PopoverAdminLocationConfig
): GridColDef[] => {
  const gridColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      width: 220,
      align: "left",
    },
    {
      field: "description",
      headerName: t("HeaderColumnDescription"),
      width: 220,
      align: "left",
    },
    {
      field: "quantityPatients",
      headerName: t("HeaderColumnQuantityPatient"),
      width: 200,
      align: "left",
    },
    {
      field: "quantityLocationChildren",
      headerName: t("HeaderColumnQuantityLocationsChildren"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: AdminLocationDataRow) =>
        (row.children as { name: string }[])?.length,
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return HtmlTooltipLines(
          t("Sublocations"),
          (params.row.children as { name: string }[])?.map((x) => x.name),
          <p>{(params.row.children as { name: string }[])?.length}</p>
        );
      },
    },
  ];

  if (popoverConf)
    gridColumns.push(...createColumnActionAdminLocation(t, popoverConf));

  return gridColumns;
};
