import React from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { UserLocationGridColumns } from "./UserLocationGridColumns";
import UserLocationDataRow from "./UserLocationDataRow";
import { ItemLocationListResponseDto } from "@doctomatic/sdk/build/dto/Location/Location";
import { GridColDef } from "@mui/x-data-grid";

interface Props {
  patientId: number;
  locations: ItemLocationListResponseDto[] | undefined;
  toggleAssign?: (item: any, assigned: boolean) => void;
}

const UserLocationsList = ({
  locations,
  patientId,
  toggleAssign,
}: Props): React.ReactElement => {
  const GRID_COMPONENT = "switchComponent";
  const { t, i18n } = useTranslation();

  const patientGridColumns: GridColDef<UserLocationDataRow>[] =
    UserLocationGridColumns(t, GRID_COMPONENT, toggleAssign);

  const locationsDataRow: UserLocationDataRow[] = locations
    ? locations.map((location: ItemLocationListResponseDto) => {
        const locationUser =
          location.location_user &&
          location.location_user.find((x) => x.user && x.user.id === patientId);
        const locationDataRow: UserLocationDataRow = {
          id: location.id,
          name: location.name,
          quantityPatients: 0,
          children: [],
          assigned: Boolean(locationUser),
          location_user: locationUser && [locationUser],
        };
        return locationDataRow;
      })
    : [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={patientGridColumns as any}
        rows={locationsDataRow}
      />
    </div>
  );
};

export { UserLocationsList };
