import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import {
  GridColDef,
  GridRowClassNameParams,
  GridSortItem,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../Page";
import LastAlertsByValueDataRow from "./LastAlertsByValueDataRow";
import {
  ILastAlertsByValueGridColumnsProps,
  LastAlertsByValueGridColumns,
} from "./LastAlertsByValueGridColumns";
import { LastAlertsByValuePatient } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { IUseLastAlertsByValueByActor } from "@doctomatic/sdk/build/modules/LastAlertsByValueByActor";
import { processError } from "../../../../../App/errorToast";
import {
  AlertState,
  IItemAlertByValue,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import {
  showAlertStateModal,
  showAlertSubscribersModal,
} from "../../../Alerts/utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useLoading } from "../../../../Loading/Loading";
import { ImageItemInterface } from "../../../../Images/utils";
import { DoctomaticStylingRowsGrid } from "../../../../Theme/ThemeDataGrid";
import {
  BasicMeasurement,
  Device,
} from "@doctomatic/components-react/build/Graphs/models";
import { ImageEditModal } from "@doctomatic/components-react/build/ImagesViews/ImageEditModal";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { UpdateMeasurementRequestDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { toast } from "react-toastify";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";

const getItemAlert = (itemAlert: IItemAlertByValue): IItemAlertByValue =>
  ({
    ...itemAlert,
    createdMeasurementAt: new Date(
      itemAlert.createdMeasurementAt ? itemAlert.createdMeasurementAt : ""
    ),
    createdAt: new Date(itemAlert.createdAt),
    ackBys: itemAlert.ackBys.map((ackBy: any) => ({
      ...ackBy,
      createdAt: new Date(ackBy.createdAt),
    })),
  } as IItemAlertByValue);

export interface ILastAlertsByValueListProps {
  actorId: number;
  actorRole: Role;
  showExternalId: boolean | undefined;
}

export const LastAlertsByValueList = ({
  actorId,
  actorRole,
  showExternalId,
}: ILastAlertsByValueListProps): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const {
    useLastAlertsByValueByActor,
    logout,
    useAlerts,
    timezone,
    useImages,
    useMeasurements,
    useDevices,
  }: IProvideApi = useApi();
  const { updateAlertComments, updateAlertState } = useAlerts();
  const { getUrl } = useImages(false, processError(logout, navigate, t));
  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByReadDevice,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const [lastAlerts, setLastAlerts] = useState<LastAlertsByValuePatient[]>([]);
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);
  const [imageItem, setImageItem] = useState<ImageItemInterface | null>(null);
  const [error, setError] = useState(false);
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [imageMeasurements, setImageMeasurements] = useState<
    BasicMeasurement[]
  >([]);
  const [device, setDevice] = useState<Device>();
  const [measurementToDelete, setMeasurementToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [readDeviceToDelete, setReadDeviceToDelete] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  // Declaration of hooks to handle pagination
  const [pageSizeByValue, setPageSizeByValue] = useState<number>(10);
  const [pageByValue, setPageByValue] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationValue, setPaginationValue] = useState<any>({
    page: 1,
    limit: 10,
    sortBy: [],
  });
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);

  const { response, mutate }: IUseLastAlertsByValueByActor =
    useLastAlertsByValueByActor(
      actorId,
      actorRole,
      true,
      paginationValue,
      processError(logout, navigate, t)
    );

  const alertsByValue = response?.data;
  const alertsByValuePaginationInfo = response?.meta;

  const handleError = () => {
    setError(true);
  };

  const openAlertStateModal = (id: number, state: AlertState) => {
    setCurrentAlertState({ id, state });
  };

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, { state });
        mutate();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      const response = await updateAlertComments(id, { comments });
      mutate();
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    const cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (alertsByValue) {
      setLastAlerts(alertsByValue);
    }
  }, [alertsByValue, alertsByValuePaginationInfo]);

  useEffect(() => {
    setLoading(true);
    setPaginationValue({
      page: pageByValue,
      limit: pageSizeByValue,
      sortBy: sortBy,
    });
  }, [pageByValue, pageSizeByValue, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [lastAlerts]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const openAckByModal = (item: any) => {
    setItem(item);
  };

  const openImageModal = async (item: LastAlertsByValueDataRow) => {
    // We force readDevice && alert to be defined since we will only display the modal if both exits
    let imageUrlResponse;
    if (item.alert!.typeReadDevice !== "ExternalRead") {
      imageUrlResponse = await getUrl(item.alert!.readDeviceId!);
    }

    /**
     * We find the rest of the measurements from the same readDevice.
     */
    const measurementResponse = await findAllByReadDevice(
      item.alert!.readDeviceId!
    );
    setImageMeasurements(measurementResponse.data as BasicMeasurement[]);

    /**
     * We need to get the measurement devie since the device
     * on measurements response doesn't contain all the
     * information requiered.
     */
    const deviceResponse = await getDevice(item.alert!.device.id);
    setDevice(deviceResponse.data);

    setImageItem({
      imageUrl: imageUrlResponse?.data ?? "",
      altText: item.alert!.value,
      readDeviceId: item.alert!.readDeviceId!,
      imageForm: false,
    });
  };

  const lastAlertsGridColumns: GridColDef<LastAlertsByValueDataRow>[] =
    LastAlertsByValueGridColumns({
      actorId,
      mutate,
      setLastAlerts,
      showExternalId,
      showModal: openAckByModal,
      showStateModal: openAlertStateModal,
      setAlertComments,
      showImageModal: openImageModal,
      currentLanguage,
    } as ILastAlertsByValueGridColumnsProps);

  const onSaveMeasurement = async (measurements: any[]) => {
    setIsLoading(true);
    try {
      const updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        const updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id;
        updateMeasurements.push(updateMeasurement);
      });
      const updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
      }
    } catch {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);

      /**
       * We need to update the measurements list in order
       * to properly display the update measurement list
       * without the recently deleted item.
       */
      if (delResponse.success === true) {
        toast.success(t("DeleteMeasurementSuccess"));
        const toUpdate = imageMeasurements.findIndex(
          (m) => m.id === measurementId
        );
        const newList = [...imageMeasurements];
        newList.splice(toUpdate, 1);
        setImageMeasurements(newList);
        await mutate();
      }
    } catch {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await delByReadDevice(readDeviceId);
      /**
       * We reset measurements list since we deleted all the measurements.
       */
      if (delResponse.success === true) {
        toast.success(t("DeleteManyMeasurementsSuccess"));
        setImageMeasurements([]);
        await mutate();
      }
    } catch {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const onChangeMeasurements = (event: any, signId: number) => {
    const toUpdate = imageMeasurements.findIndex((m) => m.signId === signId);
    const newList = [...imageMeasurements];
    if (toUpdate !== -1) {
      newList[toUpdate].value = +event.target.value;
    } else {
      const newMeasurement = new BasicMeasurement(
        0,
        +event.target.value,
        new Date(),
        signId,
        imageItem!.readDeviceId
      );
      newList.push(newMeasurement);
    }
    setImageMeasurements(newList);
  };

  const handleOnCloseImageModal = () => {
    setImageItem(null);
    setError(false);
    setImageMeasurements([]);
  };

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone: timezone,
    });

  const showEditImageModal = imageItem && (
    <ImageEditModal
      imageUrl={imageItem.imageUrl}
      altText={imageItem.altText}
      open={imageItem !== null}
      handleClose={() => handleOnCloseImageModal()}
      t={t}
      error={error}
      handleError={handleError}
      measurements={imageMeasurements}
      onSave={onSaveMeasurement}
      onDeleteMany={onDeleteManyMeasurements}
      onDeleteOne={onDeleteOneMeasurement}
      editPermissions={editMeasurementsPermissions}
      deletePermissions={deleteMeasurementsPermissions}
      onChange={onChangeMeasurements}
      device={device!}
      readDeviceId={imageItem.readDeviceId}
      measurementToDelete={measurementToDelete}
      setMeasurementToDelete={setMeasurementToDelete}
      readDeviceToDelete={readDeviceToDelete}
      setReadDeviceToDelete={setReadDeviceToDelete}
      currentLanguage={""}
    />
  );

  const lastAlertsDataRows: LastAlertsByValueDataRow[] = lastAlerts?.map(
    (lastAlertsPatient: LastAlertsByValuePatient, index: number) =>
      ({
        id: index,
        patient: lastAlertsPatient.patient,
        alert: getItemAlert(lastAlertsPatient.alert!),
      } as LastAlertsByValueDataRow)
  );

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const setRowClassName = (
    params: GridRowClassNameParams<LastAlertsByValueDataRow>
  ): string => {
    if (
      params.row.alert?.individualConfig &&
      !params.row.alert.individualConfig.active
    ) {
      return "no-actived-alert-config";
    }
    if (
      params.row.alert?.templateConfig &&
      !params.row.alert.templateConfig.active
    ) {
      return "no-actived-alert-config";
    }

    return "";
  };

  return (
    <Page title={t("LastAlertsByValueTitle")}>
      {loading ? (
        <TableSkeleton numRows={10} />
      ) : (
        <FlexLayoutGrid
          language={i18n.language}
          columns={lastAlertsGridColumns as any}
          rows={lastAlertsDataRows}
          onPageChange={setPageByValue}
          onPageSizeChange={setPageSizeByValue}
          pagination={alertsByValuePaginationInfo}
          isLoading={loading}
          setRowClassName={setRowClassName as any}
          sx={DoctomaticStylingRowsGrid() as any}
          sortBy={sortBy}
          onSortChange={(newSort) => setSortBy(newSort as GridSortItem[])}
        />
      )}
      {showModal}
      {showEditImageModal}
      {alertStateModal}
    </Page>
  );
};
