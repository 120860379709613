import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { SignDto } from "../../../Sign/Signs";
import { AlertType } from "../../IAlerts";
import { IAlertConfigByValueBasicParameters } from "../IAlertConfigByValueParameters";
import {
  AddAlertConfigTemplateRequestDto,
  AddAlertConfigTemplateResponseDto,
  GetAlertConfigTemplateResponseDto,
  ItemAlertConfigTemplateDto,
  UpdateAlertConfigTemplateRequestDto,
  UpdateAlertConfigTemplateResponseDto,
} from "./AlertConfigTemplate";

import {
  IAddAlertConfigByValueTemplateRequestDto,
  IAddAlertConfigByValueTemplateResponseDto,
  IGetAlertConfigByValueTemplateResponseDto,
  IItemAlertConfigByValueTemplateDto,
  IUpdateAlertConfigByValueTemplateRequestDto,
  IUpdateAlertConfigByValueTemplateResponseDto,
} from "./IAlertConfigByValueTemplate";

/** ADD */
export class AddAlertConfigByValueTemplateRequestDto
  extends AddAlertConfigTemplateRequestDto
  implements IAddAlertConfigByValueTemplateRequestDto, IAlertConfigByValueBasicParameters {
  @Expose() @IsOptional() signId: number = 0;
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
}

export class AddAlertConfigByValueTemplateResponseDto
  extends AddAlertConfigTemplateResponseDto
  implements IAddAlertConfigByValueTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => SignDto)
  sign: SignDto = new SignDto();
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
}

/** UPDATE */
export class UpdateAlertConfigByValueTemplateRequestDto
  extends UpdateAlertConfigTemplateRequestDto
  implements IUpdateAlertConfigByValueTemplateRequestDto {
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
}

export class UpdateAlertConfigByValueTemplateResponseDto
  extends UpdateAlertConfigTemplateResponseDto
  implements IUpdateAlertConfigByValueTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => SignDto)
  sign: SignDto = new SignDto();
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
}


export class GetAlertConfigByValueTemplateResponseDto
  extends GetAlertConfigTemplateResponseDto
  implements IGetAlertConfigByValueTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => SignDto)
  sign: SignDto = new SignDto();
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
  @Expose() @IsOptional() valueText?: string;
}

export class ItemAlertConfigByValueTemplateDto
  extends ItemAlertConfigTemplateDto
  implements IItemAlertConfigByValueTemplateDto {

  @Expose()
  @ValidateNested()
  @Type(() => SignDto)
  sign: SignDto = new SignDto();
  @Expose() min: number = 0;
  @Expose() max: number = 0;
  @Expose() alert_type: AlertType = AlertType.min;
  @Expose() range_value: number = 0;
  @Expose() range_time: string = "";
  @Expose() valueText?: string;
}
