import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
export var EcgGridColumns = function (_a) {
    var handleOpen = _a.handleOpen, downloadPdf = _a.downloadPdf, t = _a.t;
    return [
        {
            field: t("TableDate"),
            headerName: t("TableDate"),
            width: 180,
            sortable: true,
            filterable: true,
            valueGetter: function (value, row) { return row.createdAt; },
            sortComparator: function (v1, v2) {
                return new Date(v2).getTime() - new Date(v1).getTime();
            },
            renderCell: function (params) { return (React.createElement("p", null, new Date(params.row.createdAt).toLocaleString())); },
        },
        {
            field: "ViewFile",
            headerName: t("ViewFile"),
            width: 150,
            align: "center",
            renderCell: function (params) {
                return (React.createElement(Tooltip, { placement: "top", title: t("ViewFile") || "" },
                    React.createElement(IconButton, { style: { padding: "8px" }, onClick: function () { return handleOpen(params.row.file); } },
                        React.createElement(VisibilityIcon, null))));
            },
        },
        {
            field: "FileDownload",
            headerName: t("FileDownload"),
            width: 150,
            align: "center",
            renderCell: function (params) {
                return (React.createElement(Tooltip, { placement: "top", title: t("FileDownload") || "" },
                    React.createElement(IconButton, { style: { padding: "8px" }, onClick: function () { return downloadPdf(params.row.file); } },
                        React.createElement(CloudDownloadIcon, null))));
            },
        },
    ];
};
