var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, } from "@mui/material";
import DatePicker from "react-datepicker";
import i18next from "i18next";
import { CustomSwitch } from "../AlertConfig/CustomSwitch";
import { styles } from "./styles";
var datePickerStyles = {
    height: "fit-content",
    display: "inline-flex",
    fontSize: "1rem",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    width: "fit-content",
    marginTop: "4px",
    marginBottom: "4px",
};
var customDatePicker = __assign(__assign({}, datePickerStyles), { "> div.react-datepicker-wrapper": datePickerStyles, "> div > div.react-datepicker__input-container": datePickerStyles, "> div > div.react-datepicker__input-container input": __assign(__assign({}, datePickerStyles), { border: "1px solid #c4c4c4", borderRadius: "4px", padding: "16.5px 14px", ":hover": {
            border: "1px solid #000",
        }, ":focus": {
            outline: "none",
            border: "2px solid #2196F3",
        } }) });
var AssignTrackingPatientModal = function (_a) {
    var diseaseId = _a.diseaseId, disease = _a.disease, open = _a.open, onClose = _a.onClose, onSave = _a.onSave, t = _a.t;
    var _b = useState(new Date()), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(), endDate = _c[0], setEndDate = _c[1];
    var _d = useState(false), applyFrequencyTemplates = _d[0], setApplyFrequencyTemplates = _d[1];
    return (React.createElement(Dialog, { open: open, fullWidth: true },
        React.createElement(DialogTitle, null, t("AssignTracking")),
        React.createElement(DialogContent, { sx: styles.dialogContainer },
            React.createElement(Box, { style: styles.dateSelectorsContainer },
                React.createElement(Grid, { container: true, spacing: 1, columns: 12, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(DialogContentText, null, t("TrackingStartDateTitle")),
                        React.createElement(Box, { sx: customDatePicker },
                            React.createElement(DatePicker, { dateFormat: "dd/MM/yyyy", selected: startDate, locale: i18next.language, onChange: function (date) {
                                    return date ? setStartDate(date) : setStartDate(undefined);
                                }, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", isClearable: true, minDate: new Date(), maxDate: endDate, placeholderText: t ? t("StartDate") : "Fecha de inicio", popperPlacement: "bottom" })),
                        !startDate && (React.createElement(DialogContentText, { style: { marginTop: 4, fontSize: 14 } }, t("TrackingStartDateText")))),
                    React.createElement(Grid, { item: true, xs: 12, md: 6 },
                        React.createElement(DialogContentText, null, t("TrackingEndDateTitle")),
                        React.createElement(Box, { sx: customDatePicker },
                            React.createElement(DatePicker, { dateFormat: "dd/MM/yyyy", selected: endDate, locale: i18next.language, onChange: function (date) {
                                    return date ? setEndDate(date) : setEndDate(undefined);
                                }, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", isClearable: true, minDate: startDate !== null && startDate !== void 0 ? startDate : new Date(), placeholderText: t ? t("EndDate") : "Fecha final", popperPlacement: "bottom" })),
                        !endDate && (React.createElement(DialogContentText, { style: { marginTop: 4, fontSize: 14 } }, t("TrackingEndDateText")))))),
            disease && (React.createElement(Box, { style: styles.templateContainer },
                React.createElement(DialogContentText, { style: styles.templateText }, t("ApplyFrequenciesContent")),
                React.createElement(CustomSwitch, { checked: applyFrequencyTemplates, onChange: function () {
                        return setApplyFrequencyTemplates(!applyFrequencyTemplates);
                    } })))),
        React.createElement(DialogActions, null,
            React.createElement(Box, { m: 2 },
                React.createElement(Box, { mr: 2, display: "inline-block" },
                    React.createElement(Button, { onClick: onClose, style: styles.cancelButton, size: "small" }, t("ButtonCancel"))),
                React.createElement(Button, { style: styles.saveButton, onClick: function () {
                        return onSave(diseaseId, startDate, endDate, applyFrequencyTemplates);
                    }, variant: "contained", size: "small" }, t("ButtonSave"))))));
};
export { AssignTrackingPatientModal };
