import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { BasicItem } from "../../../IItemList";
import { SignDto } from "../../../Sign/Signs";
import { SubscriberDto } from "../../group/AlertGroup";
import { AlertType } from "../../IAlerts";
import { ItemAlertConfigByValueTemplateDto } from "../template/AlertConfigByValueTemplate";
import {
  AddAlertConfigIndividualRequestDto,
  AddAlertConfigIndividualResponseDto,
  GetAlertConfigIndividualResponseDto,
  ItemAlertConfigIndividualDto,
  UpdateAlertConfigIndividualRequestDto,
  UpdateAlertConfigIndividualResponseDto,
} from "./AlertConfigIndividual";

import {
  IAddAlertConfigByValueIndividualRequestDto,
  IAddAlertConfigByValueIndividualResponseDto,
  IGetAlertConfigByValueIndividualResponseDto,
  IItemAlertConfigByValueIndividualDto,
  IUpdateAlertConfigByValueIndividualRequestDto,
  IUpdateAlertConfigByValueIndividualResponseDto,
} from "./IAlertConfigByValueIndividual";
import { INotification } from "../../group/IAlertGroup";

/** ADD */
export class AddAlertConfigByValueIndividualRequestDto
  extends AddAlertConfigIndividualRequestDto
  implements IAddAlertConfigByValueIndividualRequestDto
{
  @Expose() @IsOptional() signId: number = 0;
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
  @Expose() @IsNotEmpty() subscribe: boolean = false;
  @Expose() @IsOptional() customAlertConfigTemplateId?: number;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class AddAlertConfigByValueIndividualResponseDto
  extends AddAlertConfigIndividualResponseDto
  implements IAddAlertConfigByValueIndividualResponseDto
{
  @Expose() active: boolean = true;
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
  @ValidateNested()
  @Type(() => SignDto)
  @Expose()
  sign: SignDto = new SignDto();
  @Expose() min: number = 0;
  @Expose() max: number = 0;
  @Expose() alert_type: AlertType = AlertType.min;
  @Expose() range_value: number = 0;
  @Expose() range_time: string = "";
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  patient: BasicItem = new BasicItem();
  @ValidateNested()
  @Type(() => ItemAlertConfigByValueTemplateDto)
  @Expose()
  customAlertConfigTemplate: ItemAlertConfigByValueTemplateDto =
    new ItemAlertConfigByValueTemplateDto();
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

/** UPDATE */
export class UpdateAlertConfigByValueIndividualRequestDto
  extends UpdateAlertConfigIndividualRequestDto
  implements IUpdateAlertConfigByValueIndividualRequestDto
{
  @Expose() @IsOptional() min?: number;
  @Expose() @IsOptional() max?: number;
  @Expose() @IsOptional() alert_type?: AlertType;
  @Expose() @IsOptional() range_value?: number;
  @Expose() @IsOptional() range_time?: string;
}

export class UpdateAlertConfigByValueIndividualResponseDto
  extends UpdateAlertConfigIndividualResponseDto
  implements IUpdateAlertConfigByValueIndividualResponseDto
{
  @Expose() active: boolean = true;
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
  @ValidateNested()
  @Type(() => SignDto)
  @Expose()
  sign: SignDto = new SignDto();
  @Expose() min: number = 0;
  @Expose() max: number = 0;
  @Expose() alert_type: AlertType = AlertType.min;
  @Expose() range_value: number = 0;
  @Expose() range_time: string = "";
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  patient: BasicItem = new BasicItem();
  @ValidateNested()
  @Type(() => ItemAlertConfigByValueTemplateDto)
  @Expose()
  customAlertConfigTemplate: ItemAlertConfigByValueTemplateDto =
    new ItemAlertConfigByValueTemplateDto();
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

/** GET */
export class GetAlertConfigByValueIndividualResponseDto
  extends GetAlertConfigIndividualResponseDto
  implements IGetAlertConfigByValueIndividualResponseDto
{
  @Expose() active: boolean = true;
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
  @ValidateNested()
  @Type(() => SignDto)
  @Expose()
  sign: SignDto = new SignDto();
  @Expose() min: number = 0;
  @Expose() max: number = 0;
  @Expose() alert_type: AlertType = AlertType.min;
  @Expose() range_value: number = 0;
  @Expose() range_time: string = "";
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  patient: BasicItem = new BasicItem();
  @ValidateNested()
  @Type(() => ItemAlertConfigByValueTemplateDto)
  @Expose()
  customAlertConfigTemplate: ItemAlertConfigByValueTemplateDto =
    new ItemAlertConfigByValueTemplateDto();
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class ItemAlertConfigByValueIndividualDto
  extends ItemAlertConfigIndividualDto
  implements IItemAlertConfigByValueIndividualDto
{
  @Expose() active: boolean = true;
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
  @ValidateNested()
  @Type(() => SignDto)
  @Expose()
  sign: SignDto = new SignDto();
  @Expose() min: number = 0;
  @Expose() max: number = 0;
  @Expose() alert_type: AlertType = AlertType.min;
  @Expose() range_value: number = 0;
  @Expose() range_time: string = "";
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  patient: BasicItem = new BasicItem();
  @ValidateNested()
  @Type(() => ItemAlertConfigByValueTemplateDto)
  @Expose()
  customAlertConfigTemplate: ItemAlertConfigByValueTemplateDto =
    new ItemAlertConfigByValueTemplateDto();
  @Expose() fromTemplate: boolean = false;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}
