var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dateFormat from "dateformat";
import React, { useEffect, useRef, useState } from "react";
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, ReferenceArea, } from "recharts";
import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import { styles } from "./styles";
import { TabPanel } from "./TabPanel";
import { SignType, } from "./models";
import NumericValuesModal from "./GraphTreatment/NumericValues/NumericValuesModal";
import { CustomGraphTooltip } from "./CustomTooltip";
import { useWindowSize } from "../utils";
var colors = ["#4bae51", "#007BB7", "#F4771F"];
var hexToRGBA = function (hex, a) {
    hex = "0x" + hex.substring(1);
    var r = (+hex >> 16) & 0xff;
    var g = (+hex >> 8) & 0xff;
    var b = +hex & 0xff;
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(a, ")");
};
var a11yProps = function (index) { return ({
    id: "tab-".concat(index),
    "aria-controls": "tabpanel-".concat(index),
}); };
export var Measurements = function (_a) {
    var measurements = _a.measurements, device = _a.device, limits = _a.limits, start = _a.start, end = _a.end, open = _a.open, handleClose = _a.handleClose, t = _a.t, language = _a.language, getUrl = _a.getUrl, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, onDeleteManyMeasurements = _a.onDeleteManyMeasurements;
    var _b = useState([]), parsedMeasurements = _b[0], setParsedMeasurements = _b[1];
    var tooltipCss = "\n  .recharts-tooltip-wrapper {\n    position: relative;\n    z-index: 3;\n  }\n  ";
    var _c = useState(false), isMultichoice = _c[0], setIsMultichoice = _c[1];
    var _d = useState(false), isFormDevice = _d[0], setIsFormDevice = _d[1];
    var _e = useState(false), isQuestion = _e[0], setIsQuestion = _e[1];
    var _f = useState(false), isMobile = _f[0], setIsMobile = _f[1];
    var _g = useState([]), domains = _g[0], setDomains = _g[1];
    var _h = useState(0), tab = _h[0], setTab = _h[1];
    var chartRef = useRef();
    var windowSize = useWindowSize();
    var units = device === null || device === void 0 ? void 0 : device.signs.map(function (item) { return item.unit; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
    var _j = useState([]), tabLabels = _j[0], setTabLabels = _j[1];
    var handleTabChange = function (event, value) {
        setTab(+value);
    };
    useEffect(function () {
        setTab(0);
        if (measurements) {
            var ylimits = [];
            var labels = [];
            // Parse measurements array with graph format
            var parseMeasurements = function (parsedMeasurements) {
                var tempArray = [];
                parsedMeasurements.forEach(function (measurement, index) {
                    var _a, _b;
                    if (index === 0) {
                        tempArray.push((_a = measurement.readDevice) === null || _a === void 0 ? void 0 : _a.id);
                    }
                    else {
                        var exists = tempArray.filter(function (item) { var _a, _b; return (item === null || item === void 0 ? void 0 : item.toString()) === ((_b = (_a = measurement.readDevice) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()); });
                        if (exists.length < 1) {
                            tempArray.push((_b = measurement.readDevice) === null || _b === void 0 ? void 0 : _b.id);
                        }
                    }
                });
                tempArray.forEach(function (item, index) {
                    var coincidences = parsedMeasurements.filter(function (measurement) { var _a, _b; return ((_b = (_a = measurement.readDevice) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()) === (item === null || item === void 0 ? void 0 : item.toString()); });
                    var tempObject = {};
                    var newCoincidences = [];
                    var hasDuplicates = false; // Bandera para rastrear duplicados
                    coincidences.forEach(function (coincidence) {
                        var _a;
                        // ---- Check if multichoice -------------------------------------------------------------
                        var index = newCoincidences.findIndex(function (m) { return m.signId === coincidence.signId; });
                        if (index === -1) {
                            newCoincidences.push(coincidence);
                        }
                        else {
                            hasDuplicates = true;
                        }
                        //  --------------------------------------------------------------------------------------
                        // ---- Check if device question in form -------------------------------------------------
                        if (device.formDevice) {
                            if (((_a = coincidence.sign) === null || _a === void 0 ? void 0 : _a.typeSign) === SignType.number) {
                                setIsFormDevice(true);
                            }
                            else {
                                setIsQuestion(true);
                            }
                        }
                        //  --------------------------------------------------------------------------------------
                    });
                    setIsMultichoice(hasDuplicates);
                    coincidences.forEach(function (coincidence) {
                        var _a, _b;
                        tempObject["sign".concat(coincidence.signId)] = coincidence.value
                            ? (+coincidence.value).toFixed((_a = coincidence.sign) === null || _a === void 0 ? void 0 : _a.decimals)
                            : coincidence.value;
                        tempObject["createdAt"] = (_b = coincidence.readDevice) === null || _b === void 0 ? void 0 : _b.createdAt;
                        tempObject["date"] = dateFormat(coincidence.createdAt, "dd/mm HH:MM:ss");
                        tempObject["readDeviceId"] = coincidence.readDeviceId;
                        tempObject["deviceTypeName"] = coincidence.deviceTypeName;
                    });
                    tempArray[index] = __assign({}, tempObject);
                });
                return tempArray;
            };
            var measurementFormatted = parseMeasurements(measurements);
            if (units) {
                var _loop_1 = function (u) {
                    // TODO: change any to GetSignResponseDto (x2)
                    // let signs: GetSignResponseDto[] = device ? device.signs.reduce((arr: GetSignResponseDto[], e, i) => ((e.unit === u) && arr.push(e), arr), []) : [];
                    var signs = device
                        ? device.signs.reduce(function (arr, e, i) { return (e.unit === u && arr.push(e), arr); }, [])
                        : [];
                    labels.push(signs.map(function (x) { return t(x.name); }).join(", ") + " (" + t(u) + ")");
                    var signIds = signs.map(function (sign) { return "sign".concat(sign.id); });
                    var measurementValues = parsedMeasurements
                        .map(function (x) {
                        for (var i = 0; i < signIds.length; i++) {
                            if (Object.hasOwn(x, signIds[i]))
                                return x[signIds[i]];
                        }
                    })
                        .filter(function (item) { return item !== undefined; });
                    var min = Math.min.apply(Math, measurementValues);
                    var max = Math.max.apply(Math, measurementValues);
                    var _loop_2 = function (sign) {
                        var userLimits = limits.find(function (l) { return l.sign.id === sign.id; });
                        if (userLimits) {
                            if (userLimits.min != null && min > +userLimits.min)
                                min = +userLimits.min;
                            if (userLimits.max != null && max < +userLimits.max)
                                max = +userLimits.max;
                        }
                    };
                    //Check for limits & Set checkbox initial status
                    for (var _a = 0, signs_1 = signs; _a < signs_1.length; _a++) {
                        var sign = signs_1[_a];
                        _loop_2(sign);
                    }
                    ylimits.push([Math.floor(min - 10), Math.ceil(max + 10)]);
                };
                for (var _i = 0, units_1 = units; _i < units_1.length; _i++) {
                    var u = units_1[_i];
                    _loop_1(u);
                }
            }
            setDomains(ylimits);
            setTabLabels(labels);
            setParsedMeasurements(measurementFormatted);
        }
    }, [measurements, device]);
    useEffect(function () {
        setIsMobile(windowSize.width <= 600);
    }, [windowSize]);
    var chart = function (unit, index) {
        // Filter measurements by device sign
        var signIds = device &&
            device.signs
                .filter(function (x) { return x.unit === unit; })
                .map(function (item) { return "sign".concat(item.id); });
        var domainValues = [];
        var measurementsWithSameSign = parsedMeasurements.filter(function (parsedMeasurement) {
            var signFound = false;
            for (var i = 0; i < signIds.length; i++) {
                if (Object.hasOwn(parsedMeasurement, signIds[i])) {
                    domainValues.push(+parsedMeasurement[signIds[i]]);
                    signFound = true;
                }
            }
            if (signFound)
                return __assign({}, parsedMeasurement);
        });
        // Get min/max value of each list of measurements to center YAxis with lines
        var min = Math.min.apply(Math, domainValues);
        var max = Math.max.apply(Math, domainValues);
        var domain = [min - 10, max + 10];
        return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%", key: Math.random() }, React.createElement(LineChart, { width: 500, data: measurementsWithSameSign, height: 300, ref: chartRef },
            React.createElement("style", null, tooltipCss),
            React.createElement(CartesianGrid, { strokeDasharray: "3 3" }),
            React.createElement(XAxis, { dataKey: "date", type: "category", allowDuplicatedCategory: false }),
            React.createElement(YAxis, { domain: domain }),
            React.createElement(Tooltip, { trigger: "click", content: React.createElement(CustomGraphTooltip, { getUrl: getUrl, t: t, chartRef: chartRef }), wrapperStyle: { pointerEvents: "auto" } }),
            React.createElement(Legend, null),
            // TODO: change any to GetSignResponseDto
            // device && device.signs.filter(x => x.unit === u).map((s: GetSignResponseDto, i: number) => {
            device &&
                device.signs
                    .filter(function (x) { return x.unit === unit; })
                    .map(function (s, i) {
                    // TODO: change any to GetPatientResponseDto
                    // const limit = (user as GetPatientResponseDto).limits.find(l => l.sign.id === s.id);
                    var limit = limits.find(function (l) { return l.sign.id === s.id; });
                    // TODO: change any to GetLimitResponseDto
                    // const printLimits = (limit: GetLimitResponseDto, i: number) => (<>
                    var printLimits = function (limit, i) { return (React.createElement(React.Fragment, null,
                        limit.min && limit.max && (React.createElement(ReferenceArea, { y1: limit.min, y2: limit.max, fill: hexToRGBA(colors[i], 0.5) })),
                        limit.max && (React.createElement(ReferenceLine, { y: limit.max, stroke: colors[i], strokeDasharray: "10 15" })),
                        limit.min && (React.createElement(ReferenceLine, { y: limit.min, stroke: colors[i], strokeDasharray: "10 15" })))); };
                    return (React.createElement(React.Fragment, { key: "limit-".concat(i) },
                        limit && printLimits(limit, i),
                        React.createElement(Line, { isAnimationActive: false, type: "monotone", stroke: colors[i], dataKey: "sign".concat(s.id), name: s.name, key: "sign".concat(s.id), strokeWidth: 3, activeDot: { r: 7 } })));
                }))));
    };
    if (!units)
        return React.createElement(React.Fragment, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { style: {
                display: isMobile || isMultichoice || isFormDevice || isQuestion
                    ? "flex"
                    : "-webkit-box",
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMultichoice || isFormDevice || isQuestion ? "center" : "",
                height: isMultichoice || isFormDevice || isQuestion ? "100%" : "300px",
            } }, isMultichoice || isFormDevice || isQuestion ? (React.createElement(Card, { sx: { maxWidth: 450 } },
            React.createElement(CardContent, null,
                React.createElement(Typography, { variant: "body2" }, isMultichoice
                    ? t("TextGraphMultiOptions")
                    : isFormDevice
                        ? t("TextGraphDeviceForm")
                        : t("TextGraphIsForm"))))) : (React.createElement(React.Fragment, null,
            React.createElement(Tabs, { value: tab, onChange: handleTabChange, style: styles.tabContainer, orientation: isMobile ? "horizontal" : "vertical" }, units.map(function (_u, i) { return (React.createElement(Tab, __assign({ key: "tab-".concat(i), style: styles.tab, label: tabLabels[i] }, a11yProps(i)))); })),
            units.map(function (u, i) { return (React.createElement(TabPanel, { key: "panel-".concat(i), value: tab, index: i }, chart(u, i))); })))),
        React.createElement(NumericValuesModal, { device: device, signs: device.signs, measurements: measurements, open: !!open, handleClose: handleClose, t: t, language: language, getUrl: getUrl, onSaveMeasurement: onSaveMeasurement, editMeasurementsPermissions: editMeasurementsPermissions, deleteMeasurementsPermissions: deleteMeasurementsPermissions, onDeleteOneMeasurement: onDeleteOneMeasurement, onDeleteManyMeasurements: onDeleteManyMeasurements })));
};
