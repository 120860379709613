import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { TabPanel } from "@doctomatic/components-react/build/Graphs/TabPanel";
import { useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertsPaginated } from "@doctomatic/sdk/build/modules/AlertsPaginated/AlertsPaginated";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../Page";
import { AlertsByValueList } from "./AlertsByValueList/AlertsByValueList";
import {
  AlertState,
  AlertTypeAlert,
  getReadableAlertStates,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { Role } from "@doctomatic/sdk/build/dto/User";
import MultipleSelect from "@doctomatic/components-react/build/MultipleSelect/MultipleSelect";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { processError } from "../../../App/errorToast";
import { AlertsByNoMeasureList } from "./AlertsByNoMeasureList/AlertsByNoMeasureList";
import { AdvancedSearch } from "@doctomatic/components-react/build/AdvancedSearch/AdvancedSearch";
import {
  ISearchBoxProp,
  SearchBoxProp,
} from "@doctomatic/components-react/build/AdvancedSearch/column.filter";
import { IUseImages } from "@doctomatic/sdk/build/modules/Images";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { formatAlertState } from "../../../App/formatFunction";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Filter } from "@doctomatic/sdk/build/utils/pagination/Filter";
import { FilterOperator } from "@doctomatic/sdk/build/utils/pagination/FilterOperator.enum";
import moment from "moment-timezone";
import { AlertsByFrequencyList } from "./AlertsByFrequencyList/AlertsByFrequencyList";

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const a11yProps = (index: number): { id: string; "aria-controls": string } => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

const Alerts = (): React.ReactElement => {
  const navigate = useNavigate();
  const {
    useAlertsPaginated,
    useDevices,
    useProfile,
    useAlerts,
    useCompany,
    logout,
    companyId,
    timezone,
    useImages,
    useMeasurements,
  } = useApi();
  const { t } = useTranslation();
  const location = useLocation();
  const { response: profile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const { getUrl }: IUseImages = useImages(
    false,
    processError(logout, navigate, t)
  );
  const company = companyResponse?.data;
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [alertsLength, setAlertsLength] = useState(0);
  const { setAck, updateAlertState, updateAlertComments } = useAlerts();
  const [actorRole, setActorRole] = useState<Role | undefined>(undefined);

  // Hook to manage advanced search
  const [filters, setFilters] = useState<Filter[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchToFind, setSearchToFind] = useState<string>("");

  // declaration of hooks to handle pagination
  const [pageSizeByValue, setPageSizeByValue] = useState<number>(10);
  const [pageByValue, setPageByValue] = useState<number>(1);
  const [pageSizeByNoMeasure, setPageSizeNoMeasure] = useState<number>(10);
  const [pageByNoMeasure, setPageNoMeasure] = useState<number>(1);
  const [pageSizeByFrequency, setPageSizeFrequency] = useState<number>(10);
  const [pageByFrequency, setPageFrequency] = useState<number>(1);
  const [loadingTab0, setLoadingTab0] = useState<boolean>(true);
  const [loadingTab1, setLoadingTab1] = useState<boolean>(true);
  const [loadingTab2, setLoadingTab2] = useState<boolean>(true);
  const [paginationValue, setPaginationValue] =
    useState<any>(INITIAL_PAGINATION);
  const [paginationNoMeasure, setPaginationNoMeasure] =
    useState<any>(INITIAL_PAGINATION);
  const [paginationFrequency, setPaginationFrequency] =
    useState<any>(INITIAL_PAGINATION);
  const [sortByTab0, setSortByTab0] = useState<GridSortItem[]>([]);
  const [sortByTab1, setSortByTab1] = useState<GridSortItem[]>([]);
  const [sortByTab2, setSortByTab2] = useState<GridSortItem[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [date, setDate] = useState<moment.Moment | null>(null);

  // Alert status
  const defaultState: any = {};
  getReadableAlertStates().forEach((status: any) => {
    defaultState[status] = false;
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setActorRole(decoded.role ?? undefined);
    }
  }, []);

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ): void => {
    setTab(+value);
  };

  const id = profile?.data?.id ? profile?.data?.id : 0;

  const {
    response: responseAlertsValue,
    mutate: mutateAlertsByValue,
  }: IUseAlertsPaginated = useAlertsPaginated(
    AlertTypeAlert.alert_by_value,
    paginationValue,
    searchToFind,
    filters,
    undefined,
    tab === 0
  );
  const {
    response: responseAlertsNoMeasure,
    mutate: mutateAlertsByNoMeasure,
  }: IUseAlertsPaginated = useAlertsPaginated(
    AlertTypeAlert.alert_by_no_measure,
    paginationNoMeasure,
    searchToFind,
    filters,
    undefined,
    tab === 1
  );

  const {
    response: responseAlertsFrequency,
    mutate: mutateAlertsByFrequency,
  }: IUseAlertsPaginated = useAlertsPaginated(
    AlertTypeAlert.alert_by_frequency,
    paginationFrequency,
    searchToFind,
    filters,
    undefined,
    tab === 2
  );
  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByReadDevice,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { getDevice } = useDevices(false, processError(logout, navigate, t));

  const alertsByValue = responseAlertsValue?.data;
  const alertsByValuePaginationInfo = responseAlertsValue?.meta;
  const alertsByNoMeasure = responseAlertsNoMeasure?.data;
  const alertsByNoMeasurePaginationInfo = responseAlertsNoMeasure?.meta;
  const alertsByFrequency = responseAlertsFrequency?.data;
  const alertsByFrequencyPaginationInfo = responseAlertsFrequency?.meta;

  const setAlertAck = (id: number) => {
    const setAlertAckAsync = async (id: number) => {
      setLoadingTab0(true);
      setLoadingTab1(true);
      setLoadingTab2(true);
      try {
        await setAck(id);
        mutateAlertsByValue();
        mutateAlertsByNoMeasure();
        mutateAlertsByFrequency();
      } catch (err) {
        setLoadingTab0(false);
        setLoadingTab1(false);
        setLoadingTab2(false);
        throw err;
      }
      setLoadingTab0(false);
      setLoadingTab1(false);
      setLoadingTab2(false);
    };
    setAlertAckAsync(id);
  };

  const setAlertState = (id: number, state: AlertState, type?: string) => {
    const setAlertStatusAsync = async (id: number) => {
      setLoadingTab0(true);
      setLoadingTab1(true);
      setLoadingTab2(true);
      try {
        await updateAlertState(id, { state, type });
        mutateAlertsByValue();
        mutateAlertsByNoMeasure();
        mutateAlertsByFrequency();
      } catch (err) {
        setLoadingTab0(false);
        setLoadingTab1(false);
        setLoadingTab2(false);
        throw err;
      }
      setLoadingTab0(false);
      setLoadingTab1(false);
      setLoadingTab2(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (
    id: number,
    comments: string | undefined,
    type?: string
  ) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined,
      type?: string
    ) => {
      setLoadingTab0(true);
      setLoadingTab1(true);
      setLoadingTab2(true);
      const response = await updateAlertComments(id, { comments, type });
      mutateAlertsByValue();
      mutateAlertsByNoMeasure();
      mutateAlertsByFrequency();
      setLoadingTab0(false);
      setLoadingTab1(false);
      setLoadingTab2(false);
      return response.success;
    };
    return setAlertCommentsAsync(id, comments, type);
  };

  useEffect(() => {
    if (tab === 0 && alertsByValue) {
      setAlertsLength(alertsByValuePaginationInfo.totalItems);
    } else if (tab === 1 && alertsByNoMeasure) {
      setAlertsLength(alertsByNoMeasurePaginationInfo.totalItems);
    } else if (tab === 2 && alertsByFrequency) {
      setAlertsLength(alertsByFrequencyPaginationInfo.totalItems);
    } else {
      setAlertsLength(0);
    }
  }, [
    tab,
    alertsByValue,
    alertsByValuePaginationInfo,
    alertsByNoMeasure,
    alertsByNoMeasurePaginationInfo,
    alertsByFrequency,
    alertsByFrequencyPaginationInfo,
  ]);

  useEffect(() => {
    setLoadingTab0(true);
    setPaginationValue({
      page: pageByValue,
      limit: pageSizeByValue,
      sortBy: sortByTab0,
    });
  }, [pageByValue, pageSizeByValue, sortByTab0]);

  useEffect(() => {
    setLoadingTab0(false);
  }, [alertsByValue]);

  useEffect(() => {
    setLoadingTab1(true);
    setPaginationNoMeasure({
      page: pageByNoMeasure,
      limit: pageSizeByNoMeasure,
      sortBy: sortByTab1,
    });
  }, [pageByNoMeasure, pageSizeByNoMeasure, sortByTab1]);

  useEffect(() => {
    setLoadingTab1(false);
  }, [alertsByNoMeasure]);

  useEffect(() => {
    setLoadingTab2(true);
    setPaginationFrequency({
      page: pageByFrequency,
      limit: pageSizeByFrequency,
      sortBy: sortByTab2,
    });
  }, [pageByFrequency, pageSizeByFrequency, sortByTab2]);

  useEffect(() => {
    setLoadingTab2(false);
  }, [alertsByFrequency]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tabId = query.get("tab");
    if (tabId) {
      setTab(+tabId);
    }
  }, [location.search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchToFind(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    const newSelectedStates: string[] = getReadableAlertStates().filter(
      (state) => selectedStates.includes(t(formatAlertState(state)))
    );

    const updatedFilters = [...filters];

    // Encontrar el índice del objeto agregado por este useEffect
    const index = updatedFilters.findIndex(
      (filter) =>
        filter.column === "state" && filter.typeQuery === FilterOperator.IN
    );

    if (index !== -1) {
      // Reemplazar el objeto existente con el nuevo
      if (newSelectedStates.length === 0) {
        updatedFilters.splice(index, 1);
      } else {
        updatedFilters.splice(index, 1, {
          column: "state",
          typeQuery: FilterOperator.IN,
          values: newSelectedStates,
        });
      }
      setFilters(updatedFilters);
    } else {
      if (newSelectedStates.length > 0) {
        setFilters([
          ...filters,
          {
            column: "state",
            typeQuery: FilterOperator.IN,
            values: newSelectedStates,
          },
        ]);
      }
    }
  }, [selectedStates]);

  useEffect(() => {
    const deviceNames: string[] = devices
      ? devices.map((device) => device.name)
      : [];
    const newSelectedDevices = deviceNames.filter((device) =>
      selectedDevices.includes(t(device))
    );

    const updatedFilters = [...filters];

    // Encontrar el índice del objeto agregado por este useEffect
    const index = updatedFilters.findIndex(
      (filter) =>
        (tab === 0 && filter.column === "sign.device.name") ||
        (tab !== 0 && filter.column === "device.name")
    );

    if (index !== -1) {
      // Reemplazar el objeto existente con el nuevo
      if (newSelectedDevices.length === 0) {
        updatedFilters.splice(index, 1);
      } else {
        updatedFilters.splice(index, 1, {
          column: tab === 0 ? "sign.device.name" : "device.name",
          typeQuery: FilterOperator.IN,
          values: newSelectedDevices,
        });
      }
      setFilters(updatedFilters);
    } else {
      if (newSelectedDevices.length > 0) {
        setFilters([
          ...filters,
          {
            column: tab === 0 ? "sign.device.name" : "device.name",
            typeQuery: FilterOperator.IN,
            values: newSelectedDevices,
          },
        ]);
      }
    }
  }, [selectedDevices]);

  useEffect(() => {
    const updatedFilters = [...filters];
    const values = moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss");

    // Encontrar el índice del objeto agregado por este useEffect
    const index = updatedFilters.findIndex(
      (filter) => filter.column === "created_at"
    );

    if (index !== -1) {
      // Reemplazar el objeto existente con el nuevo
      if (date) {
        updatedFilters.splice(index, 1, {
          column: "created_at",
          typeQuery: FilterOperator.LTE,
          values: [values],
        });
      } else {
        updatedFilters.splice(index, 1);
      }

      setFilters(updatedFilters);
    } else {
      if (date) {
        setFilters([
          ...filters,
          {
            column: "created_at",
            typeQuery: FilterOperator.LTE,
            values: [values],
          },
        ]);
      }
    }
  }, [date]);

  useEffect(() => {
    const resetFiltersOnChangeTab = () => {
      setPaginationValue(INITIAL_PAGINATION);
      setPaginationNoMeasure(INITIAL_PAGINATION);
      setPaginationFrequency(INITIAL_PAGINATION);
      setSelectedStates([]);
      setSelectedDevices([]);
      setDate(null);
      setSearch("");
      setSearchToFind("");
      setFilters([]);
    };
    resetFiltersOnChangeTab();
  }, [tab]);

  const footer = alertsLength > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end" px={3}>
      <Typography style={{ marginRight: "2px" }} className={classes.total}>
        {t("AlertsListTotal")}:
      </Typography>
      <Typography className={classes.total}>{alertsLength}</Typography>
    </Box>
  );

  const alertsByValueList =
    loadingTab0 || !alertsByValue ? (
      <TableSkeleton numRows={10} widthCard={"100%"} />
    ) : (
      alertsByValue && (
        <AlertsByValueList
          alerts={alertsByValue}
          actorId={id}
          actorRole={actorRole ?? Role.user}
          setAlertAck={setAlertAck}
          setAlertState={setAlertState}
          showExternalId={company?.showExternalId}
          setAlertComments={setAlertComments}
          timezone={timezone}
          onPageChange={setPageByValue}
          onPageSizeChange={setPageSizeByValue}
          paginationInfo={alertsByValuePaginationInfo}
          getUrl={getUrl}
          updateManyMeasurements={updateMany}
          delMeasurement={del}
          delManyMeasurements={delByReadDevice}
          findAllByReadDevice={findAllByReadDevice}
          getDevice={getDevice}
          sortBy={sortByTab0}
          onSortChange={(newSort: GridSortModel) =>
            setSortByTab0(newSort as GridSortItem[])
          }
        />
      )
    );
  const alertsByNoMeasureList =
    loadingTab1 || !alertsByNoMeasure ? (
      <TableSkeleton numRows={10} widthCard={"100%"} />
    ) : (
      alertsByNoMeasure && (
        <AlertsByNoMeasureList
          alerts={alertsByNoMeasure}
          actorId={id}
          actorRole={actorRole ?? Role.user}
          setAlertAck={setAlertAck}
          setAlertState={setAlertState}
          showExternalId={company?.showExternalId}
          setAlertComments={setAlertComments}
          timezone={timezone}
          onPageChange={setPageNoMeasure}
          onPageSizeChange={setPageSizeNoMeasure}
          paginationInfo={alertsByNoMeasurePaginationInfo}
          sortBy={sortByTab1}
          onSortChange={(newSort: GridSortModel) =>
            setSortByTab1(newSort as GridSortItem[])
          }
        />
      )
    );

  const alertsByFrequencyList =
    loadingTab2 || !alertsByFrequency ? (
      <TableSkeleton numRows={10} widthCard={"100%"} />
    ) : (
      alertsByFrequency && (
        <AlertsByFrequencyList
          alerts={alertsByFrequency}
          actorId={id}
          actorRole={actorRole ?? Role.user}
          setAlertAck={setAlertAck}
          setAlertState={setAlertState}
          showExternalId={company?.showExternalId}
          setAlertComments={setAlertComments}
          timezone={timezone}
          onPageChange={setPageFrequency}
          onPageSizeChange={setPageSizeFrequency}
          paginationInfo={alertsByFrequencyPaginationInfo}
          sortBy={sortByTab2}
          onSortChange={(newSort: GridSortModel) =>
            setSortByTab2(newSort as GridSortItem[])
          }
        />
      )
    );

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        { url: "", name: t("Alerts") },
      ],
    } as BreadcrumbProps;
  };

  const actorName = profile?.data?.name ? profile!.data!.name : "";

  // Advanced search properties
  const searchBoxProp: ISearchBoxProp = new SearchBoxProp(
    t("AlertSearch"),
    t("AlertSearchPlaceholder")
  );

  // Map devices to filterColumns
  const { response: devicesResponse } = useDevices(
    true,
    processError(logout, navigate, t)
  );
  const devices = devicesResponse?.data;

  // Handle search
  const handleSearch = (newSearch: string) => {
    setSearch(newSearch);
  };

  // Advanced search component
  const advancedSearchComponent = (
    <AdvancedSearch
      searchBoxProp={searchBoxProp}
      handleNewSimpleSearch={handleSearch}
      handleNewFilterSearch={() => null}
    ></AdvancedSearch>
  );

  const alertStateSelect = (
    <MultipleSelect
      placeholder={t("AlertState")}
      options={getReadableAlertStates().map((state) =>
        t(formatAlertState(state))
      )}
      selectedValues={selectedStates}
      setSelectedValues={setSelectedStates}
    />
  );

  const devicesSelect = (
    <MultipleSelect
      placeholder={t("Devices")}
      options={devices ? devices.map((device) => t(device.name)) : []}
      selectedValues={selectedDevices}
      setSelectedValues={setSelectedDevices}
    />
  );

  const datePicker = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          defaultValue={null}
          label={t("CreationDate")}
          value={date}
          disableFuture
          onChange={(newValue) => {
            setDate(newValue ?? null);
          }}
          slotProps={{ textField: { fullWidth: true } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );

  return (
    <Page
      title={""}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps(actorName)}
    >
      {advancedSearchComponent}
      <Grid container spacing={2} marginTop={1} marginBottom={1}>
        <Grid size={{ xs: 12, md: 4, lg: 4, xl: 4 }}>
          <div style={{ marginTop: "8px" }}>{alertStateSelect}</div>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 4, xl: 4 }}>
          <div style={{ marginTop: "8px" }}>{devicesSelect}</div>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 4, xl: 4 }}>{datePicker}</Grid>
      </Grid>

      <Tabs value={tab} onChange={handleTabChange} orientation="horizontal">
        <Tab label={t("HeaderAlertByValue")} {...a11yProps(0)} />
        <Tab label={t("HeaderAlertByNoMeasure")} {...a11yProps(1)} />
        <Tab label={t("HeaderAlertByFrequency")} {...a11yProps(2)} />
      </Tabs>
      <TabPanel key={`panel-0`} value={tab} index={0}>
        {alertsByValueList}
      </TabPanel>
      <TabPanel key={`panel-1`} value={tab} index={1}>
        {alertsByNoMeasureList}
      </TabPanel>
      <TabPanel key={`panel-2`} value={tab} index={2}>
        {alertsByFrequencyList}
      </TabPanel>
    </Page>
  );
};

export { Alerts };
