import React from "react";
import { Page } from "../Page";
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";

const AdministrationPage = (): React.ReactElement => {
  const { t } = useTranslation();
  const getBreadCrumbProps = (
    base1: string,
    base2: string
  ): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: base1 },
        { url: "", name: base2 },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page
      title={""}
      breadCrumbProps={getBreadCrumbProps(t("Administration"), t("General"))}
    >
      <Grid container spacing={2} columns={12} size={{ xs: 12 }}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Stack spacing={2}>
            <p>{t("Company")}</p>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.CompanyEdit.url}
            >
              {t(BreadcrumbNameMap.CompanyEdit.name)}
            </Button>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Stack spacing={2}>
            <p>{t("Staff")}</p>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AdministrationAdminCompanies.url}
            >
              {t("Administrators")}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AdministrationDoctors.url}
            >
              {t(BreadcrumbNameMap.AdministrationDoctors.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AdministrationNurses.url}
            >
              {t(BreadcrumbNameMap.AdministrationNurses.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AdministrationPatients.url}
            >
              {t(BreadcrumbNameMap.AdministrationPatients.name)}
            </Button>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Stack spacing={2}>
            <p>{t("Others")}</p>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AdminLocations.url}
            >
              {t(BreadcrumbNameMap.Locations.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.TrackingTypes.url}
            >
              {t(BreadcrumbNameMap.TrackingTypes.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AlertGroups.url}
            >
              {t(BreadcrumbNameMap.AlertGroups.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.AlertConfigTemplates.url}
            >
              {t(BreadcrumbNameMap.AlertConfigTemplates.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.ExternalReferences.url}
            >
              {t(BreadcrumbNameMap.ExternalReferences.name)}
            </Button>
            <Button
              variant="contained"
              component={Link}
              to={BreadcrumbNameMap.Forms.url}
            >
              {t(BreadcrumbNameMap.Forms.name)}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
};

export { AdministrationPage };
