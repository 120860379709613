import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../Page";
import { processError } from "../../../../../App/errorToast";
import { IUseLastAlertsByNoMeasureByPatient } from "@doctomatic/sdk/build/modules/LastAlertsByNoMeasureByPatient";
import LastAlertsByNoMeasureByPatientDataRow from "./LastAlertsByNoMeasureByPatientDataRow";
import { LastAlertsByNoMeasureByPatientGridColumns } from "./LastAlertsByNoMeasureByPatientGridColumns";
import {
  showAlertStateModal,
  showAlertSubscribersModal,
} from "../../../Alerts/utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useLoading } from "../../../../Loading/Loading";
import {
  AlertState,
  AlertTypeAlert,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { ItemAlertByNoMeasureList } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { useNavigate } from "react-router-dom";

export interface ILastAlertsByNoMeasureByPatientListProps {
  actorId: number;
  patientId: number;
  actorRole: Role;
}

export const LastAlertsByNoMeasureByPatientList = (
  props: ILastAlertsByNoMeasureByPatientListProps
): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    useLastAlertsByNoMeasureByPatient,
    useAlerts,
    logout,
    timezone,
  }: IProvideApi = useApi();
  const [lastAlerts, setLastAlerts] = useState<ItemAlertByNoMeasureList[]>([]);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);
  const { setIsLoading, isLoading } = useLoading();
  const { updateAlertState, updateAlertComments } = useAlerts();

  // Declaration of hooks to handle pagination
  const [pageSizeByNoMeasure, setPageSizeByNoMeasure] = useState<number>(10);
  const [pageByNoMeasure, setPageByNoMeasure] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageByNoMeasure,
      limit: pageSizeByNoMeasure,
    };
  }, [pageByNoMeasure, pageSizeByNoMeasure]);

  const {
    response,
    mutate: mutateAlertsByNomesure,
  }: IUseLastAlertsByNoMeasureByPatient = useLastAlertsByNoMeasureByPatient(
    props.actorId,
    props.actorRole,
    props.patientId,
    true,
    paginationValue,
    processError(logout, navigate, t)
  );

  const alertsByNoMeasure = response?.data;
  const alertsByNoMeasurePaginationInfo = response?.meta;

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, {
          state,
        });
        mutateAlertsByNomesure();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      setIsLoading(true);
      const response = await updateAlertComments(id, {
        comments,
        type: AlertTypeAlert.alert_by_no_measure,
      });
      mutateAlertsByNomesure();
      setIsLoading(false);
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    if (alertsByNoMeasure) setLastAlerts(alertsByNoMeasure);
  }, [alertsByNoMeasure, alertsByNoMeasurePaginationInfo]);

  const openAckByModal = (item: any) => {
    setItem(item);
  };
  const openAlertStateModal = (id: number, state: any) => {
    setCurrentAlertState({ id, state });
  };

  const lastAlertsGridColumns: GridColDef<LastAlertsByNoMeasureByPatientDataRow>[] =
    LastAlertsByNoMeasureByPatientGridColumns(
      timezone,
      openAckByModal,
      openAlertStateModal,
      setAlertComments
    );

  const lastAlertsDataRows: LastAlertsByNoMeasureByPatientDataRow[] = lastAlerts
    ? lastAlerts
    : [];
  const [item, setItem] = useState(null);

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone: timezone,
    });

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  return (
    <Page title={t("LastAlertsByNoMeasureTitle")}>
      <FlexLayoutGrid
        language={i18n.language}
        columns={lastAlertsGridColumns as any}
        rows={lastAlertsDataRows}
        onPageChange={setPageByNoMeasure}
        onPageSizeChange={setPageSizeByNoMeasure}
        pagination={alertsByNoMeasurePaginationInfo}
        isLoading={isLoading}
      />
      {showModal}
      {alertStateModal}
    </Page>
  );
};
