import { useTranslation } from "react-i18next";
import { AlertConfigByValue, TimeUnit } from "./alertConfig.interface";
import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ConditionsTooltip from "../Alerts/ConditionsTooltip";
import { alertTypeMap } from "../Pages/AlertConfigs/utils";
import { useCallback } from "react";
import TimeRangeTooltip from "../Alerts/TimeRangeTooltip";

export interface AlertConfigByValueBaseProps {
  time: number | undefined;
  setTime: (value: number) => void;
  timeUnit: string;
  setTimeUnit: (value: string) => void;
  condition: string;
  setCondition: (value: string) => void;
  alertConfig: any;
  setAlertConfig: (value: any) => void;
  signUnit: string;
  errors: any;
}
export const AlertConfigByValueBaseForm = ({
  time,
  setTime,
  timeUnit,
  setTimeUnit,
  condition,
  setCondition,
  alertConfig,
  setAlertConfig,
  signUnit,
  errors,
}: AlertConfigByValueBaseProps): React.ReactElement => {
  const { t } = useTranslation();
  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];
  const isRange: boolean =
    condition === "range_absolute" || condition === "range_relative";
  const isTime: boolean =
    condition === "time_absolute" || condition === "time_relative";

  const timeUnitMenuItems: JSX.Element[] = timeUnits.map(
    (t: TimeUnit, index: number) => (
      <MenuItem key={index} value={t.value}>
        {t.text}
      </MenuItem>
    )
  );

  const conditionMenuItems: JSX.Element[] = alertTypeMap.map(
    (c: string[], index: number) => (
      <MenuItem key={index} value={c[0]}>
        {t(c[1])}
      </MenuItem>
    )
  );

  // ============================== HANDLERS ==============================

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleCondition = (value: any): void => {
    const overrideAlertConfig: Partial<AlertConfigByValue> = {
      min: undefined,
      max: undefined,
      range_value: undefined,
      range_time: undefined,
      alert_type: value,
    };

    if (value === "range_absolute" || value === "range_relative") {
      overrideAlertConfig.range_value = 1;
    }

    if (value === "time_absolute" || value === "time_relative") {
      overrideAlertConfig.range_value = 1;
      overrideAlertConfig.range_time = "1h";
      if (!time) {
        setTime(1);
        setTimeUnit("h");
      }
    }

    setAlertConfig({
      ...alertConfig,
      ...overrideAlertConfig,
    });
    setCondition(value);
  };

  // ============================== COMPONENTS ==============================

  const gtEqualsComponent = (
    <FormControl sx={{ m: 1, minWidth: 160 }}>
      <TextField
        type="number"
        label={t("FieldMin")}
        value={alertConfig.min}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleAlertConfig(e.target.value, "min")}
        InputProps={{ inputProps: { min: 0 } }}
        helperText={signUnit}
        error={errors.min}
        required
      />
    </FormControl>
  );

  const ltComponent = (equal: boolean) => (
    <FormControl sx={{ m: 1, minWidth: 160 }}>
      <TextField
        type="number"
        label={equal ? t("FieldMax") : t("FieldMaxNotEq")}
        value={alertConfig.max}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleAlertConfig(e.target.value, "max")}
        InputProps={{ inputProps: { max: 0 } }}
        helperText={signUnit}
        error={errors.max}
      />
    </FormControl>
  );

  const timeRangeComponent = (
    <TimeRangeTooltip
      value={time}
      unit={timeUnit}
      onChangeTime={(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => setTime(parseInt(e.target.value))}
      onChangeUnit={(e: SelectChangeEvent) => setTimeUnit(e.target.value)}
      menuItems={timeUnitMenuItems}
      helperText={`${t("FieldRangeTime")}`}
      error={errors.range_time}
    />
  );

  return (
    // Condition
    // Condition value

    <>
      <ConditionsTooltip
        value={condition}
        menuItems={conditionMenuItems}
        onChange={handleCondition}
      />
      {condition === "min" && gtEqualsComponent}
      {condition === "max" && ltComponent(true)}
      {condition === "get_and_lt" && [
        <div></div>,
        gtEqualsComponent,
        ltComponent(false),
      ]}
      {(isRange || isTime) && (
        <>
          <FormControl required sx={{ m: 1, minWidth: 120 }}>
            <TextField
              type="number"
              label={t("FieldMaxNotEq")}
              value={alertConfig.range_value}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleAlertConfig(e.target.value, "range_value")}
              InputProps={{ inputProps: { min: 0 } }}
              helperText={condition.includes("relative") ? "+/- %" : signUnit}
              error={errors.range_value}
            />
          </FormControl>
          {isTime && timeRangeComponent}
        </>
      )}
    </>
  );
};
