import { GridColDef } from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import { ChangeEvent } from "react";
import { IPermissionUserDto } from "@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission";

export const PermissionListGridColumns = (
  onChangeSwitch: (
    row: IPermissionUserDto,
    event: ChangeEvent<HTMLInputElement>
  ) => void,
  t: (label: string) => string
): GridColDef<IPermissionUserDto>[] => {
  return [
    {
      field: "name",
      headerName: t("ColumnHeaderPermisssion"),
      width: 400,
      align: "left",
      renderCell: (params: { row: IPermissionUserDto }) => {
        const permission = params.row.name.split(".");
        if (permission[1] === "user_permission")
          return t(
            permission[0].charAt(0).toUpperCase() +
              permission[0].slice(1) +
              permission[1].charAt(0).toUpperCase() +
              permission[1].slice(1)
          );
        return `${t(
          permission[0].charAt(0).toUpperCase() + permission[0].slice(1)
        )} 
                ${t(
                  permission[1].charAt(0).toUpperCase() + permission[1].slice(1)
                )}
                `;
      },
    },
    {
      field: "iconEdit",
      headerName: t("Select"),
      width: 100,
      valueGetter: (value: any, row: any) =>
        row.userPermissionId ? true : false,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.userPermissionId ? true : false}
            onChange={(event) => {
              onChangeSwitch(params.row, event);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];
};
