import { ViewAlertsCell } from "@doctomatic/components-react/build/Alerts/ViewAlertsCell";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { GridColDef } from "@mui/x-data-grid";
import AlertsByFrequencyDataRow from "./AlertsByFrequencyDataRow";
import { Checkbox } from "@mui/material";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link, NavLink } from "react-router-dom";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { sortText } from "../../../../App/sortFunctions";
import { toLocaleString } from "../../utils";
import { Launch } from "@mui/icons-material";

export const AlertsByFrequencyGridColumns = (
  t: (text: string) => string,
  showExternalId: boolean | undefined,
  timezone: string,
  showModal?: (item: any) => void,
  showStateModal?: (id: number, item: any) => void,
  onSaveComments?: (
    alertId: number,
    comment: string | undefined
  ) => Promise<boolean>
): GridColDef<AlertsByFrequencyDataRow>[] => {
  //TODO add viewModals
  const gridColumns: GridColDef<AlertsByFrequencyDataRow>[] = [
    {
      field: "color",
      headerName: t("Level"),
      width: 75,
      align: "center",
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => row.color,
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        return (
          <ViewAlertsCell
            alerts={[{ id: params.row.id, color: params.row.color }]}
            row={undefined}
            viewModal={() => <p></p>}
          />
        );
      },
    },
    {
      field: "state",
      headerName: t("AlertState"),
      width: 120,
      align: "center",
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => row.state,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        const alertStateCapitalized =
          params.row.state[0].toUpperCase() +
          params.row.state.substr(1).toLowerCase();
        return (
          <TextWordsQuantityCell
            onClickModal={() =>
              showStateModal!(params.row.id, params.row.state)
            }
            item={params.row}
            words={[t(alertStateCapitalized)]}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "comments",
      headerName: t("Comments"),
      width: 95,
      align: "center",
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => row.comments,
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        return (
          <ElementGridStringEdit
            textCancel={t("Cancel")}
            textSave={t("Save")}
            textLabelField={t("Comments")}
            itemToAddCommentId={params.row.id}
            text={params.row.comments}
            onSave={
              onSaveComments
                ? onSaveComments
                : async (): Promise<boolean> => {
                    return true;
                  }
            }
            onClose={() => {}}
          />
        );
      },
    },
    {
      field: "patient.name",
      headerName: t("Patient"),
      width: 250,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) =>
        row.patient.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        return (
          <TextWordsQuantityCell
            item={params.row}
            words={[params.row.patient.name]}
            page={BreadcrumbNameMap.Patient.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "created_at",
      headerName: t("Date"),
      width: 150,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) =>
        row.created_at,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        return <p>{toLocaleString(params.row.created_at)}</p>;
      },
    },
    {
      field: "typeAlert",
      headerName: t("HeaderLabel"),
      width: 200,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => row.label,
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        const alertConfigUrl =
          BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
            ":patientId",
            params.row.patient.id.toString()
          );
        const content: JSX.Element = (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {params.row.individualConfig && params.row.individualConfig.id && (
              <>
                <NavLink
                  to={alertConfigUrl.concat(
                    `?alertConfigId=${params.row.individualConfig.id}`
                  )}
                >
                  <Launch />
                </NavLink>
                <p style={{ marginLeft: 5 }}></p>
                <p>{params.row.label}</p>
              </>
            )}
          </div>
        );
        return content;
      },
    },
    {
      field: "ackBy",
      headerName: t("HeaderAckBy"),
      width: 200,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => {
        const texts = row.ackBy && row.ackBy.map((ack: any) => ack.user.name);
        if (texts.length === 0) texts.push("---");
        return texts.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack) => ack.user.name);
        if (texts.length === 0) texts.push("---");

        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={texts}
            onClickModal={() => showModal!(params.row)}
            tooltipContent={{ title: t("AckBy"), lines: texts }}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "active",
      headerName: t("ConfigAlert"),
      width: 200,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) =>
        row.individualConfig
          ? row.individualConfig.active
            ? t("Active")
            : t("Deactive")
          : t("Active"),
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => {
        const active = params.row.individualConfig
          ? params.row.individualConfig.active
          : params.row.templateConfig?.active;
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={[active ? t("Active") : t("Deactive")]}
            page={BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "setAck",
      headerName: t("HeaderActions"),
      width: 200,
      valueGetter: (value: any, row: AlertsByFrequencyDataRow) => row.checkbox,
      sortable: false,
      renderCell: (params: { row: AlertsByFrequencyDataRow }) => (
        <Checkbox
          checked={params.row.checkbox}
          disabled={params.row.checkbox}
          onChange={() => {
            params.row.onClickCheckbox(params.row);
          }}
        />
      ),
    },
  ];

  return gridColumns;
};
