import React from "react";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AdminCompanyListGridColumns } from "./AdminCompanyListDataGridColumns";
import { ItemAdminCompanyListDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { GridRowClassNameParams } from "@mui/x-data-grid";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";

interface Props {
  showDeleted: boolean;
  onEdit: (adminCompany: ItemAdminCompanyListDto) => void;
  onDelete: (adminCompany: ItemAdminCompanyListDto) => void;
  onSendSms: (adminCompany: ItemAdminCompanyListDto) => void;
  onSendEmail: React.Dispatch<
    React.SetStateAction<{ id: number; name: string; email?: string } | null>
  >;
  onRestore: (adminCompany: ItemAdminCompanyListDto) => void;
  quantityDeleted: (quantity: number) => void;
}

const AdminCompanyList = ({
  showDeleted,
  onDelete,
  onEdit,
  onSendSms,
  onSendEmail,
  onRestore,
  quantityDeleted,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const { useDiseases, useProfile, useAdminCompanies, role, logout } = useApi();
  const { t, i18n } = useTranslation();
  const { response: responseGetAllAdminCompany } = useAdminCompanies(
    showDeleted,
    true,
    processError(logout, navigate, t)
  );
  const { response: diseases } = useDiseases(
    undefined,
    true,
    processError(logout, navigate, t)
  );
  const { response: profile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = React.useState<string>("");

  if (!responseGetAllAdminCompany) return <></>;
  const adminCompanys: ItemAdminCompanyListDto[] =
    responseGetAllAdminCompany?.data;
  if (!adminCompanys || !diseases || !profile) return <></>;

  const iamAdmin = role === Role.admin;
  const iamDoctor = role === Role.doctor;
  const iamNurse = role === Role.nurse;
  const iamTeam = iamNurse || iamAdmin || iamDoctor;

  const actions = (adminCompany: ItemAdminCompanyListDto) => {
    const onClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(`m${adminCompany.id}`);
    };

    const onClose = () => {
      setAnchorEl(null);
      setPopoverId("");
    };

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={`m${adminCompany.id}`}
          open={popoverId === `m${adminCompany.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            {!adminCompany.deletedAt && (
              <>
                <ListItemButton
                  onClick={() => {
                    onClose();
                    onEdit(adminCompany);
                  }}
                >
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t("ButtonEdit")} ${t("FieldAdminCompany")}`}
                  />
                </ListItemButton>

                <ListItemButton
                  disabled={!adminCompany.haveEmail}
                  onClick={() => {
                    onClose();
                    onSendSms(adminCompany);
                  }}
                >
                  <ListItemIcon>
                    <TextsmsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendSMS")} />
                </ListItemButton>

                <ListItemButton
                  disabled={!adminCompany.email}
                  onClick={() => {
                    onClose();
                    onSendEmail({
                      id: adminCompany.id,
                      name: adminCompany.name,
                      email: adminCompany.email,
                    });
                  }}
                >
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendEmail")} />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    onClose();
                    onDelete(adminCompany);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t("ButtonDelete")} ${t("FieldAdminCompany")}`}
                  />
                </ListItemButton>
              </>
            )}
            {iamTeam && adminCompany.deletedAt && (
              <ListItemButton
                onClick={() => {
                  onClose();
                  onRestore(adminCompany);
                }}
              >
                <ListItemIcon>
                  <RestoreFromTrashOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${t("ButtonRestore")} ${t("FieldAdminCompany")}`}
                />
              </ListItemButton>
            )}
          </List>
        </Popover>
      </>
    );
  };

  if (!adminCompanys) return <></>;
  quantityDeleted(adminCompanys.filter((x) => x.deletedAt).length);

  const datagridColumns = AdminCompanyListGridColumns(
    t,
    onEdit,
    onDelete,
    onRestore,
    actions
  );

  const setRowClassName = (
    params: GridRowClassNameParams<ItemAdminCompanyListDto>
  ): string => {
    if (params.row.deletedAt) return "super-app-theme--Rejected";
    return "";
  };

  return (
    <FlexLayoutGrid
      language={i18n.language}
      columns={datagridColumns as any}
      rows={adminCompanys}
      setRowClassName={setRowClassName as any}
      sx={DoctomaticStylingRowsGrid() as any}
    />
  );
};

export { AdminCompanyList };
