import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Box, Link, Typography } from "@mui/material";
import { AlertBox } from "./AlertBox";
export var ViewAlertsCell = function (props) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var firstAlerts = props.alerts.length > 3 ? props.alerts.slice(0, 3) : props.alerts;
    var handleClose = function () { return setOpen(false); };
    var containerClassName = "alerts-container-".concat(Math.floor(Math.random() * 1000));
    var PageLink = function () {
        React.createElement(Link, { id: containerClassName, href: props.page, style: { whiteSpace: "nowrap", color: "#1f28a1" } });
    };
    return (React.createElement(React.Fragment, null, firstAlerts && firstAlerts.length > 0 ? (props.viewModal ? (React.createElement(Box, { width: "100%", style: { cursor: "pointer", height: "100%" }, display: "flex", justifyContent: "center", alignItems: "center", onClick: function () { return setOpen(true); } },
        firstAlerts.map(function (alert) { return (React.createElement(AlertBox, { key: alert.id, id: alert.id, color: alert.color })); }),
        React.createElement(Typography, null, props.alerts && props.alerts.length > 3
            ? "+".concat(props.alerts.length - 3)
            : ""),
        open &&
            props.viewModal({
                open: open,
                handleClose: handleClose,
                item: props.row,
            }))) : (React.createElement("a", { href: props.page },
        React.createElement(Box, { width: "100%", style: { cursor: "pointer", height: "100%" }, display: "flex", justifyContent: "center", alignItems: "center" },
            firstAlerts.map(function (alert) { return (React.createElement(AlertBox, { key: alert.id, id: alert.id, color: alert.color })); }),
            React.createElement(Typography, null, props.alerts && props.alerts.length > 3
                ? "+".concat(props.alerts.length - 3)
                : ""))))) : (React.createElement("p", null, "--"))));
};
