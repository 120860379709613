import React from "react";
import { useApi, IProvideApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertGroups } from "@doctomatic/sdk/build/modules/AlertGroup/AlertGroup";
import { GetAlertGroupResponseDto } from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup"
import { useTranslation } from "react-i18next";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { Subscribers } from "../../../Subscribers/Subscribers";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { ResponseApi } from "@doctomatic/sdk/build/global";

const AlertGroupSubscribers = (): React.ReactElement => {
  const { id: alertGroupId }: any = useParams();
  const { useAlertGroups }: IProvideApi = useApi();
  const { t } = useTranslation();
  const { findOneById, subscribe, unsubscribe }: IUseAlertGroups = useAlertGroups();
  const [alertGroup, setAlertGroup] = useState<GetAlertGroupResponseDto>()

  useEffect(() => {
    const getAlertGroup = async (alertGroupId: number) => {
      try {
        const alertGroupResponse: ResponseApi<GetAlertGroupResponseDto> = await findOneById(alertGroupId)
        if (alertGroupResponse.success === true) {
          setAlertGroup(alertGroupResponse.data)
        }
      } catch (err: any) { }
    }
    if (alertGroupId) {
      getAlertGroup(+alertGroupId)
    }
  }, [alertGroupId])
  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: BreadcrumbNameMap.AlertGroups.url, name: t(BreadcrumbNameMap.AlertGroups.name) },
        { url: '', name: alertGroup?.name },
        { url: '', name: t('Subscribers') }
      ],
    } as BreadcrumbProps;
  };

  return (
    <Subscribers
      item={alertGroup}
      subscribe={subscribe}
      unsubscribe={unsubscribe}
      breadcrumbs={getBreadCrumbProps()} />
  );
};

export { AlertGroupSubscribers };
