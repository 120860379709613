import { Expose } from "class-transformer";
import { BasicItem, IBasicItem } from "../../../IItemList";
import {
  AddAlertConfigRequestDto,
  AddAlertConfigResponseDto,
  AlertConfigDto,
  GetAlertConfigResponseDto,
  ItemAlertConfigDto,
  UpdateAlertConfigRequestDto,
  UpdateAlertConfigResponseDto
} from "../AlertConfig";

import {
  IAddAlertConfigTemplateRequestDto,
  IAddAlertConfigTemplateResponseDto,
  IAlertConfigTemplateBasicDto,
  IAlertConfigTemplateDto,
  IGetAlertConfigTemplateResponseDto,
  IItemAlertConfigTemplateDto,
  IUpdateAlertConfigTemplateRequestDto,
  IUpdateAlertConfigTemplateResponseDto,
} from "./IAlertConfigTemplate";

export class AlertConfigTemplateBasicDto extends AlertConfigDto implements IAlertConfigTemplateBasicDto {
  alertGroups: BasicItem[] = [];

}
export class AlertConfigTemplateDto extends AlertConfigDto implements IAlertConfigTemplateDto {
  alertGroups: BasicItem[] = [];
}

/** ADD */
export class AddAlertConfigTemplateRequestDto
  extends AddAlertConfigRequestDto
  implements IAddAlertConfigTemplateRequestDto {
}

export class AddAlertConfigTemplateResponseDto
  extends AddAlertConfigResponseDto
  implements IAddAlertConfigTemplateResponseDto { }

/** UPDATE */
export class UpdateAlertConfigTemplateRequestDto
  extends UpdateAlertConfigRequestDto
  implements IUpdateAlertConfigTemplateRequestDto { }

export class UpdateAlertConfigTemplateResponseDto
  extends UpdateAlertConfigResponseDto
  implements IUpdateAlertConfigTemplateResponseDto { }

/** GET */
export class GetAlertConfigTemplateResponseDto
  extends GetAlertConfigResponseDto
  implements IGetAlertConfigTemplateResponseDto { }

export class ItemAlertConfigTemplateDto
  extends ItemAlertConfigDto
  implements IItemAlertConfigTemplateDto {
}