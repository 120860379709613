import React from "react";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { ItemLocationListResponseDto } from "@doctomatic/sdk/build/dto/Location/Location";
import { processError } from "../../../App/errorToast";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { LocationGridColumns } from "./LocationsGridColumns";
import LocationDataRow from "./LocationDataRow";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface ILocationsListProps {
  actorId: number;
  role: Role;
}

const LocationsList = ({
  actorId,
  role,
}: ILocationsListProps): React.ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const getRole = (role: Role): "doctors" | "nurses" | "patients" => {
    if (role === Role.doctor) return "doctors";
    if (role === Role.nurse) return "nurses";
    return "patients";
  };
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { useActorLocations, logout }: IProvideApi = useApi();
  const { response: locationsResponse } = useActorLocations(
    actorId,
    getRole(role),
    true,
    processError(logout, navigate, t)
  );
  const locations = locationsResponse?.data;

  if (!locations) return <></>;

  const locationGridColumns = LocationGridColumns(t);

  const locationToRow = (
    location: ItemLocationListResponseDto,
    parent?: LocationDataRow
  ): LocationDataRow[] => {
    const result = Array<LocationDataRow>();
    const newlocationDataRow: LocationDataRow = new LocationDataRow();
    newlocationDataRow.id = location.id;
    newlocationDataRow.name = location.name;
    newlocationDataRow.children = location.children.flatMap((x) =>
      locationToRow(x, newlocationDataRow)
    );
    newlocationDataRow.parent = parent;
    newlocationDataRow.quantityPatients = location.quantityPatients;

    result.push(newlocationDataRow);
    const r = location.children.flatMap((x) =>
      locationToRow(x, newlocationDataRow)
    );
    result.push(...r);
    return result;
  };

  const locationsDataRow: LocationDataRow[] = locations.flatMap((location) =>
    locationToRow(location, undefined)
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: isMobile ? "100%" : "50%",
      }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={locationGridColumns as any}
        rows={locationsDataRow}
      />
    </div>
  );
};

export { LocationsList };
