import { Expose } from 'class-transformer';
import { ICreateExternalReferenceRequestDto, ICreateExternalReferenceResponseDto, IGetExternalReferenceRequestDto, IGetExternalReferenceResponseDto, IUpdateExternalReferenceRequestDto, IUpdateExternalReferenceResponseDto } from './IExternalReferences';

export class GetExternalReferenceRequestDto implements IGetExternalReferenceRequestDto {
    @Expose() companyId: number = 0;
    @Expose() source: string = '';
    @Expose() reference: string = '';
}

export class GetExternalReferenceResponseDto implements IGetExternalReferenceResponseDto {
    @Expose() id: number = 0;
    @Expose() source: string = '';
    @Expose() reference: string = '';
    @Expose() userId: number = 0;
    @Expose() role: string = '';
}

export class CreateExternalReferenceRequestDto implements ICreateExternalReferenceRequestDto {
    @Expose() source: string = '';
    @Expose() reference: string = '';
    @Expose() userId: number = 0;
    @Expose() companyId?: number = 0;
}

export class CreateExternalReferenceResponseDto implements ICreateExternalReferenceResponseDto {
    @Expose() source: string = '';
    @Expose() reference: string = '';
    @Expose() userId: number = 0;
    @Expose() companyId: number = 0;
    @Expose() id: number = 0;
}

export class UpdateExternalReferenceRequestDto implements IUpdateExternalReferenceRequestDto {
    @Expose() source?: string = '';
    @Expose() reference?: string = '';
    @Expose() userId?: number = 0;
}

export class UpdateExternalReferenceResponseDto implements IUpdateExternalReferenceResponseDto {
    @Expose() id: number = 0;
    @Expose() source: string = '';
    @Expose() reference: string = '';
    @Expose() userId: number = 0;
    @Expose() companyId: number = 0;
}