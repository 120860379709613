import { Trans } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../../Page";
import { toast } from "react-toastify";
import { NurseList } from "./NurseList";
import { NurseCreate } from "./NurseCreate";
import { NurseEdit } from "./NurseEdit";
import { useLoading } from "../../../Loading/Loading";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  AddNurseResponseDto,
  GetNurseResponseDto,
  ItemNurseListDto,
} from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { processError } from "../../../../App/errorToast";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { AddCircle } from "@mui/icons-material";
import { ConfirmationRegisterModal } from "@doctomatic/components-react/build/ConfirmationRegisterModal/ConfirmationRegisterModal";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "0 9px",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

export const Nurses = (): React.ReactElement => {
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const navigate = useNavigate();
  const { useSMS, useMail, useNurses, logout } = useApi();
  const { t } = useTranslation();

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const {
    response: responseGetAllNurse,
    add,
    patch,
    restore,
    del,
    get,
  } = useNurses(
    showDeleted,
    pagination,
    true,
    processError(logout, navigate, t)
  );
  const { sendRegistrationEmail } = useMail();
  const { sendRegistrationSMS } = useSMS();
  const { setIsLoading } = useLoading();
  const classes = useStyles();
  const [quantityDeleted, setQuantityDeleted] = useState<number>(0);
  const [isUserFormOpen, setIsUserFormOpen] = useState<boolean>(false);
  const [nurseToDelete, setUserToDelete] = useState<ItemNurseListDto | null>(
    null
  );
  const [nurseToRestore, setUserToRestore] = useState<ItemNurseListDto | null>(
    null
  );
  const [nurseToEdit, setUserToEdit] = useState<ItemNurseListDto | null>(null);
  const [nurseToEditDto, setUserToEditDto] =
    useState<GetNurseResponseDto | null>(null);
  const [nurseToSendSMS, setUserToSendSMS] = useState<{
    id: number;
    name: string;
    phone?: string;
  } | null>(null);
  const [nurseToSendEmail, setNurseToSendEmail] = useState<{
    id: number;
    name: string;
    email?: string;
  } | null>(null);
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);

  useEffect(() => {
    const cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    const getNurseDto = async (id: number) => {
      const response = await get(id);
      if (response && response.data) setUserToEditDto(response.data);
    };
    if (nurseToEdit !== null) getNurseDto(nurseToEdit.id);
  }, [nurseToEdit]);

  const title = t("HeaderNurses");

  if (!responseGetAllNurse) return <></>;
  const nurses: ItemNurseListDto[] = responseGetAllNurse.data;
  if (!nurses) return <></>;

  const nursesPaginationInfo = responseGetAllNurse?.meta;

  const buttons = permissions &&
    permissions.find((p) => {
      return p.key === "create.nurse";
    }) && (
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={() => setIsUserFormOpen(true)}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
    );

  const footer = nurses.length > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Typography className={classes.total}>
        {t("UserListTotal")}: {nurses.length}
      </Typography>
      {showDeleted && (
        <Typography className={classes.total}>
          {t("UserListDeleted")} : {quantityDeleted}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            style={{ padding: "0 9px" }}
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
          />
        }
        label={
          <Typography className={classes.showDeletedLabel}>
            {t("UserListShowDeleted")}
          </Typography>
        }
      />
    </Box>
  );

  const confirmRestore = nurseToRestore && (
    <ConfirmModal
      open={Boolean(nurseToRestore)}
      content={
        <Trans
          i18nKey="UserListRestoreConfirm"
          values={{ user: t("FieldNurse"), name: nurseToRestore.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonRestore")}
      onCancel={() => setUserToRestore(null)}
      onClose={() => setUserToRestore(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await restore(nurseToRestore.id);
          setUserToRestore(nurseToRestore);
        } catch (err: any) {
          toast.error(
            `${t("UserListRestoreError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setUserToRestore(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmDelete = nurseToDelete && (
    <ConfirmModal
      open={Boolean(nurseToDelete)}
      content={
        <Trans
          i18nKey="UserListDeleteConfirm"
          values={{ user: t("FieldNurse"), name: nurseToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setUserToDelete(null)}
      onClose={() => setUserToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(nurseToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setUserToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const handleSendEmail = async () => {
    const { data } = await sendRegistrationEmail(
      nurseToSendEmail?.id as number
    );
    toast.info(data?.message);
  };

  const handleSendSms = async () => {
    try {
      const { data } = await sendRegistrationSMS(nurseToSendSMS?.id as number);
      if (process.env.REACT_APP_SHOW_MSG_SMS_CONSOLE === "true") {
        console.log(data?.message);
        toast.info(
          "Mira en la consola. Copia el enlace de On Boarding y pégalo en una nueva ventana en modo incógnito (sin token en localStorage)."
        );
        toast.info(data?.message);
      }
    } catch (err: any) {
      toast.error(
        `${t("UserListSMSError")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const nurseList = (
    <NurseList
      onDelete={setUserToDelete}
      onRestore={setUserToRestore}
      onEdit={setUserToEdit}
      onSendSms={setUserToSendSMS}
      onSendEmail={setNurseToSendEmail}
      setQuantityDeleted={setQuantityDeleted}
      nurses={nurses}
      setPage={setPage}
      setPageSize={setPageSize}
      nursesPaginationInfo={nursesPaginationInfo}
    />
  );

  const nurseCreateForm = (
    <NurseCreate
      open={isUserFormOpen}
      onClose={() => setIsUserFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          const response: ResponseApi<AddNurseResponseDto> = await add(user);
          setIsUserFormOpen(false);
          if (response && response.success === true && response.data!) {
            toast.info(`${t("NurseCreated")} ${response.data.name}`);
          }
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const nurseEditForm = nurseToEditDto && (
    <NurseEdit
      open={true}
      getNurseResponseDto={nurseToEditDto}
      onClose={() => {
        setUserToEditDto(null);
        setUserToEdit(null);
      }}
      onSave={async (nurse) => {
        setIsLoading(true);
        try {
          await patch(nurseToEditDto.id, nurse);
          setUserToEditDto(null);
          setUserToEdit(null);
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.AdministrationNurses.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page
      title={title}
      buttons={buttons}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps()}
    >
      {nurseList}
      {nurseCreateForm}
      {nurseEditForm}
      {confirmDelete}
      {confirmRestore}
      {nurseToSendEmail && (
        <ConfirmationRegisterModal
          userToSend={nurseToSendEmail}
          setUserToSend={setNurseToSendEmail}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendEmail}
          keyConfirmation={"UserListEmailConfirm"}
          keyError={"UserListEmailError"}
        />
      )}
      {nurseToSendSMS && (
        <ConfirmationRegisterModal
          userToSend={nurseToSendSMS}
          setUserToSend={setUserToSendSMS}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendSms}
          keyConfirmation={"UserListSMSConfirm"}
          keyError={"UserListSmsError"}
        />
      )}
    </Page>
  );
};
