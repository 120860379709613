import React, { useEffect, useState } from "react";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { DevicesList } from "./DevicesList";
import { IItemDeviceList } from "@doctomatic/sdk/build/dto/IItemList";

type IParams = {
  userId: string;
};

export const Devices = (): React.ReactElement => {
  const { userId: patientId } = useParams<IParams>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout, usePatient, useCompany, companyId }: IProvideApi = useApi();
  const [userId, setUserId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [devices, setDevices] = useState<IItemDeviceList[]>([]);
  const { response: company } = useCompany(companyId, true);
  const [bgColor, setBgColor] = useState<string>("");
  const [buttonColor, setButtonColor] = useState<string>("");
  const [autoRead, setAutoRead] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleModalOpen = (deviceId: number, deviceName: string) => {
    const basePath = `/devices/${deviceId}`;

    let path = "";
    if (deviceName === "Form") {
      path = `${basePath}/form/${userId}`;
    } else {
      localStorage.removeItem("capturedPhoto");
      path = `${basePath}/camera/${userId}`;
    }
    const url = searchParams ? `${path}?${searchParams.toString()}` : `${path}`;

    navigate(url);
  };

  const handleAutoReadModal = () => {
    localStorage.removeItem("capturedPhoto");
    if (searchParams) {
      navigate(`/devices/camera/${userId}?${searchParams.toString()}`);
    } else {
      navigate(`/devices/camera/${userId}`);
    }
  };

  const { findOneWithDevicesAndMeasurements } = usePatient(
    userId,
    false,
    processError(logout, navigate, t)
  );

  useEffect(() => {
    if (patientId) {
      setUserId(+patientId);
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        const decoded: any = jwtDecode(token);
        setUserId(decoded.sub);
      }
    }
    fetchUser();
  }, [userId, patientId]);

  useEffect(() => {
    if (company?.data?.buttonColor) {
      setButtonColor(company?.data?.buttonColor);
    }

    if (company?.data?.parametersUrl) {
      const param = JSON.parse(company?.data?.parametersUrl);
      if (param?.buttonColor) {
        setButtonColor(param?.buttonColor);
      }

      if (param?.bgColor) {
        setBgColor(param?.bgColor);
      }
    }
  }, [company]);

  const fetchUser = async () => {
    if (userId === 0) return;
    const user = await findOneWithDevicesAndMeasurements();
    if (user && user.additional_data) {
      setAutoRead(user.additional_data.autoRead);
    }
    if (user && user.data) {
      const parsedDevices = user.data.treatmentDevices.map((td) => {
        return td.device;
      });
      setDevices(parsedDevices);
    }
    setLoading(false);
  };

  return (
    <DevicesList
      loading={loading}
      devices={devices}
      displayOnlyDevices={false}
      autoRead={autoRead}
      handleOnClickDevice={handleModalOpen}
      buttonColor={buttonColor}
      bgColor={bgColor}
      handleOnClickAutoRead={handleAutoReadModal}
    />
  );
};
