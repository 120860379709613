import {
  CreateUserRequestDto,
  CreateUserResponseDto,
  GetUserResponseDto,
  UpdateProfileRequestDto,
  UpdateProfileResponseDto,
  UpdateUserRequestDto,
  UpdateUserResponseDto,
} from "../../User";
import { ItemActorListDto } from "../ActorItemListDto";
import { IAddNurseRequestDto, IAddNurseResponseDto, IGetNurseResponseDto, IItemNurseListDto, IUpdateNurseProfileRequestDto, IUpdateNurseProfileResponseDto, IUpdateNurseRequestDto, IUpdateNurseResponseDto } from "./INurse";

export class AddNurseRequestDto
  extends CreateUserRequestDto
  implements IAddNurseRequestDto {}

export class AddNurseResponseDto
  extends CreateUserResponseDto
  implements IAddNurseResponseDto {}

export class GetNurseResponseDto
  extends GetUserResponseDto
  implements IGetNurseResponseDto {}

export class UpdateNurseProfileRequestDto
  extends UpdateProfileRequestDto
  implements IUpdateNurseProfileRequestDto {}

export class UpdateNurseProfileResponseDto
  extends UpdateProfileResponseDto
  implements IUpdateNurseProfileResponseDto {}

export class UpdateNurseRequestDto
  extends UpdateUserRequestDto
  implements IUpdateNurseRequestDto {}

export class UpdateNurseResponseDto
  extends UpdateUserResponseDto
  implements IUpdateNurseResponseDto {}

export class ItemNurseListDto
  extends ItemActorListDto
  implements IItemNurseListDto
{
  locations: { id: number; name: string; }[] = [];
}