import moment from "moment-timezone";
import AlertConfigByNoMeasureDataRow from "./AlertConfigs/AlertConfigByNoMeasure/AlertConfigByNoMeasureDataRow";
import AlertConfigByValueDataRow from "./AlertConfigs/AlertConfigByValue/AlertConfigByValueDataRow";
import AlertConfigByFrequencyDataRow from "./AlertConfigs/AlertConfigByFrequency/AlertConfigByFrequencyDataRow";
import { SING_UNIT_NOT_SHOW } from "@doctomatic/constants/dist/constants";

export const toLocaleString = (date: Date | string): string => {
  let formattedDate: Date;
  if (typeof date === "string") {
    formattedDate = new Date(date);
  } else {
    formattedDate = date;
  }
  return formattedDate.toLocaleString("en-GB");
};

export const toLocaleDateString = (date: Date | string): string => {
  let formattedDate: Date;
  if (typeof date === "string") {
    formattedDate = new Date(date);
  } else {
    formattedDate = date;
  }
  return formattedDate.toLocaleDateString("en-GB");
};

export const toLocaleStringUserTimezone = (
  date: Date | string,
  timezone: string
): string => {
  let formattedDate = date;
  if (typeof date !== "string") {
    formattedDate = date.toISOString();
  }
  const momentDate = moment.tz(formattedDate, timezone);
  const offset = momentDate.utcOffset();
  return momentDate.add(offset, "minutes").format("DD-MM-YYYY HH:mm:ss");
};

export const toLocaleDateStringUserTimezone = (
  date: Date | string,
  timezone: string
): string => {
  let formattedDate = date;
  if (typeof date !== "string") {
    formattedDate = date.toISOString();
  }
  const momentDate = moment.tz(formattedDate, timezone);
  const offset = momentDate.utcOffset();

  return momentDate.add(offset, "minutes").format("DD-MM-YYYY");
};

export const checkAlertConfigByValuePathParam = (
  search: string,
  alertConfigs: AlertConfigByValueDataRow[]
) => {
  const queryParams = new URLSearchParams(search);
  const alertConfigId = queryParams.get("alertConfigId");
  if (!alertConfigId) return alertConfigs;
  const alertConfigsFiltered = alertConfigs.filter(
    (alertConfig) => alertConfig.id === +alertConfigId
  );
  return alertConfigsFiltered.length > 0 ? alertConfigsFiltered : alertConfigs;
};

export const checkAlertConfigByNoMeasurePathParam = (
  search: string,
  alertConfigs: AlertConfigByNoMeasureDataRow[]
) => {
  const queryParams = new URLSearchParams(search);
  const alertConfigId = queryParams.get("alertConfigId");
  if (!alertConfigId) return alertConfigs;
  const alertConfigsFiltered = alertConfigs.filter(
    (alertConfig) => alertConfig.id === +alertConfigId
  );
  return alertConfigsFiltered.length > 0 ? alertConfigsFiltered : alertConfigs;
};

export const checkAlertConfigByFrequencyPathParam = (
  search: string,
  alertConfigs: AlertConfigByFrequencyDataRow[]
) => {
  const queryParams = new URLSearchParams(search);
  const alertConfigId = queryParams.get("alertConfigId");
  if (!alertConfigId) return alertConfigs;
  const alertConfigsFiltered = alertConfigs.filter(
    (alertConfig) => alertConfig.id === +alertConfigId
  );
  return alertConfigsFiltered.length > 0 ? alertConfigsFiltered : alertConfigs;
};

export async function manipulateImage(): Promise<Blob> {
  const capturedPhoto = localStorage.getItem("capturedPhoto");
  if (!capturedPhoto)
    throw new Error("No captured photo found in local storage");

  const image = new Image();
  image.src = capturedPhoto;
  await new Promise((resolve) => (image.onload = resolve));

  const canvas = document.createElement("canvas");
  canvas.width = 415;
  canvas.height = 415;

  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Failed to get context from canvas");

  ctx.drawImage(image, 0, 0, 415, 415);
  const dataBlob: Blob = await getBlobFromCanvas(canvas);

  // Descomentar para descargar la imagen
  // const dataUrl = canvas.toDataURL('image/jpeg', 1);
  // const link = document.createElement('a');
  // link.href = dataUrl;
  // link.download = 'generated-image.jpg';
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);

  return dataBlob;
}

function getBlobFromCanvas(finalCanvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    finalCanvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Failed to get blob from final canvas"));
        } else {
          resolve(blob);
        }
      },
      "image/jpeg",
      1
    );
  });
}

export function getDeviceName(
  currentLanguage: string,
  device: any,
  t: any
): string {
  const deviceName = device.translations
    ? device.translations.deviceName
        .map((data: { lang: string; text: string }) => {
          if (data.lang === currentLanguage) {
            return data.text;
          }
          return null;
        })
        .filter((text: string | null) => text !== null)[0] || t(device.name)
    : t(device.name);
  return deviceName;
}

export function getMeasurementsInRead(
  currentLanguage: string,
  row: any,
  t: any
): string {
  const { device, measurementsInRead, aiResponse } = row || {};

  const data =
    measurementsInRead?.length > 0
      ? measurementsInRead
      : aiResponse?.proposedMeasurements?.length > 0
      ? aiResponse.proposedMeasurements
      : undefined;

  if (!data) return "--";

  let result = "";
  data?.forEach((m: any) => {
    const signName = getSignName(currentLanguage, m.sign, t);
    let name = signName;

    if (name == "") name = t(m.sign);

    if (device.type_device == "DeviceForm") {
      const form = JSON.parse(device.form);
      const question = form?.questions.filter((q: any) =>
        q.signId.some((signId: number) => signId === m.sign.id)
      );
      name = question
        ? getQuestionName(currentLanguage, question[0].text, t, signName)
        : signName;
    }

    if (!["image"].includes(m.sign.typeSign)) {
      result += `${name}: ${m.value}${getSingUnit(m.sign)}\n`;
    } else {
      result += `${name}: ${t("Image")}\n`;
    }
  });

  return result;
}

export function getSingUnit(sign: any) {
  if (SING_UNIT_NOT_SHOW.includes(sign.unit)) return "";
  return sign.unit.includes("percentage") ? "%" : sign.unit.replace("_", "/");
}

export function getSignName(
  currentLanguage: string,
  sign: any,
  t: any
): string {
  const signName = sign.translations
    ? sign.translations.signName
        .map((data: { lang: string; text: string }) => {
          if (data.lang === currentLanguage) {
            return data.text;
          }
          return null;
        })
        .filter((text: string | null) => text !== null)[0] || t(sign.name)
    : t(sign.name);
  return signName;
}

export function getQuestionName(
  currentLanguage: string,
  question: any,
  t: any,
  name: string
): string {
  const questionName = question
    ? question
        .map((data: { lang: string; text: string }) => {
          if (data.lang === currentLanguage) {
            return data.text;
          }
          return null;
        })
        .filter((text: string | null) => text !== null)[0] || t(name)
    : t(name);
  return questionName;
}
