import React, { useState } from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Trans, useTranslation } from "react-i18next";
import { PatientGridColumns } from "./PatientGridColumns";
import PatientDataRow from "./PatientDataRow";
import { IItemPatientListExtendedResponseDto } from "@doctomatic/sdk/build/dto/Patient/IPatient";
import { toast } from "react-toastify";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { PatientEdit } from "./PatientEdit";
import { useLoading } from "../../Loading/Loading";
import { DoctomaticStylingRowsGrid } from "../../Theme/ThemeDataGrid";
import { PatientDetail, UserFrequency } from "../Users/UserFrequencyModal";
import { GetActorResponseDto } from "@doctomatic/sdk/build/modules/Profile";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ConfirmationRegisterModal } from "@doctomatic/components-react/build/ConfirmationRegisterModal/ConfirmationRegisterModal";
import { GridSortModel } from "@mui/x-data-grid";
import {
  UpdatePatientRequestDto,
  UpdatePatientResponseDto,
} from "@doctomatic/sdk/build/dto/Patient/Patient";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";

interface Props {
  patients: any;
  profile: GetActorResponseDto | undefined;
  showDeleted: boolean;
  showExternalId: boolean | undefined;
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  isLoading?: boolean;
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
  patch: (
    id: number,
    patient: UpdatePatientRequestDto
  ) => Promise<ResponseApi<UpdatePatientResponseDto>>;
  del: (id: number) => Promise<ResponseApi<boolean>>;
  restore: (id: number) => Promise<ResponseApi<boolean>>;
  mutate: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    paddingBottom: theme.spacing(3),
  },
}));

const PatientsList = ({
  patients,
  profile,
  showExternalId,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  isLoading,
  sortBy,
  onSortChange,
  patch,
  del,
  restore,
  mutate,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { useSMS, useMail } = useApi();
  const { sendRegistrationEmail } = useMail();
  const { sendRegistrationSMS } = useSMS();
  const { setIsLoading } = useLoading();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");
  const [isPatientEditFormOpen, setIsPatientEditFormOpen] =
    useState<boolean>(false);
  const [patientToEditId, setPatientToEditId] = useState<number | null>(null);
  const [patientToRestore, setPatientToRestore] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [patientToDelete, setPatientToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [patientToSendSms, setPatientToSendSms] = useState<{
    id: number;
    name: string;
    phone?: string;
  } | null>(null);
  const [patientToSendEmail, setPatientToSendEmail] = useState<{
    id: number;
    name: string;
    email?: string;
  } | null>(null);
  const [patientFrequency, setPatientFrequency] =
    useState<PatientDetail | null>(null);
  const classes = useStyles();

  const patientEdit = patientToEditId && (
    <PatientEdit
      open={isPatientEditFormOpen}
      userToEditId={patientToEditId}
      onClose={() => setIsPatientEditFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          await patch(patientToEditId, user);
          await mutate();
          setPatientToEditId(null);
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const onEdit = (id: number) => {
    setIsPatientEditFormOpen(true);
    setPatientToEditId(id);
  };

  const onDelete = async (id: number, name: string) => {
    setPatientToDelete({ id, name });
  };

  const onRestore = async (id: number, name: string) => {
    setPatientToRestore({ id, name });
  };

  const onSendSms = async (id: number, name: string, phone: string) => {
    setPatientToSendSms({ id, name, phone });
  };

  const onSendEmail = async (id: number, name: string, email: string) => {
    setPatientToSendEmail({ id, name, email });
  };

  const handleSendEmail = async () => {
    const { data } = await sendRegistrationEmail(
      patientToSendEmail?.id as number
    );
    toast.info(data?.message);
  };

  const handleSendSms = async () => {
    try {
      const { data } = await sendRegistrationSMS(
        patientToSendSms?.id as number
      );
      if (process.env.REACT_APP_SHOW_MSG_SMS_CONSOLE === "true") {
        console.log(data?.message);
        toast.info(
          "Mira en la consola. Copia el enlace de On Boarding y pégalo en una nueva ventana en modo incógnito (sin token en localStorage)."
        );
        toast.info(data?.message);
      }
    } catch (err: any) {
      toast.error(
        `${t("UserListSmsError")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const onClose = async () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const confirmRestore = patientToRestore && (
    <ConfirmModal
      open={Boolean(patientToRestore)}
      content={
        <Trans
          i18nKey="UserListRestoreConfirm"
          values={{ user: t("FieldPatient"), name: patientToRestore.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonRestore")}
      onCancel={() => setPatientToRestore(null)}
      onClose={() => setPatientToRestore(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await restore(patientToRestore.id);
          await mutate();
        } catch (err: any) {
          toast.error(
            `${t("UserListRestoreError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setPatientToRestore(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmDelete = patientToDelete && (
    <ConfirmModal
      open={Boolean(patientToDelete)}
      content={
        <Trans
          i18nKey="UserListDeleteConfirm"
          values={{ user: t("FieldPatient"), name: patientToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setPatientToDelete(null)}
      onClose={() => setPatientToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(patientToDelete.id);
          await mutate();
        } catch (err: any) {
          toast.error(
            `${t("UserListDeleteError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setPatientToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const onFrequency = async (
    id: number,
    name: string,
    treatments: GetTreatmentResponseDto[],
    timezone?: string
  ) => {
    setPatientFrequency({ id, name, treatments, timezone });
  };

  const userFrequencyForm = patientFrequency && (
    <UserFrequency
      open={true}
      patient={patientFrequency}
      onClose={() => setPatientFrequency(null)}
      afterSave={async () => {
        setPatientFrequency(null);
        await mutate();
      }}
    />
  );

  const patientGridColumns = PatientGridColumns(showExternalId, t, {
    onClick,
    onClose,
    anchorEl,
    popoverId,
    onEdit,
    onDelete,
    onRestore,
    onSendSms,
    onSendEmail,
    onFrequency,
  });

  const patientsDataRow: PatientDataRow[] =
    patients?.map((patient: IItemPatientListExtendedResponseDto) => {
      const patientDataRow: PatientDataRow = {
        id: patient.id,
        name: patient.name,
        email: patient.email,
        phone: patient.phone,
        locations: patient.location_user.map(
          (location_user: { location: { name: string } }) => {
            return location_user.location.name;
          }
        ),
        trackings: (patient.treatments as GetTreatmentResponseDto[]) ?? [],
        alarms: patient.alertConfigByNoMeasure ?? [],
        deletedAt: patient.deletedAt,
        externalId: patient.externalId,
        birthYear: patient.birthYear,
        gender: patient.gender,
        timezone: patient.timezone,
      };
      return patientDataRow;
    }) || [];

  const setRowClassName = (params: any): string => {
    if (params.row.deletedAt) return "super-app-theme--Rejected";
    return "";
  };

  return (
    <div
      className={classes.menuButton}
      style={{ display: isLoading || !profile ? "none" : "block" }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={patientGridColumns as any}
        rows={patientsDataRow}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationInfo}
        isLoading={isLoading}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid() as any}
        sortBy={sortBy}
        onSortChange={onSortChange}
      />
      {patientEdit}
      {confirmRestore}
      {confirmDelete}
      {userFrequencyForm}
      {patientToSendEmail && (
        <ConfirmationRegisterModal
          userToSend={patientToSendEmail}
          setUserToSend={setPatientToSendEmail}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendEmail}
          keyConfirmation={"UserListEmailConfirm"}
          keyError={"UserListEmailError"}
        />
      )}
      {patientToSendSms && (
        <ConfirmationRegisterModal
          userToSend={patientToSendSms}
          setUserToSend={setPatientToSendSms}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendSms}
          keyConfirmation={"UserListSMSConfirm"}
          keyError={"UserListSmsError"}
        />
      )}
    </div>
  );
};

export { PatientsList };
