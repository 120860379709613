import React, { useEffect, useMemo, useState } from "react";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GetAdminCompanyResponseDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { GetDoctorResponseDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetSuperadminResponseDto } from "@doctomatic/sdk/build/dto/Superadmin";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { IUsePatient } from "@doctomatic/sdk/build/modules/Patient";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { AddCircle } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Page } from "../../Page";
import AlertConfigByNoMeasureDataRow from "./AlertConfigByNoMeasureDataRow";
import {
  AlertConfigByNoMeasureGridColumns,
  IAlertConfigByNoMeasureGridColumnsProps,
} from "./AlertConfigByNoMeasureGridColumns";
import { AddAlertConfigByNoMeasureModal } from "./ManageAlertConfigByNoMeasure/AddAlertConfigByNoMeasureModal";
import { UpdateAlertConfigByNoMeasureModal } from "./ManageAlertConfigByNoMeasure/UpdateAlertConfigByNoMeasureModal";
import { IUseAlertConfigsByNoMeasureByPatient } from "@doctomatic/sdk/build/modules/AlertConfigsByNoMeasureByPatient";
import { processError } from "../../../../App/errorToast";
import { IUseAlertConfigsByNoMeasureByPatientFromTemplate } from "@doctomatic/sdk/build/modules/AlertConfigsByNoMeasureByPatientFromTemplate";
import { checkAlertConfigByNoMeasurePathParam } from "../../utils";
import {
  AddAlertConfigByNoMeasureIndividualRequestDto,
  ItemAlertConfigByNoMeasureIndividualDto,
  UpdateAlertConfigByNoMeasureIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual";
import { IUseAlertConfigsByNoMeasureIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import {
  ActiveAlertFilter,
  ModeActivefilter,
} from "@doctomatic/components-react/build/ActiveAlertFilter/ActiveAlertFilter";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";

type IAlertConfigByNoMeasure = {
  patientId: string;
};

export const AlertConfigByNoMeasure = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = useParams<IAlertConfigByNoMeasure>();
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState<ModeActivefilter>(ModeActivefilter.all);
  const [modeTemplate, setModeTemplate] = useState<ModeActivefilter>(
    ModeActivefilter.all
  );
  const {
    companyId,
    useCompany,
    useProfile,
    usePatient,
    useAlertConfigsByNoMeasureByPatient,
    useAlertConfigsByNoMeasureByPatientFromTemplate,
    useAlertConfigsByNoMeasure,
    logout,
  }: IProvideApi = useApi();
  const { response: patient }: IUsePatient = usePatient(
    parseInt(patientId as string),
    true,
    processError(logout, navigate, t)
  );
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const { response: company } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );

  const [open, setOpen] = useState<boolean>(false);

  // declaration of hooks to handle pagination
  const [pageSizeByNoMeasure, setPageSizeByNoMeasure] = useState<number>(10);
  const [pageByNoMeasure, setPageByNoMeasure] = useState<number>(1);
  const [pageTemplateSizeByNoMeasure, setPageTemplateSizeByNoMeasure] =
    useState<number>(10);
  const [pageTemplateByNoMeasure, setPageTemplateByNoMeasure] =
    useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationNoMeasure = useMemo(() => {
    return {
      page: pageByNoMeasure,
      limit: pageSizeByNoMeasure,
    };
  }, [pageByNoMeasure, pageSizeByNoMeasure]);

  const paginationNoMeasureTemplate = useMemo(() => {
    return {
      page: pageTemplateByNoMeasure,
      limit: pageTemplateSizeByNoMeasure,
    };
  }, [pageTemplateByNoMeasure, pageTemplateSizeByNoMeasure]);

  useEffect(() => {
    setPageTemplateByNoMeasure(1);
  }, [modeTemplate]);

  useEffect(() => {
    setPageByNoMeasure(1);
  }, [mode]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const {
    response: alertConfigsResponse,
    mutate,
  }: IUseAlertConfigsByNoMeasureByPatient = useAlertConfigsByNoMeasureByPatient(
    parseInt(patientId as string),
    paginationNoMeasure,
    mode,
    true,
    processError(logout, navigate, t)
  );
  const {
    response: alertConfigsResponseFromTemplate,
    mutate: mutateTemplate,
  }: IUseAlertConfigsByNoMeasureByPatientFromTemplate =
    useAlertConfigsByNoMeasureByPatientFromTemplate(
      parseInt(patientId as string),
      paginationNoMeasureTemplate,
      "true",
      modeTemplate,
      true,
      processError(logout, navigate, t)
    );
  const { patch, add }: IUseAlertConfigsByNoMeasureIndividual =
    useAlertConfigsByNoMeasure();

  const alertConfigs = alertConfigsResponse?.data;
  const alertConfigsFromTemplate = alertConfigsResponseFromTemplate?.data;
  const alertConfigsByNoMeasurePaginationInfo = alertConfigsResponse?.meta;
  const alertConfigsByNoMeasureTemplatePaginationInfo =
    alertConfigsResponseFromTemplate?.meta;

  const handleOpenAdd = () => setOpen(true);
  const handleCloseAdd = async () => {
    await mutate();
    setOpen(false);
  };
  const [openAlertConfigEdit, setOpenAlertConfigEdit] =
    useState<boolean>(false);
  const handleCloseUpdate = async () => {
    await mutate();
    await mutateTemplate();
    setOpenAlertConfigEdit(false);
  };
  const [alertConfigToEdit, setAlertConfigToEdit] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const onEdit = (item: any) => {
    setAlertConfigToEdit(item);
    setOpenAlertConfigEdit(true);
  };

  const onActivate = async (
    alertConfig: ItemAlertConfigByNoMeasureIndividualDto
  ) => {
    if (alertConfig && !alertConfig.customAlertConfigTemplate) {
      //es una alertconfig individual sin relación con ninguna alertconfig Template
      const dto: UpdateAlertConfigByNoMeasureIndividualRequestDto =
        new UpdateAlertConfigByNoMeasureIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.notification = alertConfig.notification;
      dto.active = alertConfig.active ? false : true;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          await mutate();
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig &&
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id
    ) {
      //is an alert config template and add an emulate alert config
      try {
        const dto: AddAlertConfigByNoMeasureIndividualRequestDto =
          new AddAlertConfigByNoMeasureIndividualRequestDto();
        Object.assign(dto, alertConfig);
        dto.patientId = parseInt(patientId as string);
        dto.customAlertConfigTemplateId =
          alertConfig.customAlertConfigTemplate?.id;
        dto.deviceId = alertConfig?.device?.id;
        dto.active = alertConfig.active ? false : true;
        await add(dto);
        await mutate();
        await mutateTemplate();
        return;
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig &&
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id !== alertConfig.id
    ) {
      //alert config individual emulate of an alert template update
      const dto: UpdateAlertConfigByNoMeasureIndividualRequestDto =
        new UpdateAlertConfigByNoMeasureIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.active = alertConfig.active ? false : true;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          await mutate();
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const alertConfigByNoMeasureGridColumns: GridColDef[] =
    AlertConfigByNoMeasureGridColumns({
      popoverConf: {
        onClick,
        onClose,
        anchorEl,
        popoverId,
        onEdit,
        onActivate,
      },
      mutate: mutate,
      currentLanguage,
      messengerChannel: company?.data?.messengerChannel,
    } as IAlertConfigByNoMeasureGridColumnsProps);

  let alertConfigByNoMeasureDataRows: AlertConfigByNoMeasureDataRow[] =
    alertConfigs ?? [];
  let alertConfigByNoMeasureDataRowsFromTemplate: AlertConfigByNoMeasureDataRow[] =
    alertConfigsFromTemplate ?? [];

  const alertConfigIndividualFiltered = checkAlertConfigByNoMeasurePathParam(
    location.search,
    alertConfigByNoMeasureDataRows
  );
  const alertConfigTemplatelFiltered = checkAlertConfigByNoMeasurePathParam(
    location.search,
    alertConfigByNoMeasureDataRowsFromTemplate
  );

  const getBreadCrumbProps = (
    profile:
      | ResponseApi<
          | GetPatientResponseDto
          | GetDoctorResponseDto
          | GetNurseResponseDto
          | GetAdminCompanyResponseDto
          | GetSuperadminResponseDto
        >
      | undefined,
    patient: ResponseApi<GetPatientResponseDto> | undefined
  ): BreadcrumbProps | undefined =>
    patient?.data?.name && profile?.data?.name
      ? ({
          breadcrumbItems: location.search.includes("alertConfigId=")
            ? [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                {
                  url: `/alerts_config/measure/${patientId}`,
                  name: t("AlertConfigByNoMeasureTitle"),
                },
                {
                  url: "",
                  name:
                    alertConfigIndividualFiltered &&
                    alertConfigIndividualFiltered.length > 0
                      ? alertConfigIndividualFiltered[0].label
                      : "",
                },
              ]
            : [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                { url: "", name: t("AlertConfigByNoMeasureTitle") },
              ],
        } as BreadcrumbProps)
      : undefined;

  const buttons: JSX.Element = (
    <>
      <Box display="flex">
        <IconButton onClick={handleOpenAdd}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
      {open && (
        <AddAlertConfigByNoMeasureModal
          patientId={parseInt(patientId as string)}
          open={open}
          handleClose={handleCloseAdd}
          item={undefined}
          // TODO
          permissions={[]}
          currentLanguage={currentLanguage}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </>
  );

  const filters: JSX.Element = (
    <ActiveAlertFilter mode={mode} setMode={setMode} t={t} />
  );

  const buttonTemplate: JSX.Element = (
    <>
      <Box display="flex">
        <IconButton
          onClick={() =>
            navigate(BreadcrumbNameMap.AlertConfigTemplates.url + "?tab=1")
          }
        >
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
    </>
  );

  const filtersTemplate: JSX.Element = (
    <ActiveAlertFilter mode={modeTemplate} setMode={setModeTemplate} t={t} />
  );

  const setRowClassName = (
    params: GridRowClassNameParams<AlertConfigByNoMeasureDataRow>
  ): string => {
    if (!params.row.active) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Page title="" breadCrumbProps={getBreadCrumbProps(profile, patient)}>
      <Page
        title={t("CustomAlertconfigByNoMeasure")}
        buttons={buttons}
        filters={filters}
      >
        <FlexLayoutGrid
          language={i18n.language}
          columns={alertConfigByNoMeasureGridColumns as any}
          rows={alertConfigIndividualFiltered}
          setRowClassName={setRowClassName as any}
          onPageChange={setPageByNoMeasure}
          onPageSizeChange={setPageSizeByNoMeasure}
          pagination={alertConfigsByNoMeasurePaginationInfo}
          sx={DoctomaticStylingRowsGrid() as any}
        />
      </Page>
      <Box pt="2%">
        <Page
          title={t("TemplateAlertconfigByNoMeasure")}
          buttons={buttonTemplate}
          filters={filtersTemplate}
        >
          <FlexLayoutGrid
            language={i18n.language}
            columns={alertConfigByNoMeasureGridColumns as any}
            rows={alertConfigTemplatelFiltered}
            setRowClassName={setRowClassName as any}
            onPageChange={setPageTemplateByNoMeasure}
            onPageSizeChange={setPageTemplateSizeByNoMeasure}
            pagination={alertConfigsByNoMeasureTemplatePaginationInfo}
            sx={DoctomaticStylingRowsGrid() as any}
          />
        </Page>
      </Box>
      {openAlertConfigEdit && (
        <UpdateAlertConfigByNoMeasureModal
          patientId={parseInt(patientId as string)}
          open={openAlertConfigEdit}
          handleClose={handleCloseUpdate}
          item={alertConfigToEdit}
          permissions={[]}
          alertConfigByNoMeasureIndividual={alertConfigToEdit}
          currentLanguage={currentLanguage}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </Page>
  );
};
