import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LastAlertsByValueByPatientList } from "../Dashboard/LastAlertsByPatient/ByValueByPatient/LastAlertsByValueByPatientList";
import { LastAlertsByNoMeasureByPatientList } from "../Dashboard/LastAlertsByPatient/ByNoMeasureByPatient/LastAlertsByNoMeasureByPatientList";
import { useTranslation } from "react-i18next";
import { Page } from "../Page";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { PatientEdit } from "./PatientEdit";
import { useLoading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { LastReadDevicesByPatientList } from "../Dashboard/LastReadDevicesByPatient/LastReadDevicesByPatientList";
import { processError } from "../../../App/errorToast";
import { jwtDecode } from "jwt-decode";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { UserFrequency } from "../Users/UserFrequencyModal";
import { LastAlertsByFrequencyByPatientList } from "../Dashboard/LastAlertsByPatient/ByFrequencyByPatient/LastAlertsByFrequencyByPatientList";
import { getDeviceName } from "../utils";
import QRCodeCanvas, { ErrorLevel } from "../../QRCodes/QRCodeCanvas";
import { IItemDeviceList } from "@doctomatic/sdk/build/dto/IItemList";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";

export const PatientDetails = (): React.ReactElement => {
  const navigate = useNavigate();
  const urlParams = useParams<{ patientId: string }>();
  const { t, i18n } = useTranslation();
  const {
    useProfile,
    usePatient,
    usePatients,
    useCompany,
    useMail,
    logout,
    companyId,
    token,
  }: IProvideApi = useApi();
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(companyId, true);
  const company = companyResponse?.data;
  const { response: patientResponse, mutate } = usePatient(
    parseInt(urlParams.patientId as string),
    true,
    processError(logout, navigate, t)
  );
  const { patch } = usePatients();
  const patient = patientResponse?.data;
  const { sendEmail } = useMail();
  const { findOneWithDevicesAndMeasurements } = usePatient(
    parseInt(urlParams.patientId as string),
    false,
    processError(logout, navigate, t)
  );

  const [autoRead, setAutoRead] = useState(false);
  const [isPatientEditFormOpen, setIsPatientEditFormOpen] =
    useState<boolean>(false);
  const [isUserFrequencyFormOpen, setIsUserFrequencyFormOpen] =
    useState<boolean>(false);
  const [isSendQRCodeOpen, setIsSendQRCodeOpen] = useState<boolean>(false);
  const [isShowQRCodeOpen, setIsShowQRCodeOpen] = useState<boolean>(false);
  const [isSendEmailQRCodeOpen, setIsSendEmailQRCodeOpen] =
    useState<boolean>(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const { setIsLoading } = useLoading();
  const [actorRole, setActorRole] = useState<Role | undefined>(undefined);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [checked, setChecked] = useState<string>("noselect");
  const [device, setDevice] = useState<IItemDeviceList>();
  const [mailContent, setMailContent] = useState<string>(
    t("DefaultBodySendQR", {
      patient_name: patient?.name,
      device_name: device?.name,
    })
  );
  const [to, setTo] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [doctorName, setDoctorName] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");
  const [urlWeb, setUrlWeb] = useState<string>("");
  const [urlApp, setUrlApp] = useState<string>("");
  const [currentHost, setCurrentHost] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const [devices, setDevices] = useState<IItemDeviceList[]>([]);
  const [dataToast, setDataToast] = useState<string>("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchUser = async () => {
    if (userId === 0) return;
    const user = await findOneWithDevicesAndMeasurements();
    if (user && user.additional_data) {
      setAutoRead(user.additional_data.autoRead);
    }
    if (user && user.data) {
      const parsedDevices = user.data.treatmentDevices.map((td) => {
        return td.device;
      });
      setDevices(parsedDevices);
    }
  };

  function validateEmails(input: string) {
    const emails = input.split(",").map((email) => email.trim());
    const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emails.every((email) => emailRegex.test(email));
  }

  const handleDownload = (canvas: HTMLCanvasElement) => {
    if (!canvas) return;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "qrcode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSend = async (canvas: HTMLCanvasElement) => {
    if (!canvas || to === "") return;
    if (!validateEmails(to)) {
      toast.error(t("InvalidEmail"));
      return;
    }
    const emails = to.split(",");
    const { data } = await sendEmail({
      to: emails,
      subject: subject,
      body:
        mailContent +
        "<br>" +
        doctorName +
        "<br><br><br>" +
        "<center><img src=`cid:image1` alt='Image' /></center>" +
        "<br>" +
        `<center><a href="${currentHost}">Link</a></center>`,
      base64Image: canvas.toDataURL("image/png"),
    });

    setIsShowQRCodeOpen(false);
    setIsSendQRCodeOpen(false);
    setIsSendEmailQRCodeOpen(false);
    setChecked("noselect");
    setTo(patient?.email ?? "");
    setSubject("");
    setMailContent("");

    if (data?.message) {
      setDataToast(data?.message);
    }
  };

  const getAccessToken = async () => {
    if (userId === 0) return;
    const options = { headers: { Authorization: "Bearer " + token } };
    const { accessToken, refreshToken } = (
      await axios.post(
        `${process.env.REACT_APP_API_URL}/token/${patient?.id}`,
        null,
        options
      )
    ).data;

    return { accessToken, refreshToken };
  };

  useEffect(() => {
    if (+checked === 0) {
      setDevice({ id: 0, name: t("ReadAutomatically"), typeName: "Device" });
    } else {
      const foundDevice = devices?.find((d) => d.id === +checked);
      if (foundDevice) {
        setDevice(foundDevice);
      }
    }
  }, [checked]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setUserId(decoded.sub);
    }

    fetchUser();
  }, [userId]);

  useEffect(() => {
    setSubject(t("SubjectSendQR", { device_name: device?.name }));
  }, [device]);

  useEffect(() => {
    const fetchGetAccessToken = async () => {
      const tokens = await getAccessToken();

      setAccessToken(tokens?.accessToken);
      setRefreshToken(tokens?.refreshToken);
    };

    fetchGetAccessToken();
    setTo(patient?.email ?? "");
  }, [patient]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setActorRole(decoded.role ?? undefined);
    }
  }, []);

  useEffect(() => {
    if (profile?.data?.name) {
      setDoctorName(profile.data.name);
    }
  }, [profile?.data?.name]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      setUrlWeb(
        `${window.location.protocol}//${window.location.host}/devices/${
          +checked !== 0 ? checked + "/" : ""
        }camera/${
          patient?.id
        }?token=${accessToken}&refreshToken=${refreshToken}`
      );
      setUrlApp(
        `${process.env.REACT_APP_URL}?path=home/${userId}/${
          patient?.role
        }/${accessToken}/${refreshToken}/${encodeURIComponent(
          process.env.REACT_APP_API_URL as string
        )}`
      );
    }
  }, [accessToken, refreshToken, checked]);

  useEffect(() => {
    if (urlWeb && urlApp) {
      setCurrentHost(
        process.env.REACT_APP_URL === "" ||
          process.env.REACT_APP_URL === undefined
          ? urlWeb
          : urlApp
      );
    }
  }, [urlWeb, urlApp]);

  useEffect(() => {
    setMailContent(
      t("DefaultBodySendQR", {
        patient_name: patient?.name,
        device_name: device?.name,
      })
    );
  }, [device, patient]);

  if (!actorRole) return <></>;

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        {
          url: BreadcrumbNameMap.Patients.url,
          name: t(BreadcrumbNameMap.Patients.name),
        },
        { url: "", name: patient?.name },
      ],
    } as BreadcrumbProps;
  };

  const onValueChangeRadioGroup = (itemSelrialized: string) => {
    setChecked(itemSelrialized);
  };

  const editButton = patient && (
    <Box display="flex" justifyContent="flex-end">
      <Button
        size="small"
        variant="contained"
        onClick={() => setIsPatientEditFormOpen(true)}
      >
        {t("ButtonEdit")}
      </Button>
    </Box>
  );

  const buttons = patient && (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="initial"
      >
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            variant="contained"
            to={BreadcrumbNameMap.AlertsByPatient.url.replace(
              ":patientId",
              patient.id.toString()
            )}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {t("HeaderAlerts")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            variant="contained"
            to={BreadcrumbNameMap.TrackingPatient.url.replace(
              ":patientId",
              patient.id.toString()
            )}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {t("HeaderTreatments")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => setIsUserFrequencyFormOpen(true)}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {t("PatientListFrequency")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            style={{
              fontSize: "12px",
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
            variant="contained"
            to={BreadcrumbNameMap.AlertConfigByValue.url.replace(
              ":patientId",
              patient.id.toString()
            )}
          >
            {t("PatientListConfigAlertsByValue")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            style={{
              fontSize: "12px",
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
            variant="contained"
            to={BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
              ":patientId",
              patient.id.toString()
            )}
          >
            {t("PatientListConfigAlertsByNoMeasure")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            style={{
              fontSize: "12px",
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
            variant="contained"
            to={BreadcrumbNameMap.AlertConfigByFrequency.url.replace(
              ":patientId",
              patient.id.toString()
            )}
          >
            {t("PatientListConfigAlertsByFrequency")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            style={{
              fontSize: "12px",
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
            variant="contained"
            to={{
              pathname: BreadcrumbNameMap.ExternalReferences.url,
              search: `?patientId=${patient.id.toString()}`,
            }}
          >
            {t(BreadcrumbNameMap.ExternalReferences.name)}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            component={Link}
            size="small"
            style={{
              fontSize: "12px",
              width: "100%",
              height: "100%",
              textAlign: "center",
            }}
            variant="contained"
            to={{
              pathname: `${
                BreadcrumbNameMap.Devices.url
              }/${patient.id.toString()}`,
            }}
          >
            {t("Devices")}
          </Button>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => setIsSendQRCodeOpen(true)}
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            {t("SendQRCode")}
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const patientEdit = isPatientEditFormOpen && (
    <PatientEdit
      open={isPatientEditFormOpen}
      userToEditId={parseInt(urlParams.patientId as string)}
      onClose={() => setIsPatientEditFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          await patch(parseInt(urlParams.patientId as string), user);
          await mutate();
          setIsPatientEditFormOpen(false);
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const userFrequencyForm = patient && (
    <UserFrequency
      open={isUserFrequencyFormOpen}
      patient={patient}
      onClose={() => setIsUserFrequencyFormOpen(false)}
      afterSave={async () => {
        setIsUserFrequencyFormOpen(false);
        await mutate();
      }}
    />
  );

  const sendQRCode = patient && (
    <Dialog open={isSendQRCodeOpen} fullWidth={true} fullScreen={fullScreen}>
      <DialogTitle>{t("GenerateQR")}</DialogTitle>

      <DialogContent>
        <FormControl
          component="fieldset"
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          <RadioGroup
            value={checked}
            onChange={(e) => onValueChangeRadioGroup(e.target.value)}
          >
            {autoRead && (
              <FormControlLabel
                key={0}
                control={<Radio />}
                label={t("ReadAutomatically")}
                value={0}
              />
            )}
            {devices?.map((d) => (
              <FormControlLabel
                key={d.id}
                control={<Radio />}
                label={getDeviceName(currentLanguage, d, t)}
                value={d.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={() => setIsSendQRCodeOpen(false)} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Box mr={2} display="inline-block">
            <Button
              onClick={() => {
                setIsSendQRCodeOpen(false);
                setIsShowQRCodeOpen(true);
              }}
              variant="contained"
              size="small"
              disabled={checked === "noselect"}
            >
              {t("ButtonNext")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );

  const showQRCode = patient && checked && device && (
    <Dialog open={isShowQRCodeOpen} fullWidth={true} fullScreen={fullScreen}>
      <DialogTitle
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        {t(device?.name)}{" "}
      </DialogTitle>
      <DialogContent
        style={{
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginInline: "5%",
            aspectRatio: 1,
          }}
        >
          <QRCodeCanvas
            QRString={currentHost}
            options={{ width: 250, errorCorrectionLevel: ErrorLevel.low }}
            setCanvas={setCanvas}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={() => setIsShowQRCodeOpen(false)} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Box mr={2} display="inline-block">
            <Button
              onClick={() => {
                setIsShowQRCodeOpen(false);
                setIsSendEmailQRCodeOpen(true);
              }}
              variant="contained"
              size="small"
              disabled={checked === "noselect" || !mailContent}
            >
              {t("SendEmail")}
            </Button>
          </Box>
          <Button
            onClick={() => {
              handleDownload(canvas!);
            }}
            variant="contained"
            size="small"
            color="info"
            disabled={checked === "noselect"}
          >
            {t("DownloadButton")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );

  const sendEmailQRCode = patient && checked && device && (
    <Dialog
      open={isSendEmailQRCodeOpen}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        {t("SendEmail")}: {t(device?.name)}{" "}
      </DialogTitle>
      <DialogContent
        style={{
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <>
          <TextField
            margin="dense"
            variant="outlined"
            label={"Emails"}
            type="text"
            fullWidth
            onChange={(e) => setTo(e.target.value)}
            value={to}
            style={{ marginBottom: 10, marginTop: 20 }}
          />
          <Alert severity="info">{t("EmailByCommas")}</Alert>
          <TextField
            margin="dense"
            variant="outlined"
            label={t("Subject")}
            type="text"
            fullWidth
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            style={{ marginBottom: 10, marginTop: 20 }}
          />
          <TextField
            margin="dense"
            variant="outlined"
            name="name"
            label={t("FieldMessage")}
            type="text"
            fullWidth
            onChange={(e) => setMailContent(e.target.value)}
            value={mailContent}
            style={{ marginBottom: 10, marginTop: 20 }}
            multiline
          />
          <Button
            onClick={() => setMailContent("")}
            variant="contained"
            size="small"
            color="error"
          >
            {t("ClearText")}
          </Button>
          {dataToast !== "" && (
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              style={{ marginTop: 15 }}
            >
              {dataToast}
            </Alert>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button
              onClick={() => setIsSendEmailQRCodeOpen(false)}
              size="small"
            >
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Box mr={2} display="inline-block">
            <Button
              onClick={() => {
                handleSend(canvas!);
              }}
              variant="contained"
              size="small"
              disabled={checked === "noselect" || !mailContent}
            >
              {t("SendEmail")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );

  const actorName = profile?.data?.name ? profile!.data!.name : "";

  return (
    <Page breadCrumbProps={getBreadCrumbProps(actorName)}>
      <Grid container spacing={1} columns={12}>
        <Grid size={{ xs: 12, lg: 6 }}>
          {buttons}
          <Page title={t("PatientDetailsTitle")} buttons={editButton}>
            <p>
              <b>{t("Email")}:</b> {patient?.email ? patient?.email : "--"}
            </p>
            <p>
              <b>{t("Phone")}:</b> {patient?.phone ? patient?.phone : "--"}
            </p>
            <p>
              <b>{t("ExternalId")}:</b>{" "}
              {patient?.externalId ? patient?.externalId : "--"}
            </p>
            <p>
              <b>{t("FieldBirthYear")}:</b>{" "}
              {patient?.birthYear ? patient?.birthYear : "--"}
            </p>
            <p>
              <b>{t("CreationDatePatient")}:</b>{" "}
              {patient?.created_at
                ? new Date(patient?.created_at).toLocaleString()
                : "--"}
            </p>
          </Page>
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          {profile?.data?.id &&
            !isNaN(parseInt(urlParams.patientId as string)) && (
              <LastAlertsByValueByPatientList
                actorId={profile.data.id}
                actorRole={actorRole}
                patientId={parseInt(urlParams.patientId as string)}
              />
            )}
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          {profile?.data?.id && (
            <LastReadDevicesByPatientList
              actorId={parseInt(urlParams.patientId as string)}
              showExternalId={company?.showExternalId}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          {profile?.data?.id &&
            !isNaN(parseInt(urlParams.patientId as string)) && (
              <LastAlertsByNoMeasureByPatientList
                actorRole={actorRole}
                actorId={profile.data.id}
                patientId={parseInt(urlParams.patientId as string)}
              />
            )}
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          {profile?.data?.id &&
            !isNaN(parseInt(urlParams.patientId as string)) && (
              <LastAlertsByFrequencyByPatientList
                actorRole={actorRole}
                actorId={profile.data.id}
                patientId={parseInt(urlParams.patientId as string)}
              />
            )}
        </Grid>
      </Grid>
      {patientEdit}
      {userFrequencyForm}
      {sendQRCode}
      {showQRCode}
      {sendEmailQRCode}
    </Page>
  );
};
