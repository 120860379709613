import { GridColDef } from "@mui/x-data-grid";
import AlertConfigByNoMeasureDataRow from "./AlertConfigByNoMeasureDataRow";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByNoMeasureIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import { toast } from "react-toastify";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { KeyedMutator } from "swr";
import { ResponseApiList } from "@doctomatic/sdk/build/global";
import { sortText } from "../../../../App/sortFunctions";
import {
  ItemAlertConfigByNoMeasureIndividualDto,
  UpdateAlertConfigByNoMeasureIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual";
import { getDeviceName } from "../../utils";

interface PopoverConfig {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onEdit: (item: any) => void;
  onActivate: (alertConfig: ItemAlertConfigByNoMeasureIndividualDto) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export interface IAlertConfigByNoMeasureGridColumnsProps {
  popoverConf?: PopoverConfig;
  mutate: KeyedMutator<
    ResponseApiList<ItemAlertConfigByNoMeasureIndividualDto>
  >;
  currentLanguage: string;
  messengerChannel: string;
}

export const AlertConfigByNoMeasureGridColumns = ({
  popoverConf,
  mutate,
  currentLanguage,
  messengerChannel,
}: IAlertConfigByNoMeasureGridColumnsProps): GridColDef[] => {
  const { t } = useTranslation();
  const { useAlertConfigsByNoMeasure }: IProvideApi = useApi();
  const { patch }: IUseAlertConfigsByNoMeasureIndividual =
    useAlertConfigsByNoMeasure();

  const isIndividual = (item: AlertConfigByNoMeasureDataRow) => {
    return (
      !item.customAlertConfigTemplate ||
      (item.customAlertConfigTemplate && !item.fromTemplate)
    );
  };

  const toggleNotification = async (
    alertConfig: AlertConfigByNoMeasureDataRow,
    value: boolean,
    field: string
  ) => {
    try {
      const notification = alertConfig.notification;
      const dto: UpdateAlertConfigByNoMeasureIndividualRequestDto =
        new UpdateAlertConfigByNoMeasureIndividualRequestDto();
      dto.notification = { ...notification, [field]: value };

      await patch(alertConfig.id, dto);
      await mutate();
    } catch (err: any) {
      toast.error(`${t("ErrorNofifyPushNotification")}: ${err.message}`);
    }
  };

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  };

  const columns: GridColDef[] = [];

  columns.push(
    {
      field: "device",
      headerName: t("Device"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => t(row.device),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return (
          <div>{getDeviceName(currentLanguage, params.row.device, t)}</div>
        );
      },
    },
    {
      field: "label",
      headerName: t("Name"),
      width: 200,
      align: "left",
    },
    {
      field: "max_time_between",
      headerName: t("AlertAfter"),
      width: 100,
      valueGetter: (value: any, row: any) => row.max_time_between,
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <div>{params.row.max_time_between}</div>;
      },
    },
    {
      field: "color",
      headerName: t("Level"),
      width: 100,
      valueGetter: (value: any, row: any) => row.color,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => (
        <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
      ),
    },
    {
      field: "subscribers",
      headerName: t("Subscribers"),
      width: 250,
      valueGetter: (value: any, row: any) => {
        const words: string[] = row.subscribers ?? [];
        if (words.length > 0) {
          return words.join(", ");
        } else return "--";
      },
      sortable: false,
      renderCell: (params) => {
        const subscriberNames: string[] =
          params.row.subscribers && params.row.subscribers.length > 0
            ? params.row.subscribers
                .filter((item: { user?: { name: string } }) => item.user)
                .map((item: { user?: { name: string } }) =>
                  item.user ? item.user.name : ""
                )
            : ["--"];
        if (subscriberNames.length === 0) subscriberNames.push("--");
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={subscriberNames}
            page={BreadcrumbNameMap.AlertConfigSubscribers.url.replace(
              ":alertConfigId",
              params.row.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "notification",
      headerName: t("FieldPushNotifyPatient"),
      width: 200,
      valueGetter: (value: any, row: any) => row.notification.pushNotification,
      renderCell: (params) =>
        isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={params.row.notification.pushNotification}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(
                params.row,
                event.target.checked,
                "pushNotification"
              );
            }}
          />
        ) : (
          <></>
        ),
    }
  );

  if (JSON.parse(messengerChannel).sms) {
    columns.push({
      field: "sms",
      headerName: t("FieldSMSNotifyPatient"),
      width: 200,
      valueGetter: (value: any, row: any) => row.notification.sms,
      renderCell: (params) =>
        isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={params.row.notification.sms}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(params.row, event.target.checked, "sms");
            }}
          />
        ) : (
          <></>
        ),
    });
  }
  if (JSON.parse(messengerChannel).email) {
    columns.push({
      field: "email",
      headerName: t("FieldEmailNotifyPatient"),
      width: 200,
      valueGetter: (value: any, row: any) => row.notification.email,
      renderCell: (params) =>
        isIndividual(params.row) ? (
          <Switch
            disabled={params.row.active ? false : true}
            checked={params.row.notification.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): any => {
              toggleNotification(params.row, event.target.checked, "email");
            }}
          />
        ) : (
          <></>
        ),
    });
  }

  columns.push(
    {
      field: "active",
      headerName: t("ActiveColumn"),
      width: 200,
      valueGetter: (value: any, row: any) => row.active,
      renderCell: (params) => {
        return <div>{params.row.active ? t("Active") : t("Deactive")}</div>;
      },
    },
    {
      field: "viewModal",
      headerName: t("Actions"),
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => openPopover(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={`m${params.row.id}`}
            open={popoverConf?.popoverId === `m${params.row.id}`}
            anchorEl={popoverConf?.anchorEl}
            onClose={popoverConf?.onClose}
          >
            <List>
              <ListItem>
                <ListItemButton
                  component={Link}
                  to={BreadcrumbNameMap.AlertConfigSubscribers.url.replace(
                    ":alertConfigId",
                    params.row.id.toString()
                  )}
                >
                  <ListItemText primary={t("Subscribers")} />
                </ListItemButton>
              </ListItem>
              <ListItemButton
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onEdit(params.row);
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("Edit")} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onActivate(params.row);
                }}
              >
                <ListItemIcon>
                  {" "}
                  {params.row.active ? (
                    <StopCircleIcon />
                  ) : (
                    <PlayCircleFilledIcon />
                  )}{" "}
                </ListItemIcon>
                <ListItemText
                  primary={params.row.active ? t("Deactivate") : t("Activate")}
                />
              </ListItemButton>
            </List>
          </Popover>
        </>
      ),
    }
  );

  return columns;
};
