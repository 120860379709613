import React, { useState } from "react";

interface ThumbnailProp {
  image?: string;
  imageError?: string;
  hovered: boolean;
}

export const Thumbnail = ({
  image,
  imageError,
  hovered,
}: ThumbnailProp): React.ReactElement => {
  const [src, setSrc] = useState(image);

  const handleError = () => {
    setSrc(imageError);
  };

  return (
    <>
      <div
        style={
          hovered
            ? {
                position: "absolute",
                top: "28%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                width: 50,
                height: 50,
                zIndex: 10,
              }
            : {}
        }
      ></div>
      <img
        height={50}
        width={50}
        src={src}
        alt="Device"
        style={{ zIndex: 5 }}
        onError={handleError}
      />
    </>
  );
};
