import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import {
  GridColDef,
  GridRowClassNameParams,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../Page";
import LastAlertsByNoMeasureDataRow from "./LastAlertsByNoMeasureDataRow";
import {
  ILastAlertsByNoMeasureGridColumnsProps,
  LastAlertsByNoMeasureGridColumns,
} from "./LastAlertsByNoMeasureGridColumns";
import { LastAlertsByNoMeasurePatient } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { IUseLastAlertsByNoMeasureByActor } from "@doctomatic/sdk/build/modules/LastAlertsByNoMeasureByActor";
import { processError } from "../../../../../App/errorToast";
import {
  AlertState,
  IItemAlertByNoMeasure,
  AlertTypeAlert,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import {
  showAlertStateModal,
  showAlertSubscribersModal,
} from "../../../Alerts/utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useLoading } from "../../../../Loading/Loading";
import { DoctomaticStylingRowsGrid } from "../../../../Theme/ThemeDataGrid";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";

const getItemAlert = (
  itemAlert: IItemAlertByNoMeasure
): IItemAlertByNoMeasure =>
  ({
    ...itemAlert,
    createdAt: new Date(itemAlert.createdAt),
    ackBys: itemAlert.ackBys.map((ackBy: any) => ({
      ...ackBy,
      createdAt: new Date(ackBy.createdAt),
    })),
  } as IItemAlertByNoMeasure);

export interface ILastAlertsByNoMeasureListProps {
  actorId: number;
  actorRole: Role;
  showExternalId: boolean | undefined;
}

export const LastAlertsByNoMeasureList = ({
  actorId,
  actorRole,
  showExternalId,
}: ILastAlertsByNoMeasureListProps): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const {
    useLastAlertsByNoMeasureByActor,
    useAlerts,
    logout,
    timezone,
  }: IProvideApi = useApi();
  const { updateAlertComments, updateAlertState } = useAlerts();
  const [lastAlerts, setLastAlerts] = useState<LastAlertsByNoMeasurePatient[]>(
    []
  );
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  // Declaration of hooks to handle pagination
  const [pageSizeByNoMeasure, setPageSizeByNoMeasure] = useState<number>(10);
  const [pageByNoMeasure, setPageByNoMeasure] = useState<number>(1);
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationValue, setPaginationValue] = useState<any>({
    page: 1,
    limit: 10,
    sortBy: [],
  });

  const { response, mutate }: IUseLastAlertsByNoMeasureByActor =
    useLastAlertsByNoMeasureByActor(
      actorId,
      actorRole,
      true,
      paginationValue,
      processError(logout, navigate, t)
    );

  const alertsByNoMeasure = response?.data;
  const alertsByNoMeasurePaginationInfo = response?.meta;

  const openAlertStateModal = (id: number, state: AlertState) => {
    setCurrentAlertState({ id, state });
  };

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, {
          state,
          type: AlertTypeAlert.alert_by_no_measure,
        });
        mutate();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      const response = await updateAlertComments(id, {
        comments,
        type: AlertTypeAlert.alert_by_no_measure,
      });
      mutate();
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    if (alertsByNoMeasure) {
      setLastAlerts(alertsByNoMeasure);
    }
  }, [alertsByNoMeasure, alertsByNoMeasurePaginationInfo]);

  useEffect(() => {
    setLoading(true);
    setPaginationValue({
      page: pageByNoMeasure,
      limit: pageSizeByNoMeasure,
      sortBy: sortBy,
    });
  }, [pageByNoMeasure, pageSizeByNoMeasure, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [lastAlerts]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const openAckByModal = (item: any) => {
    setItem(item);
  };

  const lastAlertsGridColumns: GridColDef<LastAlertsByNoMeasureDataRow>[] =
    LastAlertsByNoMeasureGridColumns({
      actorId,
      mutate,
      setLastAlerts,
      showExternalId,
      showModal: openAckByModal,
      showStateModal: openAlertStateModal,
      setAlertComments,
      currentLanguage,
    } as ILastAlertsByNoMeasureGridColumnsProps);

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone: timezone,
    });

  const lastAlertsDataRows: LastAlertsByNoMeasureDataRow[] = lastAlerts?.map(
    (lastAlertsPatient: LastAlertsByNoMeasurePatient, index: number) =>
      ({
        id: index,
        patient: lastAlertsPatient.patient,
        alert: getItemAlert(lastAlertsPatient.alert!),
      } as LastAlertsByNoMeasureDataRow)
  );

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const setRowClassName = (
    params: GridRowClassNameParams<LastAlertsByNoMeasureDataRow>
  ): string => {
    if (
      params.row.alert?.individualConfig &&
      !params.row.alert.individualConfig.active
    ) {
      return "no-actived-alert-config";
    }
    if (
      params.row.alert?.templateConfig &&
      !params.row.alert.templateConfig.active
    ) {
      return "no-actived-alert-config";
    }

    return "";
  };

  return (
    <Page title={t("LastAlertsByNoMeasureTitle")}>
      {loading ? (
        <TableSkeleton numRows={10} />
      ) : (
        <FlexLayoutGrid
          language={i18n.language}
          columns={lastAlertsGridColumns as any}
          rows={lastAlertsDataRows}
          onPageChange={setPageByNoMeasure}
          onPageSizeChange={setPageSizeByNoMeasure}
          pagination={alertsByNoMeasurePaginationInfo}
          isLoading={loading}
          setRowClassName={setRowClassName as any}
          sx={DoctomaticStylingRowsGrid() as any}
          sortBy={sortBy}
          onSortChange={(newSort: GridSortModel) =>
            setSortBy(newSort as GridSortItem[])
          }
        />
      )}
      {showModal}
      {alertStateModal}
    </Page>
  );
};
