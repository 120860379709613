import { Button } from "@mui/material";
import { useEffect } from "react";

interface Props {
  timer: number | undefined;
  setTimer: (value: number) => void;
  t: (text: string) => string;
  deviceId: string | undefined;
}

export const Timer = ({ timer, setTimer, t, deviceId }: Props) => {
  const getTimerLocalStorage = async (deviceId: string | undefined) => {
    const timer = localStorage.getItem(`@timer-${deviceId}`);
    if (timer) {
      setTimer(parseInt(timer));
    } else {
      setTimer(0);
    }
  };

  const setTimerLocalStorage = async (timer: number, deviceId: number) => {
    localStorage.setItem(`@timer-${deviceId}`, timer.toString());
  };

  useEffect(() => {
    getTimerLocalStorage(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (timer !== undefined && deviceId) {
      setTimerLocalStorage(timer as number, parseInt(deviceId));
    }
  }, [timer, deviceId]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        onClick={() => {
          setTimer(0);
        }}
        style={{
          borderRadius: 50,
          borderColor: "white",
          backgroundColor: timer === 0 ? "#83c986" : "white",
          color: timer === 0 ? "white" : "black",
        }}
      >
        {t("No")}
      </Button>
      <Button
        onClick={() => {
          setTimer(3);
        }}
        style={{
          borderRadius: 50,
          borderColor: "white",
          marginInline: 10,
          backgroundColor: timer === 3 ? "#83c986" : "white",
          color: timer === 3 ? "white" : "black",
        }}
      >
        {"3s"}
      </Button>
      <Button
        onClick={() => {
          setTimer(10);
        }}
        style={{
          borderRadius: 50,
          borderColor: "white",
          backgroundColor: timer === 10 ? "#83c986" : "white",
          color: timer === 10 ? "white" : "black",
        }}
      >
        {"10s"}
      </Button>
    </div>
  );
};
