import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { IBasicItem } from "../../../IItemList";
import { SubscriberDto } from "../../group/AlertGroup";
import { INotification, ISubscriberDto } from "../../group/IAlertGroup";
import {
  AddAlertConfigRequestDto,
  AddAlertConfigResponseDto,
  GetAlertConfigResponseDto,
  ItemAlertConfigDto,
  UpdateAlertConfigRequestDto,
  UpdateAlertConfigResponseDto,
} from "../AlertConfig";
import { ItemAlertConfigTemplateDto } from "../template/AlertConfigTemplate";
import {
  IAddAlertConfigIndividualRequestDto,
  IAddAlertConfigIndividualResponseDto,
  IGetAlertConfigIndividualResponseDto,
  IItemAlertConfigIndividualDto,
  IUpdateAlertConfigIndividualRequestDto,
  IUpdateAlertConfigIndividualResponseDto,
} from "./IAlertConfigIndividual";

export class AddAlertConfigIndividualRequestDto
  extends AddAlertConfigRequestDto
  implements IAddAlertConfigIndividualRequestDto
{
  @Expose() patientId: number = 0;
  @Expose() active: boolean = true;
  @Expose() @IsNotEmpty() subscribe: boolean = false;
  @Expose() @IsOptional() customAlertConfigTemplateId?: number;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class AddAlertConfigIndividualResponseDto
  extends AddAlertConfigResponseDto
  implements IAddAlertConfigIndividualResponseDto
{
  @Expose()
  patient?: IBasicItem;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class UpdateAlertConfigIndividualRequestDto
  extends UpdateAlertConfigRequestDto
  implements IUpdateAlertConfigIndividualRequestDto
{
  @Expose() @IsOptional() notification?: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
  @Expose() @IsOptional() subscribe?: boolean;
}

export class UpdateAlertConfigIndividualResponseDto
  extends UpdateAlertConfigResponseDto
  implements IUpdateAlertConfigIndividualResponseDto
{
  @ValidateNested()
  @Type(() => SubscriberDto)
  @Expose()
  subscribers: ISubscriberDto[] = [];
  @Expose() @IsNotEmpty() id: number = 0;
  @Expose()
  patient?: IBasicItem;
  @Expose() @IsNotEmpty() active: boolean = true;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class GetAlertConfigIndividualResponseDto
  extends GetAlertConfigResponseDto
  implements IGetAlertConfigIndividualResponseDto
{
  @Expose()
  patient?: IBasicItem;
  @Expose() active: boolean = true;
  @ValidateNested()
  @Type(() => SubscriberDto)
  @Expose()
  subscribers: ISubscriberDto[] = [];
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class ItemAlertConfigIndividualDto
  extends ItemAlertConfigDto
  implements IItemAlertConfigIndividualDto
{
  @Expose()
  patient?: IBasicItem;
  @Expose() active: boolean = true;
  @ValidateNested()
  @Type(() => SubscriberDto)
  @Expose()
  subscribers: ISubscriberDto[] = [];

  @ValidateNested()
  @Type(() => ItemAlertConfigTemplateDto)
  @Expose()
  customAlertConfigTemplate: ItemAlertConfigTemplateDto =
    new ItemAlertConfigTemplateDto();
  @Expose() fromTemplate: boolean = false;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}
