import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByValue } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByValue";
import { toast } from "react-toastify";
import {
  AddAlertConfigByValueIndividualRequestDto,
  ItemAlertConfigByValueIndividualDto,
  UpdateAlertConfigByValueIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByValueIndividual";
import { EditAlertConfigByValue } from "../../../../AlertsConfig/EditAlertConfigByValueForm";
import { SubscriptionChecks } from "../../../../AlertsConfig/SubscriptionChecks";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";

interface TimeUnit {
  text: string;
  value: string;
}

export interface UpdateAlertConfigByValueModalProps extends ModalBaseProps {
  patientId: number;
  alertConfigByValueIndividual: ItemAlertConfigByValueIndividualDto;
  permissions: Array<string>;
  messengerChannel: string;
}

export const UpdateAlertConfigByValueModal = (
  props: UpdateAlertConfigByValueModalProps
): React.ReactElement => {
  const initNotification = props.alertConfigByValueIndividual.notification;

  const { t } = useTranslation();
  const { useAlertConfigsByValue, id }: IProvideApi = useApi();
  const { patch, add }: IUseAlertConfigsByValue = useAlertConfigsByValue();

  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];

  const initialValue: ItemAlertConfigByValueIndividualDto =
    props.alertConfigByValueIndividual;
  const initialCondition: string = props.item.alert_type;
  const initialTime = initialValue.range_time
    ? Number(initialValue.range_time.replace("h", "")?.replace("d", ""))
    : 0;

  let initialTimeUnit = initialValue.range_time
    ? initialValue.range_time.slice(-1)
    : timeUnits[0].value;

  const unitAvailableValues = timeUnits.map((x) => {
    return x.value;
  });
  if (!unitAvailableValues.includes(initialTimeUnit)) {
    initialTimeUnit = timeUnits[0].value;
  }

  const [alertConfig, setAlertConfig] =
    useState<ItemAlertConfigByValueIndividualDto>(
      props.alertConfigByValueIndividual
    );
  const [signUnit, setSignUnit] = useState<string>(
    props.alertConfigByValueIndividual.sign.unit
  );
  const [condition, setCondition] = useState<string>(initialCondition);

  const [time, setTime] = useState<number>(initialTime);
  const [timeUnit, setTimeUnit] = useState<string>(initialTimeUnit);
  const [notification, setNotification] = useState<INotification>({
    sms: initNotification.sms,
    email: initNotification.email,
    pushNotification: initNotification.pushNotification,
  });

  const subscribersIncludesLoggedUser =
    props.alertConfigByValueIndividual.subscribers &&
    props.alertConfigByValueIndividual.subscribers.filter(
      (subscriber) => subscriber.user && subscriber.user.id === id
    ).length === 1;
  // Check if alert to be edited is a template and we're trying to create a custom from this template
  // or if user is already subscribed
  const subscribeInitialValue =
    (alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id) ||
    subscribersIncludesLoggedUser;
  const [subscribe, setSubscribe] = useState<boolean>(subscribeInitialValue);

  const isTime: boolean = condition.includes("time");

  const initialErrors = {
    min: false,
    max: false,
    range_value: false,
    range_time: false,
  };
  const [errors, setErrors] = useState<any>(initialErrors);

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const checkErrors = (
    dto: UpdateAlertConfigByValueIndividualRequestDto
  ): boolean => {
    let validationErrors = initialErrors;
    if (dto.alert_type === "min") {
      validationErrors.min = dto.min === undefined || dto.min === null;
    } else if (dto.alert_type === "max") {
      validationErrors.max = dto.max === undefined || dto.max === null;
    } else if (
      dto.alert_type === "range_absolute" ||
      dto.alert_type === "range_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined ||
        dto.range_value === null ||
        dto.range_value === 0;
    } else if (
      dto.alert_type === "time_absolute" ||
      dto.alert_type === "time_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined ||
        dto.range_value === null ||
        dto.range_value === 0;
      validationErrors.range_time =
        dto.range_time === undefined || dto.range_time === null;
    }
    setErrors(validationErrors);

    return Object.values(validationErrors).some((value: boolean) => !!value);
  };

  useEffect(() => {
    let fieldNames: any = {
      min: "FieldMin",
      max: "FieldMax",
      range_value: "FieldRangeValue",
      range_time: "FieldRangeTime",
    };

    const error: string | undefined = Object.keys(errors).find(
      (key: string) => errors[key] === true
    );
    if (error)
      toast.error(
        t("ErrorRequiredField", { field_name: t(fieldNames[error]) })
      );
  }, [errors]);

  const handleFormSubmit = async (): Promise<void> => {
    if (!alertConfig.customAlertConfigTemplate) {
      //es una alertconfig individual sin relación con ninguna alertconfig Template
      const dto: UpdateAlertConfigByValueIndividualRequestDto =
        new UpdateAlertConfigByValueIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id && !checkErrors(dto)) {
          await patch(alertConfig.id, dto);
          props.handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id
    ) {
      //Es una alert Config individual emulada de una alertconfig template, actualizamos su configuracioón
      //TODO: en el caso de ser igual descartar la creación.
      try {
        const dto: AddAlertConfigByValueIndividualRequestDto =
          new AddAlertConfigByValueIndividualRequestDto();
        Object.assign(dto, alertConfig);
        dto.patientId = props.alertConfigByValueIndividual.patient.id;
        dto.customAlertConfigTemplateId =
          alertConfig.customAlertConfigTemplate?.id;
        dto.subscribe = subscribe;
        await add(dto);
        props.handleClose();
        setAlertConfig(initialValue);
        return;
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id !== alertConfig.id
    ) {
      //Es una alert Config individual emulada que customiza los valores de la alert config Template
      const dto: UpdateAlertConfigByValueIndividualRequestDto =
        new UpdateAlertConfigByValueIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id && !checkErrors(dto)) {
          await patch(alertConfig.id, dto);
          props.handleClose();
          setAlertConfig(initialValue);
          props.handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
  };

  useEffect(() => {
    if (initialValue.sign !== undefined) {
      const signUnit =
        initialValue.sign.unit === "point"
          ? `1 = ${t("Yes")} / 0 = ${t("No")}`
          : initialValue.sign.unit;
      setSignUnit(signUnit);
    }
    handleAlertConfig(initialValue.sign.id, "signId");
  }, [initialValue]);

  useEffect(() => {
    if (isTime) {
      handleAlertConfig(`${time}${timeUnit}`, "range_time");
    } else {
      handleAlertConfig(null, "range_time");
    }
  }, [time, timeUnit, isTime]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlertConfig({ ...alertConfig, label: e.target.value });
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <EditAlertConfigByValue
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            condition={condition}
            setCondition={setCondition}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            signUnit={signUnit}
            errors={errors}
          />
          <SubscriptionChecks
            sms={notification.sms}
            setSms={(checked: boolean) => handleNotification(checked, "sms")}
            email={notification.email}
            setEmail={(checked: boolean) =>
              handleNotification(checked, "email")
            }
            pushNotification={notification.pushNotification}
            setPushNotification={(checked: boolean) =>
              handleNotification(checked, "pushNotification")
            }
            subscribe={subscribe}
            setSubscribe={setSubscribe}
            messengerChannel={props.messengerChannel}
          />
          {alertConfig.customAlertConfigTemplate &&
            alertConfig.customAlertConfigTemplate.id === alertConfig.id && (
              <Box style={styles.warningAlertTemplate}>
                {t("WarningAlertConfigTemplateEdit")}
              </Box>
            )}
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("UpdateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
