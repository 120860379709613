import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { UserDto } from "../../User";
import { GetAlertGroupUserResponseDto } from "./user/AlertGroupUser";
import {
  IAddAlertGroupRequestDto,
  IUpdateAlertGroupRequestDto,
  IAddAlertGroupResponseDto,
  IUpdateAlertGroupResponseDto,
  IItemAlertGroupResponseDto,
  IGetAlertGroupResponseDto,
  IItemAlertGroupDto,
  ISubscriberDto,
  INotification,
} from "./IAlertGroup";
import { BasicItem } from "../../IItemList";
import { AlertConfigBasicDto } from "../config/AlertConfig";
import { GetDoctorResponseDto } from "../../Actors/Doctor/Doctor";
import { GetAdminCompanyResponseDto } from "../../Actors/AdminCompany/AdminCompany";
import { GetNurseResponseDto } from "../../Actors/Nurse/Nurse";

export class AddAlertGroupRequestDto implements IAddAlertGroupRequestDto {
  @IsNotEmpty() name: string = "";
  @IsNotEmpty() alertsConfig: number[] = [];
  @IsNotEmpty() patients: number[] = [];
  @IsNotEmpty() notifications: boolean = false;
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
  @IsNotEmpty() subscribe: boolean = false;
}

export class UpdateAlertGroupRequestDto implements IUpdateAlertGroupRequestDto {
  @IsOptional() name?: string;
  @IsOptional() alertsConfig?: number[];
  @IsOptional() patients?: number[];
  @IsOptional() notifications?: boolean;
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
}

export class AddAlertGroupResponseDto implements IAddAlertGroupResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = "";
  @Expose() notifications: boolean = false;
  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  createdBy: UserDto = new UserDto();
  @Expose() created_at: Date = new Date();
  @Expose() updated_at: Date = new Date();
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  patients: UserDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfig: AlertConfigBasicDto[] = [];
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
}

export class UpdateAlertGroupResponseDto
  implements IUpdateAlertGroupResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = "";
  @Expose() notifications: boolean = false;
  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  createdBy: UserDto = new UserDto();
  @Expose() created_at: Date = new Date();
  @Expose() updated_at: Date = new Date();
  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  patients: UserDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfig: AlertConfigBasicDto[] = [];
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
}

export class GetAlertGroupResponseDto implements IGetAlertGroupResponseDto {
  @Expose() id: number = 0;
  @Expose() name: string = "";
  @Expose() notifications: boolean = false;

  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  createdBy: UserDto = new UserDto();
  @Expose() created_at: Date = new Date();
  @Expose() updated_at: Date = new Date();

  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];

  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  patients: UserDto[] = [];

  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfig: AlertConfigBasicDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfigByValue: AlertConfigBasicDto[] = [];
  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfigByNoMeasure: AlertConfigBasicDto[] = [];
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
}

export class ItemAlertGroupDto implements IItemAlertGroupDto {
  @IsNotEmpty() id: number = 0;
  @IsNotEmpty() name: string = "";
}
export class ItemAlertGroupResponseDto
  extends ItemAlertGroupDto
  implements IItemAlertGroupResponseDto {
  @Expose() notifications: boolean = false;
  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  createdBy: UserDto = new UserDto();
  @Expose() created_at: Date = new Date();
  @Expose() updated_at: Date = new Date();

  @Expose()
  @ValidateNested()
  @Type(() => SubscriberDto)
  subscribers: SubscriberDto[] = [];

  @Expose()
  @ValidateNested()
  @Type(() => UserDto)
  patients: UserDto[] = [];

  @Expose()
  @ValidateNested()
  @Type(() => AlertConfigBasicDto)
  alertsConfig: AlertConfigBasicDto[] = [];
  @IsNotEmpty() notification: INotification = { email: true, sms: false, pushNotification: true };
}

export class SubscriberDto implements ISubscriberDto {
  /**
   * key in join table AlertGroupUser
   */
  id: number = 0;
  user?: GetDoctorResponseDto | GetAdminCompanyResponseDto | GetNurseResponseDto;
}