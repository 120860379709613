import React from "react";
import { useTranslation } from "react-i18next";
import { AlertGroupListGridColumns } from "./AlertGroupListDataGridColumns";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { GetAlertGroupResponseDto } from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup";

interface AlertGroupListProps {
  onEdit: (alertGroup: GetAlertGroupResponseDto) => void;
  onDelete: (alertGroup: GetAlertGroupResponseDto) => void;
  alertGroups: GetAlertGroupResponseDto[] | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  alertGroupPaginationInfo: any;
}

const AlertGroupList = ({
  onDelete,
  onEdit,
  alertGroups,
  setPage,
  setPageSize,
  alertGroupPaginationInfo,
}: AlertGroupListProps): React.ReactElement => {
  const { t, i18n } = useTranslation();

  if (!alertGroups) return <></>;

  const datagridColumns: GridColDef<GetAlertGroupResponseDto>[] =
    AlertGroupListGridColumns(t, onEdit, onDelete);

  return (
    <>
      <FlexLayoutGrid
        language={i18n.language}
        columns={datagridColumns as any}
        rows={alertGroups}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        pagination={alertGroupPaginationInfo}
        sx={DoctomaticStylingRowsGrid() as any}
      />
    </>
  );
};

export { AlertGroupList };
