import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { GetCompanyResponseDto } from "../Company";
import { IGetDeviceResponseDto } from "../Devices";
import {
  GetFrequencyResponseDto,
  IGetFrequencyResponseDto,
} from "../Frequencies";
import { IBasicItem, IPatientList } from "../IItemList";
import { IGetPatientResponseDto } from "../Patient/IPatient";
import { GetPatientResponseDto } from "../Patient/Patient";
import { IGetSignResponseDto, ISignDto } from "../Sign/ISign";
import { GetUserResponseDto, IGetUserResponseDto } from "../User";
import { IGetAlertConfigByFrequencyIndividualResponseDto } from "./config/individual/IAlertConfigByFrequencyIndividual";
import { IGetAlertConfigByNoMeasureIndividualResponseDto } from "./config/individual/IAlertConfigByNoMeasureIndividual";
import { IGetAlertConfigByValueIndividualResponseDto } from "./config/individual/IAlertConfigByValueIndividual";
import { IGetAlertConfigIndividualResponseDto } from "./config/individual/IAlertConfigIndividual";
import { IGetAlertConfigByNoMeasureTemplateResponseDto } from "./config/template/IAlertConfigByNoMeasureTemplate";
import { IGetAlertConfigByValueTemplateResponseDto } from "./config/template/IAlertConfigByValueTemplate";
import { IGetAlertConfigTemplateResponseDto } from "./config/template/IAlertConfigTemplate";

export enum AlertState {
  created = "created",
  inprogress = "inprogress",
  managed = "managed",
  newReadReceived = "newReadReceived",
}

export const getReadableAlertStates = () => {
  return [
    AlertState.created,
    AlertState.inprogress,
    AlertState.managed,
    AlertState.newReadReceived,
  ];
};

export enum AlertTypeAlert {
  alert_by_no_measure = "AlertByNoMeasure",
  alert_by_value = "AlertByValue",
  alert_by_frequency = "AlertByFrequency",
}

export enum AlertType {
  time_absolute = "time_absolute",
  time_relative = "time_relative",
  max = "max",
  min = "min",
  range_absolute = "range_absolute",
  range_relative = "range_relative",
  get_and_lt = "get_and_lt",
  //get_and_lt_time = 'get_and_lt_time'
}

export interface IAlertBasicDto {
  id: number;
  label: string;
  color: string;
}

export interface IGetAlertUserAckDto {
  user: IGetUserResponseDto;
  ack_at: Date;
}

export class GetAlertUserAckDto implements IGetAlertUserAckDto {
  @Expose()
  @ValidateNested()
  @Type(() => GetUserResponseDto)
  user: GetUserResponseDto = new GetUserResponseDto();
  @Expose() ack_at: Date = new Date();
}

export interface IGetAlertResponseDto {
  id: number;
  type: AlertType;
  patient: IGetPatientResponseDto;
  sms: boolean;
  email: boolean;
  active: boolean;
  color: string;
  label: string;
  comments: string;
  ackBy: IGetAlertUserAckDto[];
  created_at: Date;
  state: AlertState;
}

export interface IGetAlertByValueResponseDto extends IGetAlertResponseDto {
  alertConfig: IBasicItem;
  sign: ISignDto;
  value: number;
  min?: number;
  max?: number;
  alert_type?: AlertType;
  range_value?: number;
  range_time?: string;
  valueText?: string;
  individualConfig?: IGetAlertConfigByValueIndividualResponseDto;
  templateConfig?: IGetAlertConfigByValueTemplateResponseDto;
}

export interface IGetAlertByNoMeasureResponseDto extends IGetAlertResponseDto {
  device: IGetDeviceResponseDto;
  alertConfig: IBasicItem;
  max_time_between: string;
  individualConfig?: IGetAlertConfigByNoMeasureIndividualResponseDto;
  templateConfig?: IGetAlertConfigByNoMeasureTemplateResponseDto;
}

export interface IGetAlertByFrequencyResponseDto extends IGetAlertResponseDto {
  individualConfig: IGetAlertConfigByFrequencyIndividualResponseDto;
  timeout: number;
  timeToValidate: number;
}

export interface IItemAlert {
  id: number;
  name: string;
  color: string;
  comments: string;
  device: IBasicItem;
  createdAt: Date;
  ackBys: {
    id: number;
    userId: number;
    userName: string;
    createdAt: Date;
  }[];
  trackings: IBasicItem[];
  state: AlertState;
  alertConfigId?: number;
  typeAlert?: string;
}

type ItemAlertWithoutTrackings = Omit<IItemAlert, "trackings">;

export interface IItemAlertByValue extends ItemAlertWithoutTrackings {
  sign: IGetSignResponseDto;
  value: string;
  readDeviceId?: number;
  typeAlert: string;
  valueText?: string;
  individualConfig?: IGetAlertConfigIndividualResponseDto;
  templateConfig?: IGetAlertConfigTemplateResponseDto;
  createdMeasurementAt?: Date;
  typeReadDevice?: string;
}

export interface IItemAlertByNoMeasure extends ItemAlertWithoutTrackings {
  max_time_between: string;
  individualConfig?: IGetAlertConfigIndividualResponseDto;
  templateConfig?: IGetAlertConfigTemplateResponseDto;
}

type ItemAlertWithoutTrackingsAndDevice = Omit<
  IItemAlert,
  "trackings" | "device"
>;

export interface IItemAlertByFrequency
  extends ItemAlertWithoutTrackingsAndDevice {
  timeout: number;
  timeToValidate: number;
  frequency: IGetFrequencyResponseDto;
  individualConfig?: IGetAlertConfigIndividualResponseDto;
  max_time_between?: string;
  templateConfig?: IGetAlertConfigTemplateResponseDto;
}

export interface ILastAlertsByValuePatient {
  patient: IPatientList;
  alert: IItemAlertByValue;
}

export interface ILastAlertsByNoMeasurePatient {
  patient: IPatientList;
  alert: IItemAlertByNoMeasure;
}

export interface ILastAlertsByFrequencyPatient {
  patient: IPatientList;
  alert: IItemAlertByFrequency;
}

export interface IAlertCommentRequestDto {
  comments: string;
}

export interface IAlertStateRequestDto {
  state: AlertState;
}

export interface ICreateAlertValueRequestDto {
  measurementId: number;
  alertConfigId: number;
}

export interface ICreateAlertValueResponseDto {
  id: number;
  label: string;
  patientId: number;
  measurementId: number;
}
export interface ICreateAlertFrequencyRequestDto {
  patientId: number;
  alertConfigId: number;
}

export interface ICreateAlertFrequencyResponseDto {
  id: number;
  label: string;
  patient: GetPatientResponseDto;
  patientId: number;
  color: string;
  company: GetCompanyResponseDto;
  alertConfigName: string;
  createdAt: Date;
  alertConfigId: number;
  frequency: GetFrequencyResponseDto;
  frequencyId: number;
}
