import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { sortText } from "../../../../App/sortFunctions";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { getDeviceName } from "../../utils";

export interface CreateTreatmentPatientGridColumnsProps {
  t: (label: string) => string;
  onAssign: (diseaseId: number) => void;
  currentLanguage: string;
}

export const CreateTreatmentPatientGridColumns = ({
  t,
  onAssign,
  currentLanguage,
}: CreateTreatmentPatientGridColumnsProps): GridColDef[] => {
  return [
    {
      field: "assign",
      headerName: t("AssignColumn"),
      width: 70,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <IconButton onClick={() => onAssign(params.row.id)}>
          <AddCircle />
        </IconButton>
      ),
    },
    {
      field: "name",
      headerName: t("DiseaseNameColumn"),
      width: 200,
      valueGetter: (value: any, row: any) => row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => <p>{params.row.name}</p>,
    },
    {
      field: "createdBy",
      headerName: t("CreatedByColumn"),
      width: 200,
      valueGetter: (value: any, row: any) => row.createdBy,
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => <p>{params.row.createdBy}</p>,
    },
    {
      field: "devices",
      headerName: t("DevicesColumn"),
      width: 200,
      valueGetter: (value: any, row: any) => row.numDevices,
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        if (params.row.devices && params.row.devices.length > 0) {
          const devices = params.row.devices.map(
            (device: GetDeviceResponseDto) =>
              getDeviceName(currentLanguage, device, t)
          );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={params.row.devices?.map((device: GetDeviceResponseDto) =>
                getDeviceName(currentLanguage, device, t)
              )}
              tooltipContent={{ title: t("DevicesColumn"), lines: devices }}
            ></TextWordsQuantityCell>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
};
