import React from "react";
import { GridAlignment, GridColDef, GridColType } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import LastAlertsByValueDataRow from "./LastAlertsByValueDataRow";
import { ViewAlertsCell } from "@doctomatic/components-react/build/Alerts/ViewAlertsCell";
import { Link } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../../breadcrumbMap";
import {
  AlertState,
  IItemAlertByValue,
  AlertType,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { HtmlTooltipLines } from "@doctomatic/components-react/build/Tooltips/HtmlTooltip";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { ResponseApi, ResponseApiList } from "@doctomatic/sdk/build/global";
import { KeyedMutator } from "swr";
import { LastAlertsByValuePatient } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { CustomCheckbox } from "@doctomatic/components-react/build/Checkbox/CustomCheckbox";
import { formatAckBysForModal } from "../../../Alerts/utils";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { Link as NavLink } from "react-router-dom";
import { getDeviceName, getSignName, toLocaleString } from "../../../utils";
import { sortText } from "../../../../../App/sortFunctions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Launch from "@mui/icons-material/Launch";
import { IconButton } from "@mui/material";
import { IGetAlertConfigByValueTemplateResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/IAlertConfigByValueTemplate";
import { IGetAlertConfigByValueIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/IAlertConfigByValueIndividual";

export interface ILastAlertsByValueGridColumnsProps {
  actorId: number;
  mutate: KeyedMutator<ResponseApiList<LastAlertsByValuePatient>>;
  setLastAlerts: React.Dispatch<
    React.SetStateAction<LastAlertsByValuePatient[]>
  >;
  showExternalId?: boolean;
  showModal?: (item: any) => void;
  showStateModal?: (
    id: number | undefined,
    item: AlertState | undefined
  ) => void;
  setAlertComments?: (
    id: number,
    comments: string | undefined
  ) => Promise<boolean>;
  showImageModal: (item: LastAlertsByValueDataRow) => void;
  currentLanguage: string;
}

export const LastAlertsByValueGridColumns = ({
  actorId,
  mutate,
  setLastAlerts,
  showExternalId,
  showModal,
  showStateModal,
  setAlertComments,
  showImageModal,
  currentLanguage,
}: ILastAlertsByValueGridColumnsProps): GridColDef<LastAlertsByValueDataRow>[] => {
  const { t } = useTranslation();
  const { useAlerts }: IProvideApi = useApi();
  const { setAck } = useAlerts();

  const AddToolTipLastAlerts = (
    alert: any,
    element: React.ReactElement
  ): JSX.Element => {
    const lines: string[] = [alert].map((x: IItemAlertByValue) => {
      const alertConfig:
        | IGetAlertConfigByValueTemplateResponseDto
        | IGetAlertConfigByValueIndividualResponseDto
        | undefined = x.individualConfig
        ? x.individualConfig
        : x.templateConfig;
      let unitValue: string | number = "";
      switch (x.typeAlert) {
        case AlertType.max:
          if (alertConfig?.valueText) {
            unitValue = alertConfig?.valueText ? alertConfig.valueText : "";
          } else {
            unitValue = alertConfig?.max ?? "";
          }
          break;
        case AlertType.min:
          if (alertConfig?.valueText) {
            unitValue = alertConfig?.valueText ? alertConfig.valueText : "";
          } else {
            unitValue = alertConfig?.min ?? "";
          }
          break;
        case AlertType.get_and_lt:
          if (alertConfig?.valueText) {
            unitValue = alertConfig?.valueText ? alertConfig.valueText : "";
          } else {
            unitValue =
              alertConfig?.min && alertConfig?.max
                ? `${alertConfig?.min} - ${alertConfig?.max}`
                : "";
          }
          break;
        case AlertType.range_absolute:
        case AlertType.range_relative:
        case AlertType.time_absolute:
        case AlertType.time_relative:
          if (alertConfig?.valueText) {
            unitValue = alertConfig?.valueText ? alertConfig.valueText : "";
          } else {
            unitValue = alertConfig?.range_value ?? "";
          }
          break;
      }
      return `${t("FieldCondition")}: ${t(x?.typeAlert)} ${unitValue}`;
    });
    return HtmlTooltipLines(alert?.name, lines, element);
  };

  const setAlertAck = async (id: number) => {
    const response: ResponseApi<any> = await setAck(id);
    if (response && response.success === true && response.data!) {
      const mutateValueResponse:
        | ResponseApiList<LastAlertsByValuePatient>
        | undefined = await mutate();
      if (
        mutateValueResponse &&
        mutateValueResponse.success === true &&
        mutateValueResponse.data!
      ) {
        setLastAlerts(mutateValueResponse.data);
      }
    }
  };

  const gridColumns: GridColDef<LastAlertsByValueDataRow>[] = [];
  gridColumns.push({
    field: "alertColor",
    headerName: t("AlertColor"),
    width: 70,
    align: "left" as GridAlignment,
    valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
      row.alert?.color ?? "green",
    sortable: false,
    renderCell: (params: { row: LastAlertsByValueDataRow }) => {
      return (
        <ViewAlertsCell
          alerts={[
            {
              id: params.row.alert?.id ?? 0,
              color: params.row.alert?.color ?? "green",
            },
          ]}
          row={undefined}
        />
      );
    },
  });
  if (showExternalId) {
    gridColumns.push({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 150,
      align: "left" as GridAlignment,
      valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
        row.patient.externalId ? row.patient.externalId : "--",
      sortable: false,
      renderCell: (params: { row: LastAlertsByValueDataRow }) => (
        <div>
          {params.row.patient.externalId ? params.row.patient.externalId : "--"}
        </div>
      ),
    });
  }

  gridColumns.push(
    ...[
      {
        field: "state",
        headerName: t("AlertState"),
        width: 120,
        align: "center" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) => row.state,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const lastAlertState = params.row.alert?.state;
          const alertStateCapitalized = `${lastAlertState
            ?.charAt(0)
            .toUpperCase()}${lastAlertState?.substring(1).toLowerCase()}`;
          return (
            <TextWordsQuantityCell
              onClickModal={() =>
                showStateModal!(params.row.alert?.id, lastAlertState)
              }
              item={params.row}
              words={[t(alertStateCapitalized)]}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "patient.name",
        headerName: t("PatientName"),
        width: 250,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.patient.name,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const userPath = BreadcrumbNameMap.Patient.url.replace(
            ":patientId",
            params.row.patient.id.toString()
          );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={[params.row.patient.name]}
              page={userPath}
              routerLink={NavLink}
            />
          );
        },
      },
      {
        field: "created_at",
        headerName: t("CreatedAt"),
        width: 150,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.createdAt,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const content: JSX.Element = (
            <div>
              {params.row.alert
                ? toLocaleString(new Date(params.row.alert?.createdAt))
                : "--"}
            </div>
          );
          return AddToolTipLastAlerts(params.row.alert, content);
        },
      },
      {
        field: "alertName",
        headerName: t("AlertName"),
        width: 200,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.name,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const alertConfigUrl =
            BreadcrumbNameMap.AlertConfigByValue.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            );
          const content: JSX.Element = (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {params.row.alert && params.row.alert.alertConfigId ? (
                <NavLink
                  to={alertConfigUrl.concat(
                    `?alertConfigId=${params.row.alert.alertConfigId}`
                  )}
                >
                  <Launch />
                </NavLink>
              ) : (
                "--"
              )}
              <div style={{ marginLeft: 5 }}></div>
              <div>
                {params.row.alert?.device?.name === "Form" &&
                (params.row.alert?.name === "Yes" ||
                  params.row.alert?.name === "No")
                  ? t(params.row.alert?.name)
                  : params.row.alert?.name}
              </div>
            </div>
          );
          return AddToolTipLastAlerts(params.row.alert, content);
        },
      },
      {
        field: "typeAlert",
        headerName: t("TypeAlert"),
        width: 220,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.typeAlert,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const content: JSX.Element = (
            <div>
              {params.row.alert?.typeAlert
                ? t(params.row.alert?.typeAlert)
                : ""}
            </div>
          );
          return AddToolTipLastAlerts(params.row.alert, content);
        },
      },
      {
        field: "sign",
        headerName: t("Sign"),
        width: 100,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert ? row.alert.sign?.name : "",
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => (
          <div>
            {params.row.alert
              ? getSignName(currentLanguage, params.row.alert.sign, t)
              : ""}
          </div>
        ),
      },
      {
        field: "value",
        type: "number" as GridColType,
        headerName: t("Value"),
        width: 100,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.value,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => (
          <div>
            {params.row.alert?.device?.name === "Form"
              ? params.row.alert?.valueText
                ? t(params.row.alert?.valueText)
                : "--"
              : params.row.alert?.value}
          </div>
        ),
      },
      {
        field: "image",
        headerName: t("Image"),
        width: 100,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) =>
          params.row.alert &&
          params.row.alert.sign.device.typeName !== "Form" ? (
            <IconButton
              onClick={() => showImageModal(params.row)}
              color="inherit"
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            "--"
          ),
      },
      {
        field: "created_measurement_at",
        headerName: t("CreatedMeasurementAt"),
        width: 150,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.createdMeasurementAt,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const content: JSX.Element = (
            <div>
              {params.row.alert
                ? toLocaleString(
                    new Date(params.row.alert?.createdMeasurementAt ?? "")
                  )
                : "--"}
            </div>
          );
          return AddToolTipLastAlerts(params.row.alert, content);
        },
      },
      {
        field: "sign.device.name",
        headerName: t("Device"),
        width: 100,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert ? row.alert.device.name : "",
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => (
          <div>
            {params.row.alert
              ? getDeviceName(currentLanguage, params.row.alert.device, t)
              : ""}
          </div>
        ),
      },
      {
        field: "comments",
        headerName: t("Comments"),
        width: 95,
        align: "center" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.comments,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          return (
            <ElementGridStringEdit
              textCancel={t("Cancel")}
              textSave={t("Save")}
              textLabelField={t("Comments")}
              itemToAddCommentId={params.row.alert!.id}
              text={params.row.alert?.comments}
              onSave={
                setAlertComments
                  ? setAlertComments
                  : async (): Promise<boolean> => {
                      return true;
                    }
              }
              onClose={() => {}}
            />
          );
        },
      },
      {
        field: "trackings",
        headerName: t("Trackings"),
        width: 150,
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const page: string = `/trackings/patients/${params.row.patient.id}`;
          const content: JSX.Element = (
            <TextWordsQuantityCell
              item={params.row}
              words={[t("Go_to_trankings")]}
              page={page}
              routerLink={NavLink}
            />
          );
          return content;
        },
      },
      {
        field: "active",
        headerName: t("ConfigAlert"),
        width: 200,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) =>
          row.alert?.individualConfig
            ? row.alert?.individualConfig.active
              ? t("Active")
              : t("Deactive")
            : row.alert?.templateConfig?.active
            ? t("Active")
            : t("Deactive"),
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const active = params.row.alert?.individualConfig
            ? params.row.alert.individualConfig.active
            : params.row.alert?.templateConfig
            ? params.row.alert?.templateConfig.active
            : false;
          return (
            <TextWordsQuantityCell
              routerLink={Link}
              item={params.row}
              words={[active ? t("Active") : t("Deactive")]}
              page={BreadcrumbNameMap.AlertConfigByValue.url.replace(
                ":patientId",
                params.row.patient.id.toString()
              )}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "ackBy",
        headerName: t("AckBy"),
        width: 150,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) => {
          const texts = row.alert?.ackBys
            ? row.alert?.ackBys.map((ack: any) => ack.userName)
            : [];
          if (texts.length === 0) texts.push("---");
          return texts.join(", ");
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const checkedByActor: boolean = !!params.row.alert?.ackBys?.find(
            (x: any) => x.userId === actorId
          );

          return checkedByActor ? (
            <IconButton
              onClick={() => {
                const ackBysFormatted = formatAckBysForModal({
                  ackBys: params.row.alert?.ackBys,
                });
                showModal!(ackBysFormatted);
              }}
              color="inherit"
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            <CustomCheckbox
              defaultValue={checkedByActor}
              onChange={() => setAlertAck(params.row.alert!.id)}
            />
          );
        },
      },
      {
        field: "setAck",
        headerName: t("HeaderActions"),
        width: 150,
        valueGetter: (value: any, row: LastAlertsByValueDataRow) => {
          const defaultValue: boolean = !!row.alert?.ackBys?.find(
            (x: any) => x.userId === actorId
          );
          return defaultValue;
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueDataRow }) => {
          const checkedByActor: boolean = !!params.row.alert?.ackBys?.find(
            (x: any) => x.userId === actorId
          );
          return (
            <CustomCheckbox
              defaultValue={checkedByActor}
              onChange={() => setAlertAck(params.row.alert!.id)}
            />
          );
        },
      },
    ]
  );

  return gridColumns;
};
