import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AlertsByFrequencyGridColumns } from "./AlertsByFrequencyGridColumns";
import {
  AlertState,
  IGetAlertByFrequencyResponseDto,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import AlertsByFrequencyDataRow from "./AlertsByFrequencyDataRow";
import { showAlertStateModal, showAlertSubscribersModal } from "../utils";
import { GridRowClassNameParams, GridSortModel } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { GetAlertByFrequencyResponseDto } from "@doctomatic/sdk/build/dto/Alerts/Alerts";

interface Props {
  alerts: GetAlertByFrequencyResponseDto[] | undefined;
  actorId: number;
  actorRole: Role;
  timezone: string;
  setAlertAck: (id: number, item: any) => void;
  setAlertState: (id: number, item: any) => void;
  setAlertComments: (
    id: number,
    comments: string | undefined
  ) => Promise<boolean>;
  showExternalId?: boolean | undefined;
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  isLoading?: boolean;
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
}

export const AlertsByFrequencyList = ({
  alerts,
  actorId,
  actorRole,
  setAlertAck,
  setAlertState,
  showExternalId,
  setAlertComments,
  timezone,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  isLoading,
  sortBy,
  onSortChange,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);

  if (!alerts) return <></>;

  const openAckByModal = (item: any) => {
    setItem(item);
  };

  const openAlertStateModal = (id: number, state: any) => {
    setCurrentAlertState({ id, state });
  };

  const alertsGridColumns = AlertsByFrequencyGridColumns(
    t,
    showExternalId,
    timezone,
    openAckByModal,
    openAlertStateModal,
    setAlertComments
  );

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone,
    });

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(currentAlertState.id, stateSelected as AlertState);
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const alertsByFrequencyDataRow: AlertsByFrequencyDataRow[] = alerts.map(
    (alert: IGetAlertByFrequencyResponseDto) => {
      const alertByFrequencyDataRow = new AlertsByFrequencyDataRow(
        alert.id,
        alert.patient,
        alert.color,
        alert.label,
        alert.comments,
        alert.created_at,
        alert.ackBy,
        actorId,
        actorRole,
        alert.state,
        (item: any) => setAlertAck(alert.id, item),
        // TODO use individual config
        alert.individualConfig,
        alert.individualConfig.frequencyId
      );
      return alertByFrequencyDataRow;
    }
  );

  const setRowClassName = (
    params: GridRowClassNameParams<AlertsByFrequencyDataRow>
  ): string => {
    if (params.row.individualConfig && !params.row.individualConfig.active) {
      return "no-actived-alert-config";
    }

    return "";
  };

  return (
    <>
      <FlexLayoutGrid
        language={i18n.language}
        columns={alertsGridColumns as any}
        rows={alertsByFrequencyDataRow}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationInfo}
        isLoading={isLoading}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid() as any}
        sortBy={sortBy}
        onSortChange={onSortChange}
      />
      {showModal}
      {alertStateModal}
    </>
  );
};
