import { GridColDef } from "@mui/x-data-grid";
import TreatmentsDataRow from "../TreatmentsDataRow";
import {
  TreatmentsGridColumns,
  TreatmentsGridColumnsProps,
} from "../TreatmentsGridColumns";

export interface TreatmentsPatientGridColumnsProps
  extends TreatmentsGridColumnsProps {
  patientId: number;
}

export const TreatmentsPatientGridColumns = ({
  t,
  timezone,
  popoverConf,
}: TreatmentsPatientGridColumnsProps): GridColDef<TreatmentsDataRow>[] => {
  const columns = TreatmentsGridColumns({
    t,
    timezone,
    popoverConf,
  }) as GridColDef<TreatmentsDataRow>[];
  return columns;
};
