import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import LastAlertsByValueByPatientDataRow from "./LastAlertsByValueByPatientDataRow";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { Link } from "react-router-dom";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { sortText } from "../../../../../App/sortFunctions";
import { toLocaleString } from "../../../utils";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const LastAlertsByValueGridColumns = (
  timezone: string,
  showModal?: (item: any) => void,
  showStateModal?: (id: number, item: any) => void,
  setAlertComments?: (
    id: number,
    comments: string | undefined
  ) => Promise<boolean>,
  showImageModal?: (item: any) => void
): GridColDef<LastAlertsByValueByPatientDataRow>[] => {
  const { t } = useTranslation();
  const gridColumns: GridColDef<LastAlertsByValueByPatientDataRow>[] = [];
  gridColumns.push(
    ...[
      {
        field: "color",
        headerName: t("Level"),
        width: 50,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.color,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => (
          <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
        ),
      },
      {
        field: "state",
        headerName: t("AlertState"),
        width: 75,
        align: "center" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.state,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => {
          const alertStateCapitalized =
            params.row.state[0].toUpperCase() +
            params.row.state.substr(1).toLowerCase();
          return (
            <TextWordsQuantityCell
              onClickModal={() =>
                showStateModal!(params.row.id, params.row.state)
              }
              item={params.row}
              words={[t(alertStateCapitalized)]}
            ></TextWordsQuantityCell>
          );
        },
      },
      {
        field: "comments",
        headerName: t("Comments"),
        width: 95,
        align: "center" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.comments,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => {
          return (
            <ElementGridStringEdit
              textCancel={t("Cancel")}
              textSave={t("Save")}
              textLabelField={t("Comments")}
              itemToAddCommentId={params.row.id}
              text={params.row.comments}
              onSave={
                setAlertComments
                  ? setAlertComments
                  : async (): Promise<boolean> => {
                      return true;
                    }
              }
              onClose={() => {}}
            />
          );
        },
      },
      {
        field: "label",
        headerName: t("AlertName"),
        width: 200,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.label,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => (
          <p>{params.row.label}</p>
        ),
      },
      {
        field: "value",
        headerName: t("Value"),
        width: 100,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.value,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => (
          <p>{params.row.value}</p>
        ),
      },
      {
        field: "image",
        headerName: t("Image"),
        width: 100,
        renderCell: (params: { row: any }) =>
          params.row.sign && params.row.sign.device.typeName !== "Form" ? (
            <IconButton
              onClick={() => showImageModal!(params.row)}
              color="inherit"
            >
              <VisibilityIcon />
            </IconButton>
          ) : (
            "--"
          ),
      },
      {
        field: "configAlert",
        headerName: t("ConfigAlert"),
        width: 200,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) => {
          const configs = [];
          if (row.email) configs.push("email");
          if (row.sms) configs.push("SMS");
          return configs.join(",");
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => {
          const configs: string[] = [];
          if (params.row.email) configs.push("email");
          if (params.row.sms) configs.push("SMS");
          return configs.join(",");
        },
      },
      {
        field: "createdAt",
        headerName: t("CreatedAt"),
        width: 200,
        align: "left" as GridAlignment,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) =>
          row.created_at,
        sortComparator: (v1: any, v2: any) =>
          new Date(v2!).getTime() - new Date(v1!).getTime(),
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => (
          <p>{toLocaleString(new Date(params.row.created_at))}</p>
        ),
      },
      {
        field: "ackBy",
        headerName: t("HeaderAckBy"),
        width: 200,
        valueGetter: (value: any, row: LastAlertsByValueByPatientDataRow) => {
          const texts = row.ackBy && row.ackBy.map((ack: any) => ack.user.name);
          if (texts.length === 0) texts.push("---");
          return texts.join(", ");
        },
        sortable: false,
        renderCell: (params: { row: LastAlertsByValueByPatientDataRow }) => {
          const texts =
            params.row.ackBy &&
            params.row.ackBy.map((ack: any) => ack.user.name);
          if (texts.length === 0) texts.push("---");
          return (
            <TextWordsQuantityCell
              routerLink={Link}
              item={params.row}
              words={texts}
              onClickModal={() => showModal!(params.row)}
            ></TextWordsQuantityCell>
          );
        },
      },
    ]
  );
  return gridColumns;
};
