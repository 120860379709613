import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AlertsByNoMeasureGridColumns } from "./AlertsByNoMeasureGridColumns";
import { GetAlertByNoMeasureResponseDto } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import {
  AlertState,
  AlertTypeAlert,
} from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import AlertsByNoMeasureDataRow from "./AlertsByNoMeasureDataRow";
import { showAlertStateModal, showAlertSubscribersModal } from "../utils";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { GridRowClassNameParams, GridSortModel } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
interface Props {
  alerts: GetAlertByNoMeasureResponseDto[] | undefined;
  actorId: number;
  actorRole: Role;
  timezone: string;
  setAlertAck: (id: number, item: any) => void;
  setAlertState: (id: number, item: any, type: string) => void;
  setAlertComments: (
    id: number,
    comments: string | undefined,
    type?: string
  ) => Promise<boolean>;
  showExternalId?: boolean | undefined;
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  isLoading?: boolean;
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
}

export const AlertsByNoMeasureList = ({
  alerts,
  actorId,
  actorRole,
  setAlertAck,
  setAlertState,
  showExternalId,
  setAlertComments,
  timezone,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  isLoading,
  sortBy,
  onSortChange,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState<{
    id: number;
    state: AlertState;
  } | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  if (!alerts) return <></>;

  const openAckByModal = (item: any) => {
    setItem(item);
  };

  const openAlertStateModal = (id: number, state: any) => {
    setCurrentAlertState({ id, state });
  };

  const alertsGridColumns = AlertsByNoMeasureGridColumns(
    t,
    showExternalId,
    timezone,
    currentLanguage,
    openAckByModal,
    openAlertStateModal,
    setAlertComments
  );

  const showModal =
    item &&
    showAlertSubscribersModal({
      open: Boolean(item),
      item,
      handleClose: () => setItem(null),
      timezone,
    });

  const alertStateModal =
    currentAlertState &&
    showAlertStateModal({
      open: Boolean(currentAlertState),
      currentState: currentAlertState,
      handleClose: (stateSelected: any) => {
        if (
          typeof stateSelected === "string" &&
          currentAlertState.state.toString() !==
            stateSelected.toString().toLowerCase()
        ) {
          setAlertState(
            currentAlertState.id,
            stateSelected as AlertState,
            AlertTypeAlert.alert_by_no_measure
          );
        }
        setCurrentAlertState(null);
      },
      t,
    });

  const alertsByNoMeasureDataRow: AlertsByNoMeasureDataRow[] = alerts.map(
    (alert: GetAlertByNoMeasureResponseDto) => {
      const alertByNoMeasureDataRow = new AlertsByNoMeasureDataRow(
        alert.id,
        alert.patient,
        alert.color,
        alert.label,
        alert.comments,
        alert.created_at,
        alert.ackBy,
        alert.patient.treatments ? alert.patient.treatments : [],
        actorId,
        actorRole,
        alert.state,
        alert.device,
        (item: any) => setAlertAck(alert.id, item),
        undefined,
        alert.individualConfig,
        alert.templateConfig
      );
      return alertByNoMeasureDataRow;
    }
  );

  const setRowClassName = (
    params: GridRowClassNameParams<AlertsByNoMeasureDataRow>
  ): string => {
    if (params.row.individualConfig && !params.row.individualConfig.active) {
      return "no-actived-alert-config";
    }
    if (params.row.templateConfig && !params.row.templateConfig.active) {
      return "no-actived-alert-config";
    }

    return "";
  };

  return (
    <>
      <FlexLayoutGrid
        language={i18n.language}
        columns={alertsGridColumns as any}
        rows={alertsByNoMeasureDataRow}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationInfo}
        isLoading={isLoading}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid() as any}
        sortBy={sortBy}
        onSortChange={onSortChange}
      />
      {showModal}
      {alertStateModal}
    </>
  );
};
