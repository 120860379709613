import { GetCompanyResponseDto } from "../Company";
import { GetLocationUserResponseDto } from "../LocationUser";
import {
  IAssignLocationRequestDto,
  IAssignLocationResponseDto,
 ICreateLocationRequestDto, ICreateLocationResponseDto, IGetLocationResponseDto,
  IItemLocation, IUpdateLocationRequestDto, IUpdateLocationResponseDto
} from "./ILocation";
import {
  Length,
  IsOptional,
} from 'class-validator';
import { IGetUserResponseDto } from "../User";

export class CreateLocationRequestDto implements ICreateLocationRequestDto {
  @Length(1)
  name: string = '';
  @IsOptional()
  description?: string;
  parentId?: number;
}

export class CreateLocationResponseDto implements ICreateLocationResponseDto {
  id: number = 0;
  name: string = "";
  description: string = "";
  company: GetCompanyResponseDto = new GetCompanyResponseDto();
  companyId: number = 0;
  parent: GetLocationResponseDto | null = null;
}

export class GetLocationResponseDto implements IGetLocationResponseDto {
  id: number = 0;
  name: string = "";
  description: string = "";
  location_user?: GetLocationUserResponseDto[] = [];
  company: GetCompanyResponseDto = new GetCompanyResponseDto();
  companyId: number = 0;
  parent: GetLocationResponseDto | null = null;
  children: GetLocationResponseDto[] = [];
  created_at: Date = new Date();
  updated_at: Date = new Date();
  deleted_at?: Date;
  timezone: string = "";
}

export class UpdateLocationRequestDto implements IUpdateLocationRequestDto {
  name?: string;
  description?: string;
}

export class UpdateLocationResponseDto implements IUpdateLocationResponseDto {
  id: number = 0;
  name: string = "";
  description: string = "";
  company: GetCompanyResponseDto = new GetCompanyResponseDto();
  companyId: number = 0;
  parent: GetLocationResponseDto | null = null;
}

export class AssignLocationRequestDto implements IAssignLocationRequestDto {
  inheritance: boolean= false;
  actorId: number = 0;
}

export class AssignLocationResponseDto implements IAssignLocationResponseDto {
  id: number=0;
  location!: IGetLocationResponseDto;
  user!: IGetUserResponseDto;
  inheritance: boolean =false;
}

export class ItemLocationListResponseDto implements IItemLocation {
  id: number = 0;
  name: string = '';
  quantityPatients: number = 0;
  children: ItemLocation[] = [];
  parent?: IItemLocation;
  description?: string;
  location_user?: GetLocationUserResponseDto[];
}

export class ItemLocation extends ItemLocationListResponseDto implements IItemLocation {
  id: number = 0;
  name: string = '';
  quantityPatients: number = 0;
  children: ItemLocation[] = [];
  parent?: IItemLocation;
  location_user?: GetLocationUserResponseDto[];
}
