import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByNoMeasureIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import { toast } from "react-toastify";
import {
  ItemAlertConfigByNoMeasureIndividualDto,
  AddAlertConfigByNoMeasureIndividualRequestDto,
  UpdateAlertConfigByNoMeasureIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual";
import { processError } from "../../../../../App/errorToast";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { EditAlertConfigByNoMeasure } from "../../../../AlertsConfig/EditAlertConfigByNoMeasureForm";
import { SubscriptionChecks } from "../../../../AlertsConfig/SubscriptionChecks";
import { getDeviceName, getSignName } from "../../../utils";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";
import { useNavigate } from "react-router-dom";

export interface UpdateAlertConfigByNoMeasureModalProps extends ModalBaseProps {
  patientId: number;
  alertConfigByNoMeasureIndividual: ItemAlertConfigByNoMeasureIndividualDto;
  permissions: Array<string>;
  currentLanguage: string;
  messengerChannel: string;
}

export const UpdateAlertConfigByNoMeasureModal = (
  props: UpdateAlertConfigByNoMeasureModalProps
): React.ReactElement => {
  const initNotification = props.alertConfigByNoMeasureIndividual.notification;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useAlertConfigsByNoMeasure, useDevices, id, logout }: IProvideApi =
    useApi();
  const { patch, add }: IUseAlertConfigsByNoMeasureIndividual =
    useAlertConfigsByNoMeasure();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );

  const initialValue: ItemAlertConfigByNoMeasureIndividualDto =
    props.alertConfigByNoMeasureIndividual;
  const [alertConfig, setAlertConfig] =
    useState<ItemAlertConfigByNoMeasureIndividualDto>(initialValue);
  const [time, setTime] = useState<number>(
    Number(alertConfig.max_time_between.slice(0, -1))
  );
  const [timeUnit, setTimeUnit] = useState<string>(
    alertConfig.max_time_between.slice(-1)
  );

  const [notification, setNotification] = useState<INotification>({
    sms: initNotification.sms,
    email: initNotification.email,
    pushNotification: initNotification.pushNotification,
  });

  const subscribersIncludesLoggedUser =
    props.alertConfigByNoMeasureIndividual.subscribers &&
    props.alertConfigByNoMeasureIndividual.subscribers.filter(
      (subscriber) => subscriber.user && subscriber.user.id === id
    ).length === 1;
  // Check if alert to be edited is a template and we're trying to create a custom from this template
  // or if user is already subscribed
  const subscribeInitialValue =
    (alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id) ||
    subscribersIncludesLoggedUser;
  const [subscribe, setSubscribe] = useState<boolean>(subscribeInitialValue);
  const [devices, setDevices] = useState<Device[]>([]);
  const [signs, setSigns] = useState<Sign[]>([]);
  const [requestedSigns, setRequestedSigns] = useState<any>({});

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleFormSubmit = async (): Promise<void> => {
    const requestedSignsFormatted = [];
    for (const property in requestedSigns) {
      if (requestedSigns[property]) {
        requestedSignsFormatted.push(property);
      }
    }
    const requestedSignsSelected = requestedSignsFormatted.join(", ");
    if (!alertConfig.customAlertConfigTemplate) {
      //es una alertconfig individual sin relación con ninguna alertconfig Template
      const dto: UpdateAlertConfigByNoMeasureIndividualRequestDto =
        new UpdateAlertConfigByNoMeasureIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.requestedSigns = requestedSignsSelected;
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          props.handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id
    ) {
      //Es una alert Config individual emulada de una alertconfig template, creamos una individual usando de base la template
      //TODO: en el caso de ser igual descartar la creación.
      try {
        const dto: AddAlertConfigByNoMeasureIndividualRequestDto =
          new AddAlertConfigByNoMeasureIndividualRequestDto();
        Object.assign(dto, alertConfig);
        dto.patientId = props.patientId;
        dto.customAlertConfigTemplateId =
          alertConfig.customAlertConfigTemplate?.id;
        dto.deviceId = props.alertConfigByNoMeasureIndividual.device?.id;
        dto.subscribe = subscribe;
        dto.requestedSigns = requestedSignsSelected;
        await add(dto);
        props.handleClose();
        setAlertConfig(initialValue);
        return;
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
    if (
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id !== alertConfig.id
    ) {
      //Es una alert Config individual emulada que customiza los valores de la alert config Template
      const dto: UpdateAlertConfigByNoMeasureIndividualRequestDto =
        new UpdateAlertConfigByNoMeasureIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.requestedSigns = requestedSignsSelected;
      dto.subscribe = subscribe;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          props.handleClose();
          setAlertConfig(initialValue);
          props.handleClose();
          setAlertConfig(initialValue);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        return;
      }
    }
  };

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );
          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d?.id === alertConfig?.device?.id
      );
      if (device !== undefined) {
        setSigns(device.signs);
        const signs: any = {};
        setRequestedSigns(signs);
        const requestedSigns = props.item.requestedSigns?.split(", ");
        device.signs.forEach((sign: any) => {
          const isRequested = requestedSigns?.includes(sign.name);
          signs[sign.name] = isRequested;
        });
        setRequestedSigns(signs);
      }
    }
  }, [devices, alertConfig]);

  useEffect(() => {
    handleAlertConfig(`${time}${timeUnit}`, "max_time_between");
  }, [time, timeUnit]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <EditAlertConfigByNoMeasure
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            signs={signs}
            requestedSigns={requestedSigns}
            setRequestedSigns={setRequestedSigns}
          />
          <SubscriptionChecks
            sms={notification.sms}
            setSms={(checked: boolean) => handleNotification(checked, "sms")}
            email={notification.email}
            setEmail={(checked: boolean) =>
              handleNotification(checked, "email")
            }
            pushNotification={notification.pushNotification}
            setPushNotification={(checked: boolean) =>
              handleNotification(checked, "pushNotification")
            }
            subscribe={subscribe}
            setSubscribe={setSubscribe}
            messengerChannel={props.messengerChannel}
          />
          {alertConfig.customAlertConfigTemplate &&
            alertConfig.customAlertConfigTemplate.id === alertConfig.id && (
              <Box style={styles.warningAlertTemplate}>
                {t("WarningAlertConfigTemplateEdit")}
              </Box>
            )}
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("UpdateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
