import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AlertConfigByNoMeasureTemplateGridColumns } from "./AlertConfigByNoMeasureGridColumns";
import { AlertConfigByNoMeasureDataRow } from "../AlertConfigTemplatesDataRow";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useLoading } from "../../../../Loading/Loading";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { AddAlertConfigByNoMeasureModal } from "./ManageAlertConfigByNoMeasure/AddAlertConfigByNoMeasureModal";
import { UpdateAlertConfigByNoMeasureModal } from "./ManageAlertConfigByNoMeasure/UpdateAlertConfigByNoMeasureModal";
import {
  AddAlertConfigByNoMeasureTemplateRequestDto,
  AddAlertConfigByNoMeasureTemplateResponseDto,
  ItemAlertConfigByNoMeasureTemplateDto,
  UpdateAlertConfigByNoMeasureTemplateRequestDto,
  UpdateAlertConfigByNoMeasureTemplateResponseDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByNoMeasureTemplate";
import { GridRowClassNameParams } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../../Theme/ThemeDataGrid";

interface Props {
  alertConfigTemplates: ItemAlertConfigByNoMeasureTemplateDto[] | undefined;
  onEdit: (
    id: number,
    alertConfigTemplate: UpdateAlertConfigByNoMeasureTemplateRequestDto
  ) => Promise<ResponseApi<UpdateAlertConfigByNoMeasureTemplateResponseDto>>;
  addAlertConfig: (
    dto: AddAlertConfigByNoMeasureTemplateRequestDto
  ) => Promise<ResponseApi<AddAlertConfigByNoMeasureTemplateResponseDto>>;
  buttonsFx: (setModalOpen: Dispatch<SetStateAction<boolean>>) => JSX.Element;
  onPageChange: React.Dispatch<React.SetStateAction<number>>;
  onPageSizeChange: React.Dispatch<React.SetStateAction<number>>;
  paginationByNoMeasure: any;
}

export const AlertConfigByNoMeasureTemplateList = ({
  alertConfigTemplates,
  onEdit,
  addAlertConfig,
  buttonsFx,
  onPageChange,
  onPageSizeChange,
  paginationByNoMeasure,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const [addAlertConfigModalOpen, setAddAlertConfigModalOpen] =
    useState<boolean>(false);
  const [alertConfigToEdit, setAlertConfigToEdit] =
    useState<AlertConfigByNoMeasureDataRow | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  if (!alertConfigTemplates) return <></>;

  const alertConfigCreateForm = addAlertConfigModalOpen && (
    <AddAlertConfigByNoMeasureModal
      open={addAlertConfigModalOpen}
      onSave={async (dto: AddAlertConfigByNoMeasureTemplateRequestDto) => {
        setIsLoading(true);
        try {
          await addAlertConfig(dto);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAddAlertConfigModalOpen(false);
        setIsLoading(false);
      }}
      handleClose={() => setAddAlertConfigModalOpen(false)}
      item={addAlertConfigModalOpen}
      currentLanguage={currentLanguage}
    />
  );

  const alertConfigEditForm = alertConfigToEdit && (
    <UpdateAlertConfigByNoMeasureModal
      item={alertConfigToEdit}
      open={Boolean(alertConfigToEdit)}
      handleClose={() => setAlertConfigToEdit(null)}
      onSave={async (dto: UpdateAlertConfigByNoMeasureTemplateRequestDto) => {
        setIsLoading(true);
        try {
          await onEdit(alertConfigToEdit.id, dto);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAlertConfigToEdit(null);
        setIsLoading(false);
      }}
      currentLanguage={currentLanguage}
    />
  );

  const handleOnEdit = (alertConfigTemplate: AlertConfigByNoMeasureDataRow) => {
    setAlertConfigToEdit(alertConfigTemplate);
  };

  const handleOnActivate = (
    alertConfigTemplate: AlertConfigByNoMeasureDataRow
  ) => {
    const dto: UpdateAlertConfigByNoMeasureTemplateRequestDto =
      new UpdateAlertConfigByNoMeasureTemplateRequestDto();
    Object.assign(dto, alertConfigTemplate);
    if (alertConfigTemplate && alertConfigTemplate.id) {
      setIsLoading(true);
      try {
        dto.active = alertConfigTemplate.active ? false : true;
        onEdit(alertConfigTemplate.id, dto);
      } catch (err: any) {
        toast.error(err.message);
      }
      setIsLoading(false);
    }
  };

  const buttons = buttonsFx(setAddAlertConfigModalOpen);
  const alertsGridColumns = AlertConfigByNoMeasureTemplateGridColumns(
    t,
    handleOnEdit,
    handleOnActivate,
    currentLanguage
  );

  const alertsByNoMeasureDataRow: AlertConfigByNoMeasureDataRow[] =
    alertConfigTemplates.map(
      (alertConfig: ItemAlertConfigByNoMeasureTemplateDto) =>
        ({
          id: alertConfig.id,
          device: alertConfig.device,
          label: alertConfig.label,
          color: alertConfig.color,
          max_time_between: alertConfig.max_time_between,
          requestedSigns: alertConfig.requestedSigns,
          active: alertConfig.active,
        } as AlertConfigByNoMeasureDataRow)
    ) ?? [];

  const setRowClassName = (
    params: GridRowClassNameParams<AlertConfigByNoMeasureDataRow>
  ): string => {
    if (!params.row.active) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {buttons}
      <FlexLayoutGrid
        language={i18n.language}
        columns={alertsGridColumns as any}
        rows={alertsByNoMeasureDataRow}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid()}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationByNoMeasure}
      />
      {alertConfigCreateForm}
      {alertConfigEditForm}
    </Box>
  );
};
