import { GetSankeyGraphResponseData } from "../dto/CmsDashboard/CmsDashboard";
import { ResponseApi, TypeOnError } from "../global";
import { DataRange } from "../utils/query/filter.interface";
import { Symbol } from "../utils/query/query.enum";
import { CreateActionWithoutMutate } from "./IUseBaseElements";

export interface IUseSankeyGraph {
  getData: (
    companyId?: number,
    deviceId?: number,
    timeZone?: number,
    takeById?: number,
    patientId?: number,
    dateRange?: DataRange<string>,
    readDeviceRange?: DataRange<number>
  ) => Promise<ResponseApi<GetSankeyGraphResponseData>>;
}

export function useSankeyGraph(
  baseURL: string,
  token: string,
  onError?: TypeOnError
): IUseSankeyGraph {
  const getData = async (
    companyId?: number,
    deviceId?: number,
    timeZone?: number,
    takeById?: number,
    patientId?: number,
    dateRange?: DataRange<string>,
    readDeviceRange?: DataRange<number>
  ) => {
    let path: string = `/cmsdashboard/sankeyGraph`;
    let queries = "";
    let char = Symbol.FIRST;

    if (companyId) {
      queries += `${char}companyId=${companyId}`;
      char = Symbol.AND;
    }
    if (dateRange) {
      //queries += `${char}initDate=${dateRange.start}&finDate=${dateRange.end}`;
      queries += `${char}dateRangeStart=${dateRange.start}&dateRangeEnd=${dateRange.end}`;
      char = Symbol.AND;
    }
    if (deviceId) {
      queries += `${char}deviceId=${deviceId}`;
      char = Symbol.AND;
    }
    if (timeZone) {
      queries += `${char}timeZone=${timeZone}`;
      char = Symbol.AND;
    }
    if (readDeviceRange) {
      queries += `${char}readDeviceRangeStart=${readDeviceRange.start}&readDeviceRangeEnd=${readDeviceRange.end}`;
      char = Symbol.AND;
    }
    if (takeById) {
      queries += `${char}takeById=${takeById}`;
      char = Symbol.AND;
    }
    if (patientId) {
      queries += `${char}patientId=${patientId}`;
      char = Symbol.AND;
    }
    path += queries;

    const action = CreateActionWithoutMutate<
      ResponseApi<GetSankeyGraphResponseData>
    >(baseURL, token, "get", path, onError);
    return action();
  };

  return {
    getData,
  };
}
