import { GridColDef } from "@mui/x-data-grid";
import { AlertConfigByValueDataRow } from "../AlertConfigTemplatesDataRow";
import { sortText } from "../../../../../App/sortFunctions";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Tooltip } from "@mui/material";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { getDeviceName, getSignName } from "../../../utils";

export const AlertConfigByValueTemplateGridColumns = (
  t: (text: string) => string,
  onEdit: (alertConfigTemplate: AlertConfigByValueDataRow) => void,
  onActivate: (alertConfigTemplate: AlertConfigByValueDataRow) => void,
  currentLanguage: string
): GridColDef[] => {
  return [
    {
      field: "device",
      headerName: t("Device"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => t(row.device),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return (
          <p>{t(getDeviceName(currentLanguage, params.row.sign.device, t))}</p>
        );
      },
    },
    {
      field: "sign",
      headerName: t("Sign"),
      width: 100,
      valueGetter: (value: any, row: any) => t(row.sign),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{getSignName(currentLanguage, params.row.sign, t)}</p>;
      },
    },
    {
      field: "color",
      headerName: t("Level"),
      width: 100,
      valueGetter: (value: any, row: any) => row.color,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => (
        <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
      ),
    },
    {
      field: "description",
      headerName: t("Description"),
      width: 200,
      valueGetter: (value: any, row: any) => row.label,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{params.row.label !== "" ? params.row.label : "--"}</p>;
      },
    },
    {
      field: "alert_type",
      headerName: t("AlertType"),
      width: 220,
      valueGetter: (value: any, row: any) =>
        row.alert_type ? t(row.alert_type) : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{params.row.alert_type ? t(params.row.alert_type) : "--"}</p>;
      },
    },
    {
      field: "min",
      headerName: t("FieldMin"),
      width: 100,
      valueGetter: (value: any, row: any) => row.min ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return (
          <p>
            {params.row.sign.device.typeName === "Form" && params.row.min
              ? params.row.valueText
                ? t(params.row.valueText)
                : "--"
              : params.row.min ?? "--"}
          </p>
        );
      },
    },
    {
      field: "max",
      headerName: t("FieldMax"),
      width: 100,
      valueGetter: (value: any, row: any) => row.max ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return (
          <p>
            {params.row.sign.device.typeName === "Form" && params.row.max
              ? params.row.valueText
                ? t(params.row.valueText)
                : "--"
              : params.row.max ?? "--"}
          </p>
        );
      },
    },
    {
      field: "range_value",
      headerName: t("RangeValue"),
      width: 100,
      valueGetter: (value: any, row: any) => row.range_value ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <p>{params.row.range_value ?? "--"}</p>;
      },
    },
    {
      field: "range_time",
      headerName: t("RangeTime"),
      width: 100,
      valueGetter: (value: any, row: any) => row.range_time ?? "--",
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <p>{params.row.range_time ?? "--"}</p>;
      },
    },
    {
      field: "active",
      headerName: t("ActiveColumn"),
      width: 100,
      valueGetter: (value: any, row: any) => row.active,
      renderCell: (params) => {
        return <p>{params.row.active ? t("Active") : t("Deactive")}</p>;
      },
    },
    {
      field: "iconEdit",
      headerName: t("AlertConfigEdit"),
      width: 100,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t("ButtonEdit") || ""}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "iconActivate",
      headerName: t("ActivateDeactivate"),
      width: 150,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.active ? t("Deactivate") : t("Activate")}>
            <IconButton
              aria-label="delete"
              onClick={() => onActivate(params.row)}
            >
              {params.row.active ? (
                <StopCircleIcon />
              ) : (
                <PlayCircleFilledIcon />
              )}
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
};
