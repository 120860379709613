import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { Login } from "../components/Login";
import { useApi } from "@doctomatic/sdk/build/Api";
import { LoggedApp } from "./LoggedApp";
import { ToastContainer, ToastClassName } from "react-toastify";
import { SWRConfig } from "swr";
import useStyles from "./styles";
import { ForgotPassword } from "./ForgotPassword";
import { ConfigError } from "@doctomatic/sdk/build/global";
import * as Sentry from "@sentry/react";
import { processError } from "./errorToast";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "./ErrorPage";
import { Redirect as RedirectUrl } from "../components/Pages/Redirect";

const App = (): React.ReactElement => {
  const { token, logout, setToken, refreshTokens } = useApi();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  const toastClass: ToastClassName = (options) =>
    "Toastify__toast " +
    (options?.type === "error" ? classes.errorToast : classes.infoToast);
  const query = new URLSearchParams(location.search);
  const management = query.get("management") === "true" ? true : false;
  if (query.get("management")) {
    localStorage.setItem("management", management ? "true" : "false");
  }

  useEffect(() => {
    const onError = async () => {
      try {
        const { accessToken, refreshToken } = await refreshTokens(
          localStorage.getItem("refreshToken") || "",
          undefined,
          management
        );
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        return accessToken;
      } catch {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("permissions");
        localStorage.removeItem("management");
        logout();
        navigate("/");
      }
    };
    ConfigError.onError = onError;
    const queryToken = query.get("token");
    if (queryToken) localStorage.setItem("token", queryToken || "");
    if (localStorage.getItem("token"))
      setToken(localStorage.getItem("token") || "");

    const refreshToken = query.get("resfreshToken");
    if (refreshToken) localStorage.setItem("refreshToken", refreshToken || "");

    if (queryToken) {
      if (localStorage.getItem("management") === "true") {
        navigate("/?management=" + management);
      } else {
        navigate(location.pathname);
      }
    }
    setLoaded(true);
  }, [setToken, logout, refreshTokens]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setRedirectPath(currentPath);
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <SWRConfig value={{ onError: processError(logout, navigate, t) }}>
        <main className={classes.root}>
          <ToastContainer autoClose={5000} toastClassName={toastClass} />

          {loaded &&
            (token ? (
              <LoggedApp redirectPath={redirectPath} />
            ) : (
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot" element={<ForgotPassword />} />
                <Route path="/redirect/:id" element={<RedirectUrl />} />
                <Route path="*" element={<Login />} />
              </Routes>
            ))}
        </main>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
};

export { App };
