import { Type } from 'class-transformer'
import { ValidateNested } from 'class-validator';
import { GetCompanyResponseDto } from './Company'

export enum AddressType {
  personal = 'personal',
  invoice = 'invoice',
  doctor = 'doctor'
}

export interface ICreateAddressRequestDto {
  type: AddressType;
  companyName: string;
  address1: string;
  cp: string;
  city: string;
  country: string;
  isDefault: boolean;
  isActive: boolean;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  companyId?: number;
}

export interface IGetAddressSubscriptionResponseDto {
  fiscalName: string;
  address1: string;
  cp: string;
  city: string;
  country: string;
  address2?: string;
}
export interface ICreateAddressSubscriptionRequestDto {
  fiscalName: string;
  address1: string;
  cp: string;
  city: string;
  country: string;
  address2?: string;
}

export interface IUpdateAddressRequestDto {
  type?: AddressType;
  companyName?: string;
  address1?: string;
  cp?: string;
  city?: string;
  country?: string;
  isDefault?: boolean;
  isActive?: boolean;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
}

export interface ICreateAddressResponseDto {
  id: number;
  type: AddressType;
  companyName: string;
  address1: string;
  cp: string;
  city: string;
  country: string;
  isDefault: boolean;
  isActive: boolean;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  company?: GetCompanyResponseDto;
}

export interface IUpdateAddressResponseDto {
  id: number;
  type: AddressType;
  companyName: string;
  address1: string;
  cp: string;
  city: string;
  country: string;
  isDefault: boolean;
  isActive: boolean;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  company?: GetCompanyResponseDto;
}
    

export class CreateAddressRequestDto implements ICreateAddressRequestDto {
  type: AddressType = AddressType.personal;
  companyName: string = '';
  address1: string = '';
  cp: string = '';
  city: string = '';
  country: string = '';
  isDefault: boolean = true;
  isActive: boolean = true;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
  companyId?: number;
}

export class GetAddressSubscriptionResponseDto implements IGetAddressSubscriptionResponseDto {
  fiscalName: string = '';
  cp: string = '';
  city: string = '';
  country: string = '';
  address1: string = '';
  address2?: string;
}

export class CreateAddressSubscriptionRequestDto implements ICreateAddressSubscriptionRequestDto {
  fiscalName: string = '';
  cp: string = '';
  city: string = '';
  country: string = '';
  address1: string = '';
  address2?: string;
}

export class UpdateAddressRequestDto implements IUpdateAddressRequestDto {
  type?: AddressType;
  companyName?: string;
  address1?: string;
  cp?: string;
  city?: string;
  country?: string;
  isDefault?: boolean;
  isActive?: boolean;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;
}

export class CreateAddressResponseDto implements ICreateAddressResponseDto {
  id: number = 0;
  type: AddressType = AddressType.personal;
  companyName: string = '';
  address1: string = '';
  cp: string = '';
  city: string = '';
  country: string = '';
  isDefault: boolean = true;
  isActive: boolean = true;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;

  @ValidateNested()
  @Type(() => GetCompanyResponseDto)
  company?: GetCompanyResponseDto;
}

export class UpdateAddressResponseDto implements IUpdateAddressResponseDto {
  id: number = 0;
  type: AddressType = AddressType.personal;
  companyName: string = '';
  address1: string = '';
  cp: string = '';
  city: string = '';
  country: string = '';
  isDefault: boolean = true;
  isActive: boolean = true;
  address2?: string;
  observations?: string;
  taxId?: string;
  phone?: string;
  email?: string;

  @ValidateNested()
  @Type(() => GetCompanyResponseDto)
  company?: GetCompanyResponseDto;
}