import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Link as NavLink } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { sortText } from "../../../../App/sortFunctions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const ExternalReferencePatientsGridColumns = (
  t: (text: string) => string,
  onClick: (id: number) => void
): GridColDef[] => {
  const gridColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      width: 350,
      align: "left",
      valueGetter: (value: any, row: any) => row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: any }) => {
        const userPath = BreadcrumbNameMap.AdministrationPatients.url;
        return (
          <TextWordsQuantityCell
            item={params.row}
            words={[params.row.name]}
            page={userPath}
            routerLink={NavLink}
          />
        );
      },
    },
    {
      field: "externalReference",
      headerName: t("ExternalReferences"),
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: ({ row: { id } }) => {
        return (
          <>
            <IconButton onClick={() => onClick(id)}>
              <OpenInNewIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return gridColumns;
};
