import { KeyedMutator } from "swr";
import type {
  CreateFrequencyRequestDto,
  GetFrequencyResponseDto,
  UpdateFrequencyResponseDto,
} from "../dto/Frequencies";
import { ResponseApi, TypeOnError } from "../global";
import {
  CreateAction,
  CreateActionWithEndpoint,
  CreateActionWithoutMutate,
  IUseBase,
  useInstance,
} from "./IUseBaseElements";

type mutateFrequencies = ResponseApi<GetFrequencyResponseDto[]>;

export interface IUseFrequencies extends IUseBase<mutateFrequencies> {
  mutate: KeyedMutator<mutateFrequencies>;
  put: (
    patientId: number,
    frequencies: CreateFrequencyRequestDto[]
  ) => Promise<ResponseApi<UpdateFrequencyResponseDto[]>>;
  del: (patientId: number, deviceId: number) => Promise<ResponseApi<any>>;
  displayMessage: (deviceId: number) => Promise<ResponseApi<boolean>>;
}

function useFrequencies(
  baseURL: string,
  token: string,
  patientId: number,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseFrequencies {
  const swrResponse: IUseBase<mutateFrequencies> =
    useInstance<mutateFrequencies>(
      baseURL,
      token,
      shouldFetch ? `/frequencies/${patientId}` : null,
      onError
    );

  const put = async (
    patientId: number,
    frequencies: CreateFrequencyRequestDto[]
  ): Promise<ResponseApi<UpdateFrequencyResponseDto[]>> => {
    let path: string = `/frequencies/${patientId}`;
    const action = CreateActionWithEndpoint<
      ResponseApi<UpdateFrequencyResponseDto[]>,
      CreateFrequencyRequestDto[],
      mutateFrequencies
    >(baseURL, token, swrResponse.mutate, "put", onError);
    return action(path, frequencies);
  };

  const del = async (
    patientId: number,
    deviceId: number
  ): Promise<ResponseApi<any>> => {
    let path: string = `/frequencies/${patientId}/device/${deviceId}`;
    const action = CreateAction<ResponseApi<any>, void, mutateFrequencies>(
      baseURL,
      token,
      swrResponse.mutate,
      "delete",
      path,
      onError
    );
    return action();
  };

  const displayMessage = async (
    deviceId: number
  ): Promise<ResponseApi<boolean>> => {
    let path: string = `/frequencies/message/${deviceId}`;
    const action = CreateActionWithoutMutate<ResponseApi<boolean>, boolean>(
      baseURL,
      token,
      "get",
      path,
      onError
    );
    return action();
  };

  return { ...swrResponse, put, del, displayMessage };
}

export { useFrequencies };
