import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { AddAlertConfigByValueIndividualRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByValueIndividual";
import { AlertType } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { IUseAlertConfigsByValue } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByValue";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { processError } from "../../../../../App/errorToast";
import { ModalBaseProps } from "../../../../Modals/modalBaseProps";
import { alertTypeMap } from "../../utils";
import { styles } from "./styles";
import { SubscriptionChecks } from "../../../../AlertsConfig/SubscriptionChecks";
import { AddAlertConfigByValue } from "../../../../AlertsConfig/AddAlertConfigByValueForm";
import { getDeviceName, getSignName } from "../../../utils";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";
import { useNavigate } from "react-router-dom";

interface TimeUnit {
  text: string;
  value: string;
}

export interface AddAlertConfigByValueModalProps extends ModalBaseProps {
  patientId: number;
  permissions: Array<string>;
  currentLanguage: string;
  messengerChannel: string;
}

export const AddAlertConfigByValueModal = (
  props: AddAlertConfigByValueModalProps
): React.ReactElement => {
  const { t } = useTranslation();
  const { useDevices, useAlertConfigsByValue, logout }: IProvideApi = useApi();
  const navigate = useNavigate();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );
  const { add }: IUseAlertConfigsByValue = useAlertConfigsByValue();

  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: getSignName(props.currentLanguage, sign, t),
                unit: sign.unit,
              } as Sign)
          );

          return {
            id: device.id,
            name: getDeviceName(props.currentLanguage, device, t),
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) setDeviceId(devices[0].id);
  }, [devices]);

  // TODO
  const colors: string[] = ["red", "yellow", "green", "blue", "orange"];
  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];
  const conditions: string[][] = alertTypeMap;

  const checkDevicesWithSigns: boolean =
    devices &&
    devices.length > 0 &&
    devices[0].signs &&
    devices[0].signs.length > 0;

  const initialValue: AddAlertConfigByValueIndividualRequestDto = {
    signId: checkDevicesWithSigns ? devices[0].signs[0].id : 0,
    patientId: props.patientId,
    notification: { sms: false, email: true, pushNotification: true },
    subscribe: false,
    label: undefined,
    color: colors[0],
    min: undefined,
    max: undefined,
    alert_type: AlertType.min,
    range_value: undefined,
    range_time: undefined,
  } as unknown as AddAlertConfigByValueIndividualRequestDto;

  const [alertConfig, setAlertConfig] =
    useState<AddAlertConfigByValueIndividualRequestDto>(initialValue);
  const [deviceId, setDeviceId] = useState<number>(
    devices && devices.length > 0 ? devices[0].id : 0
  );
  const [signs, setSigns] = useState<Sign[]>(
    devices && devices.length > 0 ? devices[0].signs : []
  );
  const [signUnit, setSignUnit] = useState<string>(
    devices && devices.length > 0 ? devices[0].signs[0].unit : ""
  );
  const [condition, setCondition] = useState<string>(conditions[0][0]);
  const [time, setTime] = useState<number>();
  const [timeUnit, setTimeUnit] = useState<string>(timeUnits[0].value);
  const [subscribe, setSubscribe] = useState<boolean>(true);
  const [notification, setNotification] = useState<INotification>({
    sms: false,
    email: true,
    pushNotification: true,
  });

  const isRange: boolean =
    condition === "range_absolute" || condition === "range_relative";
  const isTime: boolean =
    condition === "time_absolute" || condition === "time_relative";

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const handleNotification = (value: boolean, field: string) => {
    setNotification({ ...notification, [field]: value });
  };

  const initialErrors = {
    min: false,
    max: false,
    out_of_bounds: false,
    range_value: false,
    range_time: false,
  };
  const [errors, setErrors] = useState<any>(initialErrors);

  const checkErrors = (
    dto: AddAlertConfigByValueIndividualRequestDto
  ): boolean => {
    let validationErrors = initialErrors;
    if (dto.alert_type === "min") {
      const isForm =
        devices.filter(
          (device) => device.typeName === "Form" && device.id === deviceId
        ).length > 0;
      const minIsEmpty = dto.min === undefined || dto.min === null;
      const minIsNotOneOrZero = dto.min !== 0 && dto.min !== 1;
      validationErrors.min = isForm
        ? minIsEmpty || minIsNotOneOrZero
        : minIsEmpty;
    } else if (dto.alert_type === "max") {
      const isForm =
        devices.filter(
          (device) => device.typeName === "Form" && device.id === deviceId
        ).length > 0;
      const maxIsEmpty = dto.max === undefined || dto.max === null;
      const maxIsNotOneOrZero = dto.max !== 0 && dto.max !== 1;
      validationErrors.max = isForm
        ? maxIsEmpty || maxIsNotOneOrZero
        : maxIsEmpty;
    } else if (AlertType.get_and_lt === dto.alert_type) {
      // check min is defined
      validationErrors.min = dto.min === undefined || dto.min === null;
      // check max is defined
      validationErrors.max = dto.max === undefined || dto.max === null;

      // check min is lower than max
      if (dto.min && dto.max) {
        validationErrors.out_of_bounds = Number(dto.max) < Number(dto.min);
      }
    } else if (
      dto.alert_type === "range_absolute" ||
      dto.alert_type === "range_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined ||
        dto.range_value === null ||
        dto.range_value === 0;
    } else if (
      dto.alert_type === "time_absolute" ||
      dto.alert_type === "time_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined ||
        dto.range_value === null ||
        dto.range_value === 0;
      validationErrors.range_time =
        dto.range_time === undefined || dto.range_time === null;
    }
    setErrors(validationErrors);

    return Object.values(validationErrors).some((value: boolean) => !!value);
  };

  useEffect(() => {
    let fieldNames: any = {
      min: "FieldMin",
      max: "FieldMax",
      out_of_bounds: "FieldGETLT",
      range_value: "FieldRangeValue",
      range_time: "FieldRangeTime",
    };

    const error: string | undefined = Object.keys(errors).find(
      (key: string) => errors[key] === true
    );
    if (error)
      toast.error(t("ErrorFieldValue", { fieldName: t(fieldNames[error]) }));
  }, [errors]);

  useEffect(() => {
    setNotification(alertConfig.notification);
  }, [alertConfig.notification]);

  useEffect(() => {
    handleAlertConfig(notification, "notification");
  }, [notification]);

  const handleFormSubmit = async (): Promise<void> => {
    const dto: AddAlertConfigByValueIndividualRequestDto = Object.assign(
      {} as AddAlertConfigByValueIndividualRequestDto,
      alertConfig
    );

    dto.subscribe = subscribe;
    try {
      if (!checkErrors(dto)) {
        await add(dto);
        props.handleClose();
        setAlertConfig(initialValue);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d.id === deviceId
      );
      if (device !== undefined) {
        setSigns(device.signs);
        if (condition === "min" || condition === "max") {
          handleAlertConfig(alertConfig.min, "min");
          handleAlertConfig(alertConfig.max, "max");
        }
      }
    }
  }, [devices, deviceId, alertConfig.max, alertConfig.min, condition]);

  useEffect(() => {
    if (signs && signs.length > 0) {
      handleAlertConfig(signs[0].id, "signId");
    }
  }, [signs]);

  useEffect(() => {
    if (alertConfig && alertConfig.signId) {
      const sign: Sign | undefined = signs.find(
        (s: Sign) => s.id === alertConfig.signId
      );
      if (sign !== undefined) {
        const signUnit =
          sign.unit === "point"
            ? `1 = ${t("Yes")} / 0 = ${t("No")}`
            : sign.unit;
        setSignUnit(signUnit);
        if (condition === "min" || condition === "max") {
          handleAlertConfig(alertConfig.min, "min");
          handleAlertConfig(alertConfig.max, "max");
        }
      }
    }
  }, [alertConfig.signId, alertConfig, condition, signs]);

  useEffect(() => {
    if (isRange || isTime || condition === "get_and_lt_time") {
      handleAlertConfig(`${time}${timeUnit}`, "range_time");
    }
  }, [time, timeUnit, isRange, isTime, condition]);

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAlertConfig({ ...alertConfig, label: e.target.value });
  };

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <AddAlertConfigByValue
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            devices={devices}
            signs={signs}
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            condition={condition}
            setCondition={setCondition}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            signUnit={signUnit}
            errors={errors}
          />
          <SubscriptionChecks
            sms={alertConfig.notification.sms}
            setSms={(checked: boolean) => handleNotification(checked, "sms")}
            email={alertConfig.notification.email}
            setEmail={(checked: boolean) =>
              handleNotification(checked, "email")
            }
            pushNotification={alertConfig.notification.pushNotification}
            setPushNotification={(checked: boolean) =>
              handleNotification(checked, "pushNotification")
            }
            subscribe={subscribe}
            setSubscribe={setSubscribe}
            messengerChannel={props.messengerChannel}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("CreateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
