import React from "react";
import { Page } from "../Page";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ce from "../../../assets/symbol-ce.svg";
import instruction from "../../../assets/symbol-instruction.svg";
import manufacturer from "../../../assets/symbol-manufacturer.svg";
import md from "../../../assets/symbol-md.svg";
import { WEB_VERSION } from "../../../version";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    backgroundColor: theme.palette.common.white,
    textDecoration: "none",
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: 400,
    padding: 0,
    textAlign: "left",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  symbolContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderRadius: 4,
  },
  symbolText: {
    paddingLeft: 20,
  },
}));

const About = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useCompany, logout, companyId } = useApi();
  const { response: company } = useCompany(
    companyId || 0,
    !!companyId,
    processError(logout, navigate)
  );
  const companyData = companyId ? company?.data : undefined;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let doctomaticURL = process.env.REACT_APP_WEB_URL || "";
  const doctomaticPhone = process.env.REACT_APP_PHONE || "";
  const doctomaticEmail = process.env.REACT_APP_EMAIL || "";
  const termsConditionsUrl = process.env.REACT_APP_TERMS_CONDITIONS_URL || "";

  const language = i18n.language;
  if (language === "es") doctomaticURL += "/es";

  const urlDoctomatic = process.env.REACT_APP_SITE_URL;
  const urlInstructionmanual = [
    { id: "es", url: process.env.REACT_APP_INSTRUCTION_MANUAL_ES || "" },
    { id: "en", url: process.env.REACT_APP_INSTRUCTION_MANUAL_EN || "" },
    { id: "ca", url: process.env.REACT_APP_INSTRUCTION_MANUAL_ES || "" },
    { id: "pt_BR", url: process.env.REACT_APP_INSTRUCTION_MANUAL_PT || "" },
    { id: "it", url: process.env.REACT_APP_INSTRUCTION_MANUAL_EN || "" },
    { id: "fr", url: process.env.REACT_APP_INSTRUCTION_MANUAL_EN || "" },
  ];

  const en = [
    " is a remote patient monitoring medical software which transmits the data provided by non-invasive medical devices via a mobile app to a medical platform whereby medical professionals analyze and evaluate the information of their patients.",
    "Fore more information, please visit ",
    ", call ",
    " or email ",
    "Manufacturer: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Follow the instructions before using the product.",
    "This is a Medical Device that meets the requirements set by EU Regulation 2017/745.",
    "CE Marked.",
    "Current software version of Doctomatic:",
    "This app is not intended to replace the services of a professional. The support of a health professional is necessary to obtain the full benefits related to the intended use of the app.",
    "Follow the ",
    "instructions ",
    "before using the product.",
  ];

  const es = [
    " es un producto sanitario software destinado al seguimiento remoto de pacientes mediante la transmisión de los datos proporcionados por dispositivos de monitorización rutinaria no invasiva a una aplicación web donde el profesional sanitario analiza y evalúa dicha información del paciente.",
    "Para más información visite nuestra web ",
    " o contacte con nosotros en el teléfono ",
    " o a través del email ",
    "Fabricante: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Siga las instrucciones antes de utilizar el producto.",
    "Se trata de un producto sanitario que cumple los requisitos aplicables indicados en el Reglamento UE 2017/745.",
    "Marcado CE.",
    "A continuación, se identifica la versión actual de Doctomatic:",
    "Esta aplicación no pretende reemplazar los servicios de un profesional. Es necesario el apoyo de un profesional de la salud para obtener todos los beneficios relacionados con el uso previsto de la aplicación.",
    "Siga las ",
    "instrucciones ",
    "antes de utilizar el producto.",
  ];

  const pt_BR = [
    " é um software médico de monitoramento remoto de pacientes que transmite os dados fornecidos por dispositivos médicos não invasivos por meio de um aplicativo móvel para uma plataforma médica na qual profissionais médicos analisam e avaliam as informações de seus pacientes.",
    "Para mais informações, visite ",
    ", ligar ",
    " ou email ",
    "Fabricante: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Siga as instruções antes de usar o produto.",
    "Este é um dispositivo médico que atende aos requisitos estabelecidos pelo Regulamento da UE 2017/745.",
    "Marcado CE.",
    "Versão atual do software Doctomatic:",
    "Este aplicativo não se destina a substituir os serviços de um profissional. É necessário o apoio de um profissional de saúde para obter todos os benefícios relacionados ao uso pretendido do aplicativo.",
    "Siga as ",
    "instruções ",
    "antes de usar o produto.",
  ];

  const ca = [
    " és un programari mèdic de seguiment remot de pacients que transmet les dades proporcionades per dispositius mèdics no invasius mitjançant una aplicació mòbil a una plataforma mèdica on els professionals mèdics analitzen i avaluen la informació dels seus pacients",
    "Per obtenir més informació, visiteu ",
    ", anomenada ",
    "o correu electrònic",
    "Fabricant: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelona, España.",
    "Seguiu les instruccions abans d'utilitzar el producte.",
    "Aquest és un dispositiu mèdic que compleix els requisits establerts pel Reglament UE 2017/745.",
    "Marcat CE.",
    "Versió de programari actual de Doctomatic:",
    "Aquesta aplicació no pretén reemplaçar els serveis d'un professional. És necessari el suport d'un professional de la salut per obtenir tots els beneficis relacionats amb l'ús previst de l'aplicació.",
    "Seguiu les ",
    "instruccions ",
    "abans d'utilitzar el producte.",
  ];

  const fr = [
    "est un produit logiciel de santé destiné au suivi à distance des patients en transmettant les données fournies par des dispositifs de suivi de routine non invasifs à une application web où le professionnel de santé analyse et évalue lesdites informations patient.",
    "Pour plus d'informations, visitez notre site web ",
    "ou contactez-nous par téléphone ",
    "ou par mail ",
    "Fabricant : Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcelone, Espagne.",
    "Suivez les instructions avant d'utiliser le produit.",
    "Il s'agit d'un dispositif médical qui répond aux exigences applicables indiquées dans le règlement UE 2017/745.",
    "Marquage CE.",
    "La version actuelle de Doctomatic est identifiée ci-dessous :",
    "Cette application n'est pas destinée à remplacer les services d'un professionnel. Le soutien d'un professionnel de la santé est nécessaire pour obtenir tous les avantages liés à l'utilisation prévue de l'application.",
    "Suivez les ",
    "instructions ",
    "avant d'utiliser le produit.",
  ];

  const it = [
    "è un prodotto sanitario software destinato al monitoraggio remoto dei pazienti trasmettendo i dati forniti dai dispositivi di monitoraggio di routine non invasivi a un'applicazione web in cui il professionista sanitario analizza e valuta tali informazioni sul paziente.",
    "Per maggiori informazioni, visita il nostro sito web ",
    "o contattaci telefonicamente ",
    "o via e-mail ",
    "Produttore: Doctomatic S.L. C/Pallars 242, Ent.2, 08005 Barcellona, Spagna.",
    "Seguire le istruzioni prima di utilizzare il prodotto.",
    "È un dispositivo medico che soddisfa i requisiti applicabili indicati nel Regolamento UE 2017/745.",
    "Marchio CE.",
    "L'attuale versione di Doctomatic è identificata di seguito:",
    "Questa applicazione non intende sostituire i servizi di un professionista. Il supporto di un professionista della salute è necessario per ottenere tutti i benefici legati all'uso previsto dell'applicazione.",
    "Seguire le ",
    "istruzioni ",
    "prima di utilizzare il prodotto."
  ];

  const textObj = [
    { id: "es", lang: es },
    { id: "en", lang: en },
    { id: "ca", lang: ca },
    { id: "pt_BR", lang: pt_BR },
    { id: "fr", lang: fr },
    { id: "it", lang: it },
  ];

  const langObj = textObj.find((e) => e.id === language);

  const custom =
    companyData && companyData.logo && companyData.internDescription
      ? true
      : false;

  return (
    <Page title={t("AboutTitle")}>
      {custom ? (
        <>
          <Box my={2}>
            <Box
              width={"100%"}
              marginBottom={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={companyData?.logo} alt="Logo" width={150} />
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              marginBottom={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <iframe
                src={companyData?.internDescription}
                style={{ border: "none", display: "flex" }}
                width="700"
                height="500"
                title="Description"
              ></iframe>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box my={2}>
            <Typography variant="body1">
              <b>DOCTOMATIC</b>
              {langObj ? langObj.lang[0] : en[0]}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="body1">
              {langObj ? langObj.lang[9] : en[9]}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="body1">
              {langObj ? langObj.lang[1] : en[1]}
              <a className={classes.link} href={doctomaticURL}>
                {doctomaticURL}
              </a>{" "}
              {langObj ? langObj.lang[2] : en[2]}
              <a className={classes.link} href={`tel:${doctomaticPhone}`}>
                {doctomaticPhone}
              </a>{" "}
              {langObj ? langObj.lang[3] : en[3]}
              <a className={classes.link} href={`mailto:${doctomaticEmail}`}>
                {doctomaticEmail}
              </a>
              .
            </Typography>
          </Box>

          {language === "es" && <Box my={2}>
            <Typography variant="body1">
              {t("viewTermsConditions")}: <a className={classes.link} href={doctomaticURL + termsConditionsUrl}>
              {t("termsConditions")}
              </a>.
            </Typography>
          </Box>}

          <Box my={2}>
            <Typography variant="h1">
              {t("RiskTitle")}
            </Typography>
            <Box style={{ marginLeft: 5 }}>
              <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  {t("BeforeUsingTitle")}:
                </Typography>
                <Box style={{ marginTop: 5, marginLeft: 10 }}>
                  <Typography variant="body1">
                    {t("BeforeUsingPoint1")}
                  </Typography>
                  <Typography variant="body1">
                    {t("BeforeUsingPoint2")}
                  </Typography>
                  <Typography variant="body1">
                    {t("BeforeUsingPoint3")}
                  </Typography>
                </Box>
              </Box>

              <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  {t("RisksLimitationsTitle")}
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginLeft: 10 }}>
                <Typography variant="body1">
                  {t("RisksLimitationsPoint1")}
                </Typography>
                <Typography variant="body1">
                  {t("RisksLimitationsPoint2")}
                </Typography>
                <Typography variant="body1">
                  {t("RisksLimitationsPoint3")}
                </Typography>
                <Typography variant="body1">
                  {t("RisksLimitationsPoint4")}
                </Typography>
                <Typography variant="body1">
                  {t("RisksLimitationsPoint5")}
                </Typography>
              </Box>

              <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  {t("CautionsWarningsTitle")}
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginLeft: 10 }}>
                <Typography variant="body1">
                  {t("CautionsWarningsPoint1")}
                </Typography>
                <Typography variant="body1">
                  {t("CautionsWarningsPoint2")}
                </Typography>
                <Typography variant="body1">
                  {t("CautionsWarningsPoint3")}
                </Typography>
              </Box>

              <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  {t("CaseEmergencyTitle")}
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginLeft: 10 }}>
                <Typography variant="body1">
                  {t("CaseEmergencyPoint1")}
                </Typography>
                <Typography variant="body1">
                  {t("CaseEmergencyPoint2")}
                </Typography>
              </Box>

              <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  {t("IncidentReportingTitle")}
                </Typography>
              </Box>
              <Box style={{ marginTop: 5, marginLeft: 10 }}>
                <Typography variant="body1">
                  {t("IncidentReportingPoint1")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              margin: "0 auto",
              marginTop: 20,
            }}
          >
            <div className={classes.symbolContainer}>
              <img
                src={manufacturer}
                style={{ width: 50 }}
                alt="Manufacturer"
              />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[4] : en[4]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={instruction} style={{ width: 50 }} alt="Instructions" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[10] : en[10]}
                <a
                  className={classes.link}
                  href={
                    urlDoctomatic +
                    (urlInstructionmanual?.find((e) => e.id === language)
                      ?.url || "")
                  }
                >
                  {langObj ? langObj.lang[11] : en[11]}
                </a>
                {langObj ? langObj.lang[12] : en[12]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={md} style={{ width: 50 }} alt="Medical Device" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[6] : en[6]}
              </Typography>
            </div>

            <div className={classes.symbolContainer}>
              <img src={ce} style={{ width: 50 }} alt="CE" />
              <Typography className={classes.symbolText} variant="body1">
                {langObj ? langObj.lang[7] : en[7]}
              </Typography>
            </div>

            <Box my={4}>
              <Typography
                variant="body1"
                style={{ textAlign: "center", alignSelf: "center" }}
              >
                {langObj ? langObj.lang[8] : en[8]}
                {" " + WEB_VERSION}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Page>
  );
};

export { About };
