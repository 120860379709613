var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { BasicMeasurement, SignType } from "../Graphs/models";
import { ImageViewModal } from "../ImagesViews/ImageViewModal";
import { ImageEditModal } from "../ImagesViews/ImageEditModal";
import { FlexLayoutGrid } from "../DataGrid/DataGrid";
import { ReadDeviceFormGridColumns, } from "./ReadDeviceFormGridColumns";
export default function ReadDeviceForm(_a) {
    var _this = this;
    var device = _a.device, createdAt = _a.createdAt, measurements = _a.measurements, getUrl = _a.getUrl, t = _a.t, language = _a.language, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteManyMeasurements = _a.onDeleteManyMeasurements, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, currentLanguage = _a.currentLanguage, additionalData = _a.additionalData, setSigns = _a.setSigns;
    var _b = useState(null), imageItem = _b[0], setImageItem = _b[1];
    var _c = useState(false), error = _c[0], setError = _c[1];
    var _d = useState(null), measurementToDelete = _d[0], setMeasurementToDelete = _d[1];
    var _e = useState(false), readDeviceToDelete = _e[0], setReadDeviceToDelete = _e[1];
    var _f = useState([]), measurementsUpdated = _f[0], setMeasurementsUpdated = _f[1];
    var handleError = function () {
        setError(true);
    };
    var openImageModal = function (item) { return __awaiter(_this, void 0, void 0, function () {
        var imageUrlResponse;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(item.sign.typeSign === SignType.image)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUrl(item.readDeviceId, item.id)];
                case 1:
                    imageUrlResponse = _b.sent();
                    setImageItem({
                        imageUrl: (_a = imageUrlResponse === null || imageUrlResponse === void 0 ? void 0 : imageUrlResponse.data) !== null && _a !== void 0 ? _a : "",
                        altText: "",
                        readDeviceId: item.readDeviceId,
                        imageForm: true,
                    });
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleOnDeleteOneMeasurement = function (measurementId) { return __awaiter(_this, void 0, void 0, function () {
        var toUpdate, newList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onDeleteOneMeasurement(measurementId)];
                case 1:
                    _a.sent();
                    toUpdate = measurementsUpdated.findIndex(function (m) { return m.id === measurementId; });
                    newList = __spreadArray([], measurementsUpdated, true);
                    newList.splice(toUpdate, 1);
                    setMeasurementsUpdated(newList);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleOnDeleteManyMeasurements = function (readDeviceId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onDeleteManyMeasurements(readDeviceId)];
                case 1:
                    _a.sent();
                    /**
                     * We reset measurements list since we deleted all the measurements.
                     */
                    setMeasurementsUpdated([]);
                    setImageItem(null);
                    setError(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeMeasurements = function (event, signId) {
        var toUpdate = measurementsUpdated.findIndex(function (m) { return m.signId === signId; });
        var newList = __spreadArray([], measurementsUpdated, true);
        if (toUpdate !== -1) {
            newList[toUpdate].value = +event.target.value;
        }
        else {
            var newMeasurement = new BasicMeasurement(0, +event.target.value, new Date(), signId, imageItem.readDeviceId);
            newList.push(newMeasurement);
        }
        setMeasurementsUpdated(newList);
    };
    var handleOnSave = function (measurements) { return __awaiter(_this, void 0, void 0, function () {
        var updateResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSaveMeasurement(measurements)];
                case 1:
                    updateResponse = _a.sent();
                    if (updateResponse !== undefined) {
                        setMeasurementsUpdated(updateResponse.data);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var showEditImageModal = imageItem &&
        (imageItem.imageForm ||
            (!editMeasurementsPermissions && !deleteMeasurementsPermissions) ? (React.createElement(ImageViewModal, { imageUrl: imageItem.imageUrl, altText: imageItem.altText, open: Boolean(imageItem), handleClose: function () {
                setImageItem(null);
                setError(false);
            }, t: t, error: error, handleError: handleError })) : (React.createElement(ImageEditModal, { imageUrl: imageItem.imageUrl, altText: imageItem.altText, open: Boolean(imageItem), handleClose: function () {
                setImageItem(null);
                setError(false);
            }, t: t, error: error, handleError: handleError, measurements: measurementsUpdated, onSave: handleOnSave, onDeleteMany: handleOnDeleteManyMeasurements, onDeleteOne: handleOnDeleteOneMeasurement, editPermissions: editMeasurementsPermissions, deletePermissions: deleteMeasurementsPermissions, onChange: onChangeMeasurements, device: device, readDeviceId: imageItem.readDeviceId, measurementToDelete: measurementToDelete, setMeasurementToDelete: setMeasurementToDelete, readDeviceToDelete: readDeviceToDelete, setReadDeviceToDelete: setReadDeviceToDelete, currentLanguage: language })));
    var dataGridColumns = ReadDeviceFormGridColumns({
        measurements: measurements !== null && measurements !== void 0 ? measurements : [],
        t: t,
        showImageModal: openImageModal,
        additionalData: additionalData,
        currentLanguage: currentLanguage,
        setSigns: setSigns,
    });
    var dataGridRows = [
        {
            id: 0,
            date: createdAt,
            values: measurements !== null && measurements !== void 0 ? measurements : [],
        },
    ];
    return (React.createElement("div", null,
        React.createElement(Box, null,
            React.createElement(FlexLayoutGrid, { language: language, columns: dataGridColumns, rows: dataGridRows, sx: { height: 400 } }),
            showEditImageModal)));
}
