import React, { useState, ReactElement } from "react";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { IGetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/ITreatments";
import { GridColDef } from "@mui/x-data-grid";
import {
  TreatmentsPatientGridColumns,
  TreatmentsPatientGridColumnsProps,
} from "./TreatmentsPatientGridColumns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { IUseTreatments } from "@doctomatic/sdk/build/modules/Treatments/Treatments";
import { toast } from "react-toastify";
import { processError } from "../../../../App/errorToast";
import { IUsePatientWithTreatments } from "@doctomatic/sdk/build/modules/PatientWithTreatments";
import { Treatments } from "../Treatments";
import TreatmentsDataRow from "../TreatmentsDataRow";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";

type TreatmentsPatientParams = {
  patientId: string;
};

export const TreatmentsPatientByStaff = (): ReactElement => {
  const navigate = useNavigate();
  const { patientId } = useParams<TreatmentsPatientParams>();
  const { t } = useTranslation();
  const {
    useProfile,
    usePatientWithTreatments,
    useTreatments,
    logout,
    timezone,
  }: IProvideApi = useApi();

  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { end, reopen }: IUseTreatments = useTreatments();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTracking, setSelectedTracking] = useState<TreatmentsDataRow>();
  const [showEnded, setShowEnded] = useState<boolean>(false);

  const { response: patientResponse, mutate }: IUsePatientWithTreatments =
    usePatientWithTreatments(
      parseInt(patientId as string),
      showEnded,
      true,
      processError(logout, navigate, t)
    );
  const patient = patientResponse?.data;

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const handleConfirm = (item: TreatmentsDataRow) => {
    setSelectedTracking(item);
    const haveEndDateButIsOpen = item.end && item.end >= new Date();
    if (haveEndDateButIsOpen) {
      setOpen(true);
    } else {
      onEndOrReopen(item);
    }
  };

  const onEndOrReopen = async (item: TreatmentsDataRow) => {
    try {
      if (item.end) {
        await reopen(item.id);
      } else {
        await end(item.id);
      }
      await mutate();
    } catch (err: any) {
      toast.error(
        `${t("ErrorExportTreatmentMeasurements")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const treatmentsGridColumns: GridColDef<TreatmentsDataRow>[] =
    TreatmentsPatientGridColumns({
      patientId: patientId ? +patientId : undefined,
      t,
      timezone,
      popoverConf: {
        onClick,
        onClose,
        anchorEl,
        popoverId,
        onEndOrReopen: handleConfirm,
      },
    } as TreatmentsPatientGridColumnsProps);

  const treatmentsDataRows: TreatmentsDataRow[] =
    patient?.treatments.map(
      (treatment: IGetTreatmentResponseDto) =>
        ({
          id: treatment.id,
          name: treatment.name,
          start: new Date(
            treatment.start_at ? treatment.start_at : treatment.createdAt
          ),
          end: treatment.closed_at ? new Date(treatment.closed_at) : undefined,
          lastMeasurement: treatment.lastMeasurement
            ? new Date(treatment.lastMeasurement)
            : undefined,
          alert: treatment.alert ? new Date(treatment.alert) : undefined,
          patient: patient ?? undefined,
        } as TreatmentsDataRow)
    ) ?? [];

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        { url: "/profile", name: actorName },
        { url: "/patients", name: t("Patients") },
        { url: `/patients/${patientId}`, name: patient?.name },
        { url: "", name: t("TreatmentsTitle") },
      ],
    } as BreadcrumbProps);

  const actorName = profile?.data?.name ? profile!.data!.name : "";

  const addButton = (
    <IconButton
      component={Link}
      to={BreadcrumbNameMap.CreateTrackingPatient.url.replace(
        ":patientId",
        patientId as string
      )}
    >
      <AddCircleIcon fontSize="large" />
    </IconButton>
  );

  const confirmModalContent = (
    <>
      <DialogTitle id="confirm-dialog">
        {t("EndTreatmentConfirmTitle")}
      </DialogTitle>
      <DialogContent>
        {t("EndTreatmentConfirmText", {
          endDate: selectedTracking?.end?.toLocaleDateString("es-ES") || "",
          interpolation: { escapeValue: false },
        })}
      </DialogContent>
    </>
  );

  return (
    <>
      <Treatments
        breadcrumbs={getBreadCrumbProps(actorName)}
        treatmentsDataRows={treatmentsDataRows}
        treatmentsGridColumns={treatmentsGridColumns}
        treatments={patient?.treatments}
        buttons={addButton}
        showEnded={showEnded}
        setShowEnded={setShowEnded}
      />
      <ConfirmModal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        onConfirm={async () => {
          await end(selectedTracking?.id || 0);
          await mutate();
          setOpen(!open);
        }}
        content={confirmModalContent}
        cancelText={t("ButtonNo")}
        confirmText={t("ButtonYes")}
        onCancel={() => {
          setOpen(!open);
        }}
      />
    </>
  );
};
