import { Expose } from "class-transformer";
import { IsNotEmpty } from "class-validator";
import {
  AddAlertConfigIndividualRequestDto,
  AddAlertConfigIndividualResponseDto,
  GetAlertConfigIndividualResponseDto,
  ItemAlertConfigIndividualDto,
  UpdateAlertConfigIndividualRequestDto,
  UpdateAlertConfigIndividualResponseDto,
} from "./AlertConfigIndividual";
import {
  IAddAlertConfigByFrequencyIndividualRequestDto,
  IAddAlertConfigByFrequencyIndividualResponseDto,
  IGetAlertConfigByFrequencyIndividualResponseDto,
  IItemAlertConfigByFrequencyIndividualDto,
  IUpdateAlertConfigByFrequencyIndividualRequestDto,
  IUpdateAlertConfigByFrequencyIndividualResponseDto,
} from "./IAlertConfigByFrequencyIndividual";
import { INotification } from "../../group/IAlertGroup";

export class AddAlertConfigByFrequencyIndividualRequestDto
  extends AddAlertConfigIndividualRequestDto
  implements IAddAlertConfigByFrequencyIndividualRequestDto
{
  notification: INotification = { "email": true, "sms": false, "pushNotification": true };
  timeout: number = 0;
  timeToValidate: number = 0;
  frequencyId: number = 0;
  @Expose() @IsNotEmpty() subscribe: boolean = false;
}

export class AddAlertConfigByFrequencyIndividualResponseDto
  extends AddAlertConfigIndividualResponseDto
  implements IAddAlertConfigByFrequencyIndividualResponseDto
{
  notification: INotification = { "email": true, "sms": false, "pushNotification": true };
  timeout: number = 0;
  timeToValidate: number = 0;
  frequencyId: number = 0;
}

export class UpdateAlertConfigByFrequencyIndividualRequestDto
  extends UpdateAlertConfigIndividualRequestDto
  implements IUpdateAlertConfigByFrequencyIndividualRequestDto {}

export class UpdateAlertConfigByFrequencyIndividualResponseDto
  extends UpdateAlertConfigIndividualResponseDto
  implements IUpdateAlertConfigByFrequencyIndividualResponseDto
{
  notification: INotification = { "email": true, "sms": false, "pushNotification": true };
  timeout: number = 0;
  timeToValidate: number = 0;
  frequencyId: number = 0;
}

export class GetAlertConfigByFrequencyIndividualResponseDto
  extends GetAlertConfigIndividualResponseDto
  implements IGetAlertConfigByFrequencyIndividualResponseDto
{
  timeout: number = 0;
  timeToValidate: number = 0;
  frequencyId: number = 0;
}

export class ItemAlertConfigByFrequencyIndividualDto
  extends ItemAlertConfigIndividualDto
  implements IItemAlertConfigByFrequencyIndividualDto
{
  notification: INotification = { "email": true, "sms": false, "pushNotification": true };
  timeout: number = 0;
  timeToValidate: number = 0;
  frequencyId: number = 0;
}
