import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { GetDeviceResponseDto } from "../../../Devices";
import { BasicItem } from "../../../IItemList";
import {
  AddAlertConfigTemplateRequestDto,
  AddAlertConfigTemplateResponseDto,
  GetAlertConfigTemplateResponseDto,
  ItemAlertConfigTemplateDto,
  UpdateAlertConfigTemplateRequestDto,
  UpdateAlertConfigTemplateResponseDto,
} from "./AlertConfigTemplate";
import {
  IAddAlertConfigByNoMeasureTemplateResponseDto,
  IGetAlertConfigByNoMeasureTemplateResponseDto,
  IUpdateAlertConfigByNoMeasureTemplateRequestDto,
  IUpdateAlertConfigByNoMeasureTemplateResponseDto,
  IAddAlertConfigByNoMeasureTemplateRequestDto,
  IItemAlertConfigByNoMeasureTemplateDto,
} from "./IAlertConfigByNoMeasureTemplate";


/** ADD */
export class AddAlertConfigByNoMeasureTemplateRequestDto
  extends AddAlertConfigTemplateRequestDto
  implements IAddAlertConfigByNoMeasureTemplateRequestDto {
  @Expose() color: string = "";
  @Expose() deviceId: number = 0;
  @Expose() max_time_between: string = "";
  @Expose() @IsOptional() label?: string ;
  @Expose() @IsOptional() requestedSigns?: string;
}

export class AddAlertConfigByNoMeasureTemplateResponseDto
  extends AddAlertConfigTemplateResponseDto
  implements IAddAlertConfigByNoMeasureTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() @IsNotEmpty() max_time_between: string = "";
}

/** UPDATE */
export class UpdateAlertConfigByNoMeasureTemplateRequestDto
  extends UpdateAlertConfigTemplateRequestDto
  implements IUpdateAlertConfigByNoMeasureTemplateRequestDto {
    @Expose() @IsOptional() max_time_between?: string;
    @Expose() @IsOptional() requestedSigns?: string;
}

export class UpdateAlertConfigByNoMeasureTemplateResponseDto
  extends UpdateAlertConfigTemplateResponseDto
  implements IUpdateAlertConfigByNoMeasureTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() max_time_between: string = "";
}

/** GET */
export class GetAlertConfigByNoMeasureTemplateResponseDto
  extends GetAlertConfigTemplateResponseDto
  implements IGetAlertConfigByNoMeasureTemplateResponseDto {
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() max_time_between: string = "";
}

export class ItemAlertConfigByNoMeasureTemplateDto
  extends ItemAlertConfigTemplateDto
  implements IItemAlertConfigByNoMeasureTemplateDto {
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  device: BasicItem = new BasicItem();
  @Expose() max_time_between: string = "";
  @Expose() requestedSigns?: string;
}

