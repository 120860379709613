import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { getMeasurementsInRead } from "../utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface Props {
  params: any;
  currentLanguage: string;
  t: any;
}

export const renderCellMeasurement = ({
  params,
  currentLanguage,
  t,
}: Props): React.ReactElement => {
  const { row } = params;
  const { device, measurementsInRead, aiResponse } = row || {};
  const measurementNotConfirmed: boolean =
    measurementsInRead?.length < 1 &&
    aiResponse?.proposedMeasurements?.length > 0;
  const result =
    row && (measurementsInRead || aiResponse)
      ? getMeasurementsInRead(currentLanguage, row, t)
      : "--";

  const lines = result !== "--" ? result.trim().split("\n") : [];
  const tooltipContent =
    measurementNotConfirmed && result !== "--"
      ? [t("measurementsNotConfirmed"), result].join(`\n`)
      : result;
  const commonTooltipProps = {
    disableInteractive: true,
    componentsProps: {
      tooltip: {
        sx: {
          fontSize: "14px",
          maxWidth: 800,
          whiteSpace: "pre-line",
        },
      },
    },
  };

  if (device?.type_device?.toLowerCase().includes("form")) {
    return (
      <Tooltip
        title={tooltipContent}
        {...commonTooltipProps}
        placement="top-start"
      >
        <span>{result}</span>
      </Tooltip>
    );
  }

  const color = (
    measurementNotConfirmed ? "grey" : "rgb(62, 66, 71)"
  ) as string;

  return (
    <div>
      {lines.length > 0 ? (
        lines.map((line, index) => {
          const [label, value] = line.split(":");
          return (
            <Tooltip
              key={index}
              title={tooltipContent}
              {...commonTooltipProps}
              placement="top-start"
            >
              <span
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textOverflow: "ellipsis",
                  color: color,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {label}:&nbsp;<strong>{value}</strong>
                {index < lines.length - 1 && " "}
                {index === lines.length - 1 &&
                  result !== "--" &&
                  measurementNotConfirmed && (
                    <InfoOutlinedIcon
                      style={{ marginLeft: "8px", verticalAlign: "middle" }}
                    />
                  )}
              </span>
            </Tooltip>
          );
        })
      ) : (
        <span>{result}</span>
      )}
    </div>
  );
};
