import { GridColDef } from "@mui/x-data-grid";
import { sortText } from "../../../../App/sortFunctions";
import ExternalReferenceDataRow from "./ExternalReferenceDataRow";

export type ExternalReferenceFromList = {
  id: number;
  source: string;
  reference: string;
};

export const ExternalReferenceGridColumns = (
  t: (text: string) => string,
  actions: (externalReference: ExternalReferenceFromList) => JSX.Element
): GridColDef<ExternalReferenceDataRow>[] => {
  const gridColumns: GridColDef[] = [
    {
      field: "source",
      headerName: t("Source"),
      width: 250,
      align: "left",
      valueGetter: (value: any, row: ExternalReferenceDataRow) => row.source,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: ({ row }: { row: ExternalReferenceDataRow }) => {
        return <p>{row.source}</p>;
      },
    },
    {
      field: "reference",
      headerName: t("Reference"),
      width: 300,
      align: "left",
      valueGetter: (value: any, row: ExternalReferenceDataRow) => row.reference,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: ({ row }: { row: ExternalReferenceDataRow }) => {
        return <p>{row.reference}</p>;
      },
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params: { row: ExternalReferenceDataRow }) =>
        actions(params.row as ExternalReferenceFromList),
    },
  ];

  return gridColumns;
};
