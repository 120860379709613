import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { GetAdminCompanyResponseDto } from "@doctomatic/sdk/build/dto/Actors/AdminCompany/AdminCompany";
import { GetDoctorResponseDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { GetNurseResponseDto } from "@doctomatic/sdk/build/dto/Actors/Nurse/Nurse";
import { GetPatientResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { GetSuperadminResponseDto } from "@doctomatic/sdk/build/dto/Superadmin";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { IUsePatient } from "@doctomatic/sdk/build/modules/Patient";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { AddCircle } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Page } from "../../Page";
import AlertConfigByValueDataRow from "./AlertConfigByValueDataRow";
import {
  AlertConfigByValueGridColumns,
  IAlertConfigByValueGridColumnsProps,
} from "./AlertConfigByValueGridColumns";
import { AddAlertConfigByValueModal } from "./ManageAlertConfigByValue/AddAlertConfigByValueModal";
import { UpdateAlertConfigByValueModal } from "./ManageAlertConfigByValue/UpdateAlertConfigByValueModal";
import { IUseAlertConfigsByValueByPatient } from "@doctomatic/sdk/build/modules/AlertConfigsByValueByPatient";
import { processError } from "../../../../App/errorToast";
import { IUseAlertConfigsByValueByPatientFromTemplate } from "@doctomatic/sdk/build/modules/AlertConfigsByValueByPatientFromTemplate";
import { checkAlertConfigByValuePathParam } from "../../utils";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import {
  AddAlertConfigByValueIndividualRequestDto,
  ItemAlertConfigByValueIndividualDto,
  UpdateAlertConfigByValueIndividualRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByValueIndividual";
import { IUseAlertConfigsByValue } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByValue";
import {
  ActiveAlertFilter,
  ModeActivefilter,
} from "@doctomatic/components-react/build/ActiveAlertFilter/ActiveAlertFilter";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import TableSkeleton from "../../../Skeleton/TableSkeleton";

type IAlertConfigByValue = {
  patientId: string;
};

export const AlertConfigByValue = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = useParams<IAlertConfigByValue>();
  const { t, i18n } = useTranslation();
  const [mode, setMode] = useState<ModeActivefilter>(ModeActivefilter.all);
  const [modeTemplate, setModeTemplate] = useState<ModeActivefilter>(
    ModeActivefilter.all
  );
  const {
    companyId,
    useCompany,
    useProfile,
    usePatient,
    useAlertConfigsByValueByPatient,
    useAlertConfigsByValueByPatientFromTemplate,
    logout,
    useAlertConfigsByValue,
  }: IProvideApi = useApi();
  const { response: patient }: IUsePatient = usePatient(
    parseInt(patientId as string),
    true,
    processError(logout, navigate, t)
  );
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: company } = useCompany(
    companyId,
    true,
    processError(logout, navigate, t)
  );
  const [open, setOpen] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  // declaration of hooks to handle pagination
  const [pageSizeByValue, setPageSizeByValue] = useState<number>(10);
  const [pageByValue, setPageByValue] = useState<number>(1);
  const [pageTemplateSizeByValue, setPageTemplateSizeByValue] =
    useState<number>(10);
  const [pageTemplateByValue, setPageTemplateByValue] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageByValue,
      limit: pageSizeByValue,
    };
  }, [pageByValue, pageSizeByValue]);

  const paginationValueTemplate = useMemo(() => {
    return {
      page: pageTemplateByValue,
      limit: pageTemplateSizeByValue,
    };
  }, [pageTemplateByValue, pageTemplateSizeByValue]);

  useEffect(() => {
    setPageTemplateByValue(1);
  }, [modeTemplate]);

  useEffect(() => {
    setPageByValue(1);
  }, [mode]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const {
    response: alertConfigsResponse,
    mutate,
  }: IUseAlertConfigsByValueByPatient = useAlertConfigsByValueByPatient(
    parseInt(patientId as string),
    paginationValue,
    mode,
    true,
    processError(logout, navigate, t)
  );
  const {
    response: alertConfigsResponseFromTemplate,
    mutate: mutateTemplate,
  }: IUseAlertConfigsByValueByPatientFromTemplate =
    useAlertConfigsByValueByPatientFromTemplate(
      parseInt(patientId as string),
      paginationValueTemplate,
      "true",
      modeTemplate,
      true,
      processError(logout, navigate, t)
    );
  const { patch, add }: IUseAlertConfigsByValue = useAlertConfigsByValue();
  const alertConfigs = alertConfigsResponse?.data;
  const alertConfigsFromTemplate = alertConfigsResponseFromTemplate?.data;
  const alertConfigsByValuePaginationInfo = alertConfigsResponse?.meta;
  const alertConfigsByValueTemplatePaginationInfo =
    alertConfigsResponseFromTemplate?.meta;

  const handleOpenAdd = () => setOpen(true);
  const handleCloseAdd = async () => {
    await mutate();
    setOpen(false);
  };
  const [openAlertConfigEdit, setOpenAlertConfigEdit] =
    useState<boolean>(false);
  const handleCloseUpdate = async () => {
    await mutate();
    await mutateTemplate();
    setOpenAlertConfigEdit(false);
  };
  const [alertConfigToEdit, setAlertConfigToEdit] = useState<any | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };

  const onEdit = (item: any) => {
    setAlertConfigToEdit(item);
    setOpenAlertConfigEdit(true);
  };

  const onActivate = async (
    alertConfig: ItemAlertConfigByValueIndividualDto
  ) => {
    if (alertConfig && !alertConfig.customAlertConfigTemplate) {
      //is an alert config individual, no template
      const dto: UpdateAlertConfigByValueIndividualRequestDto =
        new UpdateAlertConfigByValueIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.notification = alertConfig.notification;
      dto.active = alertConfig.active ? false : true;
      try {
        if (alertConfig && alertConfig.id) {
          await patch(alertConfig.id, dto);
          await mutate();
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
    if (
      alertConfig &&
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id === alertConfig.id
    ) {
      //add alert config individual emulate of an alert template
      try {
        const dto: AddAlertConfigByValueIndividualRequestDto =
          new AddAlertConfigByValueIndividualRequestDto();
        Object.assign(dto, alertConfig);
        dto.patientId = parseInt(patientId as string);
        dto.customAlertConfigTemplateId =
          alertConfig.customAlertConfigTemplate?.id;
        dto.signId = alertConfig.sign.id;
        dto.active = alertConfig.active ? false : true;
        await add(dto);
        await mutate();
        await mutateTemplate();
        return;
      } catch (error: any) {
        toast.error(error.message);
      }
    }
    if (
      alertConfig &&
      alertConfig.customAlertConfigTemplate &&
      alertConfig.customAlertConfigTemplate.id !== alertConfig.id
    ) {
      //alert config individual emulate of an alert template
      const dto: UpdateAlertConfigByValueIndividualRequestDto =
        new UpdateAlertConfigByValueIndividualRequestDto();
      Object.assign(dto, alertConfig);
      dto.active = alertConfig.active ? false : true;
      try {
        await patch(alertConfig.id, dto);
        await mutate();
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const alertConfigByValueGridColumns: GridColDef<AlertConfigByValueDataRow>[] =
    AlertConfigByValueGridColumns({
      popoverConf: {
        onClick,
        onClose,
        anchorEl,
        popoverId,
        onEdit,
        onActivate,
      },
      mutate,
      currentLanguage,
      messengerChannel: company?.data?.messengerChannel,
    } as IAlertConfigByValueGridColumnsProps);

  const alertConfigByValueDataRows: AlertConfigByValueDataRow[] =
    alertConfigs ?? [];
  const alertConfigByValueDataRowsFromTemplate: AlertConfigByValueDataRow[] =
    alertConfigsFromTemplate ?? [];

  const alertConfigIndividualFiltered = checkAlertConfigByValuePathParam(
    location.search,
    alertConfigByValueDataRows
  );
  const alertConfigTemplatelFiltered = checkAlertConfigByValuePathParam(
    location.search,
    alertConfigByValueDataRowsFromTemplate
  );

  const getBreadCrumbProps = (
    profile:
      | ResponseApi<
          | GetPatientResponseDto
          | GetDoctorResponseDto
          | GetNurseResponseDto
          | GetAdminCompanyResponseDto
          | GetSuperadminResponseDto
        >
      | undefined,
    patient: ResponseApi<GetPatientResponseDto> | undefined
  ): BreadcrumbProps | undefined =>
    patient?.data?.name && profile?.data?.name
      ? ({
          breadcrumbItems: location.search.includes("alertConfigId=")
            ? [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                {
                  url: `/alerts_config/value/${patientId}`,
                  name: t("AlertConfigByValueTitle"),
                },
                {
                  url: "",
                  name:
                    alertConfigIndividualFiltered &&
                    alertConfigIndividualFiltered.length > 0
                      ? alertConfigIndividualFiltered[0].label
                      : "",
                },
              ]
            : [
                { url: "/profile", name: profile.data.name },
                { url: "/patients", name: t("Patients") },
                { url: `/patients/${patientId}`, name: patient.data.name },
                { url: "", name: t("AlertConfigByValueTitle") },
              ],
        } as BreadcrumbProps)
      : undefined;

  const buttons: JSX.Element = (
    <>
      <Box display="flex">
        <IconButton onClick={handleOpenAdd}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
      {open && (
        <AddAlertConfigByValueModal
          patientId={parseInt(patientId as string)}
          open={open}
          handleClose={handleCloseAdd}
          item={undefined}
          // TODO
          permissions={[]}
          currentLanguage={currentLanguage}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </>
  );

  const filters: JSX.Element = (
    <ActiveAlertFilter mode={mode} setMode={setMode} t={t} />
  );

  const buttonTemplate: JSX.Element = (
    <>
      <Box display="flex">
        <IconButton
          onClick={() => navigate(BreadcrumbNameMap.AlertConfigTemplates.url)}
        >
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
    </>
  );

  const filtersTemplate: JSX.Element = (
    <ActiveAlertFilter mode={modeTemplate} setMode={setModeTemplate} t={t} />
  );

  const setRowClassName = (
    params: GridRowClassNameParams<AlertConfigByValueDataRow>
  ): string => {
    if (!params.row.active) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Page title="" breadCrumbProps={getBreadCrumbProps(profile, patient)}>
      <Page
        title={t("CustomAlertconfigByValue")}
        buttons={buttons}
        filters={filters}
      >
        <FlexLayoutGrid
          language={i18n.language}
          columns={alertConfigByValueGridColumns as any}
          rows={alertConfigIndividualFiltered}
          setRowClassName={setRowClassName as any}
          onPageChange={setPageByValue}
          onPageSizeChange={setPageSizeByValue}
          pagination={alertConfigsByValuePaginationInfo}
          sx={DoctomaticStylingRowsGrid() as any}
        />
      </Page>
      <Box pt="2%">
        <Page
          title={t("TemplateAlertconfigByValue")}
          buttons={buttonTemplate}
          filters={filtersTemplate}
        >
          {!profile ||
          !alertConfigsFromTemplate ||
          alertConfigsFromTemplate.length < 0 ? (
            <TableSkeleton numRows={10} widthCard={"100%"} />
          ) : (
            <FlexLayoutGrid
              language={i18n.language}
              columns={alertConfigByValueGridColumns as any}
              rows={alertConfigTemplatelFiltered}
              setRowClassName={setRowClassName as any}
              onPageChange={setPageTemplateByValue}
              onPageSizeChange={setPageTemplateSizeByValue}
              pagination={alertConfigsByValueTemplatePaginationInfo}
              sx={DoctomaticStylingRowsGrid() as any}
            />
          )}
        </Page>
      </Box>

      {openAlertConfigEdit && (
        <UpdateAlertConfigByValueModal
          patientId={parseInt(patientId as string)}
          open={openAlertConfigEdit}
          handleClose={handleCloseUpdate}
          item={alertConfigToEdit}
          permissions={[]}
          alertConfigByValueIndividual={alertConfigToEdit}
          messengerChannel={company?.data?.messengerChannel as string}
        />
      )}
    </Page>
  );
};
