import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { LastReadDevicesList } from "./LastReadDevices/LastReadDevicesList";
import { LastAlertsByValueList } from "./LastAlerts/ByValue/LastAlertsByValueList";
import { LastAlertsByNoMeasureList } from "./LastAlerts/ByNoMeasure/LastAlertsByNoMeasureList";
import { IUseProfile } from "@doctomatic/sdk/build/modules/Profile";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { LastAlertsByFrequencyList } from "./LastAlerts/ByFrequency/LastAlertsByFrequencyList";

export const Dashboard = (): React.ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { useProfile, useCompany, logout, companyId }: IProvideApi = useApi();
  const { response: profile }: IUseProfile = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: companyResponse } = useCompany(companyId);
  const company = companyResponse?.data;
  const [actorRole, setActorRole] = useState<Role | undefined>(undefined);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setActorRole(decoded.role ?? undefined);
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid
        size={{
          xs: 12,
          lg: 6,
        }}
      >
        {profile?.data?.id && (
          <LastAlertsByValueList
            actorRole={actorRole ?? Role.user}
            actorId={profile.data.id}
            showExternalId={company?.showExternalId}
          />
        )}
      </Grid>
      <Grid
        size={{
          xs: 12,
          lg: 6,
        }}
      >
        {profile?.data?.id && (
          <LastAlertsByNoMeasureList
            actorRole={actorRole ?? Role.user}
            actorId={profile.data.id}
            showExternalId={company?.showExternalId}
          />
        )}
      </Grid>
      <Grid
        size={{
          xs: 12,
          lg: 6,
        }}
      >
        {profile?.data?.id && (
          <LastAlertsByFrequencyList
            actorRole={actorRole ?? Role.user}
            actorId={profile.data.id}
            showExternalId={company?.showExternalId}
          />
        )}
      </Grid>
      <Grid
        size={{
          xs: 12,
          lg: 6,
        }}
      >
        {profile?.data?.id && (
          <LastReadDevicesList
            showExternalId={company?.showExternalId}
          />
        )}
      </Grid>
    </Grid>
  );
};
