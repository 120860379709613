import { Expose } from "class-transformer";

export enum EventAction {
  all = "*",
  create = "create",
  update = "update",
  delete = "delete",
  translate = "translate",
  deeptranslate_pending = "deeptranslate_pending",
  deeptranslate_rejected = "deeptranslate_rejected",
  deeptranslate_done = "deeptranslate_done",
  synchronize = "synchronize",
  close = "close",
  reopen = "reopen",
  confirm = "confirm",
  refuse = "refuse",
  shorten_links = "shorten_links",
  send = "send",
  subscribe = "subscribe",
  unsubscribe = "unsubscribe",
  restore = "restore",
  assign = "assign",
  unassign = "unassign",
  activate = "activate",
  assign_subscription = "assign_subscription",
  create_subscription = "create_subscription",
  assign_permission = "assign_permission",
  unassign_permission = "unassign_permission",
  add_device = "add_device",
  remove_device = "remove_device",
}

export enum EventObject {
  all = "*",
  user = "user",
  measurement = "measurement",
  payment = "payment",
  image = "image",
  doctor = "doctor",
  nurse = "nurse",
  admin = "admin",
  disease = "disease",
  tracking = "tracking",
  device = "device",
  alert = "alert",
  externalread = "externalread",
  address = "address",
  ack = "acknowledge",
  alertConfig = "alertConfig",
  alertConfigTemplate = "alertConfigTemplate",
  alertConfigGroup = "alertConfigGroup",
  company = "company",
  externalReference = "externalReference",
  file = "file",
  formDevice = "formDevice",
  frequency = "frequency",
  groupPermission = "groupPermission",
  document = "document",
  location = "location",
  locationUser = "locationUser",
  readForm = "readForm",
  read6MWT = "read6MWT",
  ticket = "ticket",
  trackingType = "trackingType",
}

export enum EventState {
  initial = "INITIAL",
  pending = "PENDING",
  sent = "SENT",
  banned = "BANNED",
  orphan = "ORPHAN",
}

export interface ICreateEventRequestDto {
  endpoint: string;
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number;
  webhookId: number;
  statusCode?: string;
  retry: number;
  lastRetry?: Date;
  state?: EventState;
  created_at: Date;
}

export class CreateEventRequestDto implements ICreateEventRequestDto {
  webhookId: number = 0;
  statusCode?: string;
  retry: number = 0;
  endpoint: string = "";
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number = 0;
  lastRetry?: Date;
  state: EventState = EventState.initial;
  created_at: Date = new Date();
}

export interface ICreateEventResponseDto {
  id: number;
  endpoint: string;
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number;
  webhookId: number;
  statusCode?: string;
  retry: number;
  message: string;
  createdAt: Date;
  state: EventState;
}

export class CreateEventResponseDto implements ICreateEventResponseDto {
  webhookId: number = 0;
  statusCode?: string;
  retry: number = 0;
  id: number = 0;
  endpoint: string = "";
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number = 0;
  message: string = "";
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
  state: EventState = EventState.initial;
}

export interface IGetEventResponseDto {
  id: number;
  endpoint: string;
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number;
  webhookId: number;
  statusCode?: string;
  retry: number;
  createdAt: Date;
  state: EventState;
}

export class GetEventResponseDto implements IGetEventResponseDto {
  webhookId: number = 0;
  statusCode?: string;
  retry: number = 0;
  id: number = 0;
  endpoint: string = "";
  eventAction?: EventAction;
  eventObject?: EventObject;
  userId: number = 0;
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
  state: EventState = EventState.initial;
}

export interface IUpdateEventRequestDto {
  retry?: number;
  statusCode?: string;
  state?: EventState;
}
export class UpdateWebhookRequestDto implements IUpdateEventRequestDto {
  retry?: number;
  statusCode?: string;
  state?: EventState;
}
