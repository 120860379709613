import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Page } from "../../Page";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import {
  Device,
  Measurement,
  Sign,
} from "@doctomatic/components-react/build/Graphs/models";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { GetAlertByNoMeasureResponseDto } from "@doctomatic/sdk/build/dto/Alerts/Alerts";
import { GraphAlert } from "@doctomatic/components-react/build/Graphs/GraphAlert/GraphAlert";
import { processError } from "../../../../App/errorToast";
import { IUseAlertByNoMeasure } from "@doctomatic/sdk/build/modules/AlertByNoMeasure";
import { IUseMeasurementsByAlert } from "@doctomatic/sdk/build/modules/MeasurementsByAlert";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { ResponseApi, ResponseApiList } from "@doctomatic/sdk/build/global";
import {
  GetMeasurementResponseDto,
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto,
} from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { IUseImages } from "@doctomatic/sdk/build/modules/Images";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { PermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/GroupPermission";
import { useLoading } from "../../../Loading/Loading";
import { toast } from "react-toastify";
import { getSignName } from "../../utils";
type AlertsByNoMeasureGraphParams = {
  alertId: string;
  actorId: string;
  actorRole: Role;
};

export const AlertsByNoMeasureGraph = (): React.ReactElement => {
  const navigate = useNavigate();
  const { alertId, actorId, actorRole } =
    useParams<AlertsByNoMeasureGraphParams>();
  const { t, i18n } = useTranslation();
  const {
    useAlertByNoMeasure,
    useMeasurementsByAlert,
    logout,
    useImages,
    useMeasurements,
  }: IProvideApi = useApi();
  const { getUrl }: IUseImages = useImages(
    false,
    processError(logout, navigate, t)
  );
  const { setIsLoading } = useLoading();

  const { response: alertResponse }: IUseAlertByNoMeasure = useAlertByNoMeasure(
    parseInt(alertId as string),
    parseInt(actorId as string),
    actorRole as Role,
    true,
    processError(logout, navigate, t)
  );
  const { updateMany, del, delByReadDevice }: IUseMeasurements =
    useMeasurements(
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      processError(logout, navigate, t)
    );
  const alert = alertResponse?.data;
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const [device, setDevice] = useState<Device>();
  const [measurementsResponse, setMeasurementsResponse] =
    useState<ResponseApiList<GetMeasurementResponseDto>>();
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [editMeasurementsPermissions, setEditMeasurementsPermissions] =
    useState<boolean>(false);
  const [deleteMeasurementsPermissions, setDeleteMeasurementsPermissions] =
    useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const onSaveMeasurement = async (measurements: any[]) => {
    let updateResponse: undefined | ResponseApi<UpdateMeasurementResponseDto[]>;
    setIsLoading(true);
    try {
      const updateMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        const updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurements.push(updateMeasurement);
      });
      updateResponse = await updateMany(updateMeasurements);
      if (updateResponse.success === true) {
        toast.success(t("UpdateMeasurementSuccess"));
      }
    } catch {
      toast.error(`${t("UpdateMeasurementError")}`);
    }
    setIsLoading(false);
    return updateResponse;
  };

  const onDeleteOneMeasurement = async (measurementId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await del(measurementId);
      if (delResponse.success === true) {
        toast.success(t("DeleteMeasurementSuccess"));
      }
    } catch {
      toast.error(`${t("DeleteMeasurementError")}`);
    }
    setIsLoading(false);
  };

  const onDeleteManyMeasurements = async (readDeviceId: number) => {
    setIsLoading(true);
    try {
      const delResponse = await delByReadDevice(readDeviceId);
      if (delResponse.success === true) {
        toast.success(t("DeleteManyMeasurementsSuccess"));
      }
    } catch {
      toast.error(`${t("DeleteManyMeasurementsError")}`);
    }
    setIsLoading(false);
  };

  const { findAll }: IUseMeasurementsByAlert = useMeasurementsByAlert(
    alert?.patient.id ?? 0,
    device?.id ?? 0,
    dateRange[0] === null
      ? undefined
      : moment(dateRange[0]).format("yyyy-MM-DD"),
    dateRange[1] === null
      ? undefined
      : moment(dateRange[1]).format("yyyy-MM-DD"),
    false,
    processError(logout, navigate, t)
  );

  const findAllMeasurements = async (
    alert: GetAlertByNoMeasureResponseDto | undefined,
    device: Device | undefined,
    dateRange: [Date | null, Date | null]
  ) => {
    if (alert?.patient?.id && device?.id) {
      const response: ResponseApiList<GetMeasurementResponseDto> =
        await findAll(
          alert?.patient?.id,
          device?.id,
          dateRange[0] === null
            ? undefined
            : moment(dateRange[0]).format("yyyy-MM-DD"),
          dateRange[1] === null
            ? undefined
            : moment(dateRange[1]).format("yyyy-MM-DD")
        );
      if (response && response.success === true && response.data!) {
        setMeasurementsResponse(response);
      }
    }
  };

  const measurements =
    measurementsResponse?.data.map((m) => {
      const newMeasure: Measurement = {
        id: m.id,
        value: m.value,
        createdAt: m.createdAt,
        signId: m.signId,
        patientId: alert?.patient.id ?? 0,
        readDeviceId: m.readDeviceId,
      };
      return newMeasure;
    }) ?? [];

  useEffect(() => {
    const cache = localStorage.getItem("permissions");
    if (cache) setPermissions(JSON.parse(cache));
  }, []);

  useEffect(() => {
    findAllMeasurements(alert, device, dateRange);
  }, [alert, device]);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (
        permissions.find((p) => {
          return p.key === "update.measurement";
        })
      ) {
        setEditMeasurementsPermissions(true);
      } else {
        setEditMeasurementsPermissions(false);
      }
      if (
        permissions.find((p) => {
          return p.key === "delete.measurement";
        })
      ) {
        setDeleteMeasurementsPermissions(true);
      } else {
        setDeleteMeasurementsPermissions(false);
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (alert) {
      const signs: Sign[] = alert.device.signs.map((s) => {
        const sign: Sign = {
          id: s.id,
          name: getSignName(currentLanguage, s, t),
          unit: s.unit,
          decimals: s.decimals,
          max: s.max,
          min: s.min,
          device: s.device,
          typeSign: s.typeSign,
        };
        return sign;
      });
      const device: Device = {
        id: alert.device.id,
        name: alert.device.name,
        signs: signs,
        typeName: alert.device.typeName,
      };

      const originDate = new Date(alert.created_at);
      const finalDate = new Date(alert.created_at);
      originDate.setDate(originDate.getDate() - 14);
      finalDate.setDate(finalDate.getDate() + 14);

      setDevice(device);
      setDateRange([originDate, finalDate]);
    }
  }, [alert, alert?.device, currentLanguage]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const getBreadCrumbProps = (
    alert: GetAlertByNoMeasureResponseDto | undefined
  ): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        {
          url: `/patients/${alert?.patient.id}`,
          name: alert?.patient.name ?? "",
        },
        { url: "/alerts?tab=1", name: t("AlertsTitle") },
        { url: "", name: alert?.label ?? "" },
      ],
    } as BreadcrumbProps);

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <DateRangePicker
        dateRangePicked={dateRange}
        setDateRange={setDateRange}
        t={t}
      />
    </Box>
  );

  if (!alert || !device) return <></>;

  return (
    <Page
      title=""
      buttons={filters}
      breadCrumbProps={getBreadCrumbProps(alert)}
    >
      <GraphAlert
        measurements={measurements}
        dateRangePicked={dateRange}
        device={device}
        t={t}
        language={i18n.language}
        getUrl={getUrl}
        onSaveMeasurement={onSaveMeasurement}
        editMeasurementsPermissions={editMeasurementsPermissions}
        deleteMeasurementsPermissions={deleteMeasurementsPermissions}
        onDeleteOneMeasurement={onDeleteOneMeasurement}
        onDeleteManyMeasurements={onDeleteManyMeasurements}
      />
    </Page>
  );
};
