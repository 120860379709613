import IconButton from "@mui/material/IconButton";
import { GridColDef } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { GetAlertGroupResponseDto } from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup";
import { AlertConfigBasicDto } from "@doctomatic/sdk/build/dto/Alerts/config/AlertConfig";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";

export const AlertGroupListGridColumns = (
  t: (text: string) => string,
  onEdit: (alertGroup: GetAlertGroupResponseDto) => void,
  onDelete: (alertGroup: GetAlertGroupResponseDto) => void
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("HeaderName"),
      width: 200,
      align: "left",
    },
    {
      field: "alertsconfigByValue",
      headerName: t("AlertConfigByValueTemplates"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        const words: string[] =
          row.alertsConfigByValue?.map((a: any) => a.label) ?? [];
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        if (params.row.alertsConfigByNoMeasure) {
          const alertConfigsByValue = params.row.alertsConfigByValue?.map(
            (a: AlertConfigBasicDto) => a.label
          );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={params.row.alertsConfigByValue?.map(
                (a: AlertConfigBasicDto) => a.label
              )}
              tooltipContent={{
                title: t("AlertConfigByValueTemplates"),
                lines: alertConfigsByValue,
              }}
            ></TextWordsQuantityCell>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: "alertsconfigByNoMeasure",
      headerName: t("AlertConfigByNoMeasureTemplates"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        const words: string[] =
          row.alertsConfigByNoMeasure?.map((a: any) => a.label) ?? [];
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        if (params.row.alertsConfigByNoMeasure) {
          const alertConfigsByNoMeasure =
            params.row.alertsConfigByNoMeasure?.map(
              (a: AlertConfigBasicDto) => a.label
            );
          return (
            <TextWordsQuantityCell
              item={params.row}
              words={alertConfigsByNoMeasure}
              tooltipContent={{
                title: t("AlertConfigByNoMeasureTemplates"),
                lines: alertConfigsByNoMeasure,
              }}
            ></TextWordsQuantityCell>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: "patients",
      headerName: t("Patients"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        const words: string[] = row.patients?.map((p: any) => p.label) ?? [];
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        const patientNames = params.row.patients?.map((a: any) => a.name);
        return (
          <TextWordsQuantityCell
            item={params.row}
            words={patientNames}
            tooltipContent={{ title: t("Patients"), lines: patientNames }}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "subscribers",
      headerName: t("Subscribers"),
      width: 250,
      valueGetter: (value: any, row: any) => {
        const words: string[] = row.subscribers ?? "--";
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        const subscriberNames: string[] =
          params.row.subscribers && params.row.subscribers.length > 0
            ? params.row.subscribers
                .filter((item: { user: any }) => item.user)
                .map((item: { user: { name: string } }) =>
                  item.user ? item.user.name : ""
                )
            : ["--"];
        if (subscriberNames.length === 0) subscriberNames.push("--");
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={subscriberNames}
            page={BreadcrumbNameMap.AlertGroupSubscribers.url.replace(
              ":id",
              params.row.id.toString()
            )}
            tooltipContent={{ title: t("Subscribers"), lines: subscriberNames }}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "iconEdit",
      headerName: t("AlertGroupEdit"),
      width: 100,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t("ButtonEdit") || ""}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "iconDelete",
      headerName: t("Delete"),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t("ButtonDelete") || ""}>
            <IconButton
              aria-label="delete"
              onClick={() => onDelete(params.row)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
};
