import React, { useEffect } from "react";
import { Page } from "../Page";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../Loading/Loading";

const Support = (): React.ReactElement => {
  const { t } = useTranslation();
  const { setIsLoading } = useLoading();
  
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [])
  
  const isTesting = process.env.REACT_APP_FRESHDESK !== 'true';
  const iframeSrc = isTesting
  ? 'https://doctomatictest.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&submitTitle=Enviar&searchArea=no'
  : 'https://doctomatic.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&submitTitle=Enviar&searchArea=no';

  return (
    <Page title={t("HeaderSupport")}>
      <div>
        <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
        <style type="text/css" media="screen, projection">
          @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        </style>
        <iframe
          title="Feedback Form"
          className="freshwidget-embedded-form"
          id="freshwidget-embedded-form"
          src={iframeSrc}
          height="600px"
          width="100%"
          style={{ border: "none" }}
          onLoad={handleIframeLoad}
        />
      </div>
    </Page>
  );
};

export { Support };
