import React, { useEffect, useState } from "react";
import { Page } from "../../Page";
import { Box, IconButton } from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { useLoading } from "../../../Loading/Loading";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { AdminLocationsList } from "./AdminLocationsList";
import { AdminLocationCreateModal } from "./AdminLocationCreateModal";
import { toast } from "react-toastify";
import {
  CreateLocationRequestDto,
  ItemLocation,
  UpdateLocationRequestDto,
} from "@doctomatic/sdk/build/dto/Location/Location";
import { AdminLocationEditModal } from "./AdminLocationEditModal";
import { processError } from "../../../../App/errorToast";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const AdminLocationPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useLocations, logout } = useApi();
  const { setIsLoading } = useLoading();
  const { t } = useTranslation();
  const { response, add, patch, del } = useLocations(
    true,
    processError(logout, navigate, t)
  );
  const [locations, setLocation] = useState<ItemLocation[]>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [locationToEdit, setLocationToEdit] = useState<ItemLocation>();

  useEffect(() => {
    if (response && response.success === true && response?.data) {
      setLocation(response!.data);
      setIsLoading(false);
    } else {
      const isLoading = Boolean(response?.error);
      setIsLoading(isLoading);
    }
  }, [response]);

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.Locations.name) },
      ],
    } as BreadcrumbProps;
  };

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setShowCreateModal(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );

  /* Actions/Functions */
  const createLocation = async (location: CreateLocationRequestDto) => {
    setIsLoading(true);
    try {
      await add(location);
    } catch (err: any) {
      toast.error(
        `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
      );
    }
    setShowCreateModal(false);
    setIsLoading(false);
  };
  const editLocation = async (
    locationId: number,
    updateLocationRequestDto: UpdateLocationRequestDto
  ) => {
    setIsLoading(true);

    try {
      await patch(locationId, updateLocationRequestDto);
    } catch (err: any) {
      toast.error(
        `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
      );
    }
    setIsLoading(false);
  };

  const deleteLocation = async (id: number) => {
    try {
      const response = await del(id);
      if (response.success === false && response.error)
        toast.error(`${t("ErrorDelete")}: ${response.error}`);
    } catch (err: any) {
      toast.error(
        `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
      );
    }
  };
  /* End Actions/Functions */

  const handlerShowEditModal = (locationId: number, location: ItemLocation) => {
    setLocationToEdit(location);
    setShowEditModal(true);
  };

  const locationCreateFormElement = (
    <AdminLocationCreateModal
      locations={locations}
      open={showCreateModal}
      onClose={() => setShowCreateModal(false)}
      onSave={createLocation}
    />
  );

  const locationEditElement = (
    <AdminLocationEditModal
      open={showEditModal}
      locationToEdit={locationToEdit}
      onClose={() => setShowEditModal(false)}
      onEdit={editLocation}
    />
  );

  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps()} buttons={buttons}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <AdminLocationsList
          locations={locations}
          showEditModal={handlerShowEditModal}
          deleteLocation={deleteLocation}
        />
        {locationCreateFormElement}
        {locationEditElement}
      </Box>
    </Page>
  );
};

export { AdminLocationPage };
