// Same as FilterOperator from nestjs-paginate
export enum FilterOperator {
    EQ = "$eq",
    GT = "$gt",
    GTE = "$gte",
    IN = "$in",
    NULL = "$null",
    LT = "$lt",
    LTE = "$lte",
    BTW = "$btw",
    NOT = "$not",
    ILIKE = "$ilike"
}