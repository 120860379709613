import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { IPermissionDto } from "@doctomatic/sdk/build/dto/GroupPermission/IGroupPermission";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { GroupPermissionModalGridColumns } from "./GroupPermissionModalGridColumns";

interface Props {
  open: boolean;
  t: (text: string) => string;
  language: string;
  onClose: () => void;
  permissions: IPermissionDto[];
}

export const GroupPermissionModal = (props: Props): React.ReactElement => {
  const gridColumns = GroupPermissionModalGridColumns(
    props.t("PermissionName"),
    props.t
  );
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{props.t("GroupPermissions")}</DialogTitle>
      <DialogContent>
        <FlexLayoutGrid
          language={props.language}
          columns={gridColumns as any}
          rows={props.permissions}
        />
      </DialogContent>
    </Dialog>
  );
};
