import React from "react";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Page } from "../../Page";
import { FormComponent } from "./FormComponent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { handleSaveForm } from "./utils";

type Params = {
  formId: string;
};

const FormsEditPage = (): React.ReactElement => {
  const { t } = useTranslation();
  const { formId } = useParams<Params>();

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        {
          url: BreadcrumbNameMap.Forms.url,
          name: t(BreadcrumbNameMap.Forms.name),
        },
        { url: "", name: t(BreadcrumbNameMap.FormsEdit.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page title={""} breadCrumbProps={getBreadCrumbProps()}>
      <FormComponent handleSaveForm={handleSaveForm} formId={Number(formId)} />
    </Page>
  );
};

export { FormsEditPage };
