import { Device, Sign } from "@doctomatic/components-react/build/Graphs/models";
import { Box, Button, Modal, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./styles";
import { useTranslation } from "react-i18next";
import { ModalBaseProps } from "../../../../../Modals/modalBaseProps";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { SignDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { processError } from "../../../../../../App/errorToast";
import { toast } from "react-toastify";
import { AlertConfigByValueDataRow } from "../../AlertConfigTemplatesDataRow";
import { UpdateAlertConfigByValueTemplateRequestDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByValueTemplate";
import { EditAlertConfigByValue } from "../../../../../AlertsConfig/EditAlertConfigByValueForm";
import { useNavigate } from "react-router-dom";

interface TimeUnit {
  text: string;
  value: string;
}

interface Props extends ModalBaseProps {
  open: boolean;
  onSave: (
    alertConfig: UpdateAlertConfigByValueTemplateRequestDto
  ) => Promise<void>;
}

export const UpdateAlertConfigByValueModal = (
  props: Props
): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useDevices, logout }: IProvideApi = useApi();
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate)
  );

  const timeUnits: TimeUnit[] = [
    { text: t("hours"), value: "h" },
    { text: t("days"), value: "d" },
  ];

  const initialValue: AlertConfigByValueDataRow = {
    id: props.item.id,
    color: props.item.color,
    min: props.item.min,
    max: props.item.max,
    alert_type: props.item.alert_type,
    range_value: props.item.range_value,
    range_time: props.item.range_time,
    label: props.item.label,
    sign: props.item.sign,
    alertGroups: props.item.alertGroups,
    active: props.item.active,
  };

  const initialTime = initialValue.range_time
    ? Number(initialValue.range_time.replace("h", "")?.replace("d", ""))
    : 0;

  let initialTimeUnit = initialValue.range_time
    ? initialValue.range_time.slice(-1)
    : timeUnits[0].value;

  const unitAvailableValues = timeUnits.map((x) => {
    return x.value;
  });
  if (!unitAvailableValues.includes(initialTimeUnit)) {
    initialTimeUnit = timeUnits[0].value;
  }

  let initialCondition: string = props.item.alert_type;

  const [devices, setDevices] = useState<Device[]>([]);
  const [alertConfig, setAlertConfig] =
    useState<AlertConfigByValueDataRow>(initialValue);
  const [deviceId, setDeviceId] = useState<number>(
    devices && devices.length > 0 ? devices[0].id : 0
  );
  const [signs, setSigns] = useState<Sign[]>(
    devices && devices.length > 0 ? devices[0].signs : []
  );
  const [signUnit, setSignUnit] = useState<string>(
    devices && devices.length > 0 ? devices[0].signs[0].unit : ""
  );
  const [condition, setCondition] = useState<string>(initialCondition);

  const [time, setTime] = useState<number>(initialTime);
  const [timeUnit, setTimeUnit] = useState<string>(initialTimeUnit);

  const isTime: boolean = condition.includes("time");

  const handleAlertConfig = useCallback(
    (value: any, field: string): void => {
      setAlertConfig({ ...alertConfig, [field]: value });
    },
    [alertConfig]
  );

  const initialErrors = {
    min: false,
    max: false,
    range_value: false,
    range_time: false,
  };
  const [errors, setErrors] = useState<any>(initialErrors);

  const checkErrors = (
    dto: UpdateAlertConfigByValueTemplateRequestDto
  ): boolean => {
    let validationErrors = initialErrors;
    if (dto.alert_type === "min") {
      validationErrors.min = dto.min === undefined || dto.min === null;
    } else if (dto.alert_type === "max") {
      validationErrors.max = dto.max === undefined || dto.max === null;
    } else if (
      dto.alert_type === "range_absolute" ||
      dto.alert_type === "range_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined || dto.range_value === null;
    } else if (
      dto.alert_type === "time_absolute" ||
      dto.alert_type === "time_relative"
    ) {
      validationErrors.range_value =
        dto.range_value === undefined || dto.range_value === null;
      validationErrors.range_time =
        dto.range_time === undefined || dto.range_time === null;
    }
    setErrors(validationErrors);

    return Object.values(validationErrors).some((value: boolean) => !!value);
  };

  const handleOnChangeLabel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleAlertConfig(e.target.value, "label");
  };

  const handleFormSubmit = async (): Promise<void> => {
    const dto: UpdateAlertConfigByValueTemplateRequestDto =
      new UpdateAlertConfigByValueTemplateRequestDto();
    Object.assign(dto, alertConfig);
    if (alertConfig.id && !checkErrors(dto)) {
      await props.onSave(dto);
    }
  };

  useEffect(() => {
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          const signs: Sign[] = device.signs?.map(
            (sign: SignDto) =>
              ({
                id: sign.id,
                decimals: sign.decimals,
                max: sign.defaultMax,
                min: sign.defaultMin,
                name: sign.name,
                unit: sign.unit,
              } as Sign)
          );
          return {
            id: device.id,
            name: device.name,
            signs: signs,
          } as Device;
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (devices && devices.length > 0) setDeviceId(devices[0].id);
  }, [devices]);

  useEffect(() => {
    let fieldNames: any = {
      min: "FieldMin",
      max: "FieldMax",
      range_value: "FieldRangeValue",
      range_time: "FieldRangeTime",
    };

    const error: string | undefined = Object.keys(errors).find(
      (key: string) => errors[key] === true
    );
    if (error)
      toast.error(
        t("ErrorRequiredField", { field_name: t(fieldNames[error]) })
      );
  }, [errors]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      const device: Device | undefined = devices.find(
        (d: Device) => d.id === deviceId
      );
      if (device !== undefined) setSigns(device.signs);
    }
  }, [deviceId, devices]);

  useEffect(() => {
    if (signs && signs.length > 0) {
      handleAlertConfig(signs[0].id, "signId");
    }
  }, [signs]);

  useEffect(() => {
    if (alertConfig && alertConfig.sign.id) {
      const sign: Sign | undefined = signs.find(
        (s: Sign) => s.id === alertConfig.sign.id
      );
      if (sign !== undefined) setSignUnit(sign.unit);
    }
  }, [alertConfig.sign.id, alertConfig, signs]);

  useEffect(() => {
    if (isTime) {
      handleAlertConfig(`${time}${timeUnit}`, "range_time");
    } else {
      handleAlertConfig(null, "range_time");
    }
  }, [time, timeUnit, isTime]);

  const handleOnColorChange = (e: SelectChangeEvent) => {
    handleAlertConfig(e.target.value, "color");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <EditAlertConfigByValue
            label={alertConfig.label ?? ""}
            handleOnChangeLabel={handleOnChangeLabel}
            color={alertConfig.color}
            handleOnChangeColor={handleOnColorChange}
            time={time}
            setTime={setTime}
            timeUnit={timeUnit}
            setTimeUnit={setTimeUnit}
            condition={condition}
            setCondition={setCondition}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            signUnit={signUnit}
            errors={errors}
          />
          <Box style={styles.btnSubmit}>
            <Button variant="contained" onClick={handleFormSubmit}>
              {t("UpdateAlert")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
