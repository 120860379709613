import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { useTranslation } from "react-i18next";
import LastReadDevicesDataRow from "./LastReadDevicesDataRow";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link as NavLink } from "react-router-dom";
import { sortText } from "../../../../App/sortFunctions";
import { getDeviceName, toLocaleString } from "../../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, List, ListItemButton, Popover } from "@mui/material";
import Launch from "@mui/icons-material/Launch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { renderCellMeasurement } from "../RenderCellMeasurement";
import { Thumbnail } from "../../../Thumbnail/Thumbnail";
import { useState } from "react";

interface Props {
  showExternalId: boolean | undefined;
  showImageModal: (item: LastReadDevicesDataRow) => void;
  currentLanguage: string;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export const LastReadDevicesGridColumns = ({
  showExternalId,
  showImageModal,
  currentLanguage,
  onClick,
  onClose,
  anchorEl,
  popoverId,
}: Props): GridColDef<LastReadDevicesDataRow>[] => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (onClick) {
      onClick(event, item);
    }
  };

  const gridColumns: GridColDef<LastReadDevicesDataRow>[] = [];
  if (showExternalId) {
    gridColumns.push({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 150,
      align: "left" as GridAlignment,
      valueGetter: (value: any, row: LastReadDevicesDataRow) =>
        row.user.externalId ?? "--",
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        return <div>{params.row.user.externalId ?? "--"}</div>;
      },
    });
  }

  gridColumns.push(
    {
      field: "user.name",
      headerName: t("PatientName"),
      width: 250,
      align: "left" as GridAlignment,
      valueGetter: (value: any, row: LastReadDevicesDataRow) => row.user.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        const userPath = BreadcrumbNameMap.Patient.url.replace(
          ":patientId",
          params.row.user.id.toString()
        );
        return (
          <TextWordsQuantityCell
            item={params.row}
            words={[params.row.user.name]}
            page={userPath}
            routerLink={NavLink}
          />
        );
      },
    },
    {
      field: "device.name",
      headerName: t("Device"),
      width: 150,
      valueGetter: (value: any, row: LastReadDevicesDataRow) =>
        row && row.device
          ? getDeviceName(currentLanguage, row.device, t)
          : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        return (
          <div>
            {params.row && params.row.device
              ? getDeviceName(currentLanguage, params.row.device, t)
              : "--"}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: t("Readings"),
      width: 120,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        const readDevicePath = BreadcrumbNameMap.ReadDevice.url.replace(
          ":readDeviceId",
          params.row.id.toString()
        );
        return (
          <NavLink to={readDevicePath}>
            <Launch />
          </NavLink>
        );
      },
    },
    {
      field: "measurements",
      headerName: t("Measurements"),
      width: 150,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        return renderCellMeasurement({ params, currentLanguage, t });
      },
    },
    {
      field: "image",
      headerName: t("Image"),
      width: 100,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) =>
        /**
         * If measurement is defined and it's not a Form
         */
        params.row.device && params.row.device.typeName !== "Form" ? (
          <>
            <div
              key={params.row.id}
              style={{
                position: "relative",
                display: "inline-block",
                width: "fit-content",
                margin: "10px",
              }}
              onMouseEnter={() => setHoveredIndex(params.row.id)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Thumbnail
                image={params.row.thumbnail}
                imageError={params.row.photo}
                hovered={hoveredIndex === params.row.id}
              />

              {hoveredIndex === params.row.id && (
                <IconButton
                  onClick={() => showImageModal(params.row)}
                  sx={{
                    position: "absolute",
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    zIndex: 15,
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              )}
            </div>
          </>
        ) : (
          "--"
        ),
    },
    {
      field: "created_at",
      headerName: t("CreatedAt"),
      width: 150,
      valueGetter: (value: any, row: LastReadDevicesDataRow) =>
        row.measurementsInRead?.createdAt,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        const content: JSX.Element = (
          <div>
            {params.row.created_at
              ? toLocaleString(new Date(params.row.created_at))
              : "--"}
          </div>
        );
        return content;
      },
    },
    {
      field: "go_to",
      headerName: t("GoTo"),
      filterable: false,
      sortable: false,
      renderCell: (params: { row: LastReadDevicesDataRow }) => {
        const alertPath = BreadcrumbNameMap.Alerts.url.replace(
          ":patientId",
          params.row.user.id.toString()
        );
        const alertConfigByValuePath =
          BreadcrumbNameMap.AlertConfigByValue.url.replace(
            ":patientId",
            params.row.user.id.toString()
          );
        const alertConfigByNoMeasurePath =
          BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
            ":patientId",
            params.row.user.id.toString()
          );
        const trackingsPath = BreadcrumbNameMap.TrackingPatient.url.replace(
          ":patientId",
          params.row.user.id.toString()
        );
        const readDeviceActions = (
          <Popover
            id={`m${params.row.id}`}
            open={popoverId === `m${params.row.id}`}
            anchorEl={anchorEl}
            onClose={onClose}
          >
            <List>
              <ListItemButton component={NavLink} to={alertPath}>
                {t("PatientListAlerts")}
              </ListItemButton>
              <ListItemButton component={NavLink} to={alertConfigByValuePath}>
                {t("PatientListConfigAlertsByValue")}
              </ListItemButton>
              <ListItemButton
                component={NavLink}
                to={alertConfigByNoMeasurePath}
              >
                {t("PatientListConfigAlertsByNoMeasure")}
              </ListItemButton>
              <ListItemButton component={NavLink} to={trackingsPath}>
                {t("PatientListTreatments")}
              </ListItemButton>
            </List>
          </Popover>
        );
        return (
          <>
            <IconButton onClick={(event) => openPopover(event, params.row)}>
              <MoreVertIcon />
            </IconButton>
            {readDeviceActions}
          </>
        );
      },
    }
  );

  return gridColumns;
};
