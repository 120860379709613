import { Expose } from "class-transformer";
import { IsOptional } from "class-validator";

import {
  ICreateReadFormRequestDto,
  ICreateReadFormResponseDto,
  IGetReadFormResponseDto,
  IGetReadFormRequestDto,
} from "./IReadFormDto";
import { FormResponse } from "./types/ReadFormTypes";

export class GetReadFormRequestDto implements IGetReadFormRequestDto {
  id: number = 0;
}
export class GetReadFormResponseDto implements IGetReadFormResponseDto {
  formResponse: FormResponse = new FormResponse();
  constructor(formResponse: FormResponse) {
    this.formResponse = formResponse;
  }
}
export class CreateReadFormRequestDto implements ICreateReadFormRequestDto {
  @IsOptional()
  formResponse: FormResponse = new FormResponse();

  @IsOptional()
  description?: string | undefined;
  constructor(formResponse: FormResponse, description?: string | undefined) {
    this.formResponse = formResponse;
    if (description) this.description = description;
  }
}
export class CreateReadFormResponseDto implements ICreateReadFormResponseDto {
  @Expose()
  id: number = 0;
}
