import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useApi } from "@doctomatic/sdk/build/Api";
import { AddressType } from "@doctomatic/sdk/build/dto/Addresses";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { CountryDropdown } from "react-country-region-selector";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { processError } from "../../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    background: theme.palette.common.white,
    margin: "8px 0",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    height: "100%",
    padding: "10px 14px",
    "& .StripeElement": {
      width: "100%",
    },
  },
  input: {
    border: 0,
    outline: 0,
    padding: 0,
    width: "100%",
    fontSize: "16px",
    "&::placeholder": {
      color: theme.palette.grey[600],
      fontSize: "16px",
    },
  },
  expiry: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    padding: "10px 14px",
  },
  cvc: {
    padding: "10px 14px",
  },
  cp: {},
  select: {
    color: theme.palette.grey[600],
    margin: "8px 0 0 0",
    fontSize: "16px",
    "-webkit-appearance": "none",
  },
  error: {
    bottom: 0,
    margin: 0,
    fontSize: "12px",
    color: theme.palette.error.main,
  },
}));

const CreditCardForm = (): React.ReactElement => {
  const navigate = useNavigate();
  const { usePayments, useProfile, useAddresses, useUserSubscription, logout } =
    useApi();
  const { t } = useTranslation();
  const classes = useStyles();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { subscribe, validate, assignSubscription } = usePayments();
  const stripe = useStripe();
  const elements = useElements();
  const { response: userSubscription } = useUserSubscription(
    true,
    processError(logout, navigate, t)
  );
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({
    number: true,
    expiry: true,
    cvc: true,
    country: false,
    fiscalName: false,
    line1: false,
    line2: false,
    city: false,
    cp: false,
    trialCode: false,
    cardName: true,
  });
  const [address, setAddress] = useState({
    fiscalName: "",
    city: "",
    country: "ES",
    line1: "",
    line2: "",
    postal_code: "",
  });
  const [dni, setDni] = useState("");
  const [trialCode, setTrialCode] = useState("");
  const cardOptions = {
    style: { base: { backgroundColor: "white", fontSize: "16px" } },
  };
  const [codeLength, setCodeLength] = useState(1);
  const [otherBillingAddess, setOtherBillingAddess] = useState(false);
  const [nextPaymentDate] = useState(
    moment().add(1, "months").format("DD/MM/YYYY")
  );
  const { response: addressSubscriptionResponse } = useAddresses(
    AddressType.personal,
    true,
    true,
    true,
    processError(logout, navigate, t)
  );
  const [cardName, setCardName] = useState("");
  const profile = profileResponse?.data;
  const addressSubscription = addressSubscriptionResponse?.data;
  const subscriptionPrice = userSubscription?.data?.price;

  useEffect(
    () => setDisabled(Object.values(errors).reduce((acc, curr) => acc || curr)),
    [errors]
  );
  if (!profile) return <></>;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    let success = await saveCard(elements, stripe);
    setLoading(false);
    if (success) window.location.reload();
  };

  const saveCard = async (
    elements: StripeElements,
    stripe: Stripe
  ): Promise<boolean> => {
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) return false;

    const userAddress = {
      city: otherBillingAddess ? address.city : addressSubscription?.city,
      country: otherBillingAddess
        ? address.country
        : addressSubscription?.country,
      line1: otherBillingAddess ? address.line1 : addressSubscription?.address1,
      line2: otherBillingAddess ? address.line2 : addressSubscription?.address2,
      postal_code: otherBillingAddess
        ? address.postal_code
        : addressSubscription?.cp,
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: userAddress,
        email: profile.email,
        name: cardName,
        phone: profile.phone,
      },
    });

    if (error) {
      toast.error(error.message);
      return false;
    }

    if (!paymentMethod) {
      toast.error(t("PaymentMethodError"));
      return false;
    }

    const billingAddress = otherBillingAddess
      ? {
          city: address.city,
          country: address.country,
          cp: address.postal_code,
          fiscalName: address.fiscalName,
          address1: address.line1,
          address2: address.line2,
        }
      : undefined;

    try {
      const {
        data: { clientSecret, status, id: subscriptionId },
      } = await subscribe(paymentMethod.id, dni, trialCode, billingAddress);
      if (status === "requires_action") {
        try {
          const response = await stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
          });
          // Return false if payment authorization declined
          if (response.error) return false;
        } catch (error) {}

        // Assign subscription to patient if authorization successful
        await assignSubscription(subscriptionId);
      }
      return true;
    } catch (err: any) {
      toast.error(err);
      return false;
    }
  };

  const updateTrialCodeLength = async (trialCode: string) => {
    const { data } = await validate(trialCode);
    setCodeLength(data ? data.length : codeLength);
    setErrors({ ...errors, trialCode: true });
  };

  const checkTrialCode = async (trialCode: string): Promise<void> => {
    const { data } = await validate(trialCode);
    setErrors({
      ...errors,
      trialCode: codeLength === 0 ? false : !data?.valid,
    });
  };

  const updateOtherBillingAddess = () => {
    setOtherBillingAddess(!otherBillingAddess);
    if (otherBillingAddess) {
      setAddress({
        fiscalName: "",
        city: "",
        country: "ES",
        line1: "",
        line2: "",
        postal_code: "",
      });
      setErrors({ ...errors, city: false, line1: false, cp: false });
      setDni("");
    } else {
      setErrors({ ...errors, city: true, line1: true, cp: true });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Typography>{t("PamyentCardDataTitle")}</Typography>
      <Box className={classes.field}>
        <input
          type="text"
          required
          id="cardName"
          placeholder={t("FieldCardName")}
          name="cardName"
          className={classes.input}
          value={cardName}
          onChange={(event) => {
            setCardName(event.target.value);
            setErrors({ ...errors, cardName: event.target.value === "" });
          }}
        />
      </Box>

      <Box className={classes.field}>
        <CardNumberElement
          options={cardOptions}
          onChange={(event) => {
            setErrors({ ...errors, number: !event.complete });
          }}
        />
      </Box>

      <Box display="flex" className={classes.field} style={{ padding: 0 }}>
        <Box width="60%" className={classes.expiry}>
          <CardExpiryElement
            options={cardOptions}
            onChange={(event) => {
              setErrors({ ...errors, expiry: !event.complete });
            }}
          />
        </Box>
        <Box width="40%" className={classes.cvc}>
          <CardCvcElement
            options={cardOptions}
            onChange={(event) => {
              setErrors({ ...errors, cvc: !event.complete });
            }}
          />
        </Box>
      </Box>
      {otherBillingAddess && (
        <Box>
          <Typography style={{ marginTop: 24 }}>
            {t("PaymentBillingAddress")}
          </Typography>

          <Box className={classes.field}>
            <input
              type="text"
              required
              id="fiscalName"
              placeholder={t("FieldFiscalName")}
              name="fiscalName"
              className={classes.input}
              value={address.fiscalName}
              onChange={(event) => {
                setAddress({ ...address, fiscalName: event.target.value });
                setErrors({ ...errors, fiscalName: event.target.value === "" });
              }}
            />
          </Box>

          <CountryDropdown
            className={`${classes.field} ${classes.select}`}
            valueType="short"
            value={address.country}
            onChange={(val) => {
              setAddress({ ...address, country: val });
              setErrors({ ...errors, country: val === "" });
            }}
          />

          {/* <RegionDropdown
          classes={`${classes.field} ${classes.select}`}
          countryValueType='short'
          defaultOptionLabel={t('FieldState')}
          country={address.country}
          value={address.state}
          onChange={(val) => {
            setAddress({ ...address, state: val })
            setErrors({ ...errors, state: val === '' })
          }}
        /> */}

          <Box className={classes.field}>
            <input
              type="text"
              required
              id="line1"
              placeholder={t("FieldAddress")}
              name="line1"
              className={classes.input}
              value={address.line1}
              onChange={(event) => {
                setAddress({ ...address, line1: event.target.value });
                setErrors({ ...errors, line1: event.target.value === "" });
              }}
            />
          </Box>

          <Box className={classes.field}>
            <input
              type="text"
              id="line2"
              placeholder={t("FieldAddress2")}
              name="line2"
              className={classes.input}
              value={address.line2}
              onChange={(event) => {
                setAddress({ ...address, line2: event.target.value });
                setErrors({ ...errors, line2: event.target.value === "" });
              }}
            />
          </Box>

          <Box className={classes.field} style={{ padding: 0 }}>
            <Box width="60%" className={classes.expiry}>
              <input
                type="text"
                required
                id="city"
                placeholder={t("FieldCity")}
                name="city"
                className={classes.input}
                value={address.city}
                onChange={(event) => {
                  setAddress({ ...address, city: event.target.value });
                  setErrors({ ...errors, city: event.target.value === "" });
                }}
              />
            </Box>
            <Box width="40%" className={classes.cvc}>
              <input
                type="text"
                required
                id="cp"
                placeholder={t("FieldPostalCode")}
                name="cp"
                className={classes.input}
                value={address.postal_code}
                onChange={(event) => {
                  setAddress({ ...address, postal_code: event.target.value });
                  setErrors({ ...errors, cp: event.target.value === "" });
                }}
              />
            </Box>
          </Box>

          <Box className={classes.field}>
            <input
              type="text"
              id="dni"
              placeholder={t("FieldDni")}
              name="dni"
              className={classes.input}
              value={dni}
              onChange={(event) => {
                setDni(event.target.value);
              }}
            />
          </Box>
        </Box>
      )}
      <Typography style={{ marginTop: 24 }}>
        {t("PaymentTrialCodeTitle")}
      </Typography>

      <Box
        className={classes.field}
        style={{ position: "relative", padding: "16px 8px" }}
      >
        <input
          type="text"
          id="trialCode"
          placeholder={t("PaymentTrialCodePlaceholder")}
          name="trialCode"
          className={classes.input}
          value={trialCode}
          autoComplete={"off"}
          onChange={(event) => {
            setTrialCode(event.target.value);
            if (event.target.value.length === 1)
              updateTrialCodeLength(event.target.value);
            else if (event.target.value.length === codeLength)
              checkTrialCode(event.target.value);
            else
              setErrors({
                ...errors,
                trialCode: event.target.value.length !== 0,
              });
          }}
        />
      </Box>
      <Box
        my={1}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox
          name={"checkbox"}
          checked={otherBillingAddess}
          onChange={() => updateOtherBillingAddess()}
        />
        <Typography>
          {t("PaymentCardOtherBillingAddressCheckboxText")}
        </Typography>
      </Box>

      <Typography>
        {/* {trialCode && !errors.trialCode
          ? t('PaymentCardBillingWithTrialMessage', { doctor_email: profile.doctorEmail, price: subscriptionPrice, day: nextPaymentDate.split('/')[0], month: nextPaymentDate.split('/')[1], year: nextPaymentDate.split('/')[2] })
          : t('PaymentCardBillingWithOutTrialMessage', { price: subscriptionPrice, doctor_email: profile.doctorEmail })} */}
        {trialCode && !errors.trialCode
          ? t("PaymentCardBillingWithTrialMessage", {
              price: subscriptionPrice,
              day: nextPaymentDate.split("/")[0],
              month: nextPaymentDate.split("/")[1],
              year: nextPaymentDate.split("/")[2],
            })
          : t("PaymentCardBillingWithOutTrialMessage", {
              price: subscriptionPrice,
            })}
      </Typography>

      {errors.trialCode && (
        <Typography className={classes.error}>
          {t("PaymentTrialCodeError")}
        </Typography>
      )}

      <Box my={3} display="flex" width={"100%"}>
        <Button
          style={{ width: "100%" }}
          id="button"
          type="submit"
          variant="contained"
          disabled={!stripe || disabled}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : trialCode && !errors.trialCode ? (
            t("ConfirmAndStartTrialButton")
          ) : (
            t("ConfirmAndPayButton")
          )}
        </Button>
      </Box>
    </form>
  );
};

export { CreditCardForm };
