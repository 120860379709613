import React, { useEffect, useMemo, useState } from "react";
import { Page } from "../Page";
import { useLoading } from "../../Loading/Loading";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { TabPanel } from "@doctomatic/components-react/build/Graphs/TabPanel";
import { useApi } from "@doctomatic/sdk/build/Api";
import { IUsePatient } from "@doctomatic/sdk/build/modules/Patient";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { AlertsByValueList } from "./AlertsByValueList/AlertsByValueList";
import { AlertsByNoMeasureList } from "./AlertsByNoMeasureList/AlertsByNoMeasureList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import { processError } from "../../../App/errorToast";
import { jwtDecode } from "jwt-decode";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { AlertState } from "@doctomatic/sdk/build/dto/Alerts/IAlerts";
import { IUseImages } from "@doctomatic/sdk/build/modules/Images";
import { IUseMeasurements } from "@doctomatic/sdk/build/modules/Measurements";
import { AlertsByFrequencyList } from "./AlertsByFrequencyList/AlertsByFrequencyList";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const a11yProps = (index: number): { id: string; "aria-controls": string } => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

type AlertsByPatientParams = {
  patientId: string;
};

const AlertsByPatient = (): React.ReactElement => {
  const navigate = useNavigate();
  const { patientId } = useParams<AlertsByPatientParams>();
  const {
    useAlertsByValueByPatient,
    useAlertsByNoMeasureByPatient,
    useAlertsByFrequencyByPatient,
    useProfile,
    useAlerts,
    usePatient,
    logout,
    id,
    role,
    timezone,
    useImages,
    useMeasurements,
    useDevices,
  } = useApi();
  const location = useLocation();
  const { setIsLoading } = useLoading();
  const { setAck, updateAlertState, updateAlertComments } = useAlerts();
  const { t } = useTranslation();
  const { response: profile } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const { response: patient }: IUsePatient = usePatient(
    parseInt(patientId as string),
    true,
    processError(logout, navigate, t)
  );
  const { getUrl }: IUseImages = useImages(
    false,
    processError(logout, navigate, t)
  );
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [alertsLength, setAlertsLength] = useState(0);
  const [actorRole, setActorRole] = useState<Role | undefined>(undefined);

  // declaration of hooks to handle pagination
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  // Store the pagination
  // Update pagination when page or pageSize changes
  const pagination = useMemo(() => {
    return {
      page: page,
      limit: pageSize,
    };
  }, [page, pageSize]);

  const { response: alertsByValueByPatientResponse, mutate: mutateByValue } =
    useAlertsByValueByPatient(
      id,
      role,
      parseInt(patientId as string),
      pagination,
      tab === 0,
      processError(logout, navigate, t)
    );
  const {
    response: alertsByNoMeasureByPatientResponse,
    mutate: mutateByNoMeasure,
  } = useAlertsByNoMeasureByPatient(
    id,
    role,
    parseInt(patientId as string),
    pagination,
    tab === 1,
    processError(logout, navigate, t)
  );

  const {
    response: alertsByFrequencyByPatientResponse,
    mutate: mutateByFrequency,
  } = useAlertsByFrequencyByPatient(
    id,
    role,
    parseInt(patientId as string),
    pagination,
    tab === 2,
    processError(logout, navigate, t)
  );

  const {
    updateMany,
    del,
    delByReadDevice,
    findAllByReadDevice,
  }: IUseMeasurements = useMeasurements(
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    processError(logout, navigate, t)
  );
  const { getDevice } = useDevices(false, processError(logout, navigate, t));
  const alertsByValue = alertsByValueByPatientResponse?.data;
  const alertsByNoMeasure = alertsByNoMeasureByPatientResponse?.data;
  const alertsByFrequency = alertsByFrequencyByPatientResponse?.data;
  const alertsByValuePaginationInfo = alertsByValueByPatientResponse?.meta;
  const alertsByNoMeasurePaginationInfo =
    alertsByNoMeasureByPatientResponse?.meta;
  const alertsByFrequencyPaginationInfo =
    alertsByFrequencyByPatientResponse?.meta;

  const setAlertComments = (id: number, comments: string | undefined) => {
    const setAlertCommentsAsync = async (
      id: number,
      comments: string | undefined
    ) => {
      setIsLoading(true);
      const response = await updateAlertComments(id, { comments });
      setIsLoading(false);
      return response.success;
    };
    return setAlertCommentsAsync(id, comments);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setActorRole(decoded.role ?? undefined);
    }
  }, []);

  useEffect(() => {
    if (tab === 0 && alertsByValue) setAlertsLength(alertsByValue.length);
    else if (tab === 1 && alertsByNoMeasure)
      setAlertsLength(alertsByNoMeasure.length);
    else setAlertsLength(0);
  }, [tab, alertsByNoMeasure, alertsByValue]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tabId = query.get("tab");
    if (tabId) setTab(+tabId);
  }, [location.search]);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any): void => {
    setTab(+value);
    setPage(1);
  };

  const setAlertAck = (id: number) => {
    const setAlertAckAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await setAck(id);
        mutateByValue();
        mutateByNoMeasure();
        mutateByFrequency();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertAckAsync(id);
  };

  const setAlertState = (id: number, state: AlertState) => {
    const setAlertStatusAsync = async (id: number) => {
      setIsLoading(true);
      try {
        await updateAlertState(id, { state });
        mutateByValue();
        mutateByNoMeasure();
        mutateByFrequency();
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
      setIsLoading(false);
    };
    setAlertStatusAsync(id);
  };

  if (!actorRole) return <></>;

  const buttons = alertsLength > 0 && (
    <Box display="flex" justifyContent="flex-end"></Box>
  );

  const footer = alertsLength > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end" px={3}>
      <Typography style={{ marginRight: "2px" }} className={classes.total}>
        {t("AlertsListTotal")}:
      </Typography>
      <Typography className={classes.total}>{alertsLength}</Typography>
    </Box>
  );

  const alertsByValueList = (
    <AlertsByValueList
      alerts={alertsByValue}
      actorId={id}
      actorRole={actorRole}
      setAlertAck={setAlertAck}
      setAlertState={setAlertState}
      setAlertComments={setAlertComments}
      timezone={timezone}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      paginationInfo={alertsByValuePaginationInfo}
      getUrl={getUrl}
      updateManyMeasurements={updateMany}
      delMeasurement={del}
      delManyMeasurements={delByReadDevice}
      findAllByReadDevice={findAllByReadDevice}
      getDevice={getDevice}
    />
  );

  const alertsByNoMeasureList = (
    <AlertsByNoMeasureList
      alerts={alertsByNoMeasure}
      actorId={id}
      actorRole={actorRole}
      setAlertAck={setAlertAck}
      setAlertState={setAlertState}
      setAlertComments={setAlertComments}
      timezone={timezone}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      paginationInfo={alertsByNoMeasurePaginationInfo}
    />
  );

  const alertsByFrequencyList = (
    <AlertsByFrequencyList
      alerts={alertsByFrequency}
      actorId={id}
      actorRole={actorRole}
      setAlertAck={setAlertAck}
      setAlertState={setAlertState}
      setAlertComments={setAlertComments}
      timezone={timezone}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      paginationInfo={alertsByFrequencyPaginationInfo}
    />
  );

  const getBreadCrumbProps = (actorName: string): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        { url: "", name: actorName },
        {
          url: BreadcrumbNameMap.Patient.url.replace(
            ":patientId",
            patientId as string
          ),
          name: patient ? patient?.data?.name : "",
        },
        { url: "", name: t("AlertsTitle") },
      ],
    } as BreadcrumbProps;
  };
  const actorName = profile?.data?.name ? profile!.data!.name : "";
  return (
    <Page
      title={""}
      buttons={buttons}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps(actorName)}
    >
      <Tabs value={tab} onChange={handleTabChange} orientation="horizontal">
        <Tab label={t("HeaderAlertByValue")} {...a11yProps(0)} />
        <Tab label={t("HeaderAlertByNoMeasure")} {...a11yProps(1)} />
        <Tab label={t("HeaderAlertByFrequency")} {...a11yProps(2)} />
      </Tabs>
      <TabPanel key={`panel-0`} value={tab} index={0}>
        {alertsByValueList}
      </TabPanel>
      <TabPanel key={`panel-1`} value={tab} index={1}>
        {alertsByNoMeasureList}
      </TabPanel>
      <TabPanel key={`panel-2`} value={tab} index={2}>
        {alertsByFrequencyList}
      </TabPanel>
    </Page>
  );
};

export { AlertsByPatient };
