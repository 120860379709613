import { makeStyles } from "@mui/styles";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { validate, ValidationError } from "class-validator";
import { useTranslation } from "react-i18next";
import { UserDto } from "@doctomatic/sdk/build/dto/User";
import { SelectMultipleModal } from "@doctomatic/components-react/build/SelectModal/SelectMultipleModal";
import {
  SelectMultipleGridColumns,
  SelectMultipleGridColumnsProps,
} from "@doctomatic/components-react/build/SelectModal/SelectMultipleGridColumns";
import { AlertConfigByValueTemplateListDataGridColumns } from "./AlertConfigByValueTemplateListDataGridColumns";
import { AlertConfigByNoMeasureTemplateListDataGridColumns } from "./AlertConfigByNoMeasureTemplateListDataGridColumns";
import { ItemAlertConfigByValueTemplateDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByValueTemplate";
import { ItemAlertConfigByNoMeasureTemplateDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByNoMeasureTemplate";
import {
  GetAlertGroupResponseDto,
  UpdateAlertGroupRequestDto,
} from "@doctomatic/sdk/build/dto/Alerts/group/AlertGroup";
import { INotification } from "@doctomatic/sdk/build/dto/Alerts/group/IAlertGroup";

interface IEditForm {
  name: string;
  notifications: boolean;
  notification: INotification;
  patients: UserDto[];
  alertconfigbyvalue: ItemAlertConfigByValueTemplateDto[];
  alertconfigbynomeasure: ItemAlertConfigByNoMeasureTemplateDto[];
}

interface Props {
  alertGroup: GetAlertGroupResponseDto;
  open: boolean;
  onClose: () => void;
  onSave: (alertGroup: UpdateAlertGroupRequestDto) => void;
  patientsByActor: UserDto[] | undefined;
  alertConfigsByValue: ItemAlertConfigByValueTemplateDto[] | undefined;
  alertConfigsByNoMeasure: ItemAlertConfigByNoMeasureTemplateDto[] | undefined;
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  paginationInfoByValue?: any;
  paginationInfoByNoMeasure?: any;
  messengerChannel: string;
}

const useStyles = makeStyles(() => ({
  visible: {
    overflowY: "visible",
    display: "flex",
    flexDirection: "column",
  },
  notifications: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
}));

const AlertGroupEdit = ({
  alertGroup,
  open,
  onClose,
  onSave,
  patientsByActor,
  alertConfigsByValue,
  alertConfigsByNoMeasure,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  paginationInfoByValue,
  paginationInfoByNoMeasure,
  messengerChannel,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const [editForm, setEditForm] = useState<IEditForm>({
    name: alertGroup.name,
    notifications: alertGroup.notifications,
    notification: alertGroup.notification,
    patients: alertGroup.patients,
    // Get all AlertByXXXXX ids. Since both type of Alerts are stored in the same table, we can just filter them by using alertconfigs ids
    alertconfigbyvalue:
      alertConfigsByValue?.filter((x) =>
        alertGroup.alertsConfig.map((a) => a.id).includes(x.id)
      ) ?? [],
    alertconfigbynomeasure:
      alertConfigsByNoMeasure?.filter((x) =>
        alertGroup.alertsConfig.map((a) => a.id).includes(x.id)
      ) ?? [],
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const classes = useStyles();
  const [openSelectPatientsModal, setOpenSelectPatientsModal] =
    useState<boolean>(false);
  const [
    openSelectAlertConfigByValueModal,
    setOpenSelectAlertConfigByValueModal,
  ] = useState<boolean>(false);
  const [
    openSelectAlertConfigByNoMeasureModal,
    setOpenSelectAlertConfigByNoMeasureModal,
  ] = useState<boolean>(false);
  const [patientsSelection, setPatientsSelection] = useState<UserDto[]>(
    editForm.patients ?? []
  );
  const [alertConfigByValueSelection, setAlertConfigByValueSelection] =
    useState<ItemAlertConfigByValueTemplateDto[]>(
      editForm.alertconfigbyvalue ?? []
    );
  const [alertConfigByNoMeasureSelection, setAlertConfigByNoMeasureSelection] =
    useState<ItemAlertConfigByNoMeasureTemplateDto[]>(
      editForm.alertconfigbynomeasure ?? []
    );

  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const checkErrors = async (
    dto: UpdateAlertGroupRequestDto
  ): Promise<boolean> => {
    const errors: ValidationError[] = await validate(dto);
    if (!editForm.name || editForm.name.length === 0) {
      const validationError: ValidationError = new ValidationError();
      validationError.property = "name";
      errors.push(validationError);
    }
    if (editForm.notifications && !editForm.notification) {
      const validationError: ValidationError = new ValidationError();
      validationError.property = "notifications";
      errors.push(validationError);
    }
    setErrors(errors);
    return errors.length === 0;
  };

  const formattedText = (list: string[]): string => {
    if (list.length === 0) return "";
    else if (list.length <= 3) return list.join(", ");
    else return list.slice(0, 3).join(", ") + ", ...";
  };

  const getError = (name: string): ValidationError | undefined => {
    return errors.find((v: ValidationError) => v.property === name);
  };

  const getHelperText = (name: string, message: string): string => {
    return getError(name) ? message : "";
  };

  const handlePatientSelectionSave = () => {
    editForm.patients = patientsSelection;
    handleClose(
      setOpenSelectPatientsModal,
      setPatientsSelection,
      editForm.patients
    );
  };

  const handleAlertConfigByValueSelectionSave = () => {
    editForm.alertconfigbyvalue = alertConfigByValueSelection;
    handleClose(
      setOpenSelectAlertConfigByValueModal,
      setAlertConfigByValueSelection,
      editForm.alertconfigbyvalue
    );
  };

  const handleAlertConfigByNoMeasureSelectionSave = () => {
    editForm.alertconfigbynomeasure = alertConfigByNoMeasureSelection;
    handleClose(
      setOpenSelectAlertConfigByNoMeasureModal,
      setAlertConfigByNoMeasureSelection,
      editForm.alertconfigbynomeasure
    );
  };

  const handleClose = (
    setOpen: Dispatch<SetStateAction<boolean>>,
    restartData: Dispatch<SetStateAction<any[]>>,
    data: any[]
  ) => {
    setOpen(false);
    restartData(data);
  };

  /* Modal selection of patients, alert config by no measure && alert config by value */
  const selectedRow = (
    dataRow: any,
    checked: boolean,
    setData: Dispatch<SetStateAction<any[]>>,
    data: any[]
  ) => {
    if (checked) {
      setData([...data, dataRow]);
    } else {
      setData(
        data.filter((d) => {
          return d.id !== dataRow.id;
        })
      );
    }
  };

  const selectedPatientRow = (dataRow: UserDto, checked: boolean) => {
    selectedRow(dataRow, checked, setPatientsSelection, patientsSelection);
  };

  const selectedAlertConfigByValueRow = (
    dataRow: ItemAlertConfigByValueTemplateDto,
    checked: boolean
  ) => {
    selectedRow(
      dataRow,
      checked,
      setAlertConfigByValueSelection,
      alertConfigByValueSelection
    );
  };

  const selectedAlertConfigByNoMeasureRow = (
    dataRow: ItemAlertConfigByNoMeasureTemplateDto,
    checked: boolean
  ) => {
    selectedRow(
      dataRow,
      checked,
      setAlertConfigByNoMeasureSelection,
      alertConfigByNoMeasureSelection
    );
  };

  const selectPatientsGridColumnsByLibraryProps: SelectMultipleGridColumnsProps<UserDto> =
    {
      columns: [
        {
          field: "name",
          headerName: t("Name"),
          width: 250,
          align: "left",
        },
      ],
      selectEvent: selectedPatientRow,
      selectedItems: patientsSelection,
    };

  const selectAlertConfigByValueGridColumnsByLibraryProps: SelectMultipleGridColumnsProps<ItemAlertConfigByValueTemplateDto> =
    AlertConfigByValueTemplateListDataGridColumns(
      t,
      selectedAlertConfigByValueRow,
      alertConfigByValueSelection,
      currentLanguage
    );

  const selectAlertConfigByNoMeasureGridColumnsByLibraryProps: SelectMultipleGridColumnsProps<ItemAlertConfigByNoMeasureTemplateDto> =
    AlertConfigByNoMeasureTemplateListDataGridColumns(
      t,
      selectedAlertConfigByNoMeasureRow,
      alertConfigByNoMeasureSelection,
      currentLanguage
    );

  const getSelectMultiplePatientsModal = (items: UserDto[] | undefined) => {
    if (!items) return <></>;
    return (
      <SelectMultipleModal<UserDto>
        breadcrumbItems={[
          { name: t("Selection"), url: "" },
          { name: t("Patients"), url: "" },
        ]}
        open={openSelectPatientsModal}
        handleClose={() =>
          handleClose(
            setOpenSelectPatientsModal,
            setPatientsSelection,
            editForm.patients
          )
        }
        item={undefined}
        columns={SelectMultipleGridColumns<UserDto>(
          selectPatientsGridColumnsByLibraryProps
        )}
        rows={items}
        buttonText={t("Save")}
        handleOnButtonClick={() => handlePatientSelectionSave()}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationInfo={paginationInfo}
      />
    );
  };

  const getSelectMultipleAlertConfigByValueModal = (
    items: ItemAlertConfigByValueTemplateDto[] | undefined
  ) => {
    if (!items) return <></>;
    return (
      <SelectMultipleModal<ItemAlertConfigByValueTemplateDto>
        breadcrumbItems={[
          { name: t("Selection"), url: "" },
          { name: t("AlertConfigByValue"), url: "" },
        ]}
        open={openSelectAlertConfigByValueModal}
        handleClose={() =>
          handleClose(
            setOpenSelectAlertConfigByValueModal,
            setAlertConfigByValueSelection,
            editForm.alertconfigbyvalue
          )
        }
        item={undefined}
        columns={SelectMultipleGridColumns<ItemAlertConfigByValueTemplateDto>(
          selectAlertConfigByValueGridColumnsByLibraryProps
        )}
        rows={items}
        buttonText={t("Save")}
        handleOnButtonClick={() => handleAlertConfigByValueSelectionSave()}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationInfo={paginationInfoByValue}
      />
    );
  };

  const getSelectMultipleAlertConfigByNoMeasureModal = (
    items: ItemAlertConfigByNoMeasureTemplateDto[] | undefined
  ) => {
    if (!items) return <></>;
    return (
      <SelectMultipleModal<ItemAlertConfigByNoMeasureTemplateDto>
        breadcrumbItems={[
          { name: t("Selection"), url: "" },
          { name: t("AlertConfigByNoMeasure"), url: "" },
        ]}
        open={openSelectAlertConfigByNoMeasureModal}
        handleClose={() =>
          handleClose(
            setOpenSelectAlertConfigByNoMeasureModal,
            setAlertConfigByNoMeasureSelection,
            editForm.alertconfigbynomeasure
          )
        }
        item={undefined}
        columns={SelectMultipleGridColumns<ItemAlertConfigByNoMeasureTemplateDto>(
          selectAlertConfigByNoMeasureGridColumnsByLibraryProps
        )}
        rows={items}
        buttonText={t("Save")}
        handleOnButtonClick={() => handleAlertConfigByNoMeasureSelectionSave()}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationInfo={paginationInfoByNoMeasure}
      />
    );
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  /* End modal selection of patients, alert config by no measure && alert config by value */

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>{`${t("ButtonEdit")} ${t("FieldAlertGroup")}`}</DialogTitle>

      <DialogContent className={classes.visible}>
        <TextField
          margin="dense"
          variant="outlined"
          name="name"
          label={t("FieldAlertGroupName")}
          type="text"
          fullWidth
          onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
          value={editForm.name}
          autoFocus
          required
          helperText={getHelperText(
            "name",
            t("ErrorRequiredField", { field_name: t("FieldAlertGroupName") })
          )}
          error={!!getError("name")}
        />
        <TextField
          inputProps={{ readOnly: true }}
          margin="dense"
          variant="outlined"
          name="name"
          fullWidth
          helperText={t("FieldPatients")}
          onClick={() => setOpenSelectPatientsModal(true)}
          type="text"
          value={formattedText(editForm.patients.map((x) => x.name))}
        />
        <TextField
          inputProps={{ readOnly: true }}
          margin="dense"
          variant="outlined"
          name="name"
          fullWidth
          helperText={t("FieldAlertConfigByValueTemplate")}
          onClick={() => setOpenSelectAlertConfigByValueModal(true)}
          type="text"
          // Display alert config text as [Device, Sign, Label]
          value={formattedText(
            editForm.alertconfigbyvalue.map(
              (x) =>
                "[" +
                t(x.sign?.device?.name) +
                ", " +
                t(x.sign?.name) +
                ", " +
                x.label +
                "]"
            )
          )}
        />
        <TextField
          inputProps={{ readOnly: true }}
          margin="dense"
          variant="outlined"
          name="name"
          fullWidth
          helperText={t("FieldAlertConfigByNoMeasureTemplate")}
          onClick={() => setOpenSelectAlertConfigByNoMeasureModal(true)}
          type="text"
          // Display alert config text as [Device, Label]
          value={formattedText(
            editForm.alertconfigbynomeasure.map(
              (x) => "[" + t(x.device?.name) + ", " + x.label + "]"
            )
          )}
        />
        <FormControlLabel
          control={
            <Switch
              checked={editForm.notifications}
              name={t("FieldNotificationsActive")}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => setEditForm({ ...editForm, notifications: checked })}
            />
          }
          label={t("FieldNotificationsActive")}
        />
        {editForm.notifications && (
          <Box className={classes.notifications}>
            <FormControlLabel
              control={
                <Switch
                  checked={editForm.notification.pushNotification}
                  style={{
                    color: getError("notifications") ? "red" : undefined,
                  }}
                  name={t("FieldPushNotificationActive")}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) =>
                    setEditForm((form) => ({
                      ...form,
                      notification: {
                        ...(form.notification as INotification),
                        pushNotification: checked,
                      },
                    }))
                  }
                />
              }
              label={t("FieldPushNotificationActive")}
            />

            {JSON.parse(messengerChannel).sms && (
              <FormControlLabel
                control={
                  <Switch
                    checked={editForm.notification.sms}
                    style={{
                      color: getError("notifications") ? "red" : undefined,
                    }}
                    name={t("FieldSMSActive")}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) =>
                      setEditForm((form) => ({
                        ...form,
                        notification: {
                          ...(form.notification as INotification),
                          sms: checked,
                        },
                      }))
                    }
                  />
                }
                label={t("FieldSMSActive")}
              />
            )}

            {JSON.parse(messengerChannel).email && (
              <FormControlLabel
                control={
                  <Switch
                    checked={editForm.notification.email}
                    style={{
                      color: getError("notifications") ? "red" : undefined,
                    }}
                    name={t("FieldEmailActive")}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) =>
                      setEditForm((form) => ({
                        ...form,
                        notification: {
                          ...(form.notification as INotification),
                          email: checked,
                        },
                      }))
                    }
                  />
                }
                label={t("FieldEmailActive")}
              />
            )}
          </Box>
        )}
        {getSelectMultiplePatientsModal(patientsByActor)}
        {getSelectMultipleAlertConfigByValueModal(alertConfigsByValue)}
        {getSelectMultipleAlertConfigByNoMeasureModal(alertConfigsByNoMeasure)}
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} size="small">
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            onClick={async () => {
              const dto = new UpdateAlertGroupRequestDto();
              dto.name = editForm.name;
              dto.notifications = editForm.notifications;
              dto.patients = editForm.patients.map((p) => p.id);
              dto.alertsConfig = [
                ...editForm.alertconfigbynomeasure.map((a) => a.id),
                ...editForm.alertconfigbyvalue.map((a) => a.id),
              ];
              dto.notification = editForm.notification as INotification;
              const valid: boolean = await checkErrors(dto);
              if (valid) onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { AlertGroupEdit };
