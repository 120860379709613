import { SignFormDto } from "../../Sign/Signs";
import {
  IForm,
  IFormBase,
  IFormResponse,
  IOption,
  IQuestion,
  IResponseOfQuestionImage,
  IResponseOfQuestionNumeric,
  IResponseOfQuestionRadio,
  IResponseOfQuestionMultiRadio,
  IResponseOfQuestionText,
  ITextLanguage,
  TypeQuestion,
  _UUID,
} from "./IReadFormTypes";

class TextLanguage implements ITextLanguage {
  lang: string = "en"; //es_ES, en_EN, en_GB, etc
  text: string = "";
}
class T {
  langs: string[] = [];
  constructor(langs: string[]) {
    if (langs.length == 0) {
      this.langs.push("en");
    } else {
      this.langs = langs;
    }
  }
  i18n(textLanguage: TextLanguage[]): string {
    if (!textLanguage) return "";
    if (textLanguage.length == 0) return "";
    for (const lang in this.langs) {
      const textLanguageFound = textLanguage.find(
        (e) => e.lang == this.langs[lang]
      );
      if (textLanguageFound) return textLanguageFound.text;
    }
    return textLanguage[0].text;
  }
}
class Option implements IOption {
  id: _UUID = "";
  order: number = 0;
  text: TextLanguage[] = [];
  textValue: string = "";
  value: number = 0;
  backgroundColor?: string;
  isValueEditable: boolean = false;
  isTextValueEditable: boolean = false;
}

class Question implements IQuestion {
  constructor(typeQuestion: TypeQuestion) {
    this.typeQuestion = typeQuestion;
  }
  id: _UUID | _UUID[] = "";
  backgroundColor?: string;
  typeQuestion: TypeQuestion = "radio";
  order: number = 0;
  signId: number[] = [0];
  sign: {
    name: string;
    unit: string;
    decimals: number;
    max: number;
    min: number;
    defaultMax: number;
    defaultMin: number;
    typeSign: TypeQuestion;
  }[] = [];
  unit: ITextLanguage[] = [];
  text: TextLanguage[] = [];
  /**
   * Only for radio
   */
  options: IOption[] = [];
  deviceId?: number | undefined;
}

class ResponseOfQuestionFactory {
  static Create(question: Question) {
    switch (question.typeQuestion) {
      case "radio": {
        /**
         * Remove the id of Question, after answer will set the option UUiD
         */
        return {
          ...question,
          value: 0,
          id: "",
          valueText: "",
        } as ResponseOfQuestionRadio;
      }
      case "multiRadio": {
        /**
         * Remove the id of Question, after answer will set the option UUiD
         */
        return {
          ...question,
          value: [],
          id: [""],
          valueText: [""],
        } as ResponseOfQuestionMultiRadio;
      }
      case "text": {
        return { ...question, response: "" } as ResponseOfQuestionText;
      }
      case "time": {
        return { ...question, response: "" } as ResponseOfQuestionText;
      }
      case "number": {
        return { ...question, response: 0 } as ResponseOfQuestionNumeric;
      }
      case "image": {
        return { ...question, url: "" } as ResponseOfQuestionImage;
      }
      default: {
        return {
          ...question,
          value: 0,
          valueText: "",
        } as ResponseOfQuestionRadio;
      }
    }
  }
}

class ResponseOfQuestionRadio
  extends Question
  implements IResponseOfQuestionRadio
{
  value: number;
  valueText: string;
  constructor(value: number, valueText: string) {
    super("radio");
    this.value = value;
    this.valueText = valueText;
  }
}
class ResponseOfQuestionMultiRadio
  extends Question
  implements IResponseOfQuestionMultiRadio
{
  value: number[];
  valueText: string[];
  constructor(value: number[], valueText: string[]) {
    super("multiRadio");
    this.value = value;
    this.valueText = valueText;
  }
}
class ResponseOfQuestionText
  extends Question
  implements IResponseOfQuestionText
{
  response: string = "";
  constructor(response: string) {
    super("text");
    this.response = response;
  }
}
class ResponseOfQuestionNumeric
  extends Question
  implements IResponseOfQuestionNumeric
{
  response: number = 0;
  constructor(response: number) {
    super("number");
    this.response = response;
  }
}
class ResponseOfQuestionImage
  extends Question
  implements IResponseOfQuestionImage
{
  id: string = "";
  url: string = "";
  constructor(id: string, url: string) {
    super("image");
    this.id = id;
    this.url = url;
  }
}

class FormBase implements IFormBase {
  id: _UUID = "";
  deviceId: number = 0;
  formId: number = 0;
  formName: string = "";
  formTitle: TextLanguage[] = [];
  formDescription: TextLanguage[] = [];
  deviceName: TextLanguage[] = [];
  version: string = "1.0";
}
class Form extends FormBase implements IForm {
  questions: Question[] = [];
}

class FormResponse extends Form implements IFormResponse {
  responses: (
    | IResponseOfQuestionRadio
    | IResponseOfQuestionMultiRadio
    | IResponseOfQuestionText
    | IResponseOfQuestionNumeric
    | IResponseOfQuestionImage
  )[] = [];
  patientId?: number;
}

export {
  Form,
  FormBase,
  FormResponse,
  TextLanguage,
  Option,
  Question,
  ResponseOfQuestionImage,
  ResponseOfQuestionRadio,
  ResponseOfQuestionMultiRadio,
  ResponseOfQuestionText,
  ResponseOfQuestionNumeric,
  ResponseOfQuestionFactory,
  T,
};
