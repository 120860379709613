import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../../App/errorToast";
import { Options, Question } from "./FormInterface";
import { DragAndDrop } from "@doctomatic/components-react/build/DragAndDrop/DragAndDrop";
import { useNavigate } from "react-router-dom";
import { IUseDevices } from "@doctomatic/sdk/build/modules/Devices";
import { getDeviceName } from "../../utils";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { TFunction } from "i18next";

interface FormComponentProps {
  handleSaveForm: (
    formName: string,
    formDescription: string,
    formTitle: string,
    nameDeviceButton: string,
    formPatientDescription: string,
    currentLanguage: string,
    questions: Question[],
    formId: number,
    deviceId: number,
    functionApi: any,
    functionApiName: string,
    navigate: any,
    t: TFunction,
    uuidForm?: string,
    order?: Item[]
  ) => void;
  formId?: number;
}

export interface Item {
  id: number;
  name: string;
  order: number;
}

interface Device {
  id: number;
  name: string;
}

const FormComponent = (props: FormComponentProps): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { useForms, useDevices, logout } = useApi();
  const navigate = useNavigate();
  const { get, add, patch } = useForms(
    false,
    undefined,
    true,
    processError(logout, navigate, t)
  );
  const { response: responseApiDevices }: IUseDevices = useDevices(
    true,
    processError(logout, navigate, t)
  );

  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [formTitle, setFormTitle] = useState<string>("");
  const [formPatientDescription, setFormPatientDescription] =
    useState<string>("");
  const [nameDeviceButton, setNameDeviceButton] = useState<string>("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [order, setOrder] = useState<Item[]>([]);
  const [selectedType, setSelectedType] = useState<string>("");
  const [uuidForm, setUuidForm] = useState<string | undefined>(undefined);
  const [optionInputs, setOptionInputs] = useState<string[][]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [deviceId, setDeviceId] = useState<number>(0);
  const [loadedQuestions, setLoadedQuestions] = useState<Question[]>([]);
  const [questionId, setQuestionId] = useState<number>(0);
  const [devices, setDevices] = useState<Device[]>();
  const [autoRead, setAutoRead] = useState(false);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedType(event.target.value);
    if (event.target.value !== "") {
      const newQuestion: Question = {
        id: questionId,
        type: event.target.value,
        text: "",
        range: { min: "", max: "", unit: "", decimals: 0 },
        options: { options: [], checked: false },
        order: questions.length,
      };
      setQuestions([...questions, newQuestion]);
      setSelectedType("");
      setQuestionId(questionId + 1);
    }
  };

  const handleRemoveQuestion = (id: number) => {
    let updatedQuestions = questions.filter((question) => question.id !== id);

    updatedQuestions = updatedQuestions.map((question, index) => ({
      ...question,
      order: index,
    }));

    setQuestions(updatedQuestions);

    const updatedLoadedQuestions = loadedQuestions.filter(
      (loadedQuestion) => loadedQuestion.id !== id
    );
    setLoadedQuestions(updatedLoadedQuestions);
  };

  const handleQuestionNameChange = (id: number, newText: string) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id ? { ...question, text: newText } : question
    );
    setQuestions(updatedQuestions);
  };

  const handleAddOptionInput = (questionId: number) => {
    const updatedInputs = optionInputs.map((input, index) =>
      index === questionId - 1 ? [...input, ""] : input
    );
    setOptionInputs(updatedInputs);

    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        const options = question.options?.options || [];
        const lastValue = options[options.length - 1]?.value || 0;
        const nextValue = lastValue + 1;

        return {
          ...question,
          options: {
            checked: question.options?.checked || false,
            options: [
              ...options,
              { id: undefined, text: "", value: nextValue, color: "#000000" },
            ],
          },
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
  };

  const handleRemoveOptionInput = (questionId: number, index: number) => {
    const updatedInputs = optionInputs.map((input, i) =>
      i === questionId - 1 ? input.filter((_, idx) => idx !== index) : input
    );
    setOptionInputs(updatedInputs);

    const updatedQuestions = questions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            options: {
              checked: question.options?.checked
                ? question.options?.checked
                : false,
              options: (question.options?.options || []).filter(
                (_, idx) => idx !== index
              ),
            },
          }
        : question
    );
    setQuestions(updatedQuestions);
  };

  const handleDevice = (id: number, deviceId: number) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id
        ? {
            ...q,
            deviceId: deviceId,
          }
        : q
    );
    setQuestions(updatedQuestions);
  };

  const handleMinChange = (id: number, value: string) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id
        ? {
            ...q,
            range: {
              ...q.range!,
              min: value,
            },
          }
        : q
    );
    setQuestions(updatedQuestions);
  };

  const handleMaxChange = (id: number, value: string) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id
        ? {
            ...q,
            range: {
              ...q.range!,
              max: value,
            },
          }
        : q
    );
    setQuestions(updatedQuestions);
  };

  const handleUnitChange = (id: number, unit: string) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id
        ? {
            ...q,
            range: {
              ...q.range!,
              unit: unit,
            },
          }
        : q
    );
    setQuestions(updatedQuestions);
  };

  const handleDecimalChange = (id: number, decimals: number) => {
    const updatedQuestions = questions.map((q) =>
      q.id === id
        ? {
            ...q,
            range: {
              ...q.range!,
              decimals: decimals,
            },
          }
        : q
    );
    setQuestions(updatedQuestions);
  };

  const handleCheckboxChange = (questionId: number, checked: boolean) => {
    let updatedQuestions = [...questions];

    if (checked) {
      const yesOption = {
        id: undefined,
        text: t("Yes"),
        value: 1,
        color: "#008000",
      };
      const noOption = {
        id: undefined,
        text: t("No"),
        value: 0,
        color: "#ff0000",
      };

      updatedQuestions = updatedQuestions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              options: {
                options: [yesOption, noOption],
                checked: true,
              },
            }
          : question
      );

      const updatedInputs = optionInputs.map((input, index) =>
        index === questionId - 1 ? [] : input
      );
      setOptionInputs(updatedInputs);
    } else {
      updatedQuestions = updatedQuestions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              options: {
                options: [],
                checked: false,
              },
            }
          : question
      );
    }

    setQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (responseApiDevices && responseApiDevices.additional_data) {
      setAutoRead(responseApiDevices.additional_data.autoRead);
    }
    if (
      responseApiDevices &&
      responseApiDevices.success === true &&
      responseApiDevices.data!
    ) {
      const devices: Device[] =
        responseApiDevices?.data?.map((device: GetDeviceResponseDto) => {
          if (device.typeName !== "Form") {
            return {
              id: device.id,
              name: getDeviceName(currentLanguage, device, t),
            } as Device;
          } else {
            return {} as Device;
          }
        }) ?? [];
      setDevices(devices);
    }
  }, [responseApiDevices]);

  useEffect(() => {
    if (
      formName !== "" &&
      formDescription !== "" &&
      formTitle !== "" &&
      nameDeviceButton !== "" &&
      formPatientDescription !== "" &&
      questions.length > 0 &&
      questions.every((question) => {
        if (question.type === "Options" || question.type === "MultiOptions") {
          return (
            question.text !== "" &&
            question.options!.options.length > 0 &&
            question.options?.options.every(
              (option: {
                id: string | undefined;
                text: string;
                value: number;
                color: string;
              }) => option.text !== ""
            ) &&
            question.options?.options.every(
              (option: {
                id: string | undefined;
                text: string;
                value: number;
                color: string;
              }) => option.value !== undefined
            ) &&
            question.options?.options.every(
              (option: {
                id: string | undefined;
                text: string;
                value: number;
                color: string;
              }) => option.color !== ""
            )
          );
        } else if (question.type === "Number") {
          return (
            question.text !== "" &&
            question.range!.unit !== "" &&
            +question.range!.max > 0 &&
            +question.range!.min < +question.range!.max &&
            question.range!.decimals >= 0
          );
        } else if (question.type === "Device") {
          return (
            question.text !== "" &&
            question.deviceId !== 0 &&
            question.deviceId !== undefined
          );
        } else {
          return question.text !== "";
        }
      })
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    formName,
    formDescription,
    questions,
    formTitle,
    nameDeviceButton,
    formPatientDescription,
  ]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const getForm = async (id: number) => {
      const response = await get(id);
      if (response && response.data) {
        const formData = response.data;
        const json = JSON.parse(response.data.json.replace(/^"|"$/g, ""));
        setUuidForm(json.id);
        setFormName(formData.name);
        setFormDescription(formData.description);
        setDeviceId(json.deviceId);
        setFormTitle(
          json.formTitle
            .map((data: { lang: string; text: string }) => {
              if (data.lang === currentLanguage) {
                return data.text;
              }
              return null;
            })
            .filter((text: string | null) => text !== null)[0] ||
            json.formTitle[0].text
        );
        setNameDeviceButton(
          json.deviceName
            .map((data: { lang: string; text: string }) => {
              if (data.lang === currentLanguage) {
                return data.text;
              }
              return null;
            })
            .filter((text: string | null) => text !== null)[0] ||
            json.deviceName[0].text
        );
        setFormPatientDescription(
          json.formDescription
            .map((data: { lang: string; text: string }) => {
              if (data.lang === currentLanguage) {
                return data.text;
              }
              return null;
            })
            .filter((text: string | null) => text !== null)[0] ||
            json.formDescription[0].text
        );

        if (json.questions && json.questions.length > 0) {
          const formattedQuestions = json.questions.map((questionData: any) => {
            const typeQuestion =
              questionData.typeQuestion === "text"
                ? "Text"
                : questionData.typeQuestion === "number"
                ? "Number"
                : questionData.typeQuestion === "radio"
                ? "Options"
                : questionData.typeQuestion === "multiRadio"
                ? "MultiOptions"
                : questionData.typeQuestion === "device"
                ? "Device"
                : questionData.typeQuestion === "time"
                ? "Time"
                : "Image";

            const questionText =
              questionData.text
                .map((data: { lang: string; text: string }) => {
                  if (data.lang === currentLanguage) {
                    return data.text;
                  }
                  return null;
                })
                .filter((text: string | null) => text !== null)[0] ||
              questionData.text[0].text;

            const range =
              typeQuestion === "Text" ||
              typeQuestion === "Image" ||
              typeQuestion === "Device" ||
              typeQuestion === "MultiOptions" ||
              typeQuestion === "Time" ||
              typeQuestion === "Options"
                ? undefined
                : {
                    min: questionData.sign[0].min,
                    max: questionData.sign[0].max,
                    unit: questionData.sign[0].unit,
                    decimals: questionData.sign[0].decimals,
                  };

            const options =
              typeQuestion === "Options" || typeQuestion === "MultiOptions"
                ? questionData.options.map((option: any) => ({
                    text:
                      option.text
                        .map((data: { lang: string; text: string }) => {
                          if (data.lang === currentLanguage) {
                            return data.text;
                          }
                          return null;
                        })
                        .filter((text: string | null) => text !== null)[0] ||
                      option.text[0].text,
                    value: option.value,
                    color: option.backgroundColor,
                    id: option.id,
                  }))
                : undefined;

            return {
              id: questionData.id,
              order: questionData.order,
              type: typeQuestion,
              text: questionText,
              range: range,
              options: { checked: false, options: options },
              deviceId: questionData.deviceId,
            };
          });

          setQuestions(formattedQuestions);
          setLoadedQuestions(formattedQuestions);
        }
      }
    };

    if (props.formId) getForm(props.formId);
  }, [props.formId]);

  useEffect(() => {
    setOrder(
      questions.map((question) => ({
        id: question.id,
        name: `${question.text} - ${t(question.type)}`,
        order: question.order,
      }))
    );
  }, [questions]);

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "center", width: "80%" }}
        >
          <Grid size={{ xs: 12 }} style={{ display: "flex" }}>
            <TextField
              required
              label={t("FormName")}
              value={formName}
              onChange={(event) => setFormName(event.target.value)}
              style={{ marginInline: "auto", width: "40%", minWidth: "14em" }}
            />
          </Grid>
          <Grid
            size={{ xs: 12 }}
            style={{ display: "flex", marginBottom: "3em" }}
          >
            <TextField
              required
              label={t("FormDescription")}
              value={formDescription}
              onChange={(event) => setFormDescription(event.target.value)}
              style={{ marginInline: "auto", width: "60%", minWidth: "14em" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        style={{
          margin: "10px",
          minWidth: "300px",
          boxShadow: "0px 0px 30px 0px rgba(224,224,224,1)",
          backgroundColor: "#fcfcfc",
        }}
      >
        <CardContent style={{ paddingTop: "3em" }}>
          <Card
            style={{
              margin: "10px",
              minWidth: "300px",
              boxShadow: "0px 0px 30px 0px rgba(224,224,224,1)",
              backgroundColor: "#fcfcfc",
              width: "80%",
              marginInline: "auto",
              paddingTop: "3em",
            }}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: "center",
                  width: "80%",
                  marginInline: "auto",
                }}
              >
                <Grid size={{ xs: 12, md: 6 }} style={{ display: "flex" }}>
                  <TextField
                    required
                    label={t("FormTitle")}
                    value={formTitle}
                    onChange={(event) => setFormTitle(event.target.value)}
                    style={{
                      marginInline: "auto",
                      width: "70%",
                      minWidth: "14em",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} style={{ display: "flex" }}>
                  <TextField
                    required
                    label={t("NameDeviceButton")}
                    value={nameDeviceButton}
                    onChange={(event) =>
                      setNameDeviceButton(event.target.value)
                    }
                    style={{
                      marginInline: "auto",
                      width: "70%",
                      minWidth: "14em",
                    }}
                  />
                </Grid>
                <Grid
                  size={{ xs: 12 }}
                  style={{ display: "flex", marginBottom: "3em" }}
                >
                  <TextField
                    required
                    label={t("PatientFormDescription")}
                    value={formPatientDescription}
                    onChange={(event) =>
                      setFormPatientDescription(event.target.value)
                    }
                    style={{
                      marginInline: "auto",
                      width: "85%",
                      minWidth: "14em",
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={2}>
            {questions.map((question) => (
              <Grid
                size={{ xs: 12 }}
                key={question.id}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Card
                  key={question.id}
                  style={{
                    margin: "10px",
                    minWidth: "300px",
                    boxShadow: "0px 0px 30px 0px rgba(224,224,224,1)",
                    backgroundColor: "#fcfcfc",
                    width: "80%",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {t("Question")} {question.order + 1} - {t("Type")}:{" "}
                      {t(question.type)}
                    </Typography>
                    <TextField
                      required
                      label={t("Question")}
                      value={question.text}
                      onChange={(event) =>
                        handleQuestionNameChange(
                          question.id,
                          event.target.value
                        )
                      }
                      style={{ marginBlock: "1em", width: "100%" }}
                      disabled={loadedQuestions.some(
                        (loadedQuestion) => loadedQuestion.id === question.id
                      )}
                    />
                    {question.type === "Number" && (
                      <div>
                        <Typography
                          variant="body1"
                          style={{ marginRight: "1em" }}
                        >
                          {t("AllowedRange")}:
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 3 }}>
                              <TextField
                                type="number"
                                required
                                label="Min"
                                value={question.range?.min || ""}
                                onChange={(event) =>
                                  handleMinChange(
                                    question.id,
                                    event.target.value
                                  )
                                }
                                inputProps={{ min: 0 }}
                                style={{ marginBlock: "1em", width: "100%" }}
                                disabled={loadedQuestions.some(
                                  (loadedQuestion) =>
                                    loadedQuestion.id === question.id
                                )}
                              />
                            </Grid>
                            <Grid size={{ xs: 12, md: 3 }}>
                              <TextField
                                type="number"
                                required
                                label="Max"
                                value={question.range?.max || ""}
                                onChange={(event) =>
                                  handleMaxChange(
                                    question.id,
                                    event.target.value
                                  )
                                }
                                inputProps={{ min: 0 }}
                                style={{ marginBlock: "1em", width: "100%" }}
                                disabled={loadedQuestions.some(
                                  (loadedQuestion) =>
                                    loadedQuestion.id === question.id
                                )}
                              />
                            </Grid>
                            <Grid size={{ xs: 12, md: 3 }}>
                              <TextField
                                type="text"
                                required
                                label={t("Unit")}
                                value={question.range?.unit || ""}
                                onChange={(event) =>
                                  handleUnitChange(
                                    question.id,
                                    event.target.value
                                  )
                                }
                                inputProps={{ min: 0 }}
                                style={{ marginBlock: "1em", width: "100%" }}
                                disabled={loadedQuestions.some(
                                  (loadedQuestion) =>
                                    loadedQuestion.id === question.id
                                )}
                              />
                            </Grid>
                            <Grid size={{ xs: 12, md: 3 }}>
                              <TextField
                                type="number"
                                required
                                label={t("Decimals")}
                                value={question.range?.decimals || 0}
                                onChange={(event) =>
                                  handleDecimalChange(
                                    question.id,
                                    parseInt(event.target.value)
                                  )
                                }
                                inputProps={{ min: 0 }}
                                style={{ marginBlock: "1em", width: "100%" }}
                                disabled={loadedQuestions.some(
                                  (loadedQuestion) =>
                                    loadedQuestion.id === question.id
                                )}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {(question.type === "Options" ||
                      question.type === "MultiOptions") && (
                      <div>
                        {question.type === "Options" && (
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleCheckboxChange(
                                question.id,
                                !question.options?.checked
                              )
                            }
                            disabled={loadedQuestions.some(
                              (loadedQuestion) =>
                                loadedQuestion.id === question.id
                            )}
                          >
                            {t("Yes")} / {t("No")}
                          </Button>
                        )}
                        {(question.options?.options || []).map(
                          (input: Options["options"][0], index: number) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "75%",
                                marginInline: "auto",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid size={{ xs: 12, md: 8 }}>
                                  <TextField
                                    required
                                    label={`${t("Option")} ${index + 1}`}
                                    inputProps={{ min: 0 }}
                                    value={input.text}
                                    onChange={(event) => {
                                      const updatedQuestions = questions.map(
                                        (q) =>
                                          q.id === question.id
                                            ? {
                                                ...q,
                                                options: {
                                                  checked: q.options?.checked
                                                    ? q.options?.checked
                                                    : false,
                                                  options:
                                                    q.options?.options?.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              text: event.target
                                                                .value,
                                                            }
                                                          : item
                                                    ) || [],
                                                },
                                              }
                                            : q
                                      );
                                      setQuestions(updatedQuestions);
                                    }}
                                    style={{
                                      marginBlock: "1em",
                                      width: "100%",
                                    }}
                                    disabled={
                                      question.options?.checked ||
                                      loadedQuestions.some(
                                        (loadedQuestion) =>
                                          loadedQuestion.id === question.id
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid size={{ xs: 12, md: 3 }}>
                                  <TextField
                                    required
                                    label={t("Value")}
                                    value={String(input.value)}
                                    onChange={(event) => {
                                      const updatedQuestions = questions.map(
                                        (q) =>
                                          q.id === question.id
                                            ? {
                                                ...q,
                                                options: {
                                                  checked: q.options?.checked
                                                    ? q.options?.checked
                                                    : false,
                                                  options:
                                                    q.options?.options?.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              value: Number(
                                                                event.target
                                                                  .value
                                                              ),
                                                            }
                                                          : item
                                                    ) || [],
                                                },
                                              }
                                            : q
                                      );
                                      setQuestions(updatedQuestions);
                                    }}
                                    type="number"
                                    style={{
                                      marginBlock: "1em",
                                      width: "100%",
                                    }}
                                    disabled={
                                      question.options?.checked ||
                                      loadedQuestions.some(
                                        (loadedQuestion) =>
                                          loadedQuestion.id === question.id
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid size={{ xs: 12, md: 1 }}>
                                  <TextField
                                    required
                                    label={t("Color")}
                                    value={input.color}
                                    onChange={(event) => {
                                      const updatedQuestions = questions.map(
                                        (q) =>
                                          q.id === question.id
                                            ? {
                                                ...q,
                                                options: {
                                                  checked: q.options?.checked
                                                    ? q.options?.checked
                                                    : false,
                                                  options:
                                                    q.options?.options?.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              color:
                                                                event.target
                                                                  .value,
                                                            }
                                                          : item
                                                    ) || [],
                                                },
                                              }
                                            : q
                                      );
                                      setQuestions(updatedQuestions);
                                    }}
                                    type="color"
                                    style={{
                                      marginBlock: "1em",
                                      width: "100%",
                                    }}
                                    disabled={
                                      question.options?.checked ||
                                      loadedQuestions.some(
                                        (loadedQuestion) =>
                                          loadedQuestion.id === question.id
                                      )
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <IconButton
                                sx={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: 1,
                                  border: "1px solid",
                                  marginLeft: "1em",
                                }}
                                onClick={() =>
                                  handleRemoveOptionInput(question.id, index)
                                }
                                disabled={
                                  question.options?.checked ||
                                  loadedQuestions.some(
                                    (loadedQuestion) =>
                                      loadedQuestion.id === question.id
                                  )
                                }
                              >
                                <RemoveIcon />
                              </IconButton>
                            </div>
                          )
                        )}
                        <Button
                          variant="outlined"
                          onClick={() => handleAddOptionInput(question.id)}
                          startIcon={<AddIcon />}
                          style={{ margin: "1em" }}
                          disabled={
                            question.options?.checked ||
                            loadedQuestions.some(
                              (loadedQuestion) =>
                                loadedQuestion.id === question.id
                            )
                          }
                        >
                          {t("AddOption")}
                        </Button>
                      </div>
                    )}
                    {question.type === "Device" && (
                      <div>
                        <div
                          style={{
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ justifyContent: "center" }}
                          >
                            <Grid size={{ xs: 12, md: 6 }}>
                              <FormControl
                                fullWidth
                                style={{ marginBlock: "1em" }}
                              >
                                <InputLabel id="device-label">
                                  {t("Device")}
                                </InputLabel>
                                <Select
                                  required
                                  labelId="device-label"
                                  id="device"
                                  value={question.deviceId || ""}
                                  label={t("Device")}
                                  onChange={(event) =>
                                    handleDevice(
                                      question.id,
                                      event.target.value as number
                                    )
                                  }
                                  disabled={loadedQuestions.some(
                                    (loadedQuestion) =>
                                      loadedQuestion.id === question.id
                                  )}
                                >
                                  {autoRead && (
                                    <MenuItem
                                      key={-1}
                                      value={-1}
                                      style={{ background: "#D8CDF2" }}
                                    >
                                      {t("ReadAutomatically")}
                                    </MenuItem>
                                  )}
                                  {devices?.map(
                                    (device) =>
                                      device.name && (
                                        <MenuItem
                                          key={device.id}
                                          value={device.id}
                                        >
                                          {device.name}
                                        </MenuItem>
                                      )
                                  ) || []}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleRemoveQuestion(question.id)}
                      style={{ margin: "auto", display: "flex" }}
                    >
                      {t("RemoveQuestion")}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "2em",
              }}
            >
              <FormControl>
                <InputLabel>Add question</InputLabel>
                <Select
                  value={selectedType}
                  onChange={handleOptionChange}
                  style={{ minWidth: "11em" }}
                >
                  <MenuItem value="Text">{t("Text")}</MenuItem>
                  <MenuItem value="Number">{t("Number")}</MenuItem>
                  <MenuItem value="Options">{t("Options")}</MenuItem>
                  <MenuItem value="MultiOptions">{t("MultiOptions")}</MenuItem>
                  <MenuItem value="Image">{t("Image")}</MenuItem>
                  <MenuItem value="Device">{t("Device")}</MenuItem>
                  <MenuItem value="Time">{t("TimeQuestion")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {order.length > 0 && (
            <>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                }}
              >
                <Card
                  key={"drag-and-drop"}
                  style={{
                    margin: 10,
                    minWidth: 400,
                    boxShadow: "0px 0px 30px 0px rgba(224,224,224,1)",
                    backgroundColor: "#fcfcfc",
                    padding: 10,
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 10,
                      }}
                    >
                      {t("OrderQuestions")}
                    </Typography>
                    <DragAndDrop items={order} setItems={setOrder} />
                  </CardContent>
                </Card>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setButtonDisabled(true);
                    try {
                      props.handleSaveForm(
                        formName,
                        formDescription,
                        formTitle,
                        nameDeviceButton,
                        formPatientDescription,
                        currentLanguage,
                        questions,
                        props.formId ? props.formId : 0,
                        deviceId,
                        props.formId ? patch : add,
                        props.formId ? "update" : "add",
                        navigate,
                        t,
                        uuidForm ? uuidForm : undefined,
                        order
                      );
                    } catch (err: any) {
                      setButtonDisabled(false);
                      throw err;
                    }
                    setButtonDisabled(false);
                  }}
                  disabled={buttonDisabled}
                >
                  {t("SaveForm")}
                </Button>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export { FormComponent };
