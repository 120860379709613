import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { DoctorListGridColumns } from "./DoctorListDataGridColumns";
import { ItemDoctorListDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { GridRowClassNameParams } from "@mui/x-data-grid";

interface Props {
  onEdit: (doctor: ItemDoctorListDto) => void;
  onDelete: (doctor: ItemDoctorListDto) => void;
  onSendSms: (doctor: ItemDoctorListDto) => void;
  onSendEmail: React.Dispatch<
    React.SetStateAction<{ id: number; name: string; email?: string } | null>
  >;
  onRestore: (doctor: ItemDoctorListDto) => void;
  quantityDeleted: (quantity: number) => void;
  doctors: ItemDoctorListDto[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  doctorsPaginationInfo: any;
}

const DoctorList = ({
  onDelete,
  onEdit,
  onSendSms,
  onSendEmail,
  onRestore,
  quantityDeleted,
  doctors,
  setPage,
  setPageSize,
  doctorsPaginationInfo,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { role } = useApi();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const iamAdmin = role === Role.admin;
  const iamDoctor = role === Role.doctor;
  const iamNurse = role === Role.nurse;
  const iamTeam = iamNurse || iamAdmin || iamDoctor;

  const actions = (doctor: ItemDoctorListDto) => {
    const onClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(`m${doctor.id}`);
    };

    const onClose = () => {
      setAnchorEl(null);
      setPopoverId("");
    };

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>

        <Popover
          id={`m${doctor.id}`}
          open={popoverId === `m${doctor.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            {!doctor.deletedAt && (
              <>
                <ListItemButton
                  onClick={() => {
                    onClose();
                    onEdit(doctor);
                  }}
                >
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={`${t('ButtonEdit')} ${doctor?.role === Role.doctor ? t('FieldDoctor') : t('FieldPatient')}`} /> */}
                  <ListItemText
                    primary={`${t("ButtonEdit")} ${t("FieldDoctor")}`}
                  />
                </ListItemButton>

                <ListItemButton
                  disabled={!doctor.haveEmail}
                  onClick={() => {
                    onClose();
                    onSendSms(doctor);
                  }}
                >
                  <ListItemIcon>
                    <TextsmsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendSMS")} />
                </ListItemButton>

                <ListItemButton
                  disabled={!doctor.email}
                  onClick={() => {
                    onClose();
                    onSendEmail({
                      id: doctor.id,
                      name: doctor.name,
                      email: doctor.email,
                    });
                  }}
                >
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("UserListSendEmail")} />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    onClose();
                    onDelete(doctor);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={`${t('ButtonDelete')} ${doctor?.role === Role.doctor ? t('FieldDoctor') : t('FieldPatient')}`} /> */}
                  <ListItemText
                    primary={`${t("ButtonDelete")} ${t("FieldDoctor")}`}
                  />
                </ListItemButton>
              </>
            )}

            {iamTeam && doctor.deletedAt && (
              <ListItemButton
                onClick={() => {
                  onClose();
                  onRestore(doctor);
                }}
              >
                <ListItemIcon>
                  <RestoreFromTrashOutlinedIcon />
                </ListItemIcon>
                {/* <ListItemText primary={`${t('ButtonRestore')} ${doctor?.role === Role.doctor ? t('FieldDoctor') : t('FieldPatient')}`} /> */}
                <ListItemText
                  primary={`${t("ButtonRestore")} ${t("FieldDoctor")}`}
                />
              </ListItemButton>
            )}
          </List>
        </Popover>
      </>
    );
  };

  if (!doctors) return <></>;
  quantityDeleted(doctors.filter((x) => x.deletedAt).length);

  const datagridColumns = DoctorListGridColumns(
    t,
    onEdit,
    onDelete,
    onRestore,
    actions
  );

  const setRowClassName = (
    params: GridRowClassNameParams<ItemDoctorListDto>
  ): string => {
    if (params.row.deletedAt) return "super-app-theme--Rejected";
    return "";
  };

  return (
    <FlexLayoutGrid
      language={i18n.language}
      columns={datagridColumns as any}
      rows={doctors}
      setRowClassName={setRowClassName as any}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      pagination={doctorsPaginationInfo}
      sx={DoctomaticStylingRowsGrid()}
    />
  );
};

export { DoctorList };
