import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  CreateFrequencyRequestDto,
  GetFrequencyResponseDto,
  Weekday,
} from "@doctomatic/sdk/build/dto/Frequencies";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useApi } from "@doctomatic/sdk/build/Api";
import { useLoading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { Frequency } from "@doctomatic/components-react/build/Frequencies/Frequency";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { processError } from "../../../App/errorToast";
import "moment-timezone";
import moment from "moment-timezone";
import { getDeviceName } from "../utils";
import { useNavigate } from "react-router-dom";
import { GetTreatmentResponseDto } from "@doctomatic/sdk/build/dto/Treatment/Treatments";

const minStringToNumbers = (min: string): number[] =>
  min.split(",").map((value: string) => Number(value));

export interface PatientDetail {
  id: number;
  name: string;
  treatments: GetTreatmentResponseDto[];
  timezone?: string;
}
interface Props {
  open: boolean;
  patient: PatientDetail;
  onClose: () => void;
  afterSave: () => Promise<void>;
}

const UserFrequency = ({
  open,
  patient,
  onClose,
  afterSave,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const week: { [key: string]: string } = {
    [Weekday.Monday]: t("Monday"),
    [Weekday.Tuesday]: t("Tuesday"),
    [Weekday.Wednesday]: t("Wednesday"),
    [Weekday.Thursday]: t("Thursday"),
    [Weekday.Friday]: t("Friday"),
    [Weekday.Saturday]: t("Saturday"),
    [Weekday.Sunday]: t("Sunday"),
  };
  const navigate = useNavigate();
  const { useFrequencies, useProfile, logout } = useApi();
  const { setIsLoading } = useLoading();
  const { response: profileResponse } = useProfile(
    false,
    true,
    processError(logout, navigate, t)
  );
  const profile = profileResponse?.data;

  const {
    response: frequenciesResponse,
    put,
    del,
  } = useFrequencies(patient.id, true);
  const [frequencies, setFrequencies] = useState<GetFrequencyResponseDto[]>(
    frequenciesResponse?.data ?? []
  );
  const [initialFrequencies, setInitialFrequencies] = useState<
    GetFrequencyResponseDto[]
  >(frequenciesResponse?.data ?? []);

  const [errors, setErrors] = useState<{ [id: string]: boolean }>({});
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    if (frequenciesResponse) {
      setFrequencies(frequenciesResponse?.data ?? []);
      setInitialFrequencies(frequenciesResponse?.data ?? []);
    }
  }, [patient, frequenciesResponse]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const devices: GetDeviceResponseDto[] = useMemo<
    GetDeviceResponseDto[]
  >(() => {
    let deviceDtos: GetDeviceResponseDto[] = [];
    if (patient) {
      for (const treatment of patient.treatments) {
        if (treatment.devices) {
          for (const device of treatment.devices) {
            if (device.typeName !== "Form") {
              if (
                !deviceDtos.find(
                  (d: GetDeviceResponseDto) => d.id === device.id
                )
              ) {
                deviceDtos.push(device);
              }
            }
          }
        }
      }
    }
    return deviceDtos;
  }, [patient, patient.treatments]);

  const handleSave = async () => {
    const deleteFrequencies = initialFrequencies.filter(
      (f) =>
        !frequencies
          .map((x) => {
            return x.id;
          })
          .includes(f.id)
    );

    let updateFrequencies: GetFrequencyResponseDto[] = [];
    frequencies.forEach((f) => {
      const existing = initialFrequencies.find((x) => x.id === f.id);
      if (!existing) {
        updateFrequencies.push(f);
      } else if (existing.min !== f.min || existing.weekdays !== f.weekdays) {
        updateFrequencies.push(f);
      }
    });

    // Update frequencies
    if (updateFrequencies.length > 0) {
      try {
        let parsedFrequencies: CreateFrequencyRequestDto[] = [];
        updateFrequencies.forEach((f) => {
          const dto = new CreateFrequencyRequestDto();
          dto.deviceId = f.device?.id || 0;
          dto.weekdays = f.weekdays;
          dto.min = f.min;
          parsedFrequencies.push(dto);
        });

        await put(patient.id, parsedFrequencies);
        toast.success(`${t("FrequencySaved")}`);
      } catch (errUpdate: any) {
        toast.error(
          `${t("FrequencyError")}: ${
            errUpdate.response?.data?.message || errUpdate.message
          }`
        );
      }
    }

    // Delete frequencies
    let deleteErrors: GetDeviceResponseDto[] = [];
    deleteFrequencies.forEach(async (f) => {
      if (f.device) {
        try {
          await del(patient.id, f.device.id);
        } catch (errDelete) {
          deleteErrors.push(devices.find((d) => d.id === f.device?.id)!);
        }
      }
    });
    if (deleteErrors.length > 0) {
      toast.error(
        `${t("FrequencyErrorDelete")}: ${deleteErrors
          .map((d) => getDeviceName(currentLanguage, d, t))
          .join(",")}`
      );
    } else if (deleteFrequencies.length > 0) {
      toast.success(`${t("FrequencyDeleted")}`);
    }
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>{t("FrequencyTitle", { name: patient.name })}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table style={{ tableLayout: "fixed", width: "1280px" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "100px", paddingLeft: 0 }}>
                  {t("TableDevice")}
                </TableCell>
                <TableCell style={{ width: "200px" }}>
                  {t("TableFrequency")}
                </TableCell>
                <TableCell style={{ width: "680px", padding: "16px 8px" }}>
                  {t("TableHours")}
                </TableCell>
                <TableCell style={{ width: "200px", padding: 0 }}>
                  {t("TableNextNotification")}
                </TableCell>
                <TableCell style={{ width: "100px", padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices &&
                devices.map((device: GetDeviceResponseDto, index: number) => {
                  const frequency: GetFrequencyResponseDto | undefined =
                    frequencies.find(
                      (f) => f.device && f.device.id === device.id
                    );

                  const initialWeekdays: string[] =
                    frequency?.weekdays && frequency.weekdays !== ""
                      ? frequency.weekdays.split(",")
                      : [];
                  const initialMin: number[] =
                    frequency?.min && frequency.min !== ""
                      ? minStringToNumbers(frequency.min)
                      : [0];

                  return (
                    <TableRow key={`d${device.id}`}>
                      <TableCell style={{ paddingLeft: 0, height: "81px" }}>
                        {getDeviceName(currentLanguage, device, t)}
                      </TableCell>
                      {frequency ? (
                        <>
                          <Frequency
                            initialWeekdays={initialWeekdays}
                            initialMin={initialMin}
                            week={week}
                            onChange={async (d: string[], m: number[]) => {
                              let freq: GetFrequencyResponseDto =
                                new GetFrequencyResponseDto();
                              freq = {
                                ...frequency,
                                device: { ...device } as GetDeviceResponseDto,
                                weekdays:
                                  d.length > 1 ? d.join(",") : d[0] ?? "",
                                min: m.length > 1 ? m.join(",") : String(m[0]),
                              } as GetFrequencyResponseDto;
                              setFrequencies([
                                ...frequencies.filter(
                                  (f) => f.device?.id !== device.id
                                ),
                                freq,
                              ]);
                            }}
                            onValidate={(val: boolean, msg: string) => {
                              setErrors({ ...errors, [device.id]: !val });
                            }}
                            translator={t}
                          />
                          <TableCell style={{ padding: 0 }}>
                            <Box display="flex">
                              <Tooltip
                                placement="top"
                                title={t("FrequencyDelete") || ""}
                              >
                                <IconButton
                                  style={{ padding: "8px" }}
                                  onClick={async () => {
                                    setFrequencies([
                                      ...frequencies.filter(
                                        (f) => f.device?.id !== device.id
                                      ),
                                    ]);

                                    const { [device.id]: _, ...newErrors } =
                                      errors;
                                    setErrors(newErrors);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell style={{ padding: "16px 8px" }}>
                            {frequency.id ? (
                              <Tooltip
                                title={
                                  <React.Fragment>
                                    <p>
                                      {moment(frequency.nextNotificationAt)
                                        .tz(patient.timezone || "")
                                        .format("DD/MM/yyyy HH:mm")}{" "}
                                      ({patient.timezone})
                                    </p>
                                    {profile &&
                                      patient.timezone !== profile.timezone && (
                                        <p>
                                          {moment(
                                            frequency.nextNotificationAt
                                          ).format("DD/MM/yyyy HH:mm")}{" "}
                                          ({profile.timezone})
                                        </p>
                                      )}
                                    <p>
                                      {moment(frequency.nextNotificationAt)
                                        .utc()
                                        .format("DD/MM/yyyy HH:mm")}{" "}
                                      (UTC)
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                <p>
                                  {moment(frequency.nextNotificationAt)
                                    .tz(patient.timezone || "")
                                    .format("DD/MM/yyyy HH:mm")}
                                </p>
                              </Tooltip>
                            ) : (
                              <p>-</p>
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <Button
                            variant="text"
                            startIcon={<AddCircleIcon />}
                            onClick={async () => {
                              let f = new GetFrequencyResponseDto();
                              f.device = device;
                              f.weekdays = "";
                              f.min = "0";
                              setFrequencies([...frequencies, f]);
                            }}
                          >
                            {t("FrequencyAdd")}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button
              onClick={async () => {
                onClose();
              }}
              size="small"
            >
              {t("ButtonCancel")}
            </Button>
          </Box>
          <Button
            disabled={Object.values(errors).includes(true)}
            variant="contained"
            size="small"
            onClick={async () => {
              setIsLoading(true);
              await handleSave();

              await afterSave();
              setIsLoading(false);
            }}
          >
            {t("ButtonSave")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { UserFrequency };
