import { Expose } from "class-transformer";
import { IsNotEmpty, IsOptional } from "class-validator";
import { BasicItem, IBasicItem } from "../../IItemList";
import {
  IAddAlertConfigRequestDto,
  IAddAlertConfigResponseDto,
  IAlertConfigBasicDto,
  IGetAlertConfigResponseDto,
  IItemAlertConfigDto,
  IUpdateAlertConfigRequestDto,
  IUpdateAlertConfigResponseDto
} from "./IAlertConfig";

export class AlertConfigBasicDto implements IAlertConfigBasicDto {
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
}

export class AlertConfigDto extends AlertConfigBasicDto {
  @Expose() createdBy: BasicItem = new BasicItem();
  @Expose() company: BasicItem = new BasicItem();
  @Expose() created_at: Date = new Date();
  @Expose() updated_at: Date = new Date();
  @Expose() deleted_at?: Date;
  @Expose() active?: boolean;
}

/** ADD */
export class AddAlertConfigRequestDto
  implements IAddAlertConfigRequestDto {
  @Expose() @IsOptional() label?: string;
  @Expose() color: string = "";
}

export class AddAlertConfigResponseDto extends AlertConfigDto
  implements IAddAlertConfigResponseDto {
}

/** UPDATE */
export class UpdateAlertConfigRequestDto
  implements IUpdateAlertConfigRequestDto {
  @Expose() @IsOptional() color?: string;
  @Expose() @IsOptional() label?: string;
  @Expose() @IsOptional() active?: boolean;
}

export class UpdateAlertConfigResponseDto extends AlertConfigDto
  implements IUpdateAlertConfigResponseDto {
}

/** GET */
export class GetAlertConfigResponseDto extends AlertConfigDto
  implements IGetAlertConfigResponseDto {
}

export class ItemAlertConfigDto
  implements IItemAlertConfigDto {
  @Expose() id: number = 0;
  @Expose() label: string = "";
  @Expose() color: string = "";
  @Expose() alertGroups: IBasicItem[] = [];
  @Expose() active: boolean = true;
}


