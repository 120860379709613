import { GridColDef } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { ItemDoctorListDto } from "@doctomatic/sdk/build/dto/Actors/Doctor/Doctor";
import { Link } from "react-router-dom";

export const DoctorListGridColumns = (
  t: (text: string) => string,
  onEdit: (user: ItemDoctorListDto) => void,
  onDelete: (user: ItemDoctorListDto) => void,
  onRestore: (user: ItemDoctorListDto) => void,
  actions: (user: ItemDoctorListDto) => JSX.Element
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("HeaderName"),
      width: 200,
      align: "left",
    },
    {
      field: "locations",
      headerName: t("HeaderLocations"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        let strings: string[];
        if (row.locations && row.locations.length > 0)
          strings = row.locations.map((x: { name: string }) => x.name);
        else strings = [t("GoToLocations")];
        return strings.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.locations && params.row.locations.length > 0)
          strings = params.row.locations.map((x: { name: string }) => x.name);
        else strings = [t("GoToLocations")];
        return (
          <TextWordsQuantityCell
            item={params.row}
            routerLink={Link}
            page={BreadcrumbNameMap.LocationsUser.url
              .replace(":role", "doctors")
              .replace(":actorId", params.row.id.toString())}
            words={strings}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "groupPermissions",
      headerName: t("HeaderGroupPermissions"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        let strings: string[];
        if (row.groupPermissions && row.groupPermissions.length > 0)
          strings = row.groupPermissions.map((x: { name: string }) => x.name);
        else strings = [t("Go_to_groupPermissions")];
        return strings.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (
          params.row.groupPermissions &&
          params.row.groupPermissions.length > 0
        )
          strings = params.row.groupPermissions.map(
            (x: { name: string }) => x.name
          );
        else strings = [t("Go_to_groupPermissions")];

        return (
          <TextWordsQuantityCell
            item={params.row}
            routerLink={Link}
            page={BreadcrumbNameMap.GroupPermissionsDoctor.url.replace(
              ":actorId",
              params.row.id.toString()
            )}
            words={strings}
          />
        );
      },
    },
    {
      field: "permissions",
      headerName: t("HeaderPermissions"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => {
        let strings: string[];
        if (row.permissions && row.permissions.length > 0)
          strings = row.permissions.map((x: { name: string }) => x.name);
        else strings = [t("Go_to_permissions")];
        return strings.join(", ");
      },
      sortable: false,
      renderCell: (params) => {
        let strings: string[];
        if (params.row.permissions && params.row.permissions.length > 0)
          strings = params.row.permissions.map((x: { name: string }) => x.name);
        else strings = [t("Go_to_permissions")];

        return (
          <TextWordsQuantityCell
            item={params.row}
            routerLink={Link}
            page={BreadcrumbNameMap.GroupPermissionsDoctor.url.replace(
              ":actorId",
              params.row.id.toString()
            )}
            words={strings}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => actions(params.row),
    },
  ];
};
