import { Expose } from "class-transformer";
import { EventAction, EventObject } from "./Events";

export interface ICreateWebhookRequestDto {
  endpoint: string;
  eventAction: EventAction;
  eventObject: EventObject;
  //counter: number;
  //banTime?: Date;
}

export class CreateWebhookRequestDto implements ICreateWebhookRequestDto {
  counter: number = 0;
  banTime?: Date;
  endpoint: string = "";
  eventAction: EventAction = EventAction.all;
  eventObject: EventObject = EventObject.all;
}

export interface ICreateWebhookResponseDto {
  id: number;
  endpoint: string;
  eventAction?: EventAction;
  eventObject?: EventObject;
  companyId: number;
  counter: number;
  banTime?: Date;
  message: string;
  createdAt: Date;
}

export class CreateWebhookResponseDto implements ICreateWebhookResponseDto {
  counter: number = 0;
  banTime?: Date;
  id: number = 0;
  endpoint: string = "";
  eventAction?: EventAction;
  eventObject?: EventObject;
  companyId: number = 0;
  message: string = "";
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
}

export interface IGetWebhookResponseDto {
  id: number;
  endpoint: string;
  eventAction?: EventAction;
  eventObject?: EventObject;
  companyId: number;
  counter: number;
  banTime?: Date;
  createdAt: Date;
  deletedAt?: Date;
}

export class GetWebhookResponseDto implements IGetWebhookResponseDto {
  counter: number = 0;
  banTime?: Date;
  id: number = 0;
  endpoint: string = "";
  eventAction?: EventAction;
  eventObject?: EventObject;
  companyId: number = 0;
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
}

export interface IUpdateWebhookRequestDto {
  counter: number;
  banTime?: Date;
}

export class UpdateWebhookRequestDto implements IUpdateWebhookRequestDto {
  counter: number = 0;
  banTime?: Date;
}
