import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Question } from "./FormInterface";
import { TFunction } from "i18next";
import { Item } from "./FormComponent";

export const handleSaveForm = async (
  formName: string,
  formDescription: string,
  formTitle: string,
  nameDeviceButton: string,
  formPatientDescription: string,
  currentLanguage: string,
  questions: Question[],
  formId: number,
  deviceId: number,
  functionApi: any,
  functionApiName: string,
  navigate: any,
  t: TFunction,
  uuidForm?: string,
  order?: Item[]
) => {
  const orderedQuestions = order
    ? (order
        .map((item) => questions.find((q) => q.id === item.id))
        .filter((q) => q !== undefined) as Question[])
    : questions;

  const formData = {
    id: uuidForm ? uuidForm : uuidv4(),
    deviceId: deviceId,
    formId: formId,
    formName: formName,
    deviceName: [
      {
        lang: currentLanguage,
        text: nameDeviceButton,
      },
    ],
    formTitle: [
      {
        lang: currentLanguage,
        text: formTitle,
      },
    ],
    formDescription: [
      {
        lang: currentLanguage,
        text: formPatientDescription,
      },
    ],
    questions: orderedQuestions.map((question, index) => {
      const { id, type, text, range, options, deviceId } = question;

      const questionId = id ? id : uuidv4();
      const questionType =
        type === "Text"
          ? "text"
          : type === "Number"
          ? "number"
          : type === "Options"
          ? "radio"
          : type === "MultiOptions"
          ? "multiRadio"
          : type === "Device"
          ? "device"
          : type === "Time"
          ? "time"
          : "image";
      const optionsForm =
        questionType === "radio" || questionType === "multiRadio"
          ? options?.options.map((option, index) => ({
              id: option.id ? option.id : uuidv4(),
              text: [
                {
                  lang: currentLanguage,
                  text: option.text,
                },
              ],
              value: option.value,
              order: index + 1,
              textValue: option.text,
              backgroundColor: option.color,
              isTextValueEditable: false,
              isValueEditable: false,
            }))
          : questionType === "text" ||
            questionType === "image" ||
            questionType === "device" ||
            questionType === "number"
          ? [
              {
                id: uuidv4(),
                text: [
                  {
                    lang: currentLanguage,
                    text: text,
                  },
                ],
                value: 1,
                order: 1,
                textValue: "",
                backgroundColor: "",
                isTextValueEditable: true,
                isValueEditable: false,
              },
            ]
          : [];

      const max =
        questionType === "number"
          ? question.range?.max
          : questionType === "radio" || questionType === "multiRadio"
          ? Math.max(
              ...(question.options?.options ?? []).map(
                (option) => option.value
              ),
              0
            )
          : 0;

      const min =
        questionType === "number"
          ? question.range?.min
          : questionType === "radio" || questionType === "multiRadio"
          ? Math.min(
              ...(question.options?.options ?? []).map(
                (option) => option.value
              ),
              0
            )
          : 100;

      const questionObject = {
        id: questionId,
        order: index,
        signId: [0],
        unit: [
          {
            lang: currentLanguage,
            text: range?.unit ? range.unit : "question",
          },
        ],
        typeQuestion: questionType,
        text: [
          {
            lang: currentLanguage,
            text: text,
          },
        ],
        sign: [
          {
            name: `question${index}`,
            unit: question.range?.unit ? question.range.unit : "question",
            decimals: question.range?.decimals ? question.range.decimals : 0,
            max: max,
            min: min,
            defaultMax: max,
            defaultMin: min,
            typeSign: questionType,
          },
        ],
        options: optionsForm,
        deviceId: questionType === "device" && deviceId ? deviceId : 0,
      };

      return questionObject;
    }),
  };

  const json = JSON.stringify(formData, null, 2);
  const dto = {
    name: formName,
    description: formDescription,
    json: json,
    draftMode: false,
  };

  let response = null;
  if (functionApiName === "add") {
    response = await functionApi(dto);
  } else {
    response = await functionApi(formId, dto);
  }

  if (response.success) {
    toast.success(t("FormSavedSuccess"));
    navigate("/forms");
  } else {
    toast.error(t("ErrorSavingForm"));
  }
};
