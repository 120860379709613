import { GridColDef } from "@mui/x-data-grid";
import { AlertConfigByNoMeasureDataRow } from "../AlertConfigTemplatesDataRow";
import { sortText } from "../../../../../App/sortFunctions";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Tooltip } from "@mui/material";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { getDeviceName } from "../../../utils";

export const AlertConfigByNoMeasureTemplateGridColumns = (
  t: (text: string) => string,
  onEdit: (alertConfigTemplate: AlertConfigByNoMeasureDataRow) => void,
  onActivate: (alertConfigTemplate: AlertConfigByNoMeasureDataRow) => void,
  currentLanguage: string
): GridColDef[] => {
  return [
    {
      field: "device",
      headerName: t("Device"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => t(row.device),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{getDeviceName(currentLanguage, params.row.device, t)}</p>;
      },
    },
    {
      field: "label",
      headerName: t("Name"),
      width: 200,
      align: "left",
    },
    {
      field: "max_time_between",
      headerName: t("AlertAfter"),
      width: 100,
      valueGetter: (value: any, row: any) => row.max_time_between,
      sortComparator: (v1: any, v2: any) =>
        v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return <p>{params.row.max_time_between}</p>;
      },
    },
    {
      field: "color",
      headerName: t("Level"),
      width: 100,
      valueGetter: (value: any, row: any) => row.color,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => (
        <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
      ),
    },
    {
      field: "active",
      headerName: t("ActiveColumn"),
      width: 100,
      valueGetter: (value: any, row: any) => row.active,
      renderCell: (params) => {
        return <p>{params.row.active ? t("Active") : t("Deactive")}</p>;
      },
    },
    {
      field: "iconEdit",
      headerName: t("AlertConfigEdit"),
      width: 100,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={t("ButtonEdit") || ""}>
            <IconButton aria-label="edit" onClick={() => onEdit(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "iconActivate",
      headerName: t("ActivateDeactivate"),
      width: 150,
      align: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.active ? t("Deactivate") : t("Activate")}>
            <IconButton
              aria-label="delete"
              onClick={() => onActivate(params.row)}
            >
              {params.row.active ? (
                <StopCircleIcon />
              ) : (
                <PlayCircleFilledIcon />
              )}
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
};
