import React from "react";
// Import React to prevent compiler errors
import { ViewAlertsCell } from "@doctomatic/components-react/build/Alerts/ViewAlertsCell";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { GridColDef } from "@mui/x-data-grid";
import AlertsByValueDataRow from "./AlertsByValueDataRow";
import { Checkbox } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { ElementGridStringEdit } from "@doctomatic/components-react/build/ElementColumn/ElementGridStringEdit";
import { sortText } from "../../../../App/sortFunctions";
import { getDeviceName, getSignName, toLocaleString } from "../../utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { Launch } from "@mui/icons-material";

export const AlertsByValueGridColumns = (
  t: (text: string) => string,
  showExternalId: boolean | undefined,
  timezone: string,
  currentLanguage: string,
  showImageModal: (item: AlertsByValueDataRow) => void,
  showModal?: (item: any) => void,
  showStateModal?: (id: number, item: any) => void,
  onSaveComments?: (
    alertId: number,
    comment: string | undefined
  ) => Promise<boolean>
): GridColDef[] => {
  const gridColumns: GridColDef[] = [
    {
      field: "color",
      headerName: t("Level"),
      width: 75,
      align: "center",
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.color,
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return (
          <ViewAlertsCell
            alerts={[{ id: params.row.id, color: params.row.color }]}
            row={undefined}
            viewModal={() => <div></div>}
          />
        );
      },
    },
    {
      field: "state",
      headerName: t("AlertState"),
      width: 120,
      align: "center",
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.state,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        const alertStateCapitalized =
          params.row.state[0].toUpperCase() +
          params.row.state.substring(1).toLowerCase();
        return (
          <TextWordsQuantityCell
            onClickModal={() =>
              showStateModal!(params.row.id, params.row.state)
            }
            item={params.row}
            words={[t(alertStateCapitalized)]}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "comments",
      headerName: t("Comments"),
      width: 95,
      align: "center",
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.comments,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return (
          <ElementGridStringEdit
            textCancel={t("Cancel")}
            textSave={t("Save")}
            textLabelField={t("Comments")}
            itemToAddCommentId={params.row.id}
            text={params.row.comments}
            onSave={
              onSaveComments
                ? onSaveComments
                : async (): Promise<boolean> => {
                    return true;
                  }
            }
            onClose={() => {}}
          />
        );
      },
    },
    {
      field: "patient",
      headerName: t("Patient"),
      width: 250,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.patient.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={[params.row.patient.name]}
            page={BreadcrumbNameMap.Patient.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "created_at",
      headerName: t("Date"),
      width: 150,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.created_at,
      sortComparator: (v1: any, v2: any) =>
        new Date(v2!).getTime() - new Date(v1!).getTime(),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return <div>{toLocaleString(params.row.created_at)}</div>;
      },
    },
    {
      field: "sign",
      headerName: t("HeaderSign"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.sign?.name,
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return <div>{getSignName(currentLanguage, params.row.sign, t)}</div>;
      },
    },
    {
      field: "value",
      headerName: t("Value"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => {
        const words: string[] = [row.value];
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={[
              params.row.device.typeName === "Form"
                ? params.row.valueText
                  ? t(params.row.valueText)
                  : "--"
                : params.row.value,
            ]}
            page={`/measurements/value/${params.row.id}/${params.row.actorRole}/${params.row.actorId}`}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "image",
      headerName: t("Image"),
      width: 100,
      renderCell: (params: { row: AlertsByValueDataRow }) =>
        params.row.sign.device?.typeName !== "Form" ? (
          <IconButton
            onClick={() => showImageModal(params.row)}
            color="inherit"
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          "--"
        ),
    },
    {
      field: "typeAlert",
      headerName: t("HeaderLabel"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.label,
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        const alertConfigUrl = BreadcrumbNameMap.AlertConfigByValue.url.replace(
          ":patientId",
          params.row.patient.id.toString()
        );
        const content: JSX.Element = (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {params.row.individualConfig && params.row.individualConfig.id ? (
              <NavLink
                to={alertConfigUrl.concat(
                  `?alertConfigId=${params.row.individualConfig.id}`
                )}
              >
                <Launch />
              </NavLink>
            ) : params.row.templateConfig && params.row.templateConfig.id ? (
              <NavLink
                to={alertConfigUrl.concat(
                  `?alertConfigId=${params.row.templateConfig.id}`
                )}
              >
                <Launch />
              </NavLink>
            ) : (
              "--"
            )}
            <div style={{ marginLeft: 5 }}></div>
            <div>
              {params.row.device?.typeName === "Form" &&
              (params.row.label === "Yes" || params.row.label === "No")
                ? t(params.row.label)
                : params.row.label}
            </div>
          </div>
        );
        return content;
      },
    },
    {
      field: "sign.device.name",
      headerName: t("HeaderDevice"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.device.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        return <div>{getDeviceName(currentLanguage, params.row.device, t)}</div>;
      },
    },
    {
      field: "trackings",
      headerName: t("HeaderTracking"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => {
        const words = row.trackings.map((tracking: any) => tracking.name);
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        const trackingsName = params.row.trackings.map(
          (tracking) => tracking.name
        );
        return (
          <TextWordsQuantityCell
            tooltipContent={{ title: t("Trackings"), lines: trackingsName }}
            item={params.row}
            words={trackingsName}
            page={BreadcrumbNameMap.Trackings.url}
            routerLink={Link}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "active",
      headerName: t("ConfigAlert"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) =>
        row.individualConfig
          ? row.individualConfig.active
            ? t("Active")
            : t("Deactive")
          : row.templateConfig?.active
          ? t("Active")
          : t("Deactive"),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        const active = params.row.individualConfig
          ? params.row.individualConfig.active
          : params.row.templateConfig?.active;
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={[active ? t("Active") : t("Deactive")]}
            page={BreadcrumbNameMap.AlertConfigByValue.url.replace(
              ":patientId",
              params.row.patient.id.toString()
            )}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "ackBy",
      headerName: t("HeaderAckBy"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => {
        const texts = row.ackBy && row.ackBy.map((ack: any) => ack.user.name);
        if (texts.length === 0) texts.push("---");
        return texts.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => {
        const texts =
          params.row.ackBy && params.row.ackBy.map((ack) => ack.user.name);
        if (texts.length === 0) texts.push("---");

        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            words={texts}
            onClickModal={() => showModal!(params.row)}
            tooltipContent={{ title: t("AckBy"), lines: texts }}
          ></TextWordsQuantityCell>
        );
      },
    },
    {
      field: "setAck",
      headerName: t("HeaderActions"),
      width: 200,
      valueGetter: (value: any, row: AlertsByValueDataRow) => row.checkbox,
      sortable: false,
      renderCell: (params: { row: AlertsByValueDataRow }) => (
        <Checkbox
          checked={params.row.checkbox}
          disabled={params.row.checkbox}
          onChange={() => {
            params.row.onClickCheckbox(params.row);
          }}
        />
      ),
    },
  ];

  if (showExternalId) {
    gridColumns.unshift({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: AlertsByValueDataRow) =>
        row.patient.externalId ? row.patient.externalId : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: AlertsByValueDataRow }) => (
        <div>
          {params.row.patient.externalId ? params.row.patient.externalId : "--"}
        </div>
      ),
    });
  }

  return gridColumns;
};
