import { useNavigate, useParams } from "react-router";
import { CameraCore } from "./CameraCore";
import { useEffect, useState } from "react";
import { CreateImageResponseDto } from "@doctomatic/sdk/build/dto/Images";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { processError } from "../../../App/errorToast";
import { useApi } from "@doctomatic/sdk/build/Api";
import FormData from "form-data";
import { GetTypeDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { IItemDeviceList } from "@doctomatic/sdk/build/dto/IItemList";
import { DevicesList } from "../Devices/DevicesList";
import { useTranslation } from "react-i18next";
import { DescriptionModal } from "../../Description/DescriptionModal";

const formTypeName = "Form";
const read6MWTName = "ReadSixMWT";
const MIN_CONFIDENCE = 0.95;

type IParams = {
  userId: string;
  deviceId?: string;
};

export const Camera = (): React.ReactElement => {
  const { userId, deviceId: paramDeviceId } = useParams<IParams>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { logout, useDevices, useImages, usePatient, useCompany, companyId } =
    useApi();
  const { getDeviceType } = useDevices(
    false,
    processError(logout, navigate, t)
  );

  const { findOneWithDevicesAndMeasurements } = usePatient(
    parseInt(userId as string),
    false,
    processError(logout, navigate, t)
  );
  const { response: company } = useCompany(companyId, true);

  const { create } = useImages(false, processError(logout, navigate, t));

  const [bgColor, setBgColor] = useState<string>("");
  const [buttonColor, setButtonColor] = useState<string>("");
  const [parametersUrl, setParametersUrl] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayDevices, setDisplayDevices] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [devices, setDevices] = useState<IItemDeviceList[]>([]);
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  // Processing image
  const [createImageResponseDto, setCreateImageResponseDto] =
    useState<CreateImageResponseDto | null>(null);
  const [takenPhoto, setTakenPhoto] = useState<Blob | undefined>();
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [openDescription, setOpenDescription] = useState<boolean>(false);

  const fetchDevices = async () => {
    setDevicesLoading(true);
    // Get devices by user
    const userResponse = await findOneWithDevicesAndMeasurements();
    if (userResponse?.data) {
      const parsedDevices: IItemDeviceList[] =
        userResponse.data.treatmentDevices
          .map((td) => {
            return td.device;
          })
          .filter(
            (x) => x.typeName !== formTypeName && x.name !== read6MWTName
          );
      setDevices(parsedDevices);
    }
    setDevicesLoading(false);
  };

  useEffect(() => {
    fetchDevices();
  }, [userId]);

  useEffect(() => {
    if (company?.data?.buttonColor) {
      setButtonColor(company?.data?.buttonColor);
    }

    if (company?.data?.parametersUrl) {
      setParametersUrl(company?.data?.parametersUrl);
      const param = JSON.parse(company?.data?.parametersUrl);

      if (param?.buttonColor) {
        setButtonColor(param?.buttonColor);
      }

      if (param?.bgColor) {
        setBgColor(param?.bgColor);
      }
    }
  }, [company]);

  const handleGetMeasurements = async (photo: Blob, deviceId: number) => {
    //TO BE REPLACED WITH CreateImageResponseDto ON 4.0.0
    const formData = new FormData();
    formData.append("file", photo, `${Date.now().toString()}.jpg`);
    const sendImageResponse = await create(
      deviceId,
      formData,
      parseInt(userId as string),
      description
    );

    //TO BE REPLACED WITH .data ON 4.0.0
    let data = "";
    if (sendImageResponse && sendImageResponse.additional_data) {
      const proposedMeasurements =
        sendImageResponse.additional_data.proposedMeasurements;
      data = proposedMeasurements ? proposedMeasurements.join(",") : "";
    }
    setCreateImageResponseDto(
      sendImageResponse?.additional_data
        ? sendImageResponse.additional_data
        : null
    );
    window.parent.postMessage(data, "*");
  };

  const handleNoDeviceProposed = async () => {
    // Get devices by user
    setDeviceId(devices[0].id.toString());
    // Display devices list
    setDisplayDevices(true);
  };

  const handleGetDevice = async (photo: Blob) => {
    const formData = new FormData();
    formData.append("file", photo, `${Date.now().toString()}.jpg`);
    const proposedDevicesResponse: ResponseApi<GetTypeDeviceResponseDto[]> =
      await getDeviceType(formData);
    if (
      proposedDevicesResponse?.data &&
      proposedDevicesResponse.data.length > 0
    ) {
      const firstProposed = proposedDevicesResponse.data[0];
      if (firstProposed.confidence > MIN_CONFIDENCE) {
        const device = devices.find((x) => x.name === firstProposed.name);
        if (device) {
          const id = device.id;
          setDeviceId(id.toString());
          await handleGetMeasurements(photo, +id);
        } else {
          await handleNoDeviceProposed();
        }
      } else {
        await handleNoDeviceProposed();
      }
    } else {
      await handleNoDeviceProposed();
    }
  };

  const onTakePhoto = async (photo: Blob) => {
    setTakenPhoto(photo);
    if (deviceId) {
      await handleGetMeasurements(photo, +deviceId);
    } else {
      await handleGetDevice(photo);
    }
  };

  const handleSelectedDevice = async (id: number) => {
    if (id) {
      setDeviceId(id.toString());
      setDisplayDevices(false);
      if (takenPhoto && deviceId) {
        setIsProcessing(true);
        await handleGetMeasurements(takenPhoto, +deviceId);
      }
      setIsProcessing(false);
    }
  };

  const handleDescriptionModal = (text: string | undefined) => {
    if (text && text.length === 0) {
      setDescription(undefined);
    } else {
      setDescription(text);
    }
    setOpenDescription(false);
  };

  const devicesSelector = devices && devices.length > 0 && (
    <DevicesList
      loading={devicesLoading}
      devices={devices}
      displayOnlyDevices={true}
      autoRead={false}
      handleOnClickDevice={handleSelectedDevice}
      buttonColor={buttonColor}
      fromAutoRead={true}
    />
  );

  return (
    <>
      {displayDevices ? (
        devicesSelector
      ) : (
        <>
          <CameraCore
            userId={userId as string}
            deviceId={deviceId}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            onTakePhoto={onTakePhoto}
            createImageResponseDto={createImageResponseDto}
            setCreateImageResponseDto={setCreateImageResponseDto}
            takenPhoto={takenPhoto}
            bgColor={bgColor}
            parametersUrl={parametersUrl}
            setOpenDescription={setOpenDescription}
          />
          <DescriptionModal
            open={openDescription}
            onSave={handleDescriptionModal}
            onClose={() => setOpenDescription(false)}
            description={description}
          />
        </>
      )}
    </>
  );
};
