import { SelectMultipleGridColumnsProps } from "@doctomatic/components-react/build/SelectModal/SelectMultipleGridColumns";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import { sortText } from "../../../../App/sortFunctions";
import { ItemAlertConfigByValueTemplateDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByValueTemplate";
import { getDeviceName, getSignName } from "../../utils";

export const AlertConfigByValueTemplateListDataGridColumns = (
  t: (text: string) => string,
  selectEvent: (
    dataRow: ItemAlertConfigByValueTemplateDto,
    checked: boolean
  ) => void,
  selectedItems: ItemAlertConfigByValueTemplateDto[],
  currentLanguage: string
): SelectMultipleGridColumnsProps<ItemAlertConfigByValueTemplateDto> => {
  return {
    columns: [
      {
        field: "device",
        headerName: t("Device"),
        width: 200,
        align: "left",
        valueGetter: (value: any, row: any) => t(row.device),
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => {
          return (
            <div>{getDeviceName(currentLanguage, params.row.sign?.device, t)}</div>
          );
        },
      },
      {
        field: "sign",
        headerName: t("Sign"),
        width: 100,
        valueGetter: (value: any, row: any) => t(row.sign),
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => {
          return <div>{getSignName(currentLanguage, params.row.sign, t)}</div>;
        },
      },
      {
        field: "color",
        headerName: t("Level"),
        width: 100,
        valueGetter: (value: any, row: any) => row.color,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => (
          <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
        ),
      },
      {
        field: "description",
        headerName: t("Description"),
        width: 200,
        valueGetter: (value: any, row: any) => row.label ?? "--",
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => {
          return <div>{params.row.label ?? "--"}</div>;
        },
      },
      {
        field: "alert_type",
        headerName: t("AlertType"),
        width: 100,
        valueGetter: (value: any, row: any) =>
          row.alert_type ? t(row.alert_type) : "--",
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => {
          return (
            <div>{params.row.alert_type ? t(params.row.alert_type) : "--"}</div>
          );
        },
      },
      {
        field: "min",
        headerName: t("FieldMin"),
        width: 100,
        valueGetter: (value: any, row: any) => row.min ?? "--",
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params) => {
          return (
            <div>
              {params.row.sign.device.typeName === "Form" && params.row.min
                ? params.row.valueText
                  ? t(params.row.valueText)
                  : "--"
                : params.row.min ?? "--"}
            </div>
          );
        },
      },
      {
        field: "max",
        headerName: t("FieldMax"),
        width: 100,
        valueGetter: (value: any, row: any) => row.max ?? "--",
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params) => {
          return (
            <div>
              {params.row.sign.device.typeName === "Form" && params.row.max
                ? params.row.valueText
                  ? t(params.row.valueText)
                  : "--"
                : params.row.max ?? "--"}
            </div>
          );
        },
      },
      {
        field: "range_value",
        headerName: t("RangeValue"),
        width: 100,
        valueGetter: (value: any, row: any) => row.range_value ?? "--",
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params) => {
          return <div>{params.row.range_value ?? "--"}</div>;
        },
      },
      {
        field: "range_time",
        headerName: t("RangeTime"),
        width: 100,
        valueGetter: (value: any, row: any) => row.range_time ?? "--",
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params) => {
          return <div>{params.row.range_time ?? "--"}</div>;
        },
      },
    ],
    selectEvent: selectEvent,
    selectedItems: selectedItems,
  };
};
