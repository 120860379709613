import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Page } from "../../Page";
import { Box, Typography, Tab, Tabs, IconButton } from "@mui/material";
import { TabPanel } from "@doctomatic/components-react/build/Graphs/TabPanel";
import { useApi } from "@doctomatic/sdk/build/Api";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { useLocation } from "react-router-dom";
import { AlertConfigByValueTemplateList } from "./AlertConfigByValueList/AlertConfigByValueList";
import { AlertConfigByNoMeasureTemplateList } from "./AlertConfigByNoMeasureList/AlertConfigByNoMeasureList";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { AddCircle } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) => ({
  total: {
    marginRight: 16,
    padding: "4px 0",
    lineHeight: 1.75,
    fontSize: 16,
  },
  deleted: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "white",
    },
  },
  showDeletedLabel: {
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

const a11yProps = (index: number): { id: string; "aria-controls": string } => ({
  id: `tab-${index}`,
  "aria-controls": `tabpanel-${index}`,
});

const AlertConfigTemplates = (): React.ReactElement => {
  const { useAlertConfigsByValueTemplate, useAlertConfigsByNoMeasureTemplate } =
    useApi();

  // declaration of hooks to handle pagination
  const [pageSizeByValue, setPageSizeByValue] = useState<number>(10);
  const [pageByValue, setPageByValue] = useState<number>(1);

  // Store the pagination value
  // Update pagination when page or pageSize changes
  const paginationValue = useMemo(() => {
    return {
      page: pageByValue,
      limit: pageSizeByValue,
    };
  }, [pageByValue, pageSizeByValue]);

  // declaration of hooks to handle pagination
  const [pageSizeNoMeasure, setPageSizeNoMeasure] = useState<number>(10);
  const [pageNoMeasure, setPageNoMeasure] = useState<number>(1);

  // Store the pagination no measure
  // Update pagination when page or pageSize changes
  const paginationNoMeasure = useMemo(() => {
    return {
      page: pageNoMeasure,
      limit: pageSizeNoMeasure,
    };
  }, [pageNoMeasure, pageSizeNoMeasure]);

  const {
    alertConfigsByValue: alertConfigByValueResponse,
    add: addAlertConfigByValue,
    patch: patchAlertConfigByValue,
  } = useAlertConfigsByValueTemplate(paginationValue, true);
  const {
    response: alertConfigByNoMeasureResponse,
    add: addAlertConfigByNoMeasure,
    patch: patchAlertConfigByNoMeasure,
  } = useAlertConfigsByNoMeasureTemplate(paginationNoMeasure, true);
  const alertConfigByValue = alertConfigByValueResponse?.data;
  const alertConfigByNoMeasure = alertConfigByNoMeasureResponse?.data;
  const paginationByValueInfo = alertConfigByValueResponse?.meta;
  const paginationByNoMeasureInfo = alertConfigByNoMeasureResponse?.meta;
  const totalItemsByValue = paginationByValueInfo
    ? paginationByValueInfo.totalItems
    : 0;
  const totalItemsByNoMeasure = paginationByNoMeasureInfo
    ? paginationByNoMeasureInfo.totalItems
    : 0;

  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [alertConfigLength, setAlertConfigLength] = useState(0);

  const title = t("AlertConfigTemplatesTitle");

  const buttonsFx = (
    setAddAlertConfigModalOpen: Dispatch<SetStateAction<boolean>>
  ): JSX.Element => {
    return (
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
        <IconButton onClick={() => setAddAlertConfigModalOpen(true)}>
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      </Box>
    );
  };

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    value: any
  ): void => {
    setTab(+value);
  };

  useEffect(() => {
    if (tab === 0 && alertConfigByValue)
      setAlertConfigLength(totalItemsByValue);
    else if (tab === 1 && alertConfigByNoMeasure)
      setAlertConfigLength(totalItemsByNoMeasure);
    else setAlertConfigLength(0);
  }, [tab, alertConfigByValue, alertConfigByNoMeasure]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tabId = query.get("tab");
    if (tabId) {
      setTab(+tabId);
    }
  }, [location.search]);

  const footer = alertConfigLength > 0 && (
    <Box my={2} display="flex" justifyContent="flex-end" px={3}>
      <Typography style={{ marginRight: "2px" }} className={classes.total}>
        {t("AlertsListTotal")}:
      </Typography>
      <Typography className={classes.total}>{alertConfigLength}</Typography>
    </Box>
  );

  const alertConfigByValueTemplateList = alertConfigByValue && (
    <AlertConfigByValueTemplateList
      alertConfigTemplates={alertConfigByValue}
      onEdit={patchAlertConfigByValue}
      buttonsFx={buttonsFx}
      addAlertConfig={addAlertConfigByValue}
      onPageChange={setPageByValue}
      onPageSizeChange={setPageSizeByValue}
      paginationByValue={paginationByValueInfo}
    />
  );
  const alertConfigByNoMeasureTemplateList = alertConfigByNoMeasure && (
    <AlertConfigByNoMeasureTemplateList
      alertConfigTemplates={alertConfigByNoMeasure}
      onEdit={patchAlertConfigByNoMeasure}
      buttonsFx={buttonsFx}
      addAlertConfig={addAlertConfigByNoMeasure}
      onPageChange={setPageNoMeasure}
      onPageSizeChange={setPageSizeNoMeasure}
      paginationByNoMeasure={paginationByNoMeasureInfo}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.AlertConfigTemplates.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page title={title} footer={footer} breadCrumbProps={getBreadCrumbProps()}>
      <Tabs value={tab} onChange={handleTabChange} orientation="horizontal">
        <Tab label={t("HeaderAlertConfigTemplateByValue")} {...a11yProps(0)} />
        <Tab
          label={t("HeaderAlertConfigTemplateByNoMeasure")}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel key={`panel-0`} value={tab} index={0}>
        {alertConfigByValueTemplateList}
      </TabPanel>
      <TabPanel key={`panel-1`} value={tab} index={1}>
        {alertConfigByNoMeasureTemplateList}
      </TabPanel>
    </Page>
  );
};

export { AlertConfigTemplates };
