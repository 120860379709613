var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { SignType, BasicMeasurement, } from "../../models";
import { FlexLayoutGrid } from "../../../DataGrid/DataGrid";
import { NumericValuesGridColumns, } from "./NumericValuesGridColumns";
import { Typography } from "@mui/material";
import { styles } from "./styles";
import dateFormat from "dateformat";
import { ImageEditModal } from "../../../ImagesViews/ImageEditModal";
import { ImageViewModal } from "../../../ImagesViews/ImageViewModal";
var style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    maxHeight: 700,
    maxWidth: 1800,
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
};
export default function NumericValuesModal(_a) {
    var _this = this;
    var device = _a.device, signs = _a.signs, measurements = _a.measurements, open = _a.open, handleClose = _a.handleClose, getUrl = _a.getUrl, t = _a.t, language = _a.language, onSaveMeasurement = _a.onSaveMeasurement, editMeasurementsPermissions = _a.editMeasurementsPermissions, deleteMeasurementsPermissions = _a.deleteMeasurementsPermissions, onDeleteOneMeasurement = _a.onDeleteOneMeasurement, onDeleteManyMeasurements = _a.onDeleteManyMeasurements;
    var _b = useState(null), imageItem = _b[0], setImageItem = _b[1];
    var _c = useState([]), dates = _c[0], setDates = _c[1];
    var _d = useState([]), newMeasurements = _d[0], setMeasurements = _d[1];
    var _e = useState([]), measurementsParsed = _e[0], setMeasurementsParsed = _e[1];
    var _f = useState([]), filteredMeasurements = _f[0], setFilteredMeasurements = _f[1];
    var _g = useState(), additionalData = _g[0], setAdditionalData = _g[1];
    var _h = useState(signs), newSings = _h[0], setSigns = _h[1];
    var _j = useState([]), filteredSignId = _j[0], setFilteredSignId = _j[1];
    var _k = useState([]), filteredSign = _k[0], setFilteredSign = _k[1];
    var _l = useState([]), signIds = _l[0], setSignIds = _l[1];
    var _m = useState(false), error = _m[0], setError = _m[1];
    var _o = useState(null), measurementToDelete = _o[0], setMeasurementToDelete = _o[1];
    var _p = useState(false), readDeviceToDelete = _p[0], setReadDeviceToDelete = _p[1];
    var _q = useState([]), measurementsUpdated = _q[0], setMeasurementsUpdated = _q[1];
    var handleError = function () {
        setError(true);
    };
    var handleOnDeleteOneMeasurement = function (measurementId) { return __awaiter(_this, void 0, void 0, function () {
        var toUpdate, newList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onDeleteOneMeasurement(measurementId)];
                case 1:
                    _a.sent();
                    toUpdate = measurementsUpdated.findIndex(function (m) { return m.id === measurementId; });
                    newList = __spreadArray([], measurementsUpdated, true);
                    newList.splice(toUpdate, 1);
                    setMeasurementsUpdated(newList);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleOnDeleteManyMeasurements = function (readDeviceId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onDeleteManyMeasurements(readDeviceId)];
                case 1:
                    _a.sent();
                    /**
                     * We reset measurements list since we deleted all the measurements.
                     */
                    setMeasurementsUpdated([]);
                    setImageItem(null);
                    setError(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeMeasurements = function (event, signId) {
        var toUpdate = measurementsUpdated.findIndex(function (m) { return m.signId === signId; });
        var newList = __spreadArray([], measurementsUpdated, true);
        if (toUpdate !== -1) {
            newList[toUpdate].value = +event.target.value;
        }
        else {
            var newMeasurement = new BasicMeasurement(0, +event.target.value, new Date(), signId, imageItem.readDeviceId);
            newList.push(newMeasurement);
        }
        setMeasurementsUpdated(newList);
    };
    var handleOnSave = function (measurements) { return __awaiter(_this, void 0, void 0, function () {
        var updateResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSaveMeasurement(measurements)];
                case 1:
                    updateResponse = _a.sent();
                    if (updateResponse !== undefined) {
                        setMeasurementsUpdated(updateResponse.data);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var openImageModal = function (item) { return __awaiter(_this, void 0, void 0, function () {
        var imageUrlResponse, imageMeasurements, filteredMeasurements_1, values, valueText, altText, parsedMeasurements;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(item.sign.typeSign === SignType.image)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUrl(item.readDeviceId, item.id)];
                case 1:
                    imageUrlResponse = _c.sent();
                    setImageItem({
                        imageUrl: (_a = imageUrlResponse === null || imageUrlResponse === void 0 ? void 0 : imageUrlResponse.data) !== null && _a !== void 0 ? _a : "",
                        altText: "",
                        readDeviceId: item.readDeviceId,
                        imageForm: true,
                    });
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getUrl(item.readDeviceId)];
                case 3:
                    imageUrlResponse = _c.sent();
                    imageMeasurements = measurementsParsed.filter(function (m) { return m.readDeviceId === item.readDeviceId; });
                    filteredMeasurements_1 = imageMeasurements.filter(function (value, index, self) {
                        return index === self.findIndex(function (m) { return m.signId === value.signId; });
                    });
                    values = filteredMeasurements_1.map(function (i) { return i.value; });
                    valueText = filteredMeasurements_1.map(function (i) { return i.valueText; });
                    altText = values.length > 0
                        ? values.join(", ")
                        : valueText.length > 0
                            ? valueText.join(", ")
                            : "";
                    setImageItem({
                        imageUrl: (_b = imageUrlResponse === null || imageUrlResponse === void 0 ? void 0 : imageUrlResponse.data) !== null && _b !== void 0 ? _b : "",
                        altText: altText,
                        readDeviceId: item.readDeviceId,
                        imageForm: false,
                    });
                    parsedMeasurements = filteredMeasurements_1.map(function (m) {
                        var _a;
                        return (__assign(__assign({}, m), { value: m.value ? +m.value : 0, readDeviceId: (_a = item.readDeviceId) !== null && _a !== void 0 ? _a : 0 }));
                    });
                    setMeasurementsUpdated(parsedMeasurements);
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var dataGridRows = dates.map(function (date, index) {
        return ({
            id: index,
            date: date,
            values: newMeasurements.filter(function (value) { return value.date === date; }),
        });
    });
    var dataGridColumns = NumericValuesGridColumns({
        signs: filteredSign,
        t: t,
        showImageModal: openImageModal,
        additionalData: additionalData,
        currentLanguage: language,
        measurements: newMeasurements,
    });
    useEffect(function () {
        var _a, _b;
        if (device) {
            setAdditionalData((_b = (_a = device === null || device === void 0 ? void 0 : device.formDevice) === null || _a === void 0 ? void 0 : _a.json) !== null && _b !== void 0 ? _b : "");
        }
    }, [device]);
    useEffect(function () {
        if (additionalData && additionalData !== "") {
            var data = JSON.parse(additionalData);
            var signs_1 = [];
            data.questions.forEach(function (q) {
                signs_1.push.apply(signs_1, q.sign);
            });
            setSigns(signs_1);
        }
    }, [additionalData]);
    useEffect(function () {
        if (measurements.length !== 0) {
            setMeasurementsParsed(measurements.map(function (m) {
                var _a, _b, _c;
                return (__assign(__assign({}, m), { value: m.value ? (+m.value).toFixed((_a = m.sign) === null || _a === void 0 ? void 0 : _a.decimals) : "0", date: dateFormat(m.createdAt, "dd/mm HH:MM:ss"), deviceTypeName: (_c = (_b = m.sign) === null || _b === void 0 ? void 0 : _b.device.typeName) !== null && _c !== void 0 ? _c : "Device" }));
            }));
        }
    }, [measurements]);
    useEffect(function () {
        var _a;
        if (newSings.length !== 0) {
            setSignIds((_a = newSings.map(function (sign) { return sign.id; })) !== null && _a !== void 0 ? _a : []);
        }
    }, [newSings]);
    useEffect(function () {
        if (measurementsParsed.length !== 0 && signIds.length !== 0) {
            setFilteredMeasurements(measurementsParsed.filter(function (value) {
                return signIds.includes(value.signId);
            }));
        }
    }, [measurementsParsed, signIds]);
    useEffect(function () {
        if (filteredMeasurements.length !== 0) {
            var dates_1 = [];
            for (var i = 0; i < filteredMeasurements.length; i++) {
                if (!dates_1.includes(filteredMeasurements[i].date))
                    dates_1.push(filteredMeasurements[i].date);
            }
            setDates(dates_1);
        }
    }, [filteredMeasurements]);
    useEffect(function () {
        if (dates.length !== 0 && filteredMeasurements.length !== 0) {
            var newMeasurements_1 = [];
            filteredMeasurements.forEach(function (measurement) {
                var index = newMeasurements_1.findIndex(function (m) {
                    return m.signId === measurement.signId && m.date === measurement.date;
                });
                if (index === -1) {
                    newMeasurements_1.push(measurement);
                }
                else {
                    newMeasurements_1[index].valueText += ", ".concat(measurement.valueText);
                }
                setMeasurements(newMeasurements_1);
            });
        }
    }, [dates]);
    useEffect(function () {
        if (newMeasurements.length !== 0) {
            var measurementSignIds_1 = newMeasurements.map(function (item) { return item.signId; });
            setFilteredSignId(signIds.filter(function (id) { return measurementSignIds_1.includes(id); }));
        }
    }, [newMeasurements]);
    useEffect(function () {
        if (newSings &&
            newSings.length > 0 &&
            filteredSignId &&
            filteredSignId.length > 0) {
            var newSingsFilter = newSings.filter(function (sign) {
                return filteredSignId.includes(sign.id);
            });
            setFilteredSign(newSingsFilter);
        }
    }, [filteredSignId]);
    var showEditImageModal = 
    /**
     * If it is an image from a form or the user
     * does not have any permissions,
     * display only the image.
     */
    imageItem &&
        (imageItem.imageForm ||
            (!editMeasurementsPermissions && !deleteMeasurementsPermissions) ? (React.createElement(ImageViewModal, { imageUrl: imageItem.imageUrl, altText: imageItem.altText, open: Boolean(imageItem), handleClose: function () {
                setImageItem(null);
                setError(false);
            }, t: t, error: error, handleError: handleError })) : (React.createElement(ImageEditModal, { imageUrl: imageItem.imageUrl, altText: imageItem.altText, open: Boolean(imageItem), handleClose: function () {
                setImageItem(null);
                setError(false);
            }, t: t, error: error, handleError: handleError, measurements: measurementsUpdated, onSave: handleOnSave, onDeleteMany: handleOnDeleteManyMeasurements, onDeleteOne: handleOnDeleteOneMeasurement, editPermissions: editMeasurementsPermissions, deletePermissions: deleteMeasurementsPermissions, onChange: onChangeMeasurements, device: device, readDeviceId: imageItem.readDeviceId, measurementToDelete: measurementToDelete, setMeasurementToDelete: setMeasurementToDelete, readDeviceToDelete: readDeviceToDelete, setReadDeviceToDelete: setReadDeviceToDelete, currentLanguage: language })));
    return (React.createElement("div", null,
        React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: style },
                React.createElement(Typography, { variant: "h2", style: styles.typography }, (device === null || device === void 0 ? void 0 : device.name) ? t(device === null || device === void 0 ? void 0 : device.name) : ""),
                React.createElement(FlexLayoutGrid, { language: language, columns: dataGridColumns, rows: dataGridRows }),
                showEditImageModal))));
}
