import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { ItemForm } from "@doctomatic/sdk/build/dto/Form/Form";
import FormDataRow from "./FormDataRow";
import { FormGridColumns } from "./FormGridColumns";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import { GridRowClassNameParams } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../Theme/ThemeDataGrid";
import { useNavigate } from "react-router-dom";

export interface FormsListProps {
  forms?: ItemForm[];
  onDelete: (form: ItemForm) => void;
  onRestore: (form: ItemForm) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  formsPaginationInfo: any;
}

const FormsList = ({
  forms,
  onDelete,
  onRestore,
  setPage,
  setPageSize,
  formsPaginationInfo,
}: FormsListProps): React.ReactElement => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const formDataRow: FormDataRow[] = forms || [];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = useState<string>("");

  const actions = (form: ItemForm) => {
    const onClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(`m${form.id}`);
    };

    const onClose = () => {
      setAnchorEl(null);
      setPopoverId("");
    };

    return (
      <>
        <IconButton onClick={onClick}>
          <MoreVertIcon />
        </IconButton>

        <Popover
          id={`m${form.id}`}
          open={popoverId === `m${form.id}`}
          anchorEl={anchorEl}
          onClose={onClose}
        >
          <List>
            {!form.deleted_at && (
              <>
                <ListItemButton
                  onClick={() => {
                    navigate(`${form.id}`);
                    onClose();
                  }}
                >
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${t("ButtonEdit")} ${t("Form")}`} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    onClose();
                    onDelete(form);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${t("ButtonDelete")} ${t("Form")}`} />
                </ListItemButton>
              </>
            )}

            {form.deleted_at && (
              <ListItemButton
                onClick={() => {
                  onClose();
                  onRestore(form);
                }}
              >
                <ListItemIcon>
                  <RestoreFromTrashOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={`${t("ButtonRestore")} ${t("Form")}`} />
              </ListItemButton>
            )}
          </List>
        </Popover>
      </>
    );
  };

  const datagridColumns = FormGridColumns(t, actions);

  const setRowClassName = (params: GridRowClassNameParams<ItemForm>): string =>
    params.row.deleted_at ? "super-app-theme--Rejected" : "";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
      }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={datagridColumns as any}
        setRowClassName={setRowClassName as any}
        rows={formDataRow}
        sx={DoctomaticStylingRowsGrid() as any}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        pagination={formsPaginationInfo}
      />
    </div>
  );
};

export { FormsList };
