
/**
 * endpoint for kafka_consumer socket messages
 */
export const RESOURCE_ENDPOINT = "message";


export const ENDPOINT_COGNIMATIC_DETECT = "cognimatic.detec";

export const SING_UNIT_NOT_SHOW = ['text', 'foot with pain', undefined, 'pain level', 'question', 'image'];

export const MAX_IMAGE_SIZE = 1 * 1024 * 1024; //Se quiere un mega de tamaño

export const MB_CONVERSION_RATE = 1024 * 1024;

export const BACKGROUND_RED = "#fb6363"; // Pastel red
export const BACKGROUND_YELLOW = "#ffdc63" // Pastel yellow
export const BACKGROUND_GREEN = "#8eff63" // Pastel green