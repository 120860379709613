var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
import i18next from "i18next";
registerLocale("es", es);
var datePickerStyles = {
    height: "100%",
    display: "inline-flex",
    fontSize: "1rem",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    width: "fit-content",
    minWidth: "260px",
};
var customDatePicker = __assign(__assign({}, datePickerStyles), { "> div.react-datepicker-wrapper": datePickerStyles, "> div > div.react-datepicker__input-container": datePickerStyles, "> div > div.react-datepicker__input-container input": __assign(__assign({}, datePickerStyles), { border: "1px solid #c4c4c4", borderRadius: "4px", padding: "16.5px 30px", ":hover": {
            border: "1px solid #000",
        }, ":focus": {
            outline: "none",
            border: "2px solid #2196F3",
        } }) });
export var DateRangePicker = function (_a) {
    var _b = _a.dateRangePicked, dateRangePicked = _b === void 0 ? [null, null] : _b, setDateRange = _a.setDateRange, t = _a.t;
    var _c = useState(dateRangePicked[0]), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(dateRangePicked[1]), endDate = _d[0], setEndDate = _d[1];
    var onChange = function (dates) {
        var start = dates[0], end = dates[1];
        setStartDate(start);
        setEndDate(end);
        setDateRange(dates);
    };
    return (React.createElement("div", { className: "customDatePicker", style: { height: "100%", marginRight: "20px", minWidth: "140" } },
        React.createElement(Box, { sx: customDatePicker },
            React.createElement(DatePicker, { selected: startDate, locale: i18next.language, onChange: onChange, startDate: startDate, endDate: endDate, selectsRange: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select", isClearable: true, maxDate: new Date(), placeholderText: t ? t("DataRangePlaceholder") : "Selector de fechas" }))));
};
