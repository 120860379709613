import { Trans } from "react-i18next";
import React, { useState } from "react";
import { Page } from "../../Page";
import { toast } from "react-toastify";
import { useLoading } from "../../../Loading/Loading";
import { Box, IconButton, Switch, Typography } from "@mui/material";
import { useApi } from "@doctomatic/sdk/build/Api";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { processError } from "../../../../App/errorToast";
import { ItemPatientListExtendedResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import { PatientCreate } from "../../Patients/PatientCreate";
import { PatientList } from "./PatientList";
import { PatientEdit } from "../../Patients/PatientEdit";
import ConfirmModal from "@doctomatic/components-react/build/ConfirmModal/ConfirmModal";
import { AddCircle } from "@mui/icons-material";
import { ConfirmationRegisterModal } from "@doctomatic/components-react/build/ConfirmationRegisterModal/ConfirmationRegisterModal";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  total: {
    marginRight: 16,
    padding: "0 9px",
    lineHeight: 1.75,
    fontSize: 16,
  },
}));

export const Patients = (): React.ReactElement => {
  const navigate = useNavigate();
  const {
    usePatientsByCompany,
    useCompany,
    useSMS,
    useMail,
    logout,
    companyId,
  } = useApi();
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    response: patientsResponse,
    add,
    restore,
    del,
    patch,
    mutate,
  } = usePatientsByCompany(
    companyId,
    showDeleted,
    true,
    processError(logout, navigate, t)
  );
  const patients = patientsResponse?.data;
  const { response: companyResponse } = useCompany(companyId, true);
  const company = companyResponse?.data;

  const { sendRegistrationEmail } = useMail();
  const { sendRegistrationSMS } = useSMS();
  const { setIsLoading } = useLoading();
  const classes = useStyles();
  const [patientToEditId, setPatientToEditId] = useState<number | null>(null);
  const [isPatientEditFormOpen, setIsPatientEditFormOpen] =
    useState<boolean>(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState<boolean>(false);
  const [patientToDelete, setUserToDelete] =
    useState<ItemPatientListExtendedResponseDto | null>(null);
  const [patientToRestore, setUserToRestore] =
    useState<ItemPatientListExtendedResponseDto | null>(null);
  const [patientToSendSMS, setUserToSendSMS] = useState<{
    id: number;
    name: string;
    phone?: string;
  } | null>(null);
  const [patientToSendEmail, setPatientToSendEmail] = useState<{
    id: number;
    name: string;
    email?: string;
  } | null>(null);
  const [needsSubscription, setNeedsSubscription] = useState<boolean>(true);
  const [completeOnboarding, setCompleteOnboarding] = useState<boolean>(
    company && company.onBoardingReq === 4 ? true : false
  );
  const title = t("HeaderPatients");

  if (!patients) return <></>;

  const buttons = (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setIsUserFormOpen(true)}>
        <AddCircle color="primary" fontSize="large" />
      </IconButton>
    </Box>
  );
  const footer = (
    <Box my={2} display="flex">
      <Switch
        checked={showDeleted}
        onChange={async () => {
          setShowDeleted(!showDeleted);
          await mutate();
        }}
      />
      <Typography className={classes.total}>{t("ShowAllCheckBox")}</Typography>
    </Box>
  );

  const confirmRestore = patientToRestore && (
    <ConfirmModal
      open={Boolean(patientToRestore)}
      content={
        <Trans
          i18nKey="UserListRestoreConfirm"
          values={{ user: t("FieldNurse"), name: patientToRestore.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonRestore")}
      onCancel={() => setUserToRestore(null)}
      onClose={() => setUserToRestore(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await restore(patientToRestore.id);
          setUserToRestore(patientToRestore);
        } catch (err: any) {
          toast.error(
            `${t("UserListRestoreError")}: ${
              err.response?.data?.message || err.message
            }`
          );
        }
        setUserToRestore(null);
        setIsLoading(false);
      }}
    />
  );

  const confirmDelete = patientToDelete && (
    <ConfirmModal
      open={Boolean(patientToDelete)}
      content={
        <Trans
          i18nKey="UserListDeleteConfirm"
          values={{ user: t("FieldNurse"), name: patientToDelete.name }}
          components={{ 1: <b /> }}
        />
      }
      cancelText={t("ButtonCancel")}
      confirmText={t("ButtonDelete")}
      onCancel={() => setUserToDelete(null)}
      onClose={() => setUserToDelete(null)}
      onConfirm={async () => {
        setIsLoading(true);
        try {
          await del(patientToDelete.id);
        } catch (err: any) {
          toast.error(
            `${t("ErrorDelete")}: ${err.response?.data?.message || err.message}`
          );
        }
        setUserToDelete(null);
        setIsLoading(false);
      }}
    />
  );

  const handleSendEmail = async () => {
    const { data } = await sendRegistrationEmail(
      patientToSendEmail?.id as number
    );
    toast.info(data?.message);
  };

  const handleSendSms = async () => {
    try {
      const { data } = await sendRegistrationSMS(
        patientToSendSMS?.id as number
      );
      if (process.env.REACT_APP_SHOW_MSG_SMS_CONSOLE === "true") {
        console.log(data?.message);
        toast.info(
          "Mira en la consola. Copia el enlace de On Boarding y pégalo en una nueva ventana en modo incógnito (sin token en localStorage)."
        );
        toast.info(data?.message);
      } else {
        toast.info(data?.message);
      }
    } catch (err: any) {
      toast.error(
        `${t("UserListSMSError")}: ${
          err.response?.data?.message || err.message
        }`
      );
    }
  };

  const patientList = (
    <PatientList
      patients={patients}
      showDeleted={showDeleted}
      onEdit={(id: number) => {
        setIsPatientEditFormOpen(true);
        setPatientToEditId(id);
      }}
      onDelete={setUserToDelete}
      onRestore={setUserToRestore}
      onSendSms={setUserToSendSMS}
      onSendEmail={setPatientToSendEmail}
    />
  );

  const patientCreateForm = isUserFormOpen && (
    <PatientCreate
      open={isUserFormOpen}
      onClose={() => setIsUserFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          await add(user);
          setIsUserFormOpen(false);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
      needsSubscription={needsSubscription}
      setNeedsSubscription={setNeedsSubscription}
      completeOnboarding={completeOnboarding}
      setCompleteOnboarding={setCompleteOnboarding}
    />
  );

  const patientEdit = patientToEditId && (
    <PatientEdit
      open={isPatientEditFormOpen}
      userToEditId={patientToEditId}
      onClose={() => setIsPatientEditFormOpen(false)}
      onSave={async (user) => {
        setIsLoading(true);
        try {
          if (patientToEditId) {
            await patch(patientToEditId, user);
            await mutate();
            setPatientToEditId(null);
          }
        } catch (err: any) {
          toast.error(
            `${t("ErrorEdit")}: ${err.response?.data?.message || err.message}`
          );
        }
        setIsLoading(false);
      }}
    />
  );

  const getBreadCrumbProps = (): BreadcrumbProps => {
    return {
      breadcrumbItems: [
        {
          url: BreadcrumbNameMap.Administration.url,
          name: t(BreadcrumbNameMap.Administration.name),
        },
        { url: "", name: t(BreadcrumbNameMap.AdministrationPatients.name) },
      ],
    } as BreadcrumbProps;
  };

  return (
    <Page
      title={title}
      buttons={buttons}
      footer={footer}
      breadCrumbProps={getBreadCrumbProps()}
    >
      {patientList}
      {patientCreateForm}
      {patientEdit}
      {confirmDelete}
      {confirmRestore}
      {patientToSendEmail && (
        <ConfirmationRegisterModal
          userToSend={patientToSendEmail}
          setUserToSend={setPatientToSendEmail}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendEmail}
          keyConfirmation={"UserListEmailConfirm"}
          keyError={"UserListEmailError"}
        />
      )}
      {setUserToSendSMS && (
        <ConfirmationRegisterModal
          userToSend={patientToSendSMS}
          setUserToSend={setUserToSendSMS}
          setIsLoading={setIsLoading}
          t={t}
          handleSend={handleSendSms}
          keyConfirmation={"UserListSMSConfirm"}
          keyError={"UserListSmsError"}
        />
      )}
    </Page>
  );
};
