import { Expose, Type } from "class-transformer";
import { IsNotEmpty, IsOptional, ValidateNested } from "class-validator";
import { GetDeviceResponseDto } from "../../../Devices";
import { BasicItem } from "../../../IItemList";
import {
  AddAlertConfigIndividualRequestDto,
  AddAlertConfigIndividualResponseDto,
  GetAlertConfigIndividualResponseDto,
  ItemAlertConfigIndividualDto,
  UpdateAlertConfigIndividualRequestDto,
  UpdateAlertConfigIndividualResponseDto,
} from "./AlertConfigIndividual";
import {
  IAddAlertConfigByNoMeasureIndividualRequestDto,
  IAddAlertConfigByNoMeasureIndividualResponseDto,
  IGetAlertConfigByNoMeasureIndividualResponseDto,
  IItemAlertConfigByNoMeasureIndividualDto,
  IUpdateAlertConfigByNoMeasureIndividualRequestDto,
  IUpdateAlertConfigByNoMeasureIndividualResponseDto,
} from "./IAlertConfigByNoMeasureIndividual";
import { INotification } from "../../group/IAlertGroup";

export class AddAlertConfigByNoMeasureIndividualRequestDto
  extends AddAlertConfigIndividualRequestDto
  implements IAddAlertConfigByNoMeasureIndividualRequestDto
{
  @Expose() deviceId: number = 0;
  @Expose() max_time_between: string = "";
  @Expose() @IsNotEmpty() subscribe: boolean = false;
  @Expose() @IsOptional() customAlertConfigTemplateId?: number;
  @Expose() @IsOptional() requestedSigns?: string;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class AddAlertConfigByNoMeasureIndividualResponseDto
  extends AddAlertConfigIndividualResponseDto
  implements IAddAlertConfigByNoMeasureIndividualResponseDto
{
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() @IsNotEmpty() max_time_between: string = "";
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class UpdateAlertConfigByNoMeasureIndividualRequestDto
  extends UpdateAlertConfigIndividualRequestDto
  implements IUpdateAlertConfigByNoMeasureIndividualRequestDto
{
  @Expose() @IsOptional() max_time_between?: string;
  @Expose() @IsOptional() requestedSigns?: string;
}

export class UpdateAlertConfigByNoMeasureIndividualResponseDto
  extends UpdateAlertConfigIndividualResponseDto
  implements IUpdateAlertConfigByNoMeasureIndividualResponseDto
{
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() max_time_between: string = "";
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}

export class GetAlertConfigByNoMeasureIndividualResponseDto
  extends GetAlertConfigIndividualResponseDto
  implements IGetAlertConfigByNoMeasureIndividualResponseDto
{
  @Expose()
  @ValidateNested()
  @Type(() => GetDeviceResponseDto)
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() max_time_between: string = "";
}

export class ItemAlertConfigByNoMeasureIndividualDto
  extends ItemAlertConfigIndividualDto
  implements IItemAlertConfigByNoMeasureIndividualDto
{
  @Expose() fromTemplate: boolean = false;
  @Expose()
  @ValidateNested()
  @Type(() => BasicItem)
  device: BasicItem = new BasicItem();
  @Expose() max_time_between: string = "";
  @Expose() @IsOptional() requestedSigns?: string;
  @Expose() notification: INotification = {
    email: true,
    sms: false,
    pushNotification: true,
  };
}
