import { useEffect, useRef, useState } from "react";
import {
  FormResponse,
  T,
} from "@doctomatic/sdk/build/dto/ReadForm/types/ReadFormTypes";
import { useApi } from "@doctomatic/sdk/build/Api";
import { manipulateImage } from "../Pages/utils";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AddCommentIcon from "@mui/icons-material/AddComment";
import QuizIcon from "@mui/icons-material/Quiz";

import QrCodeIcon from "@mui/icons-material/QrCode";
import QRCodeCanvas, { ErrorLevel } from "../QRCodes/QRCodeCanvas";
import { useLocation } from "react-router";
import CameraPermissionModal from "../Pages/Camera/CameraPermissionModal";
import { ColoredSwitch } from "../Pages/Camera/components/ColoredSwitch";

interface ImageFormProps {
  userId: number;
  form: FormResponse;
  questionIndex: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  handleResponseChange: (questionId: number, response: any) => void;
  setOpenDescription: (status: boolean) => void;
  showDescription: boolean;
}

const CANVAS_IMAGE_WIDTH = 415;

export default function ImageForm({
  form,
  questionIndex,
  onNextPage,
  onPreviousPage,
  handleResponseChange,
  setOpenDescription,
  showDescription,
}: ImageFormProps) {
  const { t } = useTranslation();
  const { lang, token } = useApi();
  const translation = new T([lang]);
  const [photoTaken, setPhotoTaken] = useState<boolean>(false);
  const [photoData, setPhotoData] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [cameraLoading, setCameraLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showModalPermissions, setShowModalPermissions] = useState(false);
  const maxMobileWidth = 768; // Ancho máximo para dispositivos móviles en píxeles
  const maxWebWidth = 1600; // Ancho máximo para dispositivos móviles en píxeles
  const width =
    window.innerWidth >= maxMobileWidth
      ? window.innerWidth >= maxWebWidth
        ? "30%"
        : "40%"
      : "95%";

  const location = useLocation();

  useEffect(() => {
    setupVideo();
  }, [photoTaken]);

  const setupVideo = async () => {
    setCameraLoading(true);
    try {
      // "user" para la cámara frontal, "environment" para la trasera
      const stream = await getDeviceStream("environment");
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error al acceder a la cámara:", err);
      setShowModalPermissions(true);
    }
    setCameraLoading(false);
  };

  const getDeviceStream = async (facingMode: "user" | "environment") => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    const facingModeVideoDevices = videoDevices.filter(
      (device) =>
        device.label.toLowerCase().includes(facingMode) ||
        device.label
          .toLowerCase()
          .includes(facingMode === "user" ? "front" : "back")
    );

    const videoDeviceId = facingModeVideoDevices.length
      ? facingModeVideoDevices[0].deviceId
      : undefined;

    const constraints = {
      audio: false,
      video: {
        width: { ideal: CANVAS_IMAGE_WIDTH },
        height: { ideal: CANVAS_IMAGE_WIDTH },
        facingMode,
        ...(videoDeviceId ? { deviceId: videoDeviceId } : {}),
      },
    };

    return navigator.mediaDevices.getUserMedia(constraints);
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const savePhotoToLocalStorage = async (blob: Blob) => {
    try {
      const base64String = await blobToBase64(blob);
      localStorage.setItem(`capturedPhoto-${questionIndex}`, base64String);
      handleResponseChange(questionIndex, form.responses[questionIndex]);
      // window.parent.postMessage(data, "*");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const takePhoto = async () => {
    setIsLoading(true);
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = CANVAS_IMAGE_WIDTH;
        canvasRef.current.height = CANVAS_IMAGE_WIDTH;
        context.drawImage(
          videoRef.current,
          0,
          0,
          CANVAS_IMAGE_WIDTH,
          CANVAS_IMAGE_WIDTH
        );
        const imageData = canvasRef.current.toDataURL("image/png");
        localStorage.setItem("capturedPhoto", imageData);
        const imageBlob = await manipulateImage();
        await savePhotoToLocalStorage(imageBlob);
        setPhotoData(imageData);
      }
      stopCamera();
      setPhotoTaken(true);
      onNextPage();
    }
  };

  const handleQR = (checked: boolean) => {
    setShowQR(checked);
    if (checked === false) {
      setupVideo();
    }
  };

  const currentHost =
    process.env.REACT_APP_URL === "" || process.env.REACT_APP_URL === undefined
      ? `${window.location.protocol}//${window.location.hostname}`
      : process.env.REACT_APP_URL;

  const imageView = () => {
    if (showQR) {
      return (
        <QRCodeCanvas
          QRString={currentHost + location.pathname + `?token=${token}`}
          options={{ width: 250, errorCorrectionLevel: ErrorLevel.low }}
        />
      );
    } else {
      if (photoTaken && photoData) {
        return <StyledImage src={photoData || ""} alt="Captured" />;
      } else {
        return (
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{ width, height: "auto", maxWidth: "100%" }}
          />
        );
      }
    }
  };

  return (
    <>
      {showModalPermissions && (
        <CameraPermissionModal
          onOpen={showModalPermissions}
          onRequestPermission={setupVideo}
          onClose={() => setShowModalPermissions(false)}
        />
      )}
      <Modal
        open={true}
        onClose={onPreviousPage}
        disableScrollLock={true}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 1)",
            height: "100%",
          },
          "& .MuiPaper-root": {
            maxHeight: "100vh",
            overflowY: "hidden",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            overflow: "auto",
            position: "relative",
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <IconButton
            onClick={() => {
              setPhotoTaken(false);
              onPreviousPage();
            }}
            color="inherit"
            style={{ color: "white" }}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 24,
              // flex: 0.9,
            }}
          >
            {translation.i18n(form.responses[questionIndex].text)}
          </h2>

          {/* QR Code Switch */}
          <div
            style={{
              marginBlockStart: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <QuizIcon sx={{ fontSize: 30, color: "white" }} />
            <ColoredSwitch
              white
              sx={{ transform: "scale(1.8)", marginX: 2 }}
              checked={showQR}
              name={"QR"}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => handleQR(checked)}
            />
            <QrCodeIcon sx={{ fontSize: 30, color: "white" }} />
          </div>

          {imageView()}
          {cameraLoading && (
            <CameraLoadingText>{t("AccessingCamera")}</CameraLoadingText>
          )}

          <StyledCanvas ref={canvasRef} />
          {!cameraLoading && !showQR && (
            <>
              <Button
                onClick={takePhoto}
                style={{ marginTop: "5%" }}
                variant="contained"
                color="secondary"
                disabled={cameraLoading || isLoading}
              >
                {!isLoading ? t("TakePhoto") : <CircularProgress size={30} />}
              </Button>
              {showDescription && (
                <div>
                  <IconButton
                    color="inherit"
                    // color="gray"
                    edge="start"
                    onClick={() => setOpenDescription(true)}
                  >
                    <AddCommentIcon />
                  </IconButton>
                </div>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

const StyledImage = styled("img")`
  width: 30%;
  height: auto;

  @media (max-width: 1600px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const CameraLoadingText = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 18px;
`;

const StyledCanvas = styled("canvas")`
  display: none;
`;
