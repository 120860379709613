import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import { Link as NavLink } from "react-router-dom";
import { BreadcrumbNameMap } from "../../../breadcrumbMap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PatientDataRow from "./PatientDataRow";
import { PopoverConfig } from "./PatientGridColumns";

interface Props {
  row: PatientDataRow;
  t: (text: string) => string;
  popoverConf?: PopoverConfig;
}

export const PatientActionsPopover = (props: Props) => {
  return (
    <Popover
      id={`m${props.row.id}`}
      open={props.popoverConf?.popoverId === `m${props.row.id}`}
      anchorEl={props.popoverConf?.anchorEl}
      onClose={props.popoverConf?.onClose}
    >
      <List>
        {!props.row.deletedAt && (
          <>
            <ListItemButton
              component={NavLink}
              to={BreadcrumbNameMap.Alerts.url.replace(
                ":patientId",
                props.row.id.toString()
              )}
            >
              {props.t("PatientListAlerts")}
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to={BreadcrumbNameMap.AlertConfigByValue.url.replace(
                ":patientId",
                props.row.id.toString()
              )}
            >
              {props.t("PatientListConfigAlertsByValue")}
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to={BreadcrumbNameMap.AlertConfigByNoMeasure.url.replace(
                ":patientId",
                props.row.id.toString()
              )}
            >
              {props.t("PatientListConfigAlertsByNoMeasure")}
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to={BreadcrumbNameMap.TrackingPatient.url.replace(
                ":patientId",
                props.row.id.toString()
              )}
            >
              {props.t("PatientListTreatments")}
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to={BreadcrumbNameMap.LocationsUser.url
                .replace(":role", "patients")
                .replace(":actorId", props.row.id.toString())}
            >
              {props.t("PatientListLocations")}
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to={{
                pathname: BreadcrumbNameMap.ExternalReferences.url,
                search: `?patientId=${props.row.id.toString()}`,
              }}
            >
              {props.t(BreadcrumbNameMap.ExternalReferences.name)}
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.popoverConf?.onClose();
                props.popoverConf?.onFrequency(
                  props.row.id,
                  props.row.name,
                  props.row.trackings,
                  props.row.timezone
                );
              }}
            >
              <ListItemIcon>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={props.t("PatientListFrequency")} />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.popoverConf?.onClose();
                props.popoverConf?.onEdit(props.row.id);
              }}
            >
              <ListItemIcon>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={props.t("ButtonEdit")} />
            </ListItemButton>
          </>
        )}
        {props.row.deletedAt ? (
          <ListItemButton
            onClick={() => {
              props.popoverConf?.onClose();
              props.popoverConf?.onRestore(props.row.id, props.row.name);
            }}
          >
            <ListItemIcon>
              <RestoreFromTrashOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={props.t("PatientListRestore")} />
          </ListItemButton>
        ) : (
          <ListItemButton
            onClick={() => {
              props.popoverConf?.onClose();
              props.popoverConf?.onDelete(props.row.id, props.row.name);
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={props.t("PatientListDelete")} />
          </ListItemButton>
        )}
        {!props.row.deletedAt && (
          <ListItemButton
            onClick={() => {
              props.popoverConf?.onClose();
              props.popoverConf?.onSendSms(
                props.row.id,
                props.row.name,
                props.row.phone as string
              );
            }}
          >
            <ListItemIcon>
              <TextsmsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={props.t("UserListSendSMS")} />
          </ListItemButton>
        )}
        {!props.row.deletedAt && (
          <ListItemButton
            onClick={() => {
              props.popoverConf?.onClose();
              props.popoverConf?.onSendEmail(
                props.row.id,
                props.row.name,
                props.row.email as string
              );
            }}
          >
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={props.t("UserListSendEmail")} />
          </ListItemButton>
        )}
      </List>
    </Popover>
  );
};
