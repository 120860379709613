import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbProps } from "@doctomatic/components-react/build/BreadcrumbDocto/BreadcrumbDocto";
import { useParams } from "react-router-dom";
import { IProvideApi, useApi } from "@doctomatic/sdk/build/Api";
import { IUseAlertConfigsByNoMeasureIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import { IUseAlertConfigsByValue } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByValue";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { GetAlertConfigByNoMeasureIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByNoMeasureIndividual";
import { GetAlertConfigByValueIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByValueIndividual";
import { Subscribers } from "../../Subscribers/Subscribers";
import { useEffect } from "react";
import { IUseAlertConfigsByFrequencyIndividual } from "@doctomatic/sdk/build/modules/AlertConfigIndividual/AlertConfigsByFrequency";
import { GetAlertConfigByFrequencyIndividualResponseDto } from "@doctomatic/sdk/build/dto/Alerts/config/individual/AlertConfigByFrequencyIndividual";

type ISubscribers = {
  alertConfigId: string;
};

type AlertConfigTypes =
  | GetAlertConfigByNoMeasureIndividualResponseDto
  | GetAlertConfigByValueIndividualResponseDto
  | GetAlertConfigByFrequencyIndividualResponseDto;

export const SubscribersAlertConfig = (): React.ReactElement => {
  const { alertConfigId } = useParams<ISubscribers>();
  const { t } = useTranslation();
  const {
    useAlertConfigsByNoMeasure,
    useAlertConfigsByValue,
    useAlertConfigsByFrequency,
    useAlertConfigs,
  }: IProvideApi = useApi();
  const {
    findOneById: findOneAlertConfigByNoMeasure,
  }: IUseAlertConfigsByNoMeasureIndividual = useAlertConfigsByNoMeasure(false);
  const { subscribe, unsubscribe } = useAlertConfigs();
  const { findOneById: findOneAlertConfigByValue }: IUseAlertConfigsByValue =
    useAlertConfigsByValue(false);
  const {
    findOneById: findOneAlertConfigByFrequency,
  }: IUseAlertConfigsByFrequencyIndividual = useAlertConfigsByFrequency(false);
  const [alertConfig, setAlertConfig] = useState<AlertConfigTypes>();

  useEffect(() => {
    const getAlertConfig = async (alertConfigId: number) => {
      try {
        const alertConfigByNoMeasure: ResponseApi<GetAlertConfigByNoMeasureIndividualResponseDto> =
          await findOneAlertConfigByNoMeasure(alertConfigId);
        if (alertConfigByNoMeasure.success === true) {
          setAlertConfig(alertConfigByNoMeasure.data);
          return alertConfigByNoMeasure;
        }
      } catch (err: any) {}

      try {
        const alertConfigByValue: ResponseApi<GetAlertConfigByValueIndividualResponseDto> =
          await findOneAlertConfigByValue(alertConfigId);
        if (alertConfigByValue.success === true) {
          setAlertConfig(alertConfigByValue.data);
          return alertConfigByValue;
        }
      } catch (err: any) {}

      try {
        const alertConfigByFrequency: ResponseApi<GetAlertConfigByFrequencyIndividualResponseDto> =
          await findOneAlertConfigByFrequency(alertConfigId);
        if (alertConfigByFrequency.success === true) {
          setAlertConfig(alertConfigByFrequency.data);
          return alertConfigByFrequency;
        }
      } catch (err: any) {}
    };
    if (alertConfigId) {
      getAlertConfig(+alertConfigId);
    }
  }, [alertConfigId]);

  const getBreadCrumbProps = (): BreadcrumbProps =>
    ({
      breadcrumbItems: [
        { url: "/patients", name: t("Patients") },
        {
          url: `/patients/${alertConfig?.patient?.id}`,
          name: alertConfig?.patient?.name,
        },
        { url: "", name: t("Alert") },
        { url: "", name: alertConfig?.label },
        { url: "", name: t("Subscribers") },
      ],
    } as BreadcrumbProps);

  return (
    <Subscribers
      item={alertConfig}
      subscribe={subscribe}
      unsubscribe={unsubscribe}
      breadcrumbs={getBreadCrumbProps()}
    />
  );
};
