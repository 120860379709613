import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { AlertConfigByValueTemplateGridColumns } from "./AlertConfigByValueGridColumns";
import { AlertConfigByValueDataRow } from "../AlertConfigTemplatesDataRow";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useLoading } from "../../../../Loading/Loading";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { AddAlertConfigByValueModal } from "./ManageAlertConfigByValue/AddAlertConfigByValueModal";
import { UpdateAlertConfigByValueModal } from "./ManageAlertConfigByValue/UpdateAlertConfigByValueModal";
import {
  AddAlertConfigByValueTemplateRequestDto,
  AddAlertConfigByValueTemplateResponseDto,
  ItemAlertConfigByValueTemplateDto,
  UpdateAlertConfigByValueTemplateRequestDto,
  UpdateAlertConfigByValueTemplateResponseDto,
} from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByValueTemplate";
import { GridRowClassNameParams } from "@mui/x-data-grid";
import { DoctomaticStylingRowsGrid } from "../../../../Theme/ThemeDataGrid";

interface Props {
  alertConfigTemplates: ItemAlertConfigByValueTemplateDto[] | undefined;
  onEdit: (
    id: number,
    alertConfigTemplate: UpdateAlertConfigByValueTemplateRequestDto
  ) => Promise<ResponseApi<UpdateAlertConfigByValueTemplateResponseDto>>;
  addAlertConfig: (
    dto: AddAlertConfigByValueTemplateRequestDto
  ) => Promise<ResponseApi<AddAlertConfigByValueTemplateResponseDto>>;
  buttonsFx: (setModalOpen: Dispatch<SetStateAction<boolean>>) => JSX.Element;
  onPageChange: React.Dispatch<React.SetStateAction<number>>;
  onPageSizeChange: React.Dispatch<React.SetStateAction<number>>;
  paginationByValue: any;
}

export const AlertConfigByValueTemplateList = ({
  alertConfigTemplates,
  onEdit,
  addAlertConfig,
  buttonsFx,
  onPageChange,
  onPageSizeChange,
  paginationByValue,
}: Props): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { setIsLoading } = useLoading();
  const [addAlertConfigModalOpen, setAddAlertConfigModalOpen] =
    useState<boolean>(false);
  const [alertConfigToEdit, setAlertConfigToEdit] =
    useState<AlertConfigByValueDataRow | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  if (!alertConfigTemplates) return <></>;

  const alertConfigCreateForm = addAlertConfigModalOpen && (
    <AddAlertConfigByValueModal
      open={addAlertConfigModalOpen}
      onSave={async (dto: AddAlertConfigByValueTemplateRequestDto) => {
        setIsLoading(true);
        try {
          await addAlertConfig(dto);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAddAlertConfigModalOpen(false);
        setIsLoading(false);
      }}
      handleClose={() => setAddAlertConfigModalOpen(false)}
      item={addAlertConfigModalOpen}
      currentLanguage={currentLanguage}
    />
  );

  const alertConfigEditForm = alertConfigToEdit && (
    <UpdateAlertConfigByValueModal
      item={alertConfigToEdit}
      open={Boolean(alertConfigToEdit)}
      handleClose={() => setAlertConfigToEdit(null)}
      onSave={async (dto: UpdateAlertConfigByValueTemplateRequestDto) => {
        setIsLoading(true);
        try {
          await onEdit(alertConfigToEdit.id, dto);
        } catch (err: any) {
          toast.error(
            `${t("ErrorCreate")}: ${err.response?.data?.message || err.message}`
          );
        }
        setAlertConfigToEdit(null);
        setIsLoading(false);
      }}
    />
  );

  const handleOnEdit = (alertConfigTemplate: AlertConfigByValueDataRow) => {
    setAlertConfigToEdit(alertConfigTemplate);
  };

  const handleOnActivate = (alertConfigTemplate: AlertConfigByValueDataRow) => {
    const dto: UpdateAlertConfigByValueTemplateRequestDto =
      new UpdateAlertConfigByValueTemplateRequestDto();
    Object.assign(dto, alertConfigTemplate);
    if (alertConfigTemplate && alertConfigTemplate.id) {
      setIsLoading(true);
      try {
        dto.active = alertConfigTemplate.active ? false : true;
        onEdit(alertConfigTemplate.id, dto);
      } catch (err: any) {
        toast.error(err.message);
      }
      setIsLoading(false);
    }
  };

  const buttons = buttonsFx(setAddAlertConfigModalOpen);
  const alertsGridColumns = AlertConfigByValueTemplateGridColumns(
    t,
    handleOnEdit,
    handleOnActivate,
    currentLanguage
  );

  const alertsByValueDataRow: AlertConfigByValueDataRow[] =
    alertConfigTemplates.map(
      (alertConfig: ItemAlertConfigByValueTemplateDto) =>
        ({
          id: alertConfig.id,
          device: alertConfig.sign?.device,
          sign: alertConfig.sign,
          label: alertConfig.label,
          color: alertConfig.color,
          min: alertConfig.min,
          max: alertConfig.max,
          range_value: alertConfig.range_value,
          alert_type: alertConfig.alert_type,
          range_time: alertConfig.range_time,
          alertGroups: alertConfig.alertGroups,
          valueText: alertConfig.valueText,
          active: alertConfig.active,
        } as AlertConfigByValueDataRow)
    ) ?? [];

  const setRowClassName = (
    params: GridRowClassNameParams<AlertConfigByValueDataRow>
  ): string => {
    if (!params.row.active) {
      return "no-actived-alert-config";
    }
    return "";
  };

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {buttons}
      <FlexLayoutGrid
        language={i18n.language}
        columns={alertsGridColumns as any}
        rows={alertsByValueDataRow}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid() as any}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationByValue}
      />
      {alertConfigCreateForm}
      {alertConfigEditForm}
    </Box>
  );
};
