import React from "react";
import { SwitchSave } from "@doctomatic/components-react/build/Switch/SwitchSave";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sortText } from "../../App/sortFunctions";
import SubscribersDataRow from "./SubscribersDataRow";

export interface ISubscribersGridColumnsProps {
  itemId: number;
  subscribe: (id: number, actorId: number) => Promise<void>;
  unsubscribe: (id: number, actorId: number) => Promise<void>;
}

export const SubscribersGridColumns = ({
  itemId,
  subscribe,
  unsubscribe,
}: ISubscribersGridColumnsProps): GridColDef<SubscribersDataRow>[] => {
  const { t } = useTranslation();

  return [
    {
      field: "name",
      headerName: t("Name"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{params.row.name}</p>;
      },
    },
    {
      field: "group",
      headerName: t("Group"),
      width: 200,
      valueGetter: (value: any, row: any) => t(row.group),
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params) => {
        return <p>{t(params.row.group)}</p>;
      },
    },
    {
      field: "subscribed",
      headerName: t("Subscribed"),
      width: 200,
      valueGetter: (value: any, row: any) => row.subscribed,
      renderCell: (params) => {
        const toggleSubscribe = async (itemId: number, value: boolean) => {
          if (value) {
            try {
              await subscribe(itemId, params.row.id);
            } catch (err: any) {
              toast.error(`${t("ErrorSubscribeAlertConfig")}: ${err.message}`);
            }
          } else {
            try {
              await unsubscribe(itemId, params.row.id);
            } catch (err: any) {
              toast.error(
                `${t("ErrorUnsubscribeAlertConfig")}: ${err.message}`
              );
            }
          }
        };

        return (
          <SwitchSave
            updateDb={toggleSubscribe}
            item={itemId}
            initialValue={params.row.subscribed}
          />
        );
      },
    },
  ];
};
