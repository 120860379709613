import { IItemReadDeviceResponseDto } from "../../dto/ReadDevice/IReadDevice";
import { ResponseApiList, TypeOnError } from "../../global";
import { CreateAction, IUseBase, useInstance } from "../IUseBaseElements";

const createQueryFilters = (
  query?: any,
  patientId?: number,
  deviceId?: number,
  date1?: string,
  date2?: string
): string => {
  let queryFilters = "";
  let simbol = "?";

  if (patientId) {
    queryFilters += `${simbol}patientId=${patientId}`;
    simbol = "&";
  }

  if (deviceId) {
    queryFilters += `${simbol}deviceId=${deviceId}`;
    simbol = "&";
  }

  if (date1) {
    queryFilters += `${simbol}date1=${date1}`;
    simbol = "&";
  }

  if (date2) {
    queryFilters += `${simbol}date2=${date2}`;
    simbol = "&";
  }

  if (query) {
    queryFilters += `${simbol}limit=${query.limit}&page=${query.page}`;
    if (query.sortBy && query.sortBy[0]) {
      queryFilters = `${queryFilters}&field=${
        query.sortBy[0]["field"]
      }&sort=${query.sortBy[0]["sort"].toUpperCase()}`;
    }
    simbol = "&";
  }

  return queryFilters;
};

export interface IUseLastReadDevices
  extends IUseBase<ResponseApiList<IItemReadDeviceResponseDto>> {
  findPaginatedByPatientId: (
    patientId: number,
    query?: any,
    deviceId?: number,
    date1?: string,
    date2?: string
  ) => Promise<ResponseApiList<IItemReadDeviceResponseDto>>;
}

export function useLastReadDevices(
  baseURL: string,
  token: string,
  patientId?: number,
  query?: any,
  deviceId?: number,
  date1?: string,
  date2?: string,
  shouldFetch?: boolean,
  onError?: TypeOnError
): IUseLastReadDevices {
  const queryFilters = createQueryFilters(
    query,
    patientId,
    deviceId,
    date1,
    date2
  );

  const path = `/read_device${queryFilters}`;

  const swrResponse: IUseBase<ResponseApiList<IItemReadDeviceResponseDto>> =
    useInstance<ResponseApiList<IItemReadDeviceResponseDto>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );

  const findPaginatedByPatientId = async (
    patientId: number,
    query?: any,
    deviceId?: number,
    date1?: string,
    date2?: string
  ): Promise<ResponseApiList<IItemReadDeviceResponseDto>> => {
    const queryFilters = createQueryFilters(
      query,
      patientId,
      deviceId,
      date1,
      date2
    );

    const path = `/read_device${queryFilters}`;

    const findPaginatedByPatientId = CreateAction<
      ResponseApiList<IItemReadDeviceResponseDto>,
      boolean,
      ResponseApiList<IItemReadDeviceResponseDto>
    >(baseURL, token, swrResponse.mutate, "get", path, onError);

    return findPaginatedByPatientId();
  };

  return {
    ...swrResponse,
    findPaginatedByPatientId,
  };
}
