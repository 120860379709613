import { sortText } from "../../../../App/sortFunctions";
import { SelectMultipleGridColumnsProps } from "@doctomatic/components-react/build/SelectModal/SelectMultipleGridColumns";
import { AlertBox } from "@doctomatic/components-react/build/Alerts/AlertBox";
import { ItemAlertConfigByNoMeasureTemplateDto } from "@doctomatic/sdk/build/dto/Alerts/config/template/AlertConfigByNoMeasureTemplate";
import { getDeviceName } from "../../utils";

export const AlertConfigByNoMeasureTemplateListDataGridColumns = (
  t: (text: string) => string,
  selectEvent: (
    dataRow: ItemAlertConfigByNoMeasureTemplateDto,
    checked: boolean
  ) => void,
  selectedItems: ItemAlertConfigByNoMeasureTemplateDto[],
  currentLanguage: string
): SelectMultipleGridColumnsProps<ItemAlertConfigByNoMeasureTemplateDto> => {
  return {
    columns: [
      {
        field: "device",
        headerName: t("Device"),
        width: 200,
        align: "left",
        valueGetter: (value: any, row: any) => t(row.device),
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => {
          return <div>{getDeviceName(currentLanguage, params.row.device, t)}</div>;
        },
      },
      {
        field: "label",
        headerName: t("Name"),
        width: 200,
        align: "left",
      },
      {
        field: "max_time_between",
        headerName: t("AlertAfter"),
        width: 100,
        valueGetter: (value: any, row: any) => row.max_time_between,
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params) => {
          return <div>{params.row.max_time_between}</div>;
        },
      },
      {
        field: "color",
        headerName: t("Level"),
        width: 100,
        valueGetter: (value: any, row: any) => row.color,
        sortComparator: (v1: string, v2: string) => sortText(v1, v2),
        renderCell: (params) => (
          <AlertBox id={params.row.id} color={params.row.color}></AlertBox>
        ),
      },
    ],
    selectEvent: selectEvent,
    selectedItems: selectedItems,
  };
};
