import {
  GetActiveUsersByDayResponseDto,
  GetCountUsersByRoleResponseDto,
  GetReadDeviceByDayCompanyResponseDto,
  GetReadDeviceByDayResponseDto,
  GetTotalCountUsersResponseDto,
  GetTranslateByDayResponseDto,
} from "../dto/CmsDashboard/CmsDashboard";
import { ResponseApi, TypeOnError } from "../global";
import { IUseBase, useInstance } from "./IUseBaseElements";
import { Symbol } from "../utils/query/query.enum";
import { DataRange } from "../utils/query/filter.interface";

export interface IUseCmsDashboard
  extends IUseBase<ResponseApi<GetTotalCountUsersResponseDto>> {
  countUsersByRole: IUseBase<ResponseApi<GetCountUsersByRoleResponseDto[]>>;
  activeUsersByDay: IUseBase<ResponseApi<GetActiveUsersByDayResponseDto[]>>;
  readDeviceByDay: IUseBase<ResponseApi<GetReadDeviceByDayResponseDto[]>>;
  translateByDay: IUseBase<ResponseApi<GetTranslateByDayResponseDto[]>>;
  readDeviceByDayCompany: IUseBase<ResponseApi<GetReadDeviceByDayCompanyResponseDto[]>>;
}

export function useCmsDashboard(
  baseURL: string,
  token: string,
  companyId?: number,
  initDate?: string,
  finDate?: string,
  deviceId?: number,
  timeZone?: number,
  shouldFetch?: boolean,
  patientsDeleted?: boolean,
  showExternalReadDevice?: boolean,
  idDeviceInit?: number,
  idDeviceEnd?:number,
  onError?: TypeOnError
): IUseCmsDashboard {
  let path = `/cmsdashboard/countUsers`;
  let queries = "";
  let char: Symbol = Symbol.FIRST;

  if (companyId) {
    queries += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }

  if (patientsDeleted) {
    queries += `${char}withDeleted=${patientsDeleted}`;
    char = Symbol.AND;
  }
  if (initDate && finDate)
    queries += `${char}initDate=${initDate}&finDate=${finDate}`;
  path += queries;
  const swrResponse: IUseBase<ResponseApi<GetTotalCountUsersResponseDto>> =
    useInstance<ResponseApi<GetTotalCountUsersResponseDto>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );

  path = `/cmsdashboard/countUsersByRole`;
  queries = "";
  char = Symbol.FIRST;

  if (companyId) {
    queries += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }
  if (patientsDeleted) {
    queries += `${char}withDeleted=${patientsDeleted}`;
    char = Symbol.AND;
  }
  if (initDate && finDate)
    queries += `${char}initDate=${initDate}&finDate=${finDate}`;
  path += queries;
  const countUsersByRole: IUseBase<
    ResponseApi<GetCountUsersByRoleResponseDto[]>
  > = useInstance<ResponseApi<GetCountUsersByRoleResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  path = `/cmsdashboard/activeUsersByDay`;
  queries = "";
  char = Symbol.FIRST;

  if (companyId) {
    queries += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }
  if (patientsDeleted) {
    queries += `${char}withDeleted=${patientsDeleted}`;
    char = Symbol.AND;
  }
  if (initDate && finDate)
    queries += `${char}initDate=${initDate}&finDate=${finDate}`;
  path += queries;
  const activeUsersByDay: IUseBase<
    ResponseApi<GetActiveUsersByDayResponseDto[]>
  > = useInstance<ResponseApi<GetActiveUsersByDayResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  path = `/cmsdashboard/readDeviceByDay`;
  queries = "";
  char = Symbol.FIRST;

  if (companyId) {
    queries += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }
  if (patientsDeleted) {
    queries += `${char}withDeleted=${patientsDeleted}`;
    char = Symbol.AND;
  }
  if (showExternalReadDevice) {
    queries += `${char}showExternalReadDevice=${showExternalReadDevice}`;
    char = Symbol.AND;
  }
  if (initDate && finDate) {
    queries += `${char}initDate=${initDate}&finDate=${finDate}`;
    char = Symbol.AND;
  }
  if (deviceId) {
    queries += `${char}deviceId=${deviceId}`;
    char = Symbol.AND;
  }
  if (timeZone) {
    queries += `${char}timeZone=${timeZone}`;
    char = Symbol.AND;
  }
  if (idDeviceInit && idDeviceEnd) {
    queries += `${char}idDeviceInit=${idDeviceInit}&idDeviceEnd=${idDeviceEnd}`;
    char = Symbol.AND;
  }
  path += queries;
  const readDeviceByDay: IUseBase<
    ResponseApi<GetReadDeviceByDayResponseDto[]>
  > = useInstance<ResponseApi<GetReadDeviceByDayResponseDto[]>>(
    baseURL,
    token,
    shouldFetch ? path : null,
    onError
  );

  path = `/cmsdashboard/translateByDay`;
  queries = "";
  char = Symbol.FIRST;

  if (companyId) {
    queries += `${char}companyId=${companyId}`;
    char = Symbol.AND;
  }
  if (patientsDeleted) {
    queries += `${char}withDeleted=${patientsDeleted}`;
    char = Symbol.AND;
  }
  if (initDate && finDate)
    queries += `${char}initDate=${initDate}&finDate=${finDate}`;
  path += queries;
  const translateByDay: IUseBase<ResponseApi<GetTranslateByDayResponseDto[]>> =
    useInstance<ResponseApi<GetTranslateByDayResponseDto[]>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );

    path = `/cmsdashboard/readDeviceByDayCompany`;
    queries = "";
    char = Symbol.FIRST;
  
    if (companyId) {
      queries += `${char}companyId=${companyId}`;
      char = Symbol.AND;
    }
    if (patientsDeleted) {
      queries += `${char}withDeleted=${patientsDeleted}`;
      char = Symbol.AND;
    }
    if (showExternalReadDevice) {
      queries += `${char}showExternalReadDevice=${showExternalReadDevice}`;
      char = Symbol.AND;
    }
    if (initDate && finDate) {
      queries += `${char}initDate=${initDate}&finDate=${finDate}`;
      char = Symbol.AND;
    }
    if (deviceId) {
      queries += `${char}deviceId=${deviceId}`;
      char = Symbol.AND;
    }
    if (timeZone) {
      queries += `${char}timeZone=${timeZone}`;
      char = Symbol.AND;
    }
    if (idDeviceInit && idDeviceEnd) {
      queries += `${char}idDeviceInit=${idDeviceInit}&idDeviceEnd=${idDeviceEnd}`;
      char = Symbol.AND;
    }
    path += queries;
    const readDeviceByDayCompany: IUseBase<
      ResponseApi<GetReadDeviceByDayCompanyResponseDto[]>
    > = useInstance<ResponseApi<GetReadDeviceByDayCompanyResponseDto[]>>(
      baseURL,
      token,
      shouldFetch ? path : null,
      onError
    );

  return {
    ...swrResponse,
    countUsersByRole,
    activeUsersByDay,
    readDeviceByDay,
    translateByDay,
    readDeviceByDayCompany
  };
}
