import { GridColDef } from "@mui/x-data-grid";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link } from "react-router-dom";
import { ItemPatientListExtendedResponseDto } from "@doctomatic/sdk/build/dto/Patient/Patient";
import PatientDataRow from "./PatientDataRow";
import { sortText } from "../../../../App/sortFunctions";

export const PatientListGridColumns = (
  showExternalId: boolean = false,
  t: (text: string) => string,
  actions: (user: ItemPatientListExtendedResponseDto) => JSX.Element
): GridColDef<PatientDataRow>[] => {
  const gridColumns: GridColDef<PatientDataRow>[] = [
    {
      field: "name",
      headerName: t("HeaderName"),
      width: 200,
      align: "left",
    },
    {
      field: "locations",
      headerName: t("HeaderLocations"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: PatientDataRow) => {
        const words: string[] =
          row.locations && row.locations.length > 0
            ? row.locations
            : [t("GoToLocations")];
        return words.join(", ");
      },
      sortable: false,
      renderCell: (params: { row: PatientDataRow }) => {
        const page: string = BreadcrumbNameMap.LocationsUser.url
          .replace(":role", "patients")
          .replace(":actorId", params.row.id.toString());
        const words: string[] =
          params.row.locations && params.row.locations.length > 0
            ? params.row.locations
            : [t("GoToLocations")];
        if (params.row.deletedAt) return <></>;
        return (
          <TextWordsQuantityCell
            routerLink={Link}
            item={params.row}
            page={page}
            words={words}
            tooltipContent={{
              title: t("Locations"),
              lines: params.row.locations,
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params: { row: PatientDataRow }) =>
        actions(params.row as unknown as ItemPatientListExtendedResponseDto),
    },
  ];

  if (showExternalId) {
    gridColumns.unshift({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: PatientDataRow) =>
        row.externalId ? row.externalId : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: PatientDataRow }) => (
        <p>{params.row.externalId ? params.row.externalId : "--"}</p>
      ),
    });
  }

  return gridColumns;
};
