import { SwitchSave } from "@doctomatic/components-react/build/Switch/SwitchSave";
import {
  IconButton,
  ListItem,
  Popover,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import UserLocationDataRow from "./UserLocationDataRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IUpdateLocationRequestDto } from "@doctomatic/sdk/build/dto/Location/ILocation";

interface PopoverConfig {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onEdit: (id: number, locationToEdit: IUpdateLocationRequestDto) => void;
  onDelete: (id: number) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export const UserLocationGridColumns = (
  t: (text: string) => string,
  component: string,
  toggleAssign?: (item: any, assigned: boolean) => void,
  popoverConf?: PopoverConfig
): GridColDef<UserLocationDataRow>[] => {
  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  };

  const actionsButton = {
    field: "Actions",
    headerName: t("Actions"),
    width: 200,
    filterable: false,
    sortable: false,
    renderCell: (params: { row: any }) => {
      return (
        <>
          <IconButton onClick={(event) => openPopover(event, params.row)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={`m${params.row.id}`}
            open={popoverConf?.popoverId === `m${params.row.id}`}
            anchorEl={popoverConf?.anchorEl}
            onClose={popoverConf?.onClose}
          >
            <List>
              <ListItem>
                <ListItemText primary={t("LocationListView")} />
              </ListItem>
              <ListItemButton
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onEdit(params.row.id, {
                    name: params.row.name,
                    description: params.row.description,
                  });
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("ButtonEdit")} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  popoverConf?.onClose();
                  popoverConf?.onDelete(params.row.id);
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={t("LocationListDelete")} />
              </ListItemButton>
            </List>
          </Popover>
        </>
      );
    },
  };

  const gridColumns: GridColDef<UserLocationDataRow>[] = [
    {
      field: "name",
      flex: 1,
      headerName: t("Name"),
      width: 200,
      align: "left",
    },
  ];
  if (component === "switchComponent") {
    gridColumns.push({
      field: "locations",
      headerName: t("Locations"),
      width: 200,
      flex: 1,
      valueGetter: (value: any, row: any) => row.assigned,
      renderCell: (params: GridCellParams) => {
        return (
          <SwitchSave
            initialValue={params.row.assigned}
            updateDb={toggleAssign}
            item={params.row}
          ></SwitchSave>
        );
      },
    });
  }

  if (component === "descriptionComponent") {
    gridColumns.push(
      {
        field: "description",
        headerName: t("Description"),
        resizable: true,
        flex: 1,
        align: "left",
      },
      actionsButton
    );
  }

  if (component === "accessComponent") {
    gridColumns.push(
      {
        field: "locations",
        headerName: t("Locations"),
        width: 300,
        flex: 1,
        valueGetter: (value: any, row: any) => (row.inheritance ? "Yes" : "No"),
        sortComparator: (v1: any, v2: any) =>
          v1!.toString().localeCompare(v2!.toString()),
        renderCell: (params: {
          row: { id: number; name: string; inheritance?: boolean };
        }) => {
          return <p>{params.row.inheritance ? "Yes" : "No"}</p>;
        },
      },
      actionsButton
    );
  }

  return gridColumns;
};
