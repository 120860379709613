import type { UpdateDeviceCompanyRequestDto } from "../dto/Devices";
import { ResponseApi, TypeOnError } from "../global";
import { CreateActionWithoutMutate } from "./IUseBaseElements";

export interface IUseDeviceCompany {
  put: (
    deviceId: number,
    dto: UpdateDeviceCompanyRequestDto
  ) => Promise<ResponseApi<any>>;
  remove: (deviceId: number) => Promise<ResponseApi<any>>;
}

export function useDeviceCompany(
  baseURL: string,
  token: string,
  companyId: number,
  onError?: TypeOnError
): IUseDeviceCompany {
  const put = async (
    deviceId: number,
    dto: UpdateDeviceCompanyRequestDto
  ): Promise<ResponseApi<any>> => {
    let path: string = `/devices/${deviceId}/company/${companyId}`;
    const action = CreateActionWithoutMutate<ResponseApi<any>, any>(
      baseURL,
      token,
      "put",
      path,
      onError
    );
    return action(dto);
  };

  const remove = async (deviceId: number): Promise<ResponseApi<any>> => {
    let path: string = `/devices/${deviceId}/company/${companyId}`;
    const action = CreateActionWithoutMutate<ResponseApi<any>, any>(
      baseURL,
      token,
      "delete",
      path,
      onError
    );
    return action();
  };
  return {
    put,
    remove,
  };
}
