import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GroupPermissionEditDataRow } from "../GroupPermissionEdit/GroupPermissionEditDataRow";

import { Switch } from "@mui/material";
import { ChangeEvent } from "react";

export const GroupPermissionEditGridColumns = (
  columnNameName: string,
  columnNameAssigned: string,
  onChangeSwitch: (id: number, event: ChangeEvent<HTMLInputElement>) => void
): GridColDef[] => {
  //TODO add viewModals
  return [
    {
      field: "name",
      headerName: columnNameName,
      width: 200,
      align: "left",
    },
    {
      field: "asigned",
      headerName: columnNameAssigned,
      width: 100,
      renderCell: (
        params: GridRenderCellParams<GroupPermissionEditDataRow>
      ) => {
        return (
          <Switch
            onChange={(event) => {
              onChangeSwitch(params.row.id, event);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
  ];
};
