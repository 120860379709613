import { GridColDef } from "@mui/x-data-grid";
import TreatmentsDataRow from "../TreatmentsDataRow";
import { sortText } from "../../../../App/sortFunctions";
import {
  TreatmentsGridColumns,
  TreatmentsGridColumnsProps,
} from "../TreatmentsGridColumns";
import { TextWordsQuantityCell } from "@doctomatic/components-react/build/TextWordsQuantity/TextWordsQuantityCell";
import { BreadcrumbNameMap } from "../../../../breadcrumbMap";
import { Link } from "react-router-dom";

export interface TreatmentsDoctorNurseGridColumnsProps
  extends TreatmentsGridColumnsProps {
  showExternalId?: boolean | undefined;
}

export const TreatmentsDoctorNurseGridColumns = ({
  t,
  timezone,
  popoverConf,
  showExternalId,
}: TreatmentsDoctorNurseGridColumnsProps): GridColDef<TreatmentsDataRow>[] => {
  const columns = TreatmentsGridColumns({
    t,
    timezone,
    popoverConf,
  }) as GridColDef<TreatmentsDataRow>[];
  columns.unshift({
    field: "patient",
    headerName: t("PatientNameColumn"),
    width: 200,
    align: "left",
    valueGetter: (value: any, row: TreatmentsDataRow) => row.patient.name,
    sortComparator: (v1: string, v2: string) => sortText(v1, v2),
    renderCell: (params: { row: TreatmentsDataRow }) => (
      <TextWordsQuantityCell
        routerLink={Link}
        page={BreadcrumbNameMap.Patient.url.replace(
          ":patientId",
          params.row.patient.id.toString()
        )}
        item={params.row}
        words={[params.row.patient.name]}
      ></TextWordsQuantityCell>
    ),
  });
  if (showExternalId) {
    columns.unshift({
      field: "externalId",
      headerName: t("ExternalId"),
      width: 200,
      align: "left",
      valueGetter: (value: any, row: TreatmentsDataRow) =>
        row.patient.externalId ? row.patient.externalId : "--",
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: TreatmentsDataRow }) => {
        return (
          <div>
            {params.row.patient.externalId
              ? params.row.patient.externalId
              : "--"}
          </div>
        );
      },
    });
  }

  return columns;
};
