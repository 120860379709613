import { Switch, SwitchProps } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

interface ColoredSwitchProps extends SwitchProps {
  white?: boolean;
}

export const ColoredSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "white", // Evita que 'white' se pase a DOM
})<ColoredSwitchProps>(({ theme, white }) => {
  const color = theme.palette.primary.main;
  const trackBackground = white ? "#FFFFFF" : color;

  return {
    "& .MuiSwitch-switchBase": {
      color: color,
      "&.Mui-checked": {
        color: color,
        "&:hover": {
          backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: trackBackground,
      opacity: white ? 1 : 0.5,
      ".Mui-checked.Mui-checked + &": {
        backgroundColor: trackBackground,
        opacity: white ? 1 : 0.7,
      },
    },
  };
});
