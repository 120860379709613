var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { HtmlTooltipLines } from '../Tooltips/HtmlTooltip';
var AddToolTip = function (title, lines, element) {
    return HtmlTooltipLines(title, lines, element);
};
/**
 * Element to manage a string
 * @param text text to manage
 * @param onSave
 * @returns
 */
export function ElementGridStringEdit(_a) {
    var _this = this;
    var text = _a.text, onClose = _a.onClose, onSave = _a.onSave, titleDialog = _a.titleDialog, textContextDialog = _a.textContextDialog, textLabelField = _a.textLabelField, textCancel = _a.textCancel, textSave = _a.textSave, itemToAddCommentId = _a.itemToAddCommentId, alertType = _a.alertType;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var _d = React.useState(text), newText = _d[0], setNewText = _d[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
        onClose();
    };
    var handleSave = function (id, actualText, type) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOpen(false);
                    setLoading(true);
                    return [4 /*yield*/, onSave(id, actualText, type)];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", null,
        text && loading && AddToolTip(titleDialog ? titleDialog : "", [text], React.createElement(CircularProgress, { size: 25 })),
        text && !loading && AddToolTip(titleDialog ? titleDialog : "", [text], React.createElement(IconButton, { color: "default", onClick: handleClickOpen },
            React.createElement(EditIcon, null))),
        !text && React.createElement(IconButton, { color: "default", onClick: handleClickOpen },
            React.createElement(AddIcon, null)),
        React.createElement(Dialog, { open: open, onClose: handleClose, fullWidth: true },
            titleDialog && React.createElement(DialogTitle, null, titleDialog),
            React.createElement(DialogContent, null,
                textContextDialog && React.createElement(DialogContentText, null, textContextDialog),
                React.createElement(TextField, { multiline: true, maxRows: 4, inputProps: { maxLength: 254 }, autoFocus: true, margin: "dense", id: "name", label: textLabelField, type: "text", fullWidth: true, variant: "standard", defaultValue: text, onChange: function (event) { setNewText(event.target.value); } })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose }, textCancel ? textCancel : "Cancel"),
                React.createElement(Button, { onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        handleSave(itemToAddCommentId, newText, alertType);
                        return [2 /*return*/];
                    }); }); } }, textSave ? textSave : "Save")))));
}
